import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close, Edit, Upload } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { setResponseValue } from "../../../../redux/features/api-response/api-response";
import { setListDataChange } from "../../../../redux/features/client-main/client-list-slice";
import {
  setClientBasicDetails,
  setEditClient,
  setInitialState,
  updateClientBasicDetailById,
} from "../../../../redux/features/client-main/client-slice";
import { getClientBoards } from "../../../../redux/features/client-main/client-board-slice";

import { uploadClientImage } from "../../../../service/client.service";

import ClientBasicDetailsForm from "../../client-stepper/ClientBasicDetails";
import Loader from "../../../../layouts/loader/Loader";

import { isValidFileUploaded } from "../../../../utils/Image-validation";
import { ClientBasicDetails } from "../../../../types/client";

import ClientImage from "../../../../assets/images/images-svg/client-large.svg";

import "../ClientDetails.scss";

export interface ClientSubBasicDetailsProps {
  clientBasicDetails: ClientBasicDetails;
}

const ClientSubBasicDetails: React.FC<ClientSubBasicDetailsProps> = ({
  clientBasicDetails,
}) => {
  // #region variables region
  //image error
  const [imageError, setImageError] = useState<string>("");
  //form handler for edit or new
  const { isEditClient } = useAppSelector((state) => state.client);

  //handles the edit for client basic details
  const [editBasicInfo, setEditBasicInfo] = useState<boolean>(false);

  //for loader component
  // const [isLoading, setIsLoading] = useState<boolean>(true);
  const isLoading = useAppSelector((state) => state.responseSlice.pending);

  const dispatch = useAppDispatch();

  // #endregion

  // #region methods region

  //handles the data setting in redux for client basic details
  const handleEditBasicClick = () => {
    dispatch(setClientBasicDetails({ ...clientBasicDetails, isEdit: true }));
    dispatch(setEditClient(true));
    setEditBasicInfo(true);
  };

  //handles profile picture upload
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      // dispatch(setProfilePicture(null));
    } else if (isValidFileUploaded(e.target.files[0])) {
      setImageError("");
      try {
        dispatch(setResponseValue({ name: "pending", value: true }));
        const data = await uploadClientImage(
          e.target.files[0],
          clientBasicDetails.id as string
        );
        if (data.status) {
          dispatch(getClientBoards({ useLoader: false }));
          dispatch(setListDataChange());
          dispatch(setResponseValue({ name: "success", value: true }));
          dispatch(setResponseValue({ name: "message", value: data.message }));
        }
      } catch (e: any) {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: e?.response?.data?.message,
          })
        );
      } finally {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    } else {
      setImageError("Please Select Valid Image");
    }
  };

  //handles the closing of basic details form
  const handleCloseForBasicDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setEditBasicInfo(false);
  };

  //handles the edit form submission for client basic details
  const onSubmitBasicInfo = () => {
    dispatch(updateClientBasicDetailById({}));
    setEditBasicInfo(false);
    dispatch(setEditClient(false));
  };

  // #endregion

  return (
    <Grid item xs={12} lg={6} xl={4}>
      <Box className="detailsCard BasicInfoBox-client">
        <Box className="detailsCard__header">
          <Typography className="detailsCard__title">Client Basic</Typography>
          <IconButton
            onClick={() => handleEditBasicClick()}
            className="detailsCard__headBtn"
            size="small"
            edge="start"
          >
            <Edit />
          </IconButton>
        </Box>
        <Box className="detailsCard__body">
          <div className="userBasicInfoBox" style={{ position: "relative" }}>
            <div className="userBasicInfoBox__img">
              <Box className="uploadProfile">
                <div className="uploadProfile__img">
                  {isLoading ? (
                    <Loader isShow={isLoading} />
                  ) : (
                    <img
                      src={
                        clientBasicDetails?.clientProfile?.url || ClientImage
                      }
                      className="img-fluid"
                      alt="image_not_found"
                    />
                  )}
                </div>
                <Button
                  variant="contained"
                  component="label"
                  className="uploadProfile__btn"
                  startIcon={<Upload />}
                >
                  Update Profile Picture
                  <input
                    type="file"
                    hidden
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileChange(e)
                    }
                  />
                </Button>
              </Box>
              <FormHelperText
                style={{
                  color: "#d32f2f",
                  position: "absolute",

                  left: "10px",
                }}
              >
                {imageError}
              </FormHelperText>
            </div>
            <div className="userBasicInfoBox__details">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Client's Name
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientBasicDetails?.childFirstName +
                        "  " +
                        clientBasicDetails?.childLastName}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Child's Gender
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientBasicDetails?.childGender}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Child's DOB
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientBasicDetails?.childDateOfBirth
                        ? clientBasicDetails?.childDateOfBirth
                        : null}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Child's Languages
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientBasicDetails?.childLanguages.join(" , ")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Parent's Name
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientBasicDetails?.parentFirstName +
                        "  " +
                        clientBasicDetails?.parentLastName}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Case Supervisor
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientBasicDetails !== undefined
                        ? clientBasicDetails.supervisor
                          ? clientBasicDetails?.supervisor?.firstName +
                            " " +
                            clientBasicDetails?.supervisor?.lastName
                          : "Not Yet Selected"
                        : ""}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </Box>
      {isEditClient && editBasicInfo ? (
        <>
          <Dialog open={editBasicInfo} className="largeModel largeModel--edit">
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client - Basic Information
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForBasicDetails}
              >
                <Close />
              </Button>
            </Box>
            <ClientBasicDetailsForm onSubmit={onSubmitBasicInfo} />
          </Dialog>
        </>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default ClientSubBasicDetails;
