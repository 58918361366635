import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setResponseValue } from "../api-response/api-response";
import BusinessSetting from "../../../service/business-setting.service";
import { ICommmunication } from "../../../types/businessSettings";
import { AllTemplates } from "../../../constants/parent-settings";
import DataCatalogService from "../../../service/dataCatalog.service";

const initialState: ICommmunication = {
  ...AllTemplates,
};

const communicationSlice = createSlice({
  name: "communicationSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetMessageTemplate.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          MessageTemplates: [...action.payload],
        };
      }
    });
    builder.addCase(getAllTemplateVariables.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          templateVariables: [...action.payload],
        };
      }
    });
  },
});

//fetches the business units
export const GetMessageTemplate = createAsyncThunk(
  "/template",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await BusinessSetting.getMessageTemplate();

      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//post parent settings form
export const PostMessageTemplateForm = createAsyncThunk(
  "/template",
  async (
    {
      name,
      audience,
      methods,
      from,
      message,
      subject,
      active,
      admins,
    }: {
      name: string;
      audience: string;
      methods: string;
      from: string;
      message: string;
      subject: string;
      active: boolean;
      admins: string[];
    },
    { getState, dispatch }
  ) => {
    const payloadData = {
      name: name,
      audience: audience,
      methods: methods,
      from: from,
      message: message,
      subject: subject,
      active: active,
      admins: admins,
    };

    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message, data } =
        await BusinessSetting.postMessageTemplateForm(payloadData);

      if (data) {
        dispatch(GetMessageTemplate());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.message ||" Error Occured" })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateMessageTemplateById = createAsyncThunk(
  "organization/business",
  async (
    {
      id,
      name,
      audience,
      methods,
      from,
      message,
      subject,
      active,
      admins,
    }: {
      id: string;
      name: string;
      audience: string;
      methods: string;
      from: string;
      message: string;
      subject: string;
      active: boolean;
      admins: string[];
    },
    { getState, dispatch }
  ) => {
    const payloadData = {
      name: name,
      audience: audience,
      methods: methods,
      from: from,
      message: message,
      subject: subject,
      active: active,
      admins: admins,
    };

    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } =
        await BusinessSetting.updateMessageTemplateById(id, payloadData);
      if (status) {
        dispatch(GetMessageTemplate());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      console.log("error:", e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//delete Message Template
export const deleteMessageTemplate = createAsyncThunk(
  "/template/",
  async (
    {
      id,
    }: {
      id: string;
    },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } =
        await BusinessSetting.deleteMessageTemplateById(id);
      if (status) {
        dispatch(GetMessageTemplate());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//For All TemplateVariables
export const getAllTemplateVariables = createAsyncThunk(
  "scheduleList/GetAllNonBillableActivity",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { data } = await DataCatalogService.GetAllTemplateVariables();
      return data;
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

// export const { setSearchKeyword, setOrganizationDataChange } =
//   communicationSlice.actions;

export default communicationSlice;
