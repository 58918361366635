import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ClientForms } from "../../../types/client";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import "../message-details/message-details.scss";
import { MailOutlineOutlined } from "@mui/icons-material";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAppSelector } from "../../../redux/store";
import { filterMessage } from "../../../helpers/message-filter-helper/messag-filter-helper";
import { MessageFilter } from "../../../types/message";
import { Tooltip } from "@mui/material";

interface MessageClientListProps {
  clientsListData: ClientForms[];
  selectedUser: { id: string; name: string };
  setSelectedUser: Dispatch<
    SetStateAction<{
      id: string;
      name: string;
      entityType: string;
    }>
  >;
  isMobileView: boolean;
  setIsMobileView: Dispatch<SetStateAction<boolean>>;
}

const MessageClientList: React.FC<MessageClientListProps> = ({
  clientsListData,
  selectedUser,
  setSelectedUser,
  isMobileView,
  setIsMobileView,
}) => {
  //#region variable region

  //handles copy state
  const [isCopied, setIsCopied] = useState(false);
  const searchValue = useAppSelector<string>(
    (state) => state.activeStep.searchValue
  );

  //variable to store filterValues
  const filterValues = useAppSelector<MessageFilter>(
    (state) => state.messageFilterSlice
  );

  const unreadUsers = useAppSelector<string[]>(
    (state) => state.messageSlice.unreadUserId
  );

  // #region methods region

  //handle selected Client
  const handleSelectClient = (ele: ClientForms) => {
    setSelectedUser({
      id: ele.clientBasicDetails.id,
      name:
        ele.clientBasicDetails.childFirstName +
        " " +
        ele.clientBasicDetails.childLastName,
      entityType: "Client",
    });
  };

  //handle copy to clipboard
  const handleCopyToClipboard = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  //#endregion

  //#region
  useEffect(() => {
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      if (width <= 886) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    });
    if (window.innerWidth <= 886 && !isMobileView) {
      setIsMobileView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window?.innerWidth >= 886 && clientsListData?.length > 0) {
      const data: any = {
        id: clientsListData[0]?.clientBasicDetails?.id,
        name:
          clientsListData[0]?.clientBasicDetails.childFirstName +
          " " +
          clientsListData[0]?.clientBasicDetails.childLastName,
        entityType: "Client",
      };
      setSelectedUser(data);
    }
    // eslint-disable-next-line
  }, [clientsListData[0]]);

  //#endregion
  // console.log("clientsListData:", clientsListData);

  return (
    <div className="mapUserList scroll-message">
      {clientsListData?.length > 0 &&
        filterMessage<ClientForms>(
          filterValues,
          searchValue,
          clientsListData
        ).map((item: ClientForms, index: number) => {
          return (
            <div
              className={
                selectedUser.id === item.clientBasicDetails.id
                  ? "fromGroup-chip-active-message"
                  : "messageUser"
              }
              key={index}
              onClick={() => {
                handleSelectClient(item);
              }}
            >
              <div className="cardLevelBlock">
                <table>
                  <thead className="map-thead">
                    <tr>
                      <th>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* blue-dot logic only base on unread In-mail now */}
                          {item?.clientBasicDetails?.messageCount > 0 ? (
                            <span
                              style={{
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                background: "#096dd9",
                                padding: "5px",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <span
                              style={{
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                background: "#096dd9",
                                padding: "5px",
                                visibility: "hidden",
                                marginRight: "10px",
                              }}
                            />
                          )}

                          <span className="userMapCart__img">
                            <img
                              style={{ minWidth: "30px" }}
                              src={
                                item?.clientBasicDetails?.clientProfile?.url ||
                                ClientImg
                              }
                              className="userSmallCircle"
                              alt=""
                            />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="userMapCart__name">
                          {item?.clientBasicDetails?.childFirstName}{" "}
                          {item?.clientBasicDetails?.childLastName}{" "}
                        </div>
                      </th>
                      <th>
                        <div className="userMapCart__contact">
                          <Tooltip
                            title={
                              isCopied ? (
                                "copied"
                              ) : (
                                <div className="userMapCart__contact">
                                  {item?.clientContactDetails?.phoneNumber}
                                  <ContentCopyIcon
                                    fontSize={"small"}
                                    onClick={() =>
                                      handleCopyToClipboard(
                                        item?.clientContactDetails?.phoneNumber
                                      )
                                    }
                                  />
                                </div>
                              )
                            }
                          >
                            <PhoneOutlinedIcon />
                          </Tooltip>

                          <Tooltip
                            title={
                              isCopied ? (
                                "copied"
                              ) : (
                                <div className="userMapCart__contact">
                                  {item?.clientContactDetails?.email}
                                  <ContentCopyIcon
                                    fontSize={"small"}
                                    onClick={() =>
                                      handleCopyToClipboard(
                                        item?.clientContactDetails?.email
                                      )
                                    }
                                  />
                                </div>
                              )
                            }
                          >
                            <MailOutlineOutlined />
                          </Tooltip>
                          {/* add message count here like 10+ or actual number */}
                          <div style={{ minWidth: "30px" }}>
                            {item?.clientBasicDetails?.messageCount}
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MessageClientList;
