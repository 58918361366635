import { Box, Typography } from "@mui/material";
import ABAEngineLogo from "../../../assets/images/logos/abaengine.png";

const SidePanel = () => {
  return (
    <Box className="sidepanel">
      <Box component={"img"} src={ABAEngineLogo} sx={{ width: "60%" }}></Box>
      <Box className="sodepanel-slogan">
        <Typography className="slogan">
          <Box component={"span"}>
            Elevate
          </Box>
          Efficiency,
        </Typography>
        <Typography className="slogan">
          <Box component={"span"}>
            Maximize
          </Box>
          Utilization,
        </Typography>
        <Typography className="slogan">
          <Box component={"span"}>
            Optimize
          </Box>
          Management.
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: "24px" }}>
          With ABA Engine, seamlessly enhance your Applied Behavior Analysis
          practice, from intake to provider scheduling.
        </Typography>
      </Box>
    </Box>
  );
};

export default SidePanel;
