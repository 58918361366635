import axios from "../axios/axios";
import { Board, BoardStage } from "../types/board";
import { SingleResponse } from "../types/response";

export const createNewBoard = async (newBoard: Board) => {
  try {
    const { data } = await axios.post<SingleResponse<any>>(
      `/customBoard`,
      newBoard
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateExistingBoard = async (updateBoard: Board) => {
  try {
    const { data } = await axios.put<SingleResponse<any>>(
      `/customBoard/${updateBoard.id}`,
      updateBoard
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getAllCustomBoards = async () => {
  try {
    const { data } = await axios.get<SingleResponse<Board[]>>("/customBoards");
    return data.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const deleteBoard = async (deleteBoard: Board) => {
  try {
    const { data } = await axios.delete<SingleResponse<any>>(
      `/customBoard/${deleteBoard.id}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getUserTemplate = async (): Promise<any> => {
  try {
    const data = await axios.get<SingleResponse<any[]>>(`/userTemplate`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getBoardCard = async (): Promise<any> => {
  try {
    const { data } = await axios.get<SingleResponse<any[]>>(`/getCards`);
    return data;
  } catch (e: any) {
    console.log(e, "error");
    throw e?.response?.data;
  }
};

export const postMoveCard = async (data: any): Promise<any> => {
  try {
    const { data: res } = await axios.post<SingleResponse<any>>(
      "/moveCards",
      data
    );
    return res;
  } catch (e) {
    return 0;
  }
};

export const getRuns = async (): Promise<any> => {
  try {
    const { data } = await axios.get<SingleResponse<any>>("/getRuns");
    return data;
  } catch (e) {
    return 0;
  }
};

export const getAutomation = async (): Promise<any> => {
  try {
    const { data } = await axios.get<SingleResponse<any>>("/automation");
    return data;
  } catch (e) {
    return 0;
  }
};

export const postAutomation = async (automation: any): Promise<any> => {
  try {
    const { data } = await axios.post<SingleResponse<any>>("/automation", {
      automation,
    });
    return data;
  } catch (e) {
    return 0;
  }
};

export const updateBoardIndex = async (updatedBoard: any): Promise<any> => {
  try {
    const { data } = await axios.put<SingleResponse<any>>("/updateBoard", {
      updatedBoard,
    });
    return data;
  } catch (e) {
    return 0;
  }
};

export async function postCardToBoardStage(
  entityType: string,
  entityId: string,
  stageId: string
) {
  try {
    const { data } = await axios.post<SingleResponse<BoardStage>>(
      "/boardCard",
      {
        entityType,
        entityId,
        stageId,
      }
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

//delete board card
export const deleteBoardCard = async (id: string) => {
  try {
    const { data } = await axios.delete("/boardCard/" + id);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const BoardService = {
  createNewBoard,
  getAllCustomBoards,
  // getAllAssessmentBoard,
  updateExistingBoard,
  deleteBoard,
  // updateStage,
  getUserTemplate,
  getBoardCard,
  postMoveCard,
  getRuns,
  getAutomation,
  postAutomation,
  postCardToBoardStage,
  deleteBoardCard,
};

export default BoardService;

/* Not Used Any more!!
export const getAllAssessmentBoard = async () => {
  try {
    const { data } = await axios.get<SingleResponse<Board[]>>("/assessmentBoard");
    return data.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateStage = async (boardInfo: any): Promise<any> => {
  try {
    const data = await axios.post<SingleResponse<any>>(
      `/updateUser`,
      boardInfo
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};
*/
