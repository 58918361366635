import { createSlice } from "@reduxjs/toolkit";
import { SchedulesInitialState } from "../../../constants/schedule";
import { MatchOption } from "../../../types/schedule";

const initialState: MatchOption = {
  ...SchedulesInitialState,
};

const matchSlice = createSlice({
  name: "matches",
  initialState: initialState,
  reducers: {
    setSchedulesMatchValues: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },

    setSelectedProviders: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    clearSchedulesMatchValues: (state, action) => {
      return {
        ...SchedulesInitialState,
      };
    },
    setRefreshValue: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  setSchedulesMatchValues,
  setSelectedProviders,
  clearSchedulesMatchValues,
  setRefreshValue,
} = matchSlice.actions;

export default matchSlice;
