import React, { useState } from "react";
import ReactHtmlParser from "html-react-parser";
import { isHtml } from "../../../constants/message";

interface IMessageformatter {
  message: string;
}

const MessageFormatter: React.FC<IMessageformatter> = ({ message }) => {
  const isHtmlMessage = isHtml(message);

  const [seeMore, setSeeMore] = useState(true);

  if (isHtmlMessage) {
    return (
      <div>
        <span
          onClick={() => setSeeMore((prev) => !prev)}
          style={{ color: "#096dd9" }}
        >
          {seeMore ? "Hide details..." : "See all details..."}
        </span>
        {seeMore && ReactHtmlParser(message)}
      </div>
    );
  }

  return (
    <>
      {/* {message.length > 200 && (
        <span
          onClick={() => setSeeMore((pre) => !pre)}
          style={{ color: "#096dd9" }}
        >
          {seeMore ? "Click to hide more..." : "Click to see more..."}
        </span>
      )} */}
      <div className={seeMore ? "" : "message-listingBlock"}>{message}</div>
    </>
  );
};

export default MessageFormatter;
