import axios from "../axios/axios";
import { DataCatalogConcepts } from "../constants/catalog";
//get all project
export const getAllProject = async () => {
  try {
    const { data } = await axios.get("/project");
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//get project by ID
export const getProjectById = async (id: string) => {
  try {
    const { data } = await axios.get(`/project/${id}`);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//create project
export const postProject = async (payloadData: {
  name: string;
  description: string;
}) => {
  try {
    const { data } = await axios.post("/project", payloadData);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//update project by Id
export const updateProjectbyId = async (
  payloadData: { name: string; description: string },
  id: string
) => {
  try {
    const { data } = await axios.put(`/project/${id}`, payloadData);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//delete project by Id
export const deleteProjectById = async (id: string) => {
  try {
    const { data } = await axios.delete(`/project/${id}`);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//get current User Task
export const getMyTasks = async () => {
  try {
    const { data } = await axios.get("/task");
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//creates Task
export const postTask = async (payloadData: any) => {
  try {
    const { data } = await axios.post("/task", payloadData);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//updates Task by Id
export const updateTaskById = async (id: string, payloadData: any) => {
  try {
    const { data } = await axios.put(`/task/${id}`, payloadData);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//delete Task by Id
export const deleteTaskById = async (id: string) => {
  try {
    const { data } = await axios.delete(`/task/${id}`);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//assign Task to user
export const patchTaskById = async (
  id: string,
  payload: { type: string; value: string }
) => {
  try {
    const { data } = await axios.patch(`/task/${id}`, payload);
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//for all providers type
export const getAllTaskType = async () => {
  try {
    const concept = DataCatalogConcepts.TaskType;
    const { data } = await axios.get(`/datacatalog/${concept}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const updateTaskOrder = async (orders: Record<string, number>) => {
  try {
    const { data } = await axios.patch(`/task/order`, { orders });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const TaskManagerService = {
  getAllProject,
  getProjectById,
  postProject,
  updateProjectbyId,
  deleteProjectById,
  getMyTasks,
  postTask,
  updateTaskById,
  deleteTaskById,
  patchTaskById,
  getAllTaskType,
  updateTaskOrder,
};

export default TaskManagerService;
