import { CardData } from "../../types/board";


export const canFilterBoards = (searchVal: string): boolean => {
  if (searchVal) {
    return true;
  }
  return false;
};

//logic for filtering a single provider and Client
export const filterSingleClient = (
  item:  CardData,
  searchVal: string
): boolean => {
  if (searchVal) {
    if (
      searchVal &&
      !item?.cardTitle
        .toLowerCase()
        .includes(searchVal.toLowerCase()) 
    ) {
      return false;
    }
  }

  return true;
};

//logic for filtering a single provider and Client
export const filterSingleProvider = (
  item: CardData,
  searchVal: string
): boolean => {
  if (searchVal) {
    if (
      searchVal &&
      !item?.cardTitle
        .toLowerCase()
        .includes(searchVal.toLowerCase())
    ) {
      return false;
    }
  }
  return true;
};

//return if an object is provider
function instanceOfA(object: any): object is CardData {
  return  object;
}

//logic for handling the search and filter
export const filterBoards = <T>(
  searchVal: string,
  data: T[]
): T[] => {
  return canFilterBoards( searchVal)
    ? data.filter((item: T) => {
        if (instanceOfA(item)) {
          return filterSingleProvider(item as CardData, searchVal);
        } else {
          return filterSingleClient(item as CardData, searchVal);
        }
      })
    : data;
};
