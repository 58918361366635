import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Switch,
  Typography,
  Button,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  Table,
  TableContainer,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  TableHead,
  Card,
  MenuItem,
  Select,
  Checkbox,
  TextField,
  FormControl,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
// import { OrgData } from "../../../types/company";
import jotformLogo from "../../../assets/images/images-svg/jotform-icon-transparent.svg";
import surveyLogo from "../../../assets/images/images-svg/SurveyIcon.svg";
import internetformLogo from "../../../assets/images/images-png/internet.png";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ParentAppForm from "./ParentAppForm";
import { BorderColor, Close } from "@mui/icons-material";
import {
  DuplicateFormDataById,
  UpdateFormDataById,
  deleteIntake,
} from "../../../redux/features/intake/intake-form-slice";
// import { IntakeFormList } from "../../../types/businessSettings";
import { useNavigate } from "react-router-dom";
import {
  GetFormCards,
  UpdateSessionFormCheckList,
  UpdateFormActiveById,
  UpdateSessionFormApplicable,
} from "../../../redux/features/forms/form-slice";
import { IFormCard } from "../../../types/forms";
import LaunchIcon from "@mui/icons-material/Launch";
import moment from "moment";
import { MenuProps } from "../../../constants/providers";
import { NewDataCatalog } from "../../../types/businessSettings";
import { DataCatalogConcepts } from "../../../constants/catalog";
// import { LinkSessionForm } from "../../../redux/features/data-catalog/data-catalog-slice";
import {
  BusinessFormGroup,
  BusinessFormType,
  DefaultSessionForm,
} from "../../../constants/forms";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ClearIcon from "@mui/icons-material/Clear";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useUser } from "@descope/react-sdk";
import { getRolesForTenant } from "../../../utils/getRolesForTenant";
// Custom Table
export const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

// Custom Table Col style
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

// Custom Table Row style
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    // height: "40px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  // minHeight: "50px",
}));

interface IBussinessForm {
  tabState: string;
}
const BusinessForm: React.FC<IBussinessForm> = ({ tabState }) => {
  //#region variable region
  //Dispatch
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  const { user, isUserLoading } = useUser();
  const currentTenantId = user?.customAttributes?.currentTenantId;
  const currentRoles = useMemo(() => {
    return getRolesForTenant(user, currentTenantId);
  }, [user, currentTenantId]);

  //track path
  const navigate = useNavigate();

  // Pop up Set for save Files
  const [editDialog, setEditDialog] = useState<boolean>(false);

  //handles the popup for editing Credential details
  const [editFormDetails, setEditFormDetails] = useState<boolean>(false);

  //store id
  const [intakeFormId, setIntakeFormId] = useState<string>("");

  //delete popup handler
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  //stores selected form type
  const [selectedFormType, setSelectedFormType] =
    useState<string>("Intake Packet Form");

  //filters form group
  const [filterFormGroup, setFilterGroup] = useState<string>(
    tabState || "Intake"
  );

  //store id
  const [parentformId, setParentFormID] = useState<string>("");

  //handles the popup for editing Credential details
  // eslint-disable-next-line
  const [addFormsDetails, setAddFormsDetails] = useState<boolean>(false);

  //open external form
  const [checklistPopup, setChecklistPopup] = useState<boolean>(false);
  const [formChecklists, setFormChecklist] = useState<{
    formId: string;
    checklists: string[];
  }>({
    formId: "",
    checklists: [],
  });
  const [newChecklist, setNewChecklist] = useState<string>("");

  //stores allForms
  const allForms = useSelector<RootState, IFormCard[]>(
    (state) => state.formSlice.AllForms
  );

  //fetch all catalogData
  const authList = useSelector<RootState, NewDataCatalog[]>((state) =>
    state.dataCatalogSlice.NewDataCatalog.filter(
      (item) => item.catalogName === DataCatalogConcepts.AuthCode
    )
  );

  const insuranceList = useSelector<RootState, NewDataCatalog[]>((state) =>
    state.dataCatalogSlice.NewDataCatalog.filter(
      (item) => item.catalogName === DataCatalogConcepts.Insurance
    )
  );
  const [linkedAuths, setLinkedAuth] = useState<
    {
      formId: string;
      authId: string;
      authValue: string;
    }[]
  >([]);

  const [linkedInsurance, setLinkedInsurance] = useState<
    { formId: string; insuranceId: string; insuranceName: string }[]
  >([]);

  //#endregion

  //#region method region
  const isAssessmentForm =(formType: string) => {
    return formType === BusinessFormType.AssessmentForm ||
      formType === BusinessFormType.ShareableAssessmentForm;
  }

  // handle active intake forms
  const handleSwitchChange = (item: IFormCard) => {
    dispatch(
      UpdateFormActiveById({
        id: item.id,
        value: !item.active,
      })
    );
  };

  // const handleTypeChange = (e: any) => {
  //   setSchoolAddressObj((prevState: any) => {
  //     return { ...prevState, [e.target.name]: e.target.value };
  //   });
  // };

  //for sorting data
  const sortData = (item: IFormCard[]) => {
    let data: IFormCard[] = [];
    if (item.length > 0) {
      let result = [...item].sort((a: IFormCard, b: IFormCard) => {
        return (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any);
      });
      data = [...result];
    }
    return data;
  };

  const getFilteredList = (list: IFormCard[], formGroup: string) => {
    let result: IFormCard[] = [];
    if (list && list.length > 0) {
      if (formGroup === BusinessFormGroup.Intake) {
        result = list.filter(
          (item) =>
            item.formType === BusinessFormType.IntakeClientForm ||
            item.formType === BusinessFormType.IntakePacketForm
        );
      } else if (formGroup === BusinessFormGroup.Onboarding) {
        result = list.filter(
          (item) =>
            item.formType === BusinessFormType.NewEmployeeForm ||
            item.formType === BusinessFormType.EmployeePacketForm
        );
      } else if (formGroup === BusinessFormGroup.Assessment) {
        result = list.filter(
          (item) => isAssessmentForm(item.formType) && item.active
        );
      } else if (formGroup === BusinessFormGroup.Session) {
        result = list.filter(
          (item) => item.formType === BusinessFormType.SessionForm
        );
      } else {
        result = list;
      }
    }
    return result;
  };

  //handles formType change
  const handleFormTypeChange = (item: IFormCard, value: string) => {
    dispatch(
      UpdateFormDataById({
        id: item.id,
        name: item?.name,
        jsonData: item.formSchema,
        formType: value,
      })
    );
  };

  // handle duplication form
  const handleDuplicateForm = (formId: string) => {
    dispatch(DuplicateFormDataById({ id: formId }));
  };

  const handleAuthChange = (value: string[], formId: string) => {
    const authToAdd = value.filter((authValue) => {
      return !linkedAuths.some(
        (item) => authValue === item.authValue && formId === item.formId
      );
    });

    if (authToAdd.length > 0) {
      const auths: { formId: string; authId: string; authValue: string }[] = [];
      authToAdd.forEach((authValue) => {
        const auth = authList.find((item) => item.conceptValue === authValue);
        if (auth) {
          auths.push({ formId, authId: auth.id, authValue: auth.conceptValue });
        }
      });
      setLinkedAuth((prevLinkedAuths) => [...prevLinkedAuths, ...auths]);
    } else {
      const filteredAuth = linkedAuths.filter(
        (item) => !(item.formId === formId && !value.includes(item.authValue))
      );
      setLinkedAuth(filteredAuth);
    }
  };

  const handleInsuranceChange = (value: string[], formId: string) => {
    const insuranceToAdd = value.filter((insurance) => {
      return !linkedInsurance.some(
        (item) => insurance === item.insuranceName && formId === item.formId
      );
    });

    if (insuranceToAdd.length > 0) {
      const insurances: {
        formId: string;
        insuranceId: string;
        insuranceName: string;
      }[] = [];
      insuranceToAdd.forEach((authValue) => {
        const insurance = insuranceList.find(
          (item) => item.conceptValue === authValue
        );
        if (insurance) {
          insurances.push({
            formId,
            insuranceId: insurance.id,
            insuranceName: insurance.conceptValue,
          });
        }
      });
      setLinkedInsurance((prevLinkedAuths) => [
        ...prevLinkedAuths,
        ...insurances,
      ]);
    } else {
      const filteredAuth = linkedInsurance.filter(
        (item) =>
          !(item.formId === formId && !value.includes(item.insuranceName))
      );
      setLinkedInsurance(filteredAuth);
    }
  };

  const handleApplicable = (formId: string) => {
    let selectedAuthIds: string[] = [];
    let selectedInsuranceIds: string[] = [];
    
    //authIds 
    selectedAuthIds = linkedAuths.reduce<string[]>((acc, item) => {
      if (item.formId === formId) acc.push(item.authId);
      return acc;
    }, []);

   selectedInsuranceIds = linkedInsurance.reduce<string[]>((acc, item) => {
      if (item.formId === formId) acc.push(item.insuranceId);
      return acc;
    }, []);

    dispatch(
      UpdateSessionFormApplicable({
        formId,
        insurances: selectedInsuranceIds,
        authCodes: selectedAuthIds,
      })
    );

    // dispatch(
    //   LinkSessionForm({
    //     formId,
    //     authId: selectedAuthIds,
    //     insuranceId: selectedInsuranceIds,
    //     linkType: linkType,
    //   })
    // );
  };

  const dragHandler = ({ source, destination }: DropResult) => {
    if (
      !destination ||
      destination.index === undefined ||
      source.index === destination.index
    ) {
      return;
    }
    const updatedChecklist = Array.from(formChecklists.checklists);
    const [draggedItem] = updatedChecklist.splice(source.index, 1);
    updatedChecklist.splice(destination.index, 0, draggedItem);
    setFormChecklist({ ...formChecklists, checklists: updatedChecklist });
  };

  //#endregion

  //#region useEffect region
  useEffect(() => {
    dispatch(GetFormCards({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sessionForms = allForms
      .filter((item) => item.formType === BusinessFormType.SessionForm);
    console.log("sessionForms", sessionForms);

    //create linkedAuth based on sessionForm.applicableTo.authCodes
    const linkedAuths: {
      formId: string;
      authId: string;
      authValue: string;
    }[] = [];
    const linkedInsurances: {
      formId: string;
      insuranceId: string;
      insuranceName: string;
    }[] = [];

    sessionForms.forEach((form) => {
      form.applicableTo?.authCodes?.forEach((authCode) => {
        //lookup authCode from authList
        const auth = authList.find((item) => item.id === authCode);
        if (auth) {
          linkedAuths.push({
            formId: form.id,
            authId: auth.id,
            authValue: auth?.conceptValue || "",
          });
        }
      });

      form.applicableTo?.insurances?.forEach((insurance) => {
        const insuranceData = insuranceList.find(
          (item) => item.id === insurance
        );
        if (insuranceData) {
          linkedInsurances.push({
            formId: form.id,
            insuranceId: insuranceData.id,
            insuranceName: insuranceData?.conceptValue || "",
          });
        }
      }
      );
    });

    setLinkedAuth(linkedAuths);
    setLinkedInsurance(linkedInsurances);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allForms.length, authList.length, insuranceList.length]);
  //#endregion

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mt="22px">
        <div className="listingTable" style={{ marginBottom: 0 }}>
          <div
            className="listingTable__header"
            style={{ paddingBottom: "20px" }}
          >
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                gap: "15px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                className="listingTable__title"
                gutterBottom
                // variant="h6"
                style={{ fontWeight: 500, fontSize: "20px" }}
              >
                Select Group:
              </Typography>
              <Box sx={{ display: "flex", gap: "15px" }} className="form-tabs">
                {Object.values(BusinessFormGroup)?.map((item, index) => (
                  <Box className="fromGroup-chips" key={item}>
                    <Card
                      key={index}
                      className={
                        filterFormGroup === item ? "fromGroup-chip-active" : ""
                      }
                      onClick={() => {
                        filterFormGroup === item
                          ? setFilterGroup("")
                          : setFilterGroup(item);
                      }}
                    >
                      {item}
                    </Card>
                  </Box>
                ))}
              </Box>
            </Box>
            {filterFormGroup !== BusinessFormGroup.Assessment && (
              <div
                style={{
                  position: "relative",
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    if (filterFormGroup === "Intake") {
                      navigate("/surveycreator/newintakeform", {
                        state: {
                          type: BusinessFormType.IntakePacketForm,
                        },
                      });
                    } else if (filterFormGroup === "Onboarding") {
                      navigate("/surveycreator/newemployeeform", {
                        state: {
                          type: BusinessFormType.EmployeePacketForm,
                        },
                      });
                    } else if (filterFormGroup === "Session") {
                      navigate("/surveycreator/newSessionForm", {
                        state: {
                          type: "Session Form",
                        },
                      });
                    }
                  }}
                  sx={{ margin: " 0px 5px 5px 5px" }}
                >
                  + Design Form
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setEditDialog(true);
                    setAddFormsDetails(true);
                  }}
                  sx={{ margin: "0px 5px 5px 5px" }}
                >
                  Link External Form
                </Button>
              </div>
            )}
          </div>

          <Grid item xs={12} className="listingTable">
            <TableContainer component={Paper} className="tableResponsive">
              <StyledTable
                sx={{
                  minWidth: 820,
                  borderCollapse: "separate",
                  cursor: "pointer",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    {filterFormGroup !== BusinessFormGroup.Session && (
                      <StyledTableCell align="center">Type</StyledTableCell>
                    )}
                    {filterFormGroup === BusinessFormGroup.Session && (
                      <>
                        <StyledTableCell align="center">
                          Insurance
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Auth Code
                        </StyledTableCell>
                        <StyledTableCell>Checklist</StyledTableCell>
                      </>
                    )}
                    <StyledTableCell align="center">Updated On</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {allForms?.length > 0 &&
                    (filterFormGroup
                      ? sortData(getFilteredList(allForms, filterFormGroup))
                      : sortData(allForms)
                    )?.map((item) => {
                      const authValues = linkedAuths
                        .filter((authCode) => authCode.formId === item.id)
                        ?.map((auth) => auth.authValue);
                      const insuranceValues = linkedInsurance
                        .filter((insurance) => insurance.formId === item.id)
                        ?.map((insurance) => insurance.insuranceName);
                      return (
                        <StyledTableRow
                          style={{ borderRadius: 20 }}
                          key={item.id}
                        >
                          {/* column - form logo & name */}
                          <StyledTableCell>
                            <Box display={"flex"} gap={2} alignItems={"center"}>
                              <span>
                                {item.formSchema !== null ? (
                                  <img
                                    className="survetFormLogo"
                                    src={surveyLogo}
                                    alt="surveyLogo"
                                  />
                                ) : item.isJot === true ? (
                                  <img
                                    className="jotformLogo"
                                    src={jotformLogo}
                                    alt="jotformLogo "
                                  />
                                ) : (
                                  <img
                                    className="formLogo"
                                    src={internetformLogo}
                                    alt="internetformLogo "
                                  />
                                )}
                              </span>
                              <Typography>{item.name}</Typography>
                              {item.url !== null && (
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "black" }}
                                  >
                                    <LaunchIcon />
                                  </a>
                                )}
                            </Box>
                          </StyledTableCell>

                          {/* column - form type */}
                          {filterFormGroup !== BusinessFormGroup.Session && (
                            <StyledTableCell>
                              <Box>
                                {item.formType ===
                                  BusinessFormType.IntakePacketForm ||
                                item.formType ===
                                  BusinessFormType.EmployeePacketForm ? (
                                  <Select
                                    className="form-control"
                                    labelId="demo-simple-select-label"
                                    value={item.formType}
                                    name="formType"
                                    onChange={(e) =>
                                      handleFormTypeChange(item, e.target.value)
                                    }
                                    MenuProps={MenuProps}
                                    style={{
                                      maxWidth: "200px",
                                      width: "200px",
                                      height: "33px",
                                    }}
                                  >
                                    <MenuItem
                                      value={BusinessFormType.IntakePacketForm}
                                    >
                                      {BusinessFormType.IntakePacketForm}
                                    </MenuItem>
                                    <MenuItem
                                      value={
                                        BusinessFormType.EmployeePacketForm
                                      }
                                    >
                                      {BusinessFormType.EmployeePacketForm}
                                    </MenuItem>
                                  </Select>
                                ) : (
                                  <Box className="border-boxBlockForm">
                                    {item.formType}
                                  </Box>
                                )}
                              </Box>
                            </StyledTableCell>
                          )}

                          {/* column - SessionForm Only: insurance, authCode, checklist */}
                          {item.formType === BusinessFormType.SessionForm && (
                            <>
                              <StyledTableCell>
                                <Select
                                  // className="form-control"
                                  labelId="demo-simple-select-label"
                                  multiple
                                  value={insuranceValues}
                                  name="Auth Code"
                                  onChange={(e) => {
                                    handleInsuranceChange(
                                      e.target.value as string[],
                                      item.id
                                    );
                                  }}
                                  onClose={() => {
                                    handleApplicable(item.id);
                                  }}
                                  renderValue={(selected: string[]) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                  style={{
                                    width: "250px",
                                    height: "33px",
                                  }}
                                >
                                  {insuranceList?.map((insurance) => (
                                    <MenuItem value={insurance.conceptValue}>
                                      <Checkbox
                                        checked={insuranceValues.includes(
                                          insurance.conceptValue
                                        )}
                                      />
                                      {insurance.conceptValue}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Select
                                  // className="form-control"
                                  labelId="demo-simple-select-label"
                                  multiple
                                  value={authValues}
                                  name="Auth Code"
                                  onChange={(e) => {
                                    handleAuthChange(
                                      e.target.value as string[],
                                      item.id
                                    );
                                  }}
                                  onClose={() => {
                                    handleApplicable(item.id);
                                  }}
                                  renderValue={(selected: string[]) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                  style={{
                                    width: "250px",
                                    height: "33px",
                                  }}
                                >
                                  {authList?.map((auth) => (
                                    <MenuItem value={auth.conceptValue}>
                                      <Checkbox
                                        checked={authValues.includes(
                                          auth.conceptValue
                                        )}
                                      />
                                      {auth.conceptValue}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Button
                                  onClick={() => {
                                    setFormChecklist({
                                      formId: item.id,
                                      checklists: item.checkList,
                                    });
                                    setChecklistPopup(true);
                                  }}
                                >
                                  <ChecklistIcon color="primary" />
                                </Button>
                              </StyledTableCell>
                            </>
                          )}
                          <StyledTableCell>
                            {item.updatedAt &&
                              `${moment(item.updatedAt as any).format("lll")}`}
                          </StyledTableCell>

                          {/* column - action */}
                          <StyledTableCell
                            style={{ display: "flex", gap: "23px" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                marginTop: "10px",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              {/* for toggle button */}
                              {item.formType !==
                                BusinessFormType.IntakeClientForm &&
                                item.formType !==
                                  BusinessFormType.NewEmployeeForm && (
                                  <Switch
                                    checked={item.active}
                                    onChange={() => {
                                      handleSwitchChange(item);
                                    }}
                                  />
                                )}

                              {/* for delete button */}
                              {/* {(!isAssessmentForm(item.formType) || 
                              (isAssessmentForm(item.formType) && 
                              (currentRoles.includes("Owner") ||
                              currentRoles.includes("ABAEngine Admin"))
                            )) && ( */}
                              {!isAssessmentForm(item.formType) && (
                                  <>
                                    <BorderColor
                                      color="primary"
                                      onClick={() => {
                                        if (item.formSchema !== null) {
                                          navigate(
                                            "/surveycreator/" + item.id,
                                            {
                                              state: {
                                                url: item.url,
                                                type: item.formType,
                                              },
                                            }
                                          );
                                        } else {
                                          setEditDialog(true);
                                          setEditFormDetails(true);
                                          setParentFormID(item.id);
                                        }
                                      }}
                                    />
                                    {item.formType !==
                                      BusinessFormType.IntakeClientForm &&
                                      item.formType !==
                                        BusinessFormType.NewEmployeeForm &&
                                      !DefaultSessionForm.includes(
                                        item.name
                                      ) && (
                                        <DeleteIcon
                                          color="primary"
                                          onClick={() => {
                                            setDeletePopup(true);
                                            setIntakeFormId(item.id);
                                          }}
                                        />
                                      )}

                                    <ContentCopyIcon
                                      color="primary"
                                      onClick={() =>
                                        handleDuplicateForm(item.id)
                                      }
                                    ></ContentCopyIcon>
                                  </>
                                )}
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Grid>
        </div>
        {editDialog && (
          <Dialog open={editDialog} className="largeModel">
            <Grid>
              <Box className="largeModel__head">
                <Typography variant="h5" className="largeModel__title">
                  Add Form / Edit Form
                </Typography>
                <Button
                  className="largeModel__closeBtn"
                  onClick={() => {
                    setEditDialog(false);
                    if (editFormDetails) {
                      setEditFormDetails(false);
                    } else {
                      setAddFormsDetails(false);
                    }
                  }}
                >
                  <Close />
                </Button>
              </Box>
            </Grid>

            <ParentAppForm
              editFormDetails={editFormDetails}
              setAddFormsDetails={setAddFormsDetails}
              setEditFormDetails={setEditFormDetails}
              parentformId={parentformId}
              setEditDialog={setEditDialog}
              setSelectedFormType={setSelectedFormType}
              selectedFormType={selectedFormType}
              formType={selectedFormType}
            />
          </Dialog>
        )}

        <Dialog open={deletePopup} className="smallModel">
          <div>
            <DialogContent>
              Do you want to delete this form record ?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => setDeletePopup(false)}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteIntake({
                      id: intakeFormId,
                      formType: "Intake Packet Form",
                    })
                  );
                  setDeletePopup(false);
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog open={checklistPopup} className="checklistModal">
          <div>
            <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
              <Typography variant="h3" className="smallModel__title">
                Checklist
              </Typography>
              <Button
                className="smallModel__closeBtn"
                onClick={() => {
                  setChecklistPopup(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <DialogContent>
              <Box className="add-checklist">
                <Box className="fromGroup checklist-bar">
                  <FormControl>
                    <TextField
                      className="border-none"
                      placeholder="Add checklist"
                      value={newChecklist}
                      onChange={(e) => setNewChecklist(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && newChecklist !== "") {
                          setFormChecklist({
                            ...formChecklists,
                            checklists: [
                              ...formChecklists.checklists,
                              newChecklist,
                            ],
                          });
                          setNewChecklist("");
                        }
                      }}
                    />
                  </FormControl>
                  <button
                    onClick={() => {
                      if (newChecklist === "") {
                        return;
                      }
                      setFormChecklist({
                        ...formChecklists,
                        checklists: [
                          ...formChecklists.checklists,
                          newChecklist,
                        ],
                      });
                      setNewChecklist("");
                    }}
                  >
                    Add
                  </button>
                </Box>
              </Box>
              <DragDropContext onDragEnd={dragHandler}>
                <Droppable droppableId="droppableId" direction="vertical">
                  {(provider) => (
                    <Box
                      className="checklists"
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {formChecklists.formId !== "" &&
                        formChecklists.checklists?.map((item, index) => (
                          <Draggable
                            key={item + index}
                            draggableId={item}
                            index={index}
                          >
                            {(provider) => (
                              <Box
                                ref={provider.innerRef}
                                {...provider.draggableProps}
                                {...provider.dragHandleProps}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  background: "#d8ecff",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  margin: "0px 5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant="caption">
                                  {item}
                                </Typography>
                                <ClearIcon
                                  fontSize="small"
                                  onClick={() => {
                                    const updatedChecklist =
                                      formChecklists.checklists.filter(
                                        (ele) => ele !== item
                                      );
                                    setFormChecklist({
                                      ...formChecklists,
                                      checklists: updatedChecklist,
                                    });
                                  }}
                                  sx={{ marginLeft: "5px", cursor: "pointer" }}
                                />
                              </Box>
                            )}
                          </Draggable>
                        ))}
                      {provider.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </DialogContent>
            <DialogActions>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  setChecklistPopup(false);
                  if (formChecklists.formId !== "") {
                    dispatch(
                      UpdateSessionFormCheckList({
                        checkList: formChecklists.checklists,
                        formId: formChecklists.formId,
                      })
                    );
                  }
                }}
              >
                Save Changes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default BusinessForm;
