export const ClientType = [
  { key: 1, value: "Intake" },
  { key: 2, value: "Ongoing" },
  { key: 3, value: "Inactive" },
]as const;

export const OnTrackStage = [
  { key: 1, value: "New" },
  { key: 2, value: "Benefit Check in Process" },
  { key: 3, value: "Pending Client Paperwork" },
  { key: 4, value: "Initial Auth Request in Process" },
  { key: 5, value: "Initial Auth Request Received" },
  { key: 6, value: "Clinical - Scheduling/Completing Assessment" },
  { key: 7, value: "Clinical - Assessment Complete" },
  { key: 8, value: "Direct Service Auth Request in Process" },
  { key: 9, value: "Direct Service Auth Request Received" },
  { key: 10, value: "Scheduling" },
  { key: 11, value: "Intake Complete" },
] as const;


// export const ConcernedStage = [
//   { key: 1, value: "On Hold" },
//   { key: 2, value: "Waiting on Client Diagnosis" },
//   { key: 3, value: "Waiting on ABA Script for Services" },
//   { key: 4, value: "Discontinued" },
// ]as const;
