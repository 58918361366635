import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import {
  addClientReducer,
  setInitialState,
} from "../../../redux/features/client-main/client-slice";

import AdminDetails from "../client-stepper/AdminDetails";
import AuthorizationsDetails from "../client-stepper/AuthorizationsDetails";
import ClientAvailabilityDetailsNew from "../client-stepper/ClientAvailabilityDetailsNew";
import ClientBasicDetailsForm from "../client-stepper/ClientBasicDetails";
import ClientContactDetails from "../client-stepper/ClientContactDetails";
import InsuranceDetails from "../client-stepper/InsuranceDetails";

import { clientStepsLabels } from "../../../constants/client";
import { ClientBasicDetails } from "../../../types/client";

interface IAddClient {
  isOpenClientModal: boolean;
  setOpenClientModal: Dispatch<SetStateAction<boolean>>;
  clientBasicDetails: ClientBasicDetails;
  isBoardPage: boolean;
}

const AddClient: React.FC<IAddClient> = ({
  isOpenClientModal,
  setOpenClientModal,
  clientBasicDetails,
  isBoardPage,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useAppDispatch();

  //tracks active step
  const activeStep = useAppSelector((state) => state.activeStep.activeStep);

  //Open and Close Popup for Dialog
  const [popup, setPopup] = useState<boolean>(false);

  //handles the SavePopup
  const [saveClient, setSaveClient] = useState<boolean>(false);

  // #endregion

  // #region methods region

  //handles the forms navigation
  const handleSteps = (step: number = 5) => {
    switch (step) {
      case 0:
        return <ClientBasicDetailsForm />;
      case 1:
        return <ClientContactDetails />;
      case 2:
        return <AdminDetails />;
      case 3:
        return <InsuranceDetails />;
      case 4:
        return (
          <AuthorizationsDetails
            headerTitle={"All Authorizations"}
            isEdit={false}
          />
        );
      case 5:
        return (
          <ClientAvailabilityDetailsNew
            onSubmit={() => setOpenClientModal(false)}
            isBoardPage={isBoardPage}
          />
          // <ClientAvailabilityDetails
          //   onSubmit={() => setOpenClientModal(false)}
          //   isBoardPage={isBoardPage}
          // />
        );

      default:
        throw new Error("Unknown step");
    }
  };

  // #endregion

  return (
    <div>
      {activeStep <= 5 && (
        <Dialog open={isOpenClientModal} className="largeModel">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Add New Client
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setPopup(true);
                setSaveClient(true);
                // if (
                //   clientBasicDetails.parentFirstName &&
                //   clientBasicDetails.parentLastName &&
                //   clientBasicDetails.childFirstName &&
                //   clientBasicDetails.childLastName &&
                //   clientBasicDetails.childLanguages &&
                //   clientBasicDetails.childGender &&
                //   clientBasicDetails.childDateOfBirth
                // ) {
                //   setSaveClient(true);
                // } else {
                //   dispatch(setActiveSteps(0));
                //   setOpenClientModal(false);
                //   dispatch(setInitialState({}));
                // }
              }}
            >
              <Close />
            </Button>
          </Box>
          {activeStep !== 6 && (
            <Box className="largeModel__body">
              <Stepper
                activeStep={activeStep}
                sx={{ py: 3 }}
                alternativeLabel
                className="largeModel__stepperHead"
              >
                {clientStepsLabels.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {handleSteps(activeStep)}
            </Box>
          )}
        </Dialog>
      )}
      {popup && (
        <Dialog open={saveClient} className="smallModel">
          <div>
            <DialogContent>
              Do you want to save current client data?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => {
                  dispatch(setActiveSteps(0));
                  setOpenClientModal(false);
                  dispatch(setInitialState({}));
                  setSaveClient(false);
                }}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  dispatch(addClientReducer({ isBoardPage: isBoardPage }));
                  setSaveClient(false);
                  dispatch(setActiveSteps(0));
                  setOpenClientModal(false);
                  // dispatch(setListDataChange(!dataChange));
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default AddClient;
