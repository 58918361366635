import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Container, TextareaAutosize } from "@mui/material";

import { Box } from "@mui/system";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { ProviderMessages } from "../../../../types/provider";
import { EditProviderMessageById } from "../../../../redux/features/providers-main/provider-list-slice";

interface IProviderSubComments {
  commentObj: ProviderMessages;
  isActiveClose: Dispatch<SetStateAction<boolean>>;
  providerId: string;
}

const ProviderSubEditComments: React.FC<IProviderSubComments> = ({
  commentObj,
  isActiveClose,
  providerId,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //handle message
  const [message, setMessage] = useState<string>(commentObj.message);

  //handle error
  const [error, setError] = useState<string>("");

  // #endregion

  // #region methods region

  //hadler update comments
  const editMessageHandle = () => {
    if (message.trim().length === 0) {
      setError("Please Enter Message");
      return;
    }
    dispatch(
      EditProviderMessageById({
        providerId,
        selectedMessage: message,
        commentId: commentObj.id,
      })
    );
    setMessage("");
    isActiveClose(false);
  };

  //handle Cancel Edit
  const cancelMessageHandle = () => {
    isActiveClose(false);
  };

  return (
    <>
      <Container maxWidth={"md"}>
        <span style={{ color: "red" }}>{error}</span>
        <Box className="commentForm">
          <TextareaAutosize
            className="textarea-box"
            value={message}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setMessage(e.target.value);
              setError("");
            }}
            placeholder="Leave a comment..."
            minRows={2}
          />
          <Box className="textarea-btn-box">
            <Button onClick={cancelMessageHandle} className="button cancel-btn">
              Cancel
            </Button>
            <Button
              onClick={() => editMessageHandle()}
              variant="contained"
              className="button"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default ProviderSubEditComments;
