import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NewDataCatalog } from "../../../types/businessSettings";
import { ClientForms, OtherAddresses } from "../../../types/client";
import { MatchResult } from "../../../types/schedule";
import {
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TableRow,
  styled,
} from "@mui/material";
import { StyledTableCell } from "./SchedulesMatch";
import { useDispatch } from "react-redux";
import DiscoverBookingEditModal from "../booking-scheduler/DiscoverBookingEditModal";
import { ProviderForms } from "../../../types/provider";
import { repeat } from "lodash";

// import "../booking-scheduler/BookingEditModal.scss";

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

interface IAdditionOption {
  isEndAuth: boolean;
  isEndMonth: boolean;
  isCustom: boolean;
}

enum RepeatOptions {
  EndOfAuth = "till end of auth",
  EndOfMonth = "till end of month",
  CustomEnd = "till custom end",
}

const ScheduleMatchList: React.FC<{
  item: MatchResult;
  index: number;
  selectedClientData: ClientForms | null;
  isScheduleBooked: boolean;
  setIsScheduledBooked: Dispatch<SetStateAction<boolean>>;
  setLastBookedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  selectedProviderData: ProviderForms | null;
  setSelectedMatchResult: Dispatch<SetStateAction<string[]>>;
  selectedMatchResult: string[];
  entityType: string;
  checkStartDate: Date | undefined;
  checkEndDate: Date | undefined;
  
}> = ({
  item,
  index,
  selectedClientData,
  isScheduleBooked,
  setIsScheduledBooked,
  setLastBookedDate,
  selectedProviderData,
  setSelectedMatchResult,
  selectedMatchResult,
  entityType,
  checkStartDate,
  checkEndDate,
}) => {
  const repeatOptions = [
    RepeatOptions.EndOfAuth as string,
    RepeatOptions.EndOfMonth as string,
    RepeatOptions.CustomEnd as string,
  ];
  const [popUp, setPopup] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<string>(item.time[0]);
  const [selectedDate, setSelectedDate] = useState<string[]>([item.dates[0]]);
  const [repeatService, setRepeatService] = useState<number>(0);

  //store custom end
  const [customEnd, setCustomEnd] = useState<string>("");
  const [isEndMonth, setIsEndMonth] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleDateSelection = (value: string[]) => {
    let specialOptions = repeatOptions;
    if (value?.length === 0) {
      setRepeatService(0);
      setIsEndMonth(false);
      setCustomEnd("");
      setSelectedDate([]);
      return;
    };
    
    let filteredSpecialOptions = value.filter((val) =>
      specialOptions.includes(val)
    );
    let nonSpecialOptions = value.filter(
      (val) => !specialOptions.includes(val)
    );

    //get last value whether it is special option
    let finalValue : any[] = [];
    let lastValue = value[value.length - 1];
    
    if (specialOptions.includes(lastValue)) {
      //if last value is special option, remove all other values
      nonSpecialOptions = [];
      filteredSpecialOptions = [lastValue];
    } else {
      //if last value is not special option, remove all special options
      filteredSpecialOptions = [];
    }
    
    finalValue = [...filteredSpecialOptions, ...nonSpecialOptions];
    setSelectedDate(finalValue);
    
  };
  const handleOpenBookingModal = () => {
    //check customEnd first
    if (selectedDate.includes(RepeatOptions.CustomEnd)) {
      setRepeatService(3); //3: custom end
      setCustomEnd(selectedDate[selectedDate.length - 1]);
      setSelectedDate(selectedDate.slice(0, selectedDate.length - 1));
      setPopup(true);
      return;
    }
    
    // check end of month
    if (selectedDate.includes(RepeatOptions.EndOfMonth)) {
      setRepeatService(1); //1: end of month
      const now = new Date();
      const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      const endOfMonth = new Date(nextMonth.getTime() - 1);
      setCustomEnd(endOfMonth.toString());
      //filter dates between today and endOfMonth
      const filteredDates = item.dates.filter(
        (date) =>
          new Date(date).getTime() >= new Date().getTime() &&
          new Date(date).getTime() <= endOfMonth.getTime()
      );
      setSelectedDate(filteredDates);
      setIsEndMonth(true);
      setPopup(true);
      return;
    }

    // check end of month
    if (selectedDate.includes(RepeatOptions.EndOfAuth)) {
      setRepeatService(2); //1: end of auth
      //filter dates between today and endOfAuth
      const filteredDates = item.dates.filter(
        (date) =>
          new Date(date).getTime() >= new Date().getTime() &&
          new Date(date).getTime() <= new Date(item.authorizations[0]?.authPeriod?.endDate!).getTime()
      );
      setSelectedDate(filteredDates);
      setIsEndMonth(false);
      setCustomEnd("");
      setPopup(true);
      return;
    }

    // single select
    setRepeatService(0); //0: single select
    setCustomEnd("");
    setPopup(true);

  };

  //handles select checkbox
  const handleCheckboxChange = () => {
    let uniqueDetails =entityType === "Client" ?
      (item.baseProvider?.employmentDetails.id + item.dayOfWeek) : (item.baseClient?.clientBasicDetails.id + item.dayOfWeek )
    if (uniqueDetails) {
      if (selectedMatchResult.includes(uniqueDetails)) {
        // Uncheck the checkbox: remove the id from selectedMatchResult
        setSelectedMatchResult(
          selectedMatchResult.filter(
            (selectedId) => selectedId !== uniqueDetails
          )
        );
      } else {
        // Check the checkbox: add the id to selectedMatchResult
        setSelectedMatchResult([...selectedMatchResult, uniqueDetails]);
      }
    }
  };

  useEffect(() => {
    setSelectedDate([item.dates[0]]);
    setSelectedSlot(item.time[0]);
  }, [item.dates]);

  useEffect(() => {
    if (isScheduleBooked) {
      setLastBookedDate(new Date(selectedDate[0]));
    }
  }, [isScheduleBooked]);

  return (
    <>
      <StyledTableRow key={index}>
        <StyledTableCell>
          <Checkbox
            checked={selectedMatchResult.includes(
              entityType === "Client"
                ? item.baseProvider?.employmentDetails.id + item.dayOfWeek
                : item.baseClient?.clientBasicDetails.id + item.dayOfWeek
            )}
            onChange={handleCheckboxChange}
          />
        </StyledTableCell>

        {entityType === "Client" ? (
          <>
          <StyledTableCell>{item.clientName}{"\n"}
            {item.weeklyAvgBookedForClient.toFixed(2)}
            {" / "}
            {item.weeklyAvgRemainedAuthForClient.toFixed(2)}
            {" ("}{(item.utilizationPcntByAuthorizationForClient * 100).toFixed(2)}{"%)"}
          </StyledTableCell>
          <StyledTableCell>{item.providerName}{"\n"}
            {item.weeklyAvgBookedForProvider.toFixed(2)}
            {" / "}
            {item.weeklyAvgRemainedByAvailabilityForProvider.toFixed(2)}
            {" ("}{(item.utilizationPcntByAvailabilityForProvider * 100).toFixed(2)}{"%)"}
          </StyledTableCell>
          </>
         ) : (
          <>
          <StyledTableCell>{item.providerName}{"\n"}
            {item.weeklyAvgBookedForProvider.toFixed(2)}
            {" / "}
            {item.weeklyAvgRemainedByAvailabilityForProvider.toFixed(2)}
            {" ("}{(item.utilizationPcntByAvailabilityForProvider * 100).toFixed(2)}{"%)"}
          </StyledTableCell>
          <StyledTableCell>{item.clientName}{"\n"}
            {item.weeklyAvgBookedForClient.toFixed(2)}
            {" / "}
            {item.weeklyAvgRemainedAuthForClient.toFixed(2)}
            {" ("}{(item.utilizationPcntByAuthorizationForClient * 100).toFixed(2)}{"%)"}
          </StyledTableCell>
          </>
         )
        }

        <StyledTableCell width={20}>{item.distance}</StyledTableCell>
        <StyledTableCell>
          {item.authorizations[0]?.authorizationName}{"\n"}
          {item.authorizations[0]?.authPeriod?.startDate}{" - "}{item.authorizations[0]?.authPeriod?.endDate}{"\n"}
          {item.authCode}{" remain: "}{item.remainingAuthHours.toFixed(2)}
        </StyledTableCell>
        <StyledTableCell width={20}>{item.weeks}</StyledTableCell>
        <StyledTableCell width={20}>{item.dayOfWeek}</StyledTableCell>
        <StyledTableCell>
          <FormControl sx={{ m: 1, width: 150 }} size="small">
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedDate}
              onChange={({ target: { value } }: any) =>
                handleDateSelection(value)
              }
              input={<OutlinedInput />}
              renderValue={(selected: any[]) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: 350, width: 200 },
                },
              }}
            >
              {[...repeatOptions, ...item.dates].map((date) => (
                <MenuItem key={date} value={date}>
                  <Checkbox
                    checked={selectedDate && selectedDate.indexOf(date) > -1}
                  />
                  <ListItemText primary={date} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledTableCell>
        <StyledTableCell>{item.slotSummary}</StyledTableCell>
        <StyledTableCell width={250}>
          <FormControl sx={{ m: 1, width: 200 }} size="small">
            <Select
              value={selectedSlot}
              onChange={({ target: { value } }) => setSelectedSlot(value)}
              input={<OutlinedInput />}
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: 350, width: 150 },
                },
              }}
            >
              {item.time.map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledTableCell>

        <StyledTableCell>
          <span style={{ wordBreak: "break-all" }}>
            {item.matchOn.join("\n")}
          </span>
        </StyledTableCell>
        <StyledTableCell>
          <Button variant="contained" onClick={() => handleOpenBookingModal()}>
            Book
          </Button>
        </StyledTableCell>
      </StyledTableRow>

      {popUp && item.baseProvider !== null && selectedClientData !== null && (
        <DiscoverBookingEditModal
          pageTitle={"Add Booking"}
          clientData={selectedClientData}
          provider={item.baseProvider}
          isOpen={popUp}
          isEdit={false}
          isNew={true}
          closePopup={setPopup}
          argsdata={undefined}
          setIsDataEdited={setIsScheduledBooked}
          allSelectedClients={[selectedClientData]}
          providersData={[item.baseProvider]}
          fromPlanning={false}
          isAutoPlanned={true}
          autoPlanData={{
            isClient: true,
            selectedSlot: selectedSlot,
            repeatService: repeatService,
            selectedDate: selectedDate,
            selectedDays: [item.dayOfWeek],
            isEndMonth: isEndMonth,
            customEnd: customEnd,
            authId: item.authorizations[0]?.id,
            authCode: item.authCode,
          }}
        />
      )}

      {popUp && item.baseClient !== null && selectedProviderData !== null && (
        <DiscoverBookingEditModal
          pageTitle={"Add Booking"}
          provider={selectedProviderData}
          clientData={item.baseClient}
          isOpen={popUp}
          isEdit={false}
          isNew={true}
          closePopup={setPopup}
          argsdata={undefined}  //calendar event data
          setIsDataEdited={setIsScheduledBooked}
          allSelectedClients={[item.baseClient]}
          providersData={[selectedProviderData]}
          fromPlanning={false}
          isAutoPlanned={true}
          autoPlanData={{
            isClient: false,
            selectedSlot: selectedSlot,
            repeatService: repeatService,
            selectedDate: selectedDate,
            selectedDays: [item.dayOfWeek],
            isEndMonth: isEndMonth,
            customEnd: customEnd,
            authId: item.authorizations[0]?.id,
            authCode: item.authCode,
          }}
        />
      )}
    </>
  );
};

export default ScheduleMatchList;
