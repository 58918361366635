import axios from "../axios/axios";
import { UpdateResponse, DeleteResponse } from "../types/response";

//must match backend, may need to rename as ClientFileCategory
export enum FileCategory {
  Intake="Intake",
  Assessment="Assessment", //for assessment form (pdf)
  Onboarding="Onboarding",
  Authorization="Authorization",
  Credentialing="Credentialing",
  Session="Session",
  Evaluation="Evaluation", //for assessment evaluation (png & pdf)
}

export const updateFileCategory = async (
  fileId: string,
  entityId: string,
  category: string
) => {
  try {
    //validdate category must be one of the enum
    if (!Object.values(FileCategory).includes(category as FileCategory)) {
      throw new Error("Invalid file category");
    }

    const { data } = await axios.put<UpdateResponse>(
      `/file/category/${fileId}`,
      {
        entityId,
        category,
      }
    );
    return data;
  } catch (e: any) {
    throw e;
  }
};

export const postFiles = async (
  category: string,
  type: string,
  model: string,
  modelId: string,
  files: any[]
) => {

  //valiate category must be one of the enum
  if (!Object.values(FileCategory).includes(category as FileCategory)) {
    throw new Error("Invalid file category");
  }

  const formData = new FormData();
  formData.append("category", category);
  formData.append("type", type);
  formData.append("model", model);
  formData.append("modelId", modelId); //looks like modelId = entityId
  formData.append("entityId", modelId);
  files.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const { data } = await axios.post<UpdateResponse>(
      "/file/upload-files",
      formData
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export async function getFilesByEntityId(entityId: string) {
  try {
    const { data } = await axios.get(`/files?entityId=${entityId}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function deleteFileById(id: string) {
  try {
    const { data } = await axios.delete<DeleteResponse>(`/file?fileId=${id}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export async function updateFileNameById(id: string, fileName: string) {
  try {
    const { data } = await axios.put<UpdateResponse>(`/file/name/${id}`, {
      fileName,
    });
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
}

export async function moveToAnotherBoard(
  fromBoardId: string,
  toBoardId: string | null,
  cardId: string,
  index: number
  // entityType: string
) {
  try {
    const res = await axios.put<UpdateResponse>("/board/changeState", {
      fromBoardId,
      toBoardId,
      cardId,
      index,
      // entityType,
    });
    return res.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export const postFilteredUtilzation = async (payloadData: {
  startDate: string | null;
  endDate: string | null;
  clientIds: string[];
  providerIds: string[];
}) => {
  try {
    const { data } = await axios.post("/refreshUtilization", payloadData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getPreviewFileUrl = async (fileId: string) => {
  try {
    const { data } = await axios.get(`/file/preview/${fileId}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const CommonService = {
  updateFileCategory,
  postFiles,
  getFilesByEntityId,
  deleteFileById,
  moveToAnotherBoard,
  postFilteredUtilzation,
  updateFileNameById,
  getPreviewFileUrl,
};

export default CommonService;
