import moment from "moment";
import { formattedDate } from "../../constants/common";

import {
  ClientFilters,
  ClientForms,
  ISchedules,
  InsuranceProviders,
} from "../../types/client";
import { AvailabilityDetails } from "../../types/availability";
import { searchClientText } from "../../utils/FilterUtils";
import { EntityScheduleStatus } from "../../constants/providers";

//checks if any value is selected filter filter or search
export const canFilterClients = (
  filterValues: ClientFilters,
  searchVal: string
): boolean => {
  if (
    filterValues.clientStatus.length > 0 ||
    filterValues.businessUnit.length > 0 ||
    filterValues.availabilityStatus ||
    filterValues.insuranceProvider.length > 0 ||
    filterValues.intakeStage.length > 0 ||
    filterValues.concerns.length > 0 ||
    filterValues.ScheduleStatus ||
    filterValues.startDate ||
    filterValues.endDate ||
    // filterValues.age !== null ||
    searchVal
  ) {
    return true;
  }
  return false;
};

//to sorting availabilitydeatails according to begindate
export const sortingAvailability = (item: ClientForms) => {
  let sorted = [...item.availabilityDetails].sort(function (a, b) {
    return (
      (new Date(formattedDate(a?.beginDate)) as any) -
      (new Date(formattedDate(b?.beginDate)) as any)
    );
  });

  return sorted;
};

//to sorting availabilityDetails according to endDates
export const sortingAvailabilityEndDate = (item: ClientForms) => {
  let sorted = [...item.availabilityDetails].sort(function (a, b) {
    return (
      (new Date(formattedDate(b?.endDate)) as any) -
      (new Date(formattedDate(a?.endDate)) as any)
    );
  });

  return sorted;
};

//logic for filtering a single provider and Client
export const filterSingleClient = (
  item: ClientForms,
  filterValues: ClientFilters,
  searchVal: string
): boolean => {
  var startDate =
    item.availabilityDetails?.length > 0
      ? // ? new Date(item.availabilityDetails[0]?.beginDate as string)
        new Date(sortingAvailability(item)[0].beginDate as string)
      : null;
  var endDate =
    item.availabilityDetails?.length > 0
      ? // ? new Date(item.availabilityDetails[0]?.endDate as string)
        new Date(sortingAvailabilityEndDate(item)[0].endDate as string)
      : null;
  var today = new Date();

  const searchText = searchClientText(searchVal, item);
  if (!searchText) {
    return false;
  }
  
  if (item.clientBasicDetails) {
    if (
      !filterValues.businessUnit.includes("All Units") &&
      filterValues.businessUnit.length > 0 &&
      // !filterValues.businessUnit.includes(item.clientBasicDetails.businessUnit)
      !filterValues.businessUnit.some((ele) =>
        item.clientBasicDetails.businessUnit.includes(ele)
      )
      // filterValues.businessUnit !== "All Units" &&
      // filterValues.businessUnit &&
      // !(item.clientBasicDetails.businessUnit === filterValues.businessUnit)
    ) {
      return false;
    }
    if (filterValues.clientStatus.length > 0) {
      if (
        !filterValues.clientStatus.includes(item.clientBasicDetails.clientType)
      ) {
        return false;
      }
    }
    if (
      filterValues.availabilityStatus === "Current" &&
      startDate &&
      endDate &&
      !(today >= startDate && today <= endDate)
    ) {
      return false;
    } else if (
      filterValues.availabilityStatus === "Expired" &&
      endDate &&
      !(today > endDate)
    ) {
      return false;
    }
    if (
      filterValues.intakeStage.length > 0 &&
      !filterValues.intakeStage.includes("All Stages")
    ) {
      if (
        !filterValues.intakeStage.includes(item.clientBasicDetails.intakeStage)
      ) {
        return false;
      }
    }
    if (
      !filterValues.concerns.includes("All Concerns") &&
      filterValues.concerns.length > 0
    ) {
      if (
        filterValues.concerns &&
        !filterValues.concerns.includes(
          item.clientBasicDetails.checklistHistory[0]
        )
        // !item.clientBasicDetails.concernedStage.includes(filterValues.concerns[0])
      ) {
        return false;
      }
    }
    // if (filterValues.age !== null) {
    //   if (
    //     moment().diff(item.clientBasicDetails.childDateOfBirth, "years") ===
    //     Number(filterValues.age)
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  }
  if (
    !filterValues.insuranceProvider.includes("All Employees") &&
    filterValues.insuranceProvider.length > 0
  ) {
    let count = 0;
    item.clientInsurance.forEach((item: InsuranceProviders) => {
      if (filterValues.insuranceProvider.includes(item.insuranceProviderName)) {
        count++;
      }
    });
    if (count === 0) {
      return false;
    }
  }
  // if (filterValues.ScheduleStatus) {
  //   if (item.clientUtilization.status === filterValues.ScheduleStatus) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  if (filterValues.ScheduleStatus) {
    if (filterValues.ScheduleStatus === EntityScheduleStatus.NoAvailability ||
      filterValues.ScheduleStatus === EntityScheduleStatus.AvailabilityExpired) {
        //read from clientUtilization
        if (item.clientUtilization.status === filterValues.ScheduleStatus) {
          return true;
        } else {
          return false;
        }
    } else {
      //read from clientAuthUtilization
      if (item.clientAuthUtilization.status === filterValues.ScheduleStatus) {
        return true;
      } else {
        return false;
      }
    }
  }

  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  return true;
};

//return evaluate schedule array
export const evaluateSchedulerData = (
  item: ClientForms,
  filterValues: { startDate: string | null; endDate: string | null }
) => {
  let arr: AvailabilityDetails[] = [];
  item.availabilityDetails.filter((item: AvailabilityDetails) => {
    return filterSingleScheduleListItem(item, filterValues) && arr.push(item);
  });
  return arr;
};

//logic for filtering a single provider and Client
export const filterSingleScheduleListItem = (
  item: AvailabilityDetails,
  filterValues: { startDate: string | null; endDate: string | null }
): boolean => {
  if (filterValues.startDate && filterValues.endDate) {
    // if (
    //   new Date(moment(filterValues.startDate).format("YYYY-MM-DD")) <=
    //     new Date(item.specificDate) &&
    //   new Date(item.specificDate) <=
    //     new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
    // ) {
    //   return true;
    // }
    if (
      new Date(moment(filterValues.endDate).format("YYYY-MM-DD")) >=
      new Date(moment(item.endDate).format("YYYY-MM-DD"))
    ) {
      if (
        new Date(moment(item.endDate).format("YYYY-MM-DD")) >=
        new Date(moment(filterValues.startDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        new Date(moment(item.beginDate).format("YYYY-MM-DD")) <=
        new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
};

//logic for handling the search and filter
export const filterClients = (
  filterValues: ClientFilters,
  searchVal: string,
  data: ClientForms[]
): ClientForms[] => {
  if (data && data?.length > 0) {
    const applicable = canFilterClients(filterValues, searchVal);
    if (applicable) {
      return data.filter((item: ClientForms) => {
        return filterSingleClient(item, filterValues, searchVal);
      });
    }
  }

  return data;
};
