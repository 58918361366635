import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Dialog,
  Button,
  Box,
} from "@mui/material";
import { Edit, Close } from "@mui/icons-material";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  setClientBasicDetails,
  setEditClient,
  setInitialState,
  updateClientBasicDetailById,
} from "../../../../redux/features/client-main/client-slice";

import AdminDetails from "../../client-stepper/AdminDetails";

import { getBusinessUnitName } from "../../../../utils/EntityUtils";

import { ClientBasicDetails } from "../../../../types/client";

interface ClientSubAdminDetailsProps {
  clientBasicDetails: ClientBasicDetails;
}

const ClientSubAdminDetails: React.FC<ClientSubAdminDetailsProps> = ({
  clientBasicDetails,
}) => {
  // #region variables region

  //business units
  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //form handler for edit or new
  const { isEditClient } = useAppSelector((state) => state.client);

  //handles the edit for client administrative details
  const [editAdministrativeInfo, setEditAdministrativeInfo] =
    useState<boolean>(false);

  //dispatch for redux
  const dispatch = useAppDispatch();

  // #endregion

  // #region methods region

  //handles the close logic when save changes or close edit form for client contact details
  const handleCloseForAdministrativeDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setEditAdministrativeInfo(false);
  };

  //handles the edit form submission for client basic details
  const onSubmitBasicInfo = () => {
    dispatch(updateClientBasicDetailById({}));
    setEditAdministrativeInfo(false);
    dispatch(setEditClient(false));
  };

  //handles the data setting in redux for client administrative details
  const handleEditAdministractiveClick = () => {
    dispatch(setClientBasicDetails({ ...clientBasicDetails, isEdit: true }));
    dispatch(setEditClient(true));
    setEditAdministrativeInfo(true);
  };
  // #endregion

  return (
    <Grid item xs={12} lg={6} xl={4}>
      <div className="detailsCard">
        <div className="detailsCard__header">
          <Typography className="detailsCard__title">
            Administrative Information
          </Typography>
          <IconButton
            className="detailsCard__headBtn"
            size="small"
            edge="start"
            onClick={() => {
              handleEditAdministractiveClick();
            }}
          >
            <Edit />
          </IconButton>
        </div>
        <div className="detailsCard__body">
          <div className="detailsCard-part">
            <Typography className="detailsCard-part__title">
              Business Unit
            </Typography>
            <Typography className="detailsCard-part__text">
              {/* {getBusinessUnitName(clientBasicDetails?.businessUnit)} */}
              {getBusinessUnitName(
                businessUnits,
                clientBasicDetails?.businessUnit
              )?.map((res: string, index: number) => {
                return `${index ? "," : ""} ${res}`;
              })}
            </Typography>
          </div>
          <div className="detailsCard-part">
            <Typography className="detailsCard-part__title">
              Referral Source
            </Typography>
            <Typography className="detailsCard-part__text">
              {clientBasicDetails?.hearAboutUs}
            </Typography>
          </div>
          <div className="detailsCard-part">
            <Typography className="detailsCard-part__title">
              Intake Start Date
            </Typography>
            <Typography className="detailsCard-part__text">
              {moment(clientBasicDetails?.createdAt).format("MM/DD/YYYY")}
            </Typography>
          </div>
          <div className="detailsCard-part">
            <Typography className="detailsCard-part__title">
              Concerns
            </Typography>
            <Typography className="detailsCard-part__text">
              {clientBasicDetails?.concerns}
            </Typography>
          </div>
        </div>
      </div>
      {isEditClient && editAdministrativeInfo && (
        <>
          <Dialog
            open={editAdministrativeInfo}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client - Admin Information
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForAdministrativeDetails}
              >
                <Close />
              </Button>
            </Box>
            <AdminDetails onSubmit={onSubmitBasicInfo} />
          </Dialog>
        </>
      )}
    </Grid>
  );
};

export default ClientSubAdminDetails;
