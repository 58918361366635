import { Droppable } from "react-beautiful-dnd";
import Task from "./Task";
import styled from "styled-components";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { filterBoards } from "../../helpers/boards-filter-helper/boards-filter-helper";

interface IBoard {
  //boardName: string; //board ColumnName
  // tasks: CardData[] | CardData[];
  //goal: any;
  stage: any; //boardStage Interface
  tasks: any[];
  index: number;
  callbacks: ((clientId: string) => Promise<void>)[];
  chatCommand: (id: string) => void;
}

const Wrapper = styled.div`
  // Wrapping Handle, Area
  display: flex;
  flex-direction: column;
  padding-left: 100px;
`;

const TaskCount = styled.div`
  // Showing how many tasks in the board.
  padding-left: 10px;
  color: ${(props) => props.theme.textBlurColor};
`;
const Area = styled.div<{ isDraggingOver: boolean }>`
  display: flex;
  // min-height: 400px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  row-gap: 2px;
  background-color: ${(props) =>
    props.isDraggingOver ? props.theme.boardDraggingOverColor : "none"};
`;

const Board: React.FC<IBoard> = ({ stage, tasks, callbacks, chatCommand }) => {
  //Variable stores searchValues
  const searchValue = useSelector<RootState, string>(
    (state) => state.activeStep.searchValue
  );

  return (
    <Wrapper className="StageCard">
      <h5 className="StageCard__title">{stage?.stageName}</h5>
      <div className="StageCard__status">
        <TaskCount>
          Goal: {stage?.goal === 1 ? `1 Day` : `${stage?.goal} Days`}{" "}
        </TaskCount>
      </div>
      <div className="StageCard__dropBox">
        <Droppable droppableId={stage?.stageName}>
          {(provided, snapshot) => (
            <Area
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {filterBoards(searchValue, tasks)?.map((task, index) => (
                <Task
                  key={task.id}
                  id={task.id}
                  index={index}
                  task={task}
                  callbacks={callbacks}
                  chatCommand={chatCommand}
                  stageName={stage?.stageName}
                  intakeOrOnboarding={true}
                />
              ))}
              {provided.placeholder}
            </Area>
          )}
        </Droppable>
      </div>
    </Wrapper>
  );
};

export default Board;
