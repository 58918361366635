import axios from "../axios/axios";

export const retrieveAllDescopeRoles = async () => {
  try {
    const { data } = await axios.get("/descope/roles");
    return data;
  } catch (e: any) {
    //throw error?.response?.data;
    return e;
  }
};

export const switchTenantForUser = async (
  tenantId: string, //new tenantId
) => {
  try {
    const { data } = await axios.put(`/descope/switch/${tenantId}`);
    return { data };
  } catch (e: any) {
    return e;
  }
};

const DescopeService = {
  retrieveAllDescopeRoles,
  switchTenantForUser
};

export default DescopeService;
