import React from "react";
import App from "./App";
import store from "./redux/store";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "./assets/style/index.css";
import "react-calendar-heatmap/dist/styles.css";
import { registerLicense } from "@syncfusion/ej2-base";
import { AuthProvider } from "@descope/react-sdk";
import ApiResponsePopup from "./layouts/global-api-response/GlobalApiResponse";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";
import { useNavigationType, createRoutesFromChildren } from "react-router";
import { matchRoutes } from "react-router";
import { isProd, isTracking } from "./utils/platform";
// Registering Syncfusion license key
registerLicense(
  process.env.REACT_APP_SYNCFUSION_LICENSE_KEY || ""
  // "Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTnxXdkZiX31bdnxUQQ==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TcUdnWXxfdnZQQGlaVg==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkJhXX9dcXJXQ2dcWEM=;MjU5Njg1MEAzMjMwMmUzNDJlMzBHY0VoaHZ4anhFRzRHRnRxeHlGWHRiNU90R1lsZ0IrVkxPMHRROVU1SVE0PQ==;MjU5Njg1MUAzMjMwMmUzNDJlMzBYTFZuZ1JZSFFZN3B6LzBrQ3lHOTRVdUJDMVM5MVAva1VBSkRURXNNSVNBPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVkW3tec3VTRGVbWUdx;MjU5Njg1M0AzMjMwMmUzNDJlMzBlOW90MDdmWHRBTUtXdTE4ekI2L0ZuMWhHbExEdXgyMmhxMkhlVmVCT0VvPQ==;MjU5Njg1NEAzMjMwMmUzNDJlMzBtZitUbmR0bElzUyszaVRFNEdIQnpEcUNrRWZCMTJvblh6cHZSWWhCTFdzPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkJhXX9dcXJXQ2hbVUM=;MjU5Njg1NkAzMjMwMmUzNDJlMzBtRjVlNTRIRzlFTUNkZVJGVDNzcmhtR2Z2VlZpZ0ZrOUx3S1UvQ0lqek5VPQ==;MjU5Njg1N0AzMjMwMmUzNDJlMzBJaEJlMHBKOVN0czVpTVJlTGhGdEdtaVlXSlc2bEpqeVY1Q3dFUnRQUFEwPQ==;MjU5Njg1OEAzMjMwMmUzNDJlMzBlOW90MDdmWHRBTUtXdTE4ekI2L0ZuMWhHbExEdXgyMmhxMkhlVmVCT0VvPQ=="
);

if (isProd()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
} else {
  console.log("Sentry disabled in local environment.");
}

window.initMap = () => {};

const container = document.getElementById("root")!;
const root = createRoot(container);

const descopeProjectId = process.env.REACT_APP_DESCOPE_PROJECT_ID || "";
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN || "";

root.render(
  <Provider store={store}>
    <ApiResponsePopup />
    <AuthProvider projectId={descopeProjectId} baseUrl={cookieDomain}>
      <App />
    </AuthProvider>
  </Provider>
);

reportWebVitals();
