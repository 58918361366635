import React, { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import { ProviderForms } from "../../../types/provider";
import { useSelector } from "react-redux";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";

interface SupervisorProps {
  supervisorId: string;
}

const SupervisorList: React.FC<SupervisorProps> = ({
  supervisorId,
}) => {
  //#region variable region

  //variable to store supervisor
  const [supervisor, setSupervisor] = useState<ProviderForms>();

  //variable to store providerdata
  const providerData = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.listData
  );

  //#endregion

  //#region useEffect region

  //handle supervisor and supervising provider data
  useEffect(() => {
    const supervisorData = providerData.filter((provider) => {
      return provider.employmentDetails.id === supervisorId;
    });
    setSupervisor(supervisorData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supervisorId]);

  //#endregion

  let bookedHours = 0,
    remainingHours = 0;

    supervisor?.availabilityDetails.forEach((details: any) => {
    remainingHours = details.totalRemainingHours
      ? remainingHours + details.totalRemainingHours
      : remainingHours;

    bookedHours = details.totalBookedHours
      ? bookedHours + details.totalBookedHours
      : bookedHours;
  });

  return (
    <div className="expend-level-title">
      <div className="border-box">
        <div className="cardLevelBlock">
          <span className="userMapCart__img">
            <img
              src={
                supervisor?.employmentDetails?.providerProfile?.url ||
                ProviderImg
              }
              className="userSmallCircle"
              alt=""
            />
          </span>
          <div className="userMapCart__name">
            <span>
              {supervisor?.employmentDetails?.firstName}{" "}
              {supervisor?.employmentDetails?.lastName}{" "}
            </span>
            <span className="userMapCart__statusBox">
              <div style={{}}>
                <strong>{bookedHours}</strong>
              </div>
              <div>
                <strong>{remainingHours}</strong>
              </div>
            </span>
          </div>
        </div>
        <div className="border-dot" />
        {providerData.map((e) => {
          if (
            supervisor?.employmentDetails?.supervisor.team.includes(
              e.employmentDetails.id
            )
          ) {
            let remainingHours = 0;
            let bookedHours = 0;
            e.availabilityDetails.forEach((details: any) => {
              remainingHours = details.totalRemainingHours
                ? remainingHours + details.totalRemainingHours
                : remainingHours;

              bookedHours = details.totalBookedHours
                ? bookedHours + details.totalBookedHours
                : bookedHours;
            });
            return (
              <div className="cardLevelBlock">
                <span className="userMapCart__img">
                  <img
                    src={
                      e?.employmentDetails?.providerProfile?.url || ProviderImg
                    }
                    className="userSmallCircle"
                    alt=""
                  />
                </span>
                <div className="userMapCart__name">
                  <span>
                    {e?.employmentDetails?.firstName}{" "}
                    {e?.employmentDetails?.lastName}{" "}
                  </span>
                  <span className="userMapCart__statusBox">
                    <div style={{}}>
                      <strong>{bookedHours}</strong>
                    </div>
                    <div>
                      <strong>{remainingHours}</strong>
                    </div>
                  </span>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SupervisorList;
