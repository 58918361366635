import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

type InLoaderProps = {
  isShow: boolean;
};

const InLoader: React.FC<InLoaderProps> = ({ isShow }) => {

  return (
    <div>
      <Backdrop
        sx={{
          color: "skyBlue",
          // backgroundColor: "#E0E0E0",
          position: "absolute",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isShow}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default InLoader;
