import moment from "moment";
import { ClientForms, ISchedules } from "../../types/client";
import { PlannerFilter } from "../../types/planning";
import { ProviderForms } from "../../types/provider";
import { searchClientText, searchProviderText } from "../../utils/FilterUtils";
import { EntityScheduleStatus } from "../../constants/providers";

export const getDayName = (dayIndex: number) => {
  let daysArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return daysArray[dayIndex];
};

export const canFilterPlanners = (
  filterValues: PlannerFilter,
  searchVal: string
): boolean => {
  if (
    filterValues.businessUnit.length > 0 ||
    filterValues.intakeStage.length > 0 ||
    filterValues.clientConcerns.length > 0 ||
    filterValues.insuranceProvider.length > 0 ||
    filterValues.providerTasks.length > 0 ||
    filterValues.onBoardingStage.length > 0 ||
    filterValues.clientStatus.length > 0 ||
    filterValues.providerStatus.length > 0 ||
    filterValues.providerType.length > 0 ||
    filterValues.providerSpeciality.length > 0 ||
    (filterValues.startDate && filterValues.endDate) ||
    filterValues.months ||
    filterValues.authCode.length > 0 ||
    // filterValues.age !== null ||
    searchVal
  ) {
    return true;
  }
  return false;
};

//logic for filtering a single provider and Client
export const filterSingleClient = (
  item: ClientForms,
  filterValues: PlannerFilter,
  searchVal: string,
  renderDate: Date
): boolean => {
  const searchText = searchClientText(searchVal, item);
  if (!searchText) {
    return false;
  }
  if (item.clientBasicDetails) {
    if (
      filterValues.businessUnit.find((item) => item === "All Units") !==
        "All Units" &&
      filterValues.businessUnit.length &&
      // !filterValues.businessUnit.find(
      //   (unit) => unit === item.clientBasicDetails.businessUnit
      // )
      !filterValues.businessUnit.some((ele) =>
        item.clientBasicDetails.businessUnit.includes(ele)
      )
    ) {
      return false;
    }
    if (filterValues.clientStatus.length > 0) {
      if (
        !filterValues.clientStatus.includes(item.clientBasicDetails.clientType)
      ) {
        return false;
      }
    }
    if (
      filterValues.intakeStage.length &&
      filterValues.intakeStage.find((item) => item === "All Stages") !==
        "All Stages"
    ) {
      if (
        !filterValues.intakeStage.includes(item.clientBasicDetails.intakeStage)
      ) {
        return false;
      }
    }

    if (
      !filterValues.clientConcerns.includes("All Concerns") &&
      filterValues.clientConcerns.length
    ) {
      if (
        filterValues.clientConcerns.length &&
        !filterValues.clientConcerns.includes(
          item.clientBasicDetails.checklistHistory[0]
        )
      ) {
        return false;
      }
    }
    // if (filterValues.age !== null) {
    //   if (
    //     moment().diff(item.clientBasicDetails.childDateOfBirth, "years") ===
    //     Number(filterValues.age)
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  }
  // if (filterValues.ScheduleStatus) {
  //   if (item.clientUtilization.status === filterValues.ScheduleStatus) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  if (filterValues.ScheduleStatus) {
    if (
      filterValues.ScheduleStatus === EntityScheduleStatus.NoAvailability ||
      filterValues.ScheduleStatus === EntityScheduleStatus.AvailabilityExpired
    ) {
      //read from clientUtilization
      if (item.clientUtilization.status === filterValues.ScheduleStatus) {
        return true;
      } else {
        return false;
      }
    } else {
      //read from clientAuthUtilization
      if (item.clientAuthUtilization.status === filterValues.ScheduleStatus) {
        return true;
      } else {
        return false;
      }
    }
  }
  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  if (filterValues.months.length > 0) {
    const result = checkMonthsInRange(item.availabilityDetails, filterValues);
    if (result) {
      return true;
    } else {
      return false;
    }
  }
  if (filterValues.authCode.length > 0) {
    let isExist = false;
    if (item.authorizations.length > 0) {
      let result = [];
      item.authorizations.forEach((ele) => {
        ele.auth.forEach((auth) => {
          if (filterValues.authCode.includes(auth.authCode)) {
            result.push(true);
          }
        });
      });
      if (result.length > 0) {
        return (isExist = true);
      } else {
        return (isExist = false);
      }
    }
    return isExist;
  }
  return true;
};

//logic for filtering a single provider and Client
export const filterSingleProvider = (
  item: ProviderForms,
  filterValues: PlannerFilter,
  searchVal: string,
  renderDate: Date
): boolean => {
  const searchText = searchProviderText(searchVal, item);
  if (!searchText) {
    return false;
  }

  if (
    filterValues.insuranceProvider.find((item) => item === "All Employees") !==
      "All Employees" &&
    filterValues.insuranceProvider.length
  ) {
    let count = 0;
    item.providerInsurance.forEach((item) => {
      if (filterValues.insuranceProvider.find((unit) => unit === item.name)) {
        count++;
      }
    });
    if (count === 0) {
      return false;
    }
  }
  // if (
  //   filterValues.businessUnit !== "All Units" &&
  //   filterValues.businessUnit &&
  //   !(item.employmentDetails.businessUnit === filterValues.businessUnit)
  // ) {
  //   return false;
  // }
  if (
    filterValues.businessUnit.find((item) => item !== "All Units") &&
    filterValues.businessUnit.length &&
    // !filterValues.businessUnit.find(
    //   (unit) => unit === item.employmentDetails.businessUnit
    // )
    !filterValues.businessUnit.some((ele) =>
      item.employmentDetails.businessUnit.includes(ele)
    )
  ) {
    return false;
  }
  if (filterValues.providerStatus.length > 0) {
    if (
      !filterValues.providerStatus.includes(item.employmentDetails.providerType)
    ) {
      return false;
    }
  }

  if (filterValues.providerType.length > 0) {
    if (!filterValues.providerType.includes(item.employmentDetails.type)) {
      return false;
    }
  }

  if (filterValues.ScheduleStatus) {
    if (item.providerUtilization.status === filterValues.ScheduleStatus) {
      return true;
    } else {
      return false;
    }
  }
  if (
    filterValues.providerSpeciality.length > 0 &&
    // !filterValues.businessUnit.includes(item.employmentDetails?.businessUnit)
    !filterValues.providerSpeciality.some((ele) =>
      item.employmentDetails.specialities.includes(ele)
    )
  ) {
    return false;
  }
  let onBoardingFlag = true;
  while (onBoardingFlag) {
    if (filterValues.onBoardingStage.length > 0) {
      if (filterValues.onBoardingStage.includes("All Stages")) {
        onBoardingFlag = false;
      } else {
        let count = 0;
        filterValues.onBoardingStage.forEach((matchValue: string) => {
          if (item.employmentDetails.onBoardingStage === matchValue) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        onBoardingFlag = false;
      }
    }
    onBoardingFlag = false;
  }

  let checkListFlag = true;
  while (checkListFlag) {
    if (filterValues.providerTasks.length > 0) {
      if (filterValues.providerTasks.includes("All Stages")) {
        checkListFlag = false;
      } else {
        let count = 0;
        filterValues.providerTasks.forEach((matchValue: string) => {
          if (item.employmentDetails.checklistHistory.includes(matchValue)) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        checkListFlag = false;
      }
    }
    checkListFlag = false;
  }

  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  if (filterValues.months.length > 0) {
    const result = checkMonthsInRange(item.availabilityDetails, filterValues);
    if (result) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};
function checkMonthsInRange(
  availabilityDetails: any[],
  filterValues: { months: string[] }
): boolean {
  const monthMapping: { [key: string]: number } = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  // Convert month names to numbers
  const filterMonths = filterValues.months.map((month) => monthMapping[month]);

  return availabilityDetails.some((detail: any) => {
    const beginDate = new Date(detail.beginDate);
    const endDate = new Date(detail.endDate);
    const beginYear = beginDate.getFullYear();
    const beginMonth = beginDate.getMonth() + 1; // getMonth() returns 0-11, so we add 1
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth() + 1; // getMonth() returns 0-11, so we add 1

    // Function to check if a month is within the range
    const isMonthInRange = (month: number) => {
      if (beginYear === endYear) {
        return month >= beginMonth && month <= endMonth;
      } else if (beginYear < endYear) {
        return (
          (month >= beginMonth && month <= 12) ||
          (month >= 1 && month <= endMonth)
        );
      }
      return false;
    };

    // Check if any month in filterMonths is within the range
    return filterMonths.some(isMonthInRange);
  });
}

// Example usage:
const availabilityDetails = [
  { beginDate: "2024-01-15", endDate: "2024-03-10" },
  { beginDate: "2024-05-01", endDate: "2024-08-31" },
];

const filterValues = { months: ["February", "June"] }; // February and June

const result = checkMonthsInRange(availabilityDetails, filterValues);
// console.log(result); // true, because February is in the first range and June is in the second range

// export const filterSingleProvider = (
//   item: ProviderForms,
//   filterValues: PlannerFilter,
//   searchVal: string
// ): boolean => {
//   if (searchVal) {
//     if (
//       searchVal &&
//       !item.employmentDetails.firstName
//         .toLowerCase()
//         .includes(searchVal.toLowerCase()) &&
//       !item.employmentDetails.lastName
//         .toLowerCase()
//         .includes(searchVal.toLowerCase())
//     ) {
//       return false;
//     }
//   }

//   if (
//     filterValues.insuranceProvider.find((item) => item === "All Employees") !==
//       "All Employees" &&
//     filterValues.insuranceProvider.length
//   ) {
//     let count = 0;
//     item.providerInsurance.forEach((item) => {
//       if (filterValues.insuranceProvider.find((unit) => unit === item.name)) {
//         count++;
//       }
//     });
//     if (count === 0) {
//       return false;
//     }
//   }
//   if (
//     filterValues.businessUnit.filter((item) => item !== "All Units") &&
//     filterValues.businessUnit.length &&
//     // !filterValues.businessUnit.filter(
//     //   (unit) => unit === item.employmentDetails.businessUnit.find((ele)=> ele === unit)
//     // )
//     !filterValues.businessUnit.some(ele => item.employmentDetails.businessUnit.includes(ele))
//   ) {
//     return false;
//   }
//   if (filterValues.providerStatus.length > 0) {
//     if (
//       !filterValues.providerStatus.includes(item.employmentDetails.providerType)
//     ) {
//       return false;
//     }
//   }

//   let onBoardingFlag = true;
//   while (onBoardingFlag) {
//     if (filterValues.onBoardingStage.length > 0) {
//       if (filterValues.onBoardingStage.includes("All Stages")) {
//         onBoardingFlag = false;
//       } else {
//         let count = 0;
//         filterValues.onBoardingStage.forEach((matchValue: string) => {
//           if (item.employmentDetails.onBoardingStage === matchValue) {
//             count++;
//           }
//         });
//         if (count === 0) {
//           return false;
//         }
//         onBoardingFlag = false;
//       }
//     }
//     onBoardingFlag = false;
//   }

//   let checkListFlag = true;
//   while (checkListFlag) {
//     if (filterValues.providerTasks.length > 0) {
//       if (filterValues.providerTasks.includes("All Stages")) {
//         checkListFlag = false;
//       } else {
//         let count = 0;
//         filterValues.providerTasks.forEach((matchValue: string) => {
//           if (item.employmentDetails.checklist.includes(matchValue)) {
//             count++;
//           }
//         });
//         if (count === 0) {
//           return false;
//         }
//         checkListFlag = false;
//       }
//     }
//     checkListFlag = false;
//   }
//   if (filterValues.ScheduleStatus) {
//     if (item.providerUtilization.status === filterValues.ScheduleStatus) {
//       return true;
//     } else {
//       return false;
//     }
//   }
//   if (item.availabilityDetails?.length > 0) {
//     if (filterValues.startDate && filterValues.endDate) {
//       if (evaluateSchedulerData(item, filterValues).length > 0) {
//         return true;
//       } else {
//         return false;
//       }
//     }
//   }

//   return true;
// };

//return evaluate schedule array
export const evaluateSchedulerData = (
  item: ClientForms | ProviderForms,
  filterValues: { startDate: string | null; endDate: string | null }
) => {
  let arr: any[] = [];
  item.availabilityDetails.filter((item: any) => {
    return filterSingleScheduleListItem(item, filterValues) && arr.push(item);
  });
  return arr;
};

//logic for filtering a single provider and Client
export const filterSingleScheduleListItem = (
  item: any,
  filterValues: { startDate: string | null; endDate: string | null }
): boolean => {
  if (filterValues.startDate && filterValues.endDate) {
    // if (
    //   new Date(moment(filterValues.startDate).format("YYYY-MM-DD")) <=
    //     new Date(item.beginDate) &&
    //   new Date(item.endDate) <=
    //     new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
    // ) {
    //   return true;
    // }
    if (
      new Date(moment(filterValues.endDate).format("YYYY-MM-DD")) >=
      new Date(item.endDate)
    ) {
      if (
        new Date(item.endDate) >=
        new Date(moment(filterValues.startDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        new Date(item.beginDate) <=
        new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
};

//return if an object is provider
function instanceOfA(object: any): object is ProviderForms {
  if (object) return "employmentDetails" in object;
  else return false;
}

//logic for handling the search and filter
export const filterPlanners = <T>(
  filterValues: PlannerFilter,
  searchVal: string,
  data: T[],
  renderDate?: Date
): T[] => {
  return canFilterPlanners(filterValues, searchVal)
    ? data.filter((item: T) => {
        if (instanceOfA(item)) {
          return filterSingleProvider(
            item as ProviderForms,
            filterValues,
            searchVal,
            renderDate !== undefined ? renderDate : new Date()
          );
        } else {
          return filterSingleClient(
            item as ClientForms,
            filterValues,
            searchVal,
            renderDate !== undefined ? renderDate : new Date()
          );
        }
      })
    : data;
};
