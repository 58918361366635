import React, { useEffect, useState } from "react";
import PlanningsHeading from "./planning-Heading/PlanningHeading";
import PlanningsDetails from "./planning-Details/PlanningsDetails";

const Plannings: React.FC = () => {

  //tracks selection changes in planning calender list
  const [changedList, setchangedList] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      localStorage.removeItem("schedule");
    };
  }, []);
  
  return (
    <div className="pageTemplate mapDetailsTemplate">
      <PlanningsHeading
        changedList={changedList}
        setchangedList={setchangedList}
      />
      <PlanningsDetails setchangedList={setchangedList} />
    </div>
  );
};

export default Plannings;
