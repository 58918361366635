import moment from "moment";
import { ClientForms, ISchedules } from "../../types/client";
import { MessageFilter } from "../../types/message";
import { ProviderForms } from "../../types/provider";
import { AvailabilityDetails } from "../../types/availability";
import { searchClientText, searchProviderText } from "../../utils/FilterUtils";
import { EntityScheduleStatus } from "../../constants/providers";
import { IUserProfile } from "../../types/user";

export const canFilterMessage = (
  filterValues: MessageFilter,
  searchVal: string
): boolean => {
  if (
    filterValues.businessUnit.length > 0 ||
    filterValues.intakeStage.length > 0 ||
    filterValues.clientConcerns.length > 0 ||
    filterValues.insuranceProvider.length > 0 ||
    filterValues.providerStatus.length > 0 ||
    filterValues.providerTasks.length > 0 ||
    filterValues.onBoardingStage.length > 0 ||
    filterValues.clientStatus.length > 0 ||
    filterValues.startDate ||
    filterValues.endDate ||
    filterValues.ScheduleStatus ||
    searchVal
  ) {
    return true;
  }
  return false;
};

//return evaluate schedule array
export const evaluateSchedulerData = (
  item: ClientForms | ProviderForms,
  filterValues: { startDate: string | null; endDate: string | null }
) => {
  let arr: AvailabilityDetails[] = [];
  item.availabilityDetails.filter((item: AvailabilityDetails) => {
    return filterSingleScheduleListItem(item, filterValues) && arr.push(item);
  });
  return arr;
};

//logic for filtering a single provider and Client
export const filterSingleScheduleListItem = (
  item: AvailabilityDetails,
  filterValues: { startDate: string | null; endDate: string | null }
): boolean => {
  if (filterValues.startDate && filterValues.endDate) {
    // if (
    //   new Date(moment(filterValues.startDate).format("YYYY-MM-DD")) <=
    //     new Date(item.specificDate) &&
    //   new Date(item.specificDate) <=
    //     new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
    // ) {
    //   return true;
    // }
    if (
      new Date(moment(filterValues.endDate).format("YYYY-MM-DD")) >=
      new Date(moment(item.endDate).format("YYYY-MM-DD"))
    ) {
      if (
        new Date(moment(item.endDate).format("YYYY-MM-DD")) >=
        new Date(moment(filterValues.startDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        new Date(moment(item.beginDate).format("YYYY-MM-DD")) <=
        new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
};

//logic for filtering a single provider and Client
export const filterSingleClient = (
  item: ClientForms,
  filterValues: MessageFilter,
  searchVal: string
): boolean => {
  const searchText = searchClientText(searchVal, item);
  if (!searchText) {
    return false;
  }

  if (item.clientBasicDetails) {
    if (
      filterValues.businessUnit.find((item) => item === "All Units") !==
        "All Units" &&
      filterValues.businessUnit.length &&
      // !filterValues.businessUnit.find(
      //   (unit) => unit === item.clientBasicDetails.businessUnit
      // )
      !filterValues.businessUnit.some((ele) =>
        item.clientBasicDetails.businessUnit.includes(ele)
      )
    ) {
      return false;
    }
    if (filterValues.clientStatus.length > 0) {
      if (
        !filterValues.clientStatus.includes(item.clientBasicDetails.clientType)
      ) {
        return false;
      }
    }
    if (
      filterValues.intakeStage.length &&
      filterValues.intakeStage.find((item) => item === "All Stages") !==
        "All Stages"
    ) {
      if (
        !filterValues.intakeStage.includes(item.clientBasicDetails.intakeStage)
      ) {
        return false;
      }
    }

    if (
      !filterValues.clientConcerns.includes("All Concerns") &&
      filterValues.clientConcerns.length
    ) {
      if (
        filterValues.clientConcerns.length &&
        !filterValues.clientConcerns.includes(
          item.clientBasicDetails.checklistHistory[0]
        )
      ) {
        return false;
      }
    }

    if (
      filterValues.insuranceProvider.find(
        (item) => item === "All Employees"
      ) !== "All Employees" &&
      filterValues.insuranceProvider.length
    ) {
      let count = 0;
      item.clientInsurance.forEach((item) => {
        if (
          filterValues.insuranceProvider.find(
            (unit) => unit === item.insuranceProviderName
          )
        ) {
          count++;
        }
      });
      if (count === 0) {
        return false;
      }
    }
  }
  // if (filterValues.ScheduleStatus) {
  //   if (item.clientUtilization.status === filterValues.ScheduleStatus) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  if (filterValues.ScheduleStatus) {
    if (
      filterValues.ScheduleStatus === EntityScheduleStatus.NoAvailability ||
      filterValues.ScheduleStatus === EntityScheduleStatus.AvailabilityExpired
    ) {
      //read from clientUtilization
      if (item.clientUtilization.status === filterValues.ScheduleStatus) {
        return true;
      } else {
        return false;
      }
    } else {
      //read from clientAuthUtilization
      if (item.clientAuthUtilization.status === filterValues.ScheduleStatus) {
        return true;
      } else {
        return false;
      }
    }
  }
  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  return true;
};

//logic for filtering a single provider
export const filterSingleProvider = (
  item: ProviderForms,
  filterValues: MessageFilter,
  searchVal: string
): boolean => {
  const searchText = searchProviderText(searchVal, item);
  if (!searchText) {
    return false;
  }

  if (
    filterValues.insuranceProvider.find((item) => item === "All Employees") !==
      "All Employees" &&
    filterValues.insuranceProvider.length
  ) {
    let count = 0;
    item.providerInsurance.forEach((item) => {
      if (filterValues.insuranceProvider.find((unit) => unit === item.name)) {
        count++;
      }
    });
    if (count === 0) {
      return false;
    }
  }

  if (
    filterValues.businessUnit.filter((item) => item !== "All Units") &&
    filterValues.businessUnit.length &&
    // !filterValues.businessUnit.filter(
    //   (unit) => unit === item.employmentDetails.businessUnit.find((ele)=> ele === unit)
    // )
    !filterValues.businessUnit.some((ele) =>
      item.employmentDetails.businessUnit.includes(ele)
    )
  ) {
    return false;
  }
  if (filterValues.providerStatus.length > 0) {
    if (
      !filterValues.providerStatus.includes(item.employmentDetails.providerType)
    ) {
      return false;
    }
  }

  let onBoardingFlag = true;
  while (onBoardingFlag) {
    if (filterValues.onBoardingStage.length > 0) {
      if (filterValues.onBoardingStage.includes("All Stages")) {
        onBoardingFlag = false;
      } else {
        let count = 0;
        filterValues.onBoardingStage.forEach((matchValue: string) => {
          if (item.employmentDetails.onBoardingStage === matchValue) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        onBoardingFlag = false;
      }
    }
    onBoardingFlag = false;
  }

  let checkListFlag = true;
  while (checkListFlag) {
    if (filterValues.providerTasks.length > 0) {
      if (filterValues.providerTasks.includes("All Stages")) {
        checkListFlag = false;
      } else {
        let count = 0;
        filterValues.providerTasks.forEach((matchValue: string) => {
          if (item.employmentDetails.checklistHistory.includes(matchValue)) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        checkListFlag = false;
      }
    }
    checkListFlag = false;
  }
  if (filterValues.ScheduleStatus) {
    if (item.providerUtilization.status === filterValues.ScheduleStatus) {
      return true;
    } else {
      return false;
    }
  }
  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  return true;
};

//logic for filtering a single provider and Client
export const filterSingleAdmin = (
  item: IUserProfile,
  filterValues: MessageFilter,
  searchVal: string
): boolean => {
  const fullName = `${item.firstName} ${item.lastName}`;
  if (
    searchVal &&
    !fullName.toLowerCase().includes(searchVal.toLowerCase().trim())
  ) {
    return false;
  }

  if (
    filterValues.businessUnit.filter((item) => item !== "All Units") &&
    filterValues.businessUnit.length &&
    !filterValues.businessUnit.some((ele) => item.businessUnit.includes(ele))
  ) {
    return false;
  }

  return true;
};

function instanceOfA(object: any): object is ProviderForms {
  return object && "employmentDetails" in object;
}

function instanceOfIUserProfile(object: any): object is IUserProfile {
  return object && "authRole" in object;
}

// Modify the filter logic to simplify
export const filterMessage = <T>(
  filterValues: MessageFilter,
  searchVal: string,
  data: T[]
): T[] => {
  return canFilterMessage(filterValues, searchVal)
    ? data.filter((item: T) => {
        if (instanceOfA(item)) {
          return filterSingleProvider(
            item as ProviderForms,
            filterValues,
            searchVal
          );
        } else if (instanceOfIUserProfile(item)) {
          return filterSingleAdmin(
            item as IUserProfile,
            filterValues,
            searchVal
          );
        } else {
          return filterSingleClient(
            item as ClientForms,
            filterValues,
            searchVal
          );
        }
      })
    : data;
};