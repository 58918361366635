//same as the backend
export enum DataCatalogConcepts {
  AuthCode = "Auth Code",
  Insurance = "Insurance",
  ProviderType = "Employee Type",
  DiagnosisType = "Diagnosis Type",
  ClientCancelReason = "Client Cancel Reason",
  ProviderCancelReason = "Employee Cancel Reason",
  BillableActivity = "Billable Activity",
  NonBillableActivity = "Non Billable Activity",
  // ChecklistPaperWork = "Checklist PaperWork",
  // ChecklistTraining = "Checklist Training",
  // ChecklistCredentialing = "Checklist Credentialing",
  // ConcernedStage = "Concerned Stage",
  TemplateVariable = "Template Variable",
  ProviderSpecialty = "Provider Specialty",
  TaskType = "Task Type",
}
