import React, { useEffect, useState } from "react";
import { flushSync } from "react-dom";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import { RootState, AppDispatch } from "../../../redux/store";

import DeleteIcon from "@mui/icons-material/Delete";
import "../../providers/provider/Providers.scss";
import { IAvailabilityDays } from "../../../types/Common";
import {
  AvailabilityTimes,
  AvailabilityTimesOne,
  WeekDays,
} from "../../../constants/providers";
import { AvailabilityDetails, SelectedDay } from "../../../types/availability";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
//import { SelectedDay } from "../../../types/provider";
import {
  addClientReducer,
  addNewAvailability,
  deleteAvailability,
  deleteSingleAvailability,
  removeAvailability,
  setBeginDate,
  setClientSelectedDays,
  setDay,
  setEndDate,
  setEntityId,
  setFromTime,
  setToTime,
} from "../../../redux/features/client-main/client-slice";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AvailabilityTimesForSchedulesBars } from "../../../constants/schedules-filters";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { formattedDate } from "../../../constants/common";
import { getOneWeekAvailability } from "../../../utils/AvailabilityUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IClientAvailabilityDetails {
  onSubmit?: () => void;
  isBoardPage: boolean;
  isEdit?: boolean;
  setAvailabilityId?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ClientAvailabilityDetailsNew: React.FC<IClientAvailabilityDetails> = ({
  onSubmit,
  isBoardPage,
  isEdit,
  setAvailabilityId,
}) => {
  // #region variable region

  //defined dispatch
  const dispatch = useDispatch<AppDispatch>();

  //store Multi Availability
  const availabilityDetails = useSelector<RootState, AvailabilityDetails[]>(
    (state) => state.client.availabilityDetails
  );

  //new error
  const [, setNewError] = useState<boolean>(false);

  //time error
  const [, setTimeError] = useState<boolean>(false);

  // store availability Index
  const [availabilityIndex, setAvailabilityIndex] = useState<number>(0);
  //Store variable startdate

  const [startDate, setStartDate] = useState<Dayjs | null | any>(
    availabilityDetails[availabilityIndex]?.beginDate
  );

  //Store availability endDate
  const [endDate, setendDate] = useState<Dayjs | null | any>(
    availabilityDetails[availabilityIndex]?.endDate
  );

  //active step for the stepper
  const { activeStep } = useSelector((state: RootState) => state.activeStep);

  //Edit Client to set boolean for popups and change Buttons Featurs
  const { isEditClient } = useSelector((state: RootState) => state.client);

  //handle the Error to show the warnings
  const [hasError, setHasError] = useState<boolean>(false);

  //show for warning message
  const [errorMessage, setErrorMessage] = useState<string>("");

  //handle dayError
  const [dayError, setDayError] = useState<boolean>(false);

  //time overlap on same day error
  const [timeErrorMessage, setTimeErrorMessage] = useState<string>("");

  // #endregion

  // #region methods region

  //Handles the start time logic
  const handleFromTime = (
    e: SelectChangeEvent<string>,
    index: number,
    AvailabilityTimesOne: string[]
  ): void => {
    dispatch(
      setFromTime({
        availabilityIndex: availabilityIndex,
        index: index,
        value: e.target.value,
      })
    );
  };

  //Handles the end Time logic
  const handleToTime = (e: SelectChangeEvent<string>, index: number): void => {
    dispatch(
      setToTime({
        availabilityIndex: availabilityIndex,
        index: index,
        value: e.target.value,
      })
    );
  };

  //create duplicate availability
  const duplicateAvailability = (index: number, item: SelectedDay): void => {
    const newAvailability = [
      ...availabilityDetails[availabilityIndex]?.selectedDays,
    ];
    // newAvailability.splice(index, 0, item);
    newAvailability.push({
      ...item,
      startTime: item.startTime,
      endTime: item.endTime,
    });
    dispatch(setClientSelectedDays({ availabilityIndex, newAvailability }));
  };

  //Returns the drop down values for start and end time
  const getValues = (index: number): JSX.Element[] => {
    const data: JSX.Element[] = [];
    if (index < AvailabilityTimes.length) {
      for (let i = index; i < AvailabilityTimes.length; i++) {
        data.push(
          <MenuItem key={i} value={AvailabilityTimes[i].value}>
            {AvailabilityTimes[i].value}
          </MenuItem>
        );
      }
    }
    return data;
  };

  //returns day index
  const returnDayIndex = (day: string): number => {
    return WeekDays.indexOf(day);
  };

  //gets index from time
  const getIndexFromTime = (val: string): number => {
    for (let i = 0; i < AvailabilityTimesForSchedulesBars.length; i++) {
      if (AvailabilityTimesForSchedulesBars[i] === val) return i;
    }
    return -1;
  };

  // handle form submit
  const handleSubmit = () => {
    /*if (!availabilityDetails.endDate) {
      setIsDateNull(true);
      return;
    } else {
      setIsDateNull(false);
    }*/
    if (!availabilityDetails[availabilityIndex]?.endDate) {
      // dispatch(setEndDate(initialEndDate));
    }

    let newAvailabilityArray: IAvailabilityDays[][] = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ];

    if (
      availabilityDetails[availabilityIndex].selectedDays[0].dayOfWeek
        .length === 0 ||
      availabilityDetails[availabilityIndex].selectedDays[0].startTime
        .length === 0 ||
      availabilityDetails[availabilityIndex].selectedDays[0].endTime.length ===
        0
    ) {
      setDayError(true);
      return;
    }

    availabilityDetails[availabilityIndex]?.selectedDays.forEach(
      (item: SelectedDay) => {
        let index = returnDayIndex(item.dayOfWeek);
        let day = {
          dayOfWeek: item.dayOfWeek,
          startIndex: getIndexFromTime(item.startTime),
          endIndex: getIndexFromTime(item.endTime),
        };
        if (day.startIndex < 0 || day.endIndex < 0 || index < 0) {
          setTimeError(true);
          setTimeout(() => {
            setTimeError(false);
          }, 3000);
          return;
        }
        newAvailabilityArray[index].push(day);
      }
    );

    newAvailabilityArray = newAvailabilityArray.map(
      (item: IAvailabilityDays[]) => {
        return item.sort(
          (a: IAvailabilityDays, b: IAvailabilityDays) =>
            a.startIndex - b.startIndex
        );
      }
    );

    let flag = false;
    newAvailabilityArray.forEach((item) => {
      for (let i = 0; i < item.length - 1; i++) {
        if (item[i].endIndex > item[i + 1].startIndex) {
          flag = true;
          break;
        }
      }
    });

    if (flag) {
      setTimeErrorMessage("For same selected day time should not overlap");
      setNewError(true);
      setTimeout(() => {
        setTimeErrorMessage("");
        setNewError(false);
      }, 3000);
      return;
    }

    if (!hasError) {
      if (isEditClient || isEdit) {
        if (onSubmit) {
          dispatch(setEntityId({}));
          onSubmit();
        }
      } else {
        dispatch(addClientReducer({ isBoardPage: isBoardPage }));
        dispatch(setEntityId({}));
        dispatch(setActiveSteps(0));
        if (onSubmit) {
          onSubmit();
        }
      }
    }
  };

  //handle the prevent availiability overlaping
  const availabilityOverlap = (d1: any, availabilityIndex: number) => {
    let overlap = false;

    let val: AvailabilityDetails[] = availabilityDetails.filter(
      (i) => i.id === ""
    );

    let data: AvailabilityDetails[] = [];
    if (val.length > 0) {
      data = availabilityDetails.filter((i) => i.id !== "");
    } else {
      data = availabilityDetails.filter(
        (i, index: number) => index !== availabilityIndex
      );
    }

    data.forEach((a) => {
      if (
        new Date(formattedDate(d1)) <= new Date(formattedDate(a.endDate)) &&
        new Date(formattedDate(d1)) >= new Date(formattedDate(a.beginDate))
      ) {
        overlap = true;
      }
    });

    setHasError(overlap);

    if (overlap) {
      setErrorMessage(
        "Please select other date, date should not overlap previous date "
      );
    }

    return overlap;
  };

  // handle start date of authperiod
  const handleStartDate = (newValue: Dayjs | null) => {
    //update state immediately
    flushSync(() => {
      setStartDate(newValue);
    });

    const checkDate = dayjs(newValue).format("YYYY-MM-DD");

    if (checkDate !== "Invalid Date") {
      dispatch(
        setBeginDate({
          availabilityIndex: availabilityIndex,
          beginDate: checkDate,
        })
      );
    }

    if (availabilityIndex > 0) {
      availabilityOverlap(
        dayjs(newValue).format("YYYY-MM-DD"),
        availabilityIndex
      );
    }
  };

  //handle enddate of authperiod
  const handleEndDate = (newValue: Dayjs | null) => {
    //update state immediately
    flushSync(() => {
      setendDate(newValue);
    });

    // const checkDate = dayjs(newValue).format("YYYY-MM-DD");
    const obj = {
      endDate: dayjs(newValue).format("YYYY-MM-DD"),
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
    };

    if (obj.endDate !== "Invalid Date") {
      dispatch(
        setEndDate({
          availabilityIndex: availabilityIndex,
          endDate: obj.endDate,
        })
      );
      setHasError(false);
    }

    if (availabilityIndex > 0) {
      availabilityOverlap(
        dayjs(newValue).format("YYYY-MM-DD"),
        availabilityIndex
      );
    }
  };

  //handle delete Availability
  const handleDeleteAvailability = (id: string, index: number) => {
    if (id && !setAvailabilityId) {
      dispatch(
        deleteSingleAvailability({
          availableId: id,
        })
      );
      dispatch(removeAvailability({ id: id }));
    } else if (setAvailabilityId) {
      setAvailabilityId(id);
      dispatch(removeAvailability({ id: id }));
    } else {
      dispatch(removeAvailability({ index: index }));
    }
    setAvailabilityIndex(0);
  };

  // #endregion

  // #region useEffect region
  useEffect(() => {
    if (availabilityIndex >= 0) {
      setStartDate(availabilityDetails[availabilityIndex]?.beginDate);
      setendDate(availabilityDetails[availabilityIndex]?.endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityIndex]);

  // #endregion

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm onSubmit={handleSubmit}>
          <div className="stepperForm__inner" style={{ marginBottom: 0 }}>
            <Grid container spacing={2} className="InsuranceHeader">
              <Grid item xs={12} lg={6}></Grid>

              <Grid item xs={12} lg={6} className="InsuranceHeader__btn">
                <Button
                  onClick={() => {
                    const newStartDate = moment(
                      availabilityDetails[availabilityDetails?.length - 1]
                        ?.endDate
                    )
                      .add(1, "days")
                      .format("YYYY-MM-DD");
                    const newEndDate = moment(newStartDate)
                      .add(6, "M")
                      .format("YYYY-MM-DD");

                    dispatch(
                      addNewAvailability({
                        beginDate: newStartDate,
                        endDate: newEndDate,
                      })
                    );
                    setStartDate(newStartDate);
                    setendDate(newEndDate); //note: not setEndDate
                    setAvailabilityIndex(availabilityDetails?.length);
                    setDayError(false);
                  }}
                >
                  + Add Availability Table
                </Button>
              </Grid>
              {/* )} */}
            </Grid>

            {errorMessage && hasError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                {errorMessage}
              </FormHelperText>
            )}
          </div>

          <div className="stepperForm__inner">
            <Grid container spacing={3} style={{ position: "relative" }}>
              {/* Show Left Panel */}
              <Grid item xs={12} lg={3}>
                <div className="authListBox">
                  {availabilityDetails?.map(
                    (item: AvailabilityDetails, index: number) => {
                      return (
                        <div className="authListBox__item" key={index}>
                          <div
                            className={
                              availabilityIndex === index
                                ? "authListBox__head active"
                                : "authListBox__head"
                            }
                          >
                            <p
                              className="authListBox__title"
                              onClick={() => {
                                setAvailabilityIndex(index);
                                setDayError(false);
                              }}
                            >
                              {item?.beginDate
                                ? moment(item?.beginDate).format("YYYY-MM-DD")
                                : ""}
                              {" - "} <br></br>
                              {item?.endDate
                                ? moment(item?.endDate).format("YYYY-MM-DD")
                                : ""}
                            </p>
                            {availabilityDetails?.length === 1 ? null : (
                              <span className="authListBox__removeBtn">
                                <DeleteIcon
                                  onClick={() =>
                                    handleDeleteAvailability(item.id, index)
                                  }
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </Grid>

              {/* Show Right Panel */}
              <Grid item xs={12} lg={9}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <DesktopDatePicker
                            // disablePast={true}
                            className="singleDatePicker"
                            label="Begin Date"
                            inputFormat="YYYY-MM-DD"
                            value={startDate}
                            maxDate={endDate}
                            onChange={handleStartDate}
                            renderInput={(params) => (
                              <TextField
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DesktopDatePicker
                            // disablePast={true}
                            className="singleDatePicker"
                            label="End Date"
                            inputFormat="YYYY-MM-DD"
                            value={endDate}
                            minDate={startDate}
                            onChange={handleEndDate}
                            renderInput={(params) => (
                              <TextField
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={10} style={{ marginBottom: "-10px" ,marginLeft:"8px"}}>
                    <div className="time-group">
                      <Box className="time-group-select">
                        <label>Day</label>
                      </Box>
                      <span className="time-group__space"></span>
                      <Box className="time-group-select">
                        <label>Time Range</label>
                      </Box>
                      <span className="time-group__space"></span>
                      <Box className="time-group-select">
                        <label></label>
                      </Box>
                      <span className="time-group__space">
                        <label></label>
                      </span>
                    </div>
                  </Grid>
                  {availabilityDetails[availabilityIndex]?.selectedDays.map(
                    (item, index) => {
                      return (
                        <Grid item xs={12} key={index}>
                          <Box className="fromGroup">
                            <Grid container spacing={0} alignItems={"center"}>
                              <Grid item xs={12} sm={12}>
                                <div className="time-group">
                                  <Box className="time-group-select">
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={item.dayOfWeek}
                                      MenuProps={MenuProps}
                                      onChange={(
                                        e: SelectChangeEvent<string>
                                      ) => {
                                        setDayError(false);
                                        dispatch(
                                          setDay({
                                            availabilityIndex:
                                              availabilityIndex,
                                            index: index,
                                            value: e.target.value,
                                          })
                                        );
                                      }}
                                    >
                                      {WeekDays.map(
                                        (day: string, index: number) => {
                                          return (
                                            <MenuItem key={index} value={day}>
                                              {day}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </Box>
                                  <span className="time-group__space">-</span>
                                  <Box className="time-group-select">
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={item.startTime}
                                      MenuProps={MenuProps}
                                      onChange={(
                                        e: SelectChangeEvent<string>
                                      ) => {
                                        handleFromTime(
                                          e,
                                          index,
                                          AvailabilityTimesOne
                                        );
                                        setDayError(false);
                                      }}
                                    >
                                      {getValues(0)}
                                    </Select>
                                  </Box>
                                  <span className="time-group__space">-</span>
                                  <Box className="time-group-select">
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      MenuProps={MenuProps}
                                      value={item.endTime}
                                      onChange={(
                                        e: SelectChangeEvent<string>
                                      ) => {
                                        handleToTime(e, index);
                                        setDayError(false);
                                      }}
                                    >
                                      {getValues(
                                        getIndexFromTime(item.startTime) + 1
                                      )}
                                    </Select>
                                  </Box>
                                  <span className="time-group__space">
                                    <ContentCopyIcon
                                      className="removeTimeBtn"
                                      onClick={() =>
                                        duplicateAvailability(index, item)
                                      }
                                    />
                                  </span>
                                  <span className="time-group__space">
                                    <DeleteIcon
                                      className="removeTimeBtn"
                                      onClick={() => {
                                        if (
                                          availabilityDetails[availabilityIndex]
                                            .selectedDays.length > 1
                                        ) {
                                          dispatch(
                                            deleteAvailability({
                                              availabilityIndex:
                                                availabilityIndex,
                                              index: index,
                                            })
                                          );
                                        } else {
                                          dispatch(
                                            setDay({
                                              availabilityIndex:
                                                availabilityIndex,
                                              index: index,
                                              value: "",
                                            })
                                          );
                                          dispatch(
                                            setFromTime({
                                              availabilityIndex:
                                                availabilityIndex,
                                              index: index,
                                              value: "",
                                            })
                                          );
                                          dispatch(
                                            setToTime({
                                              availabilityIndex:
                                                availabilityIndex,
                                              index: index,
                                              value: "",
                                            })
                                          );
                                        }
                                      }}
                                    />
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      );
                    }
                  )}
                  <Grid item xs={12}>
                    <Typography mt={2} className="">
                      Total Hours:{" "}
                      {getOneWeekAvailability(
                        availabilityDetails,
                        availabilityIndex
                      )}
                    </Typography>
                  </Grid>

                  {timeErrorMessage && (
                    <FormHelperText
                      style={{ color: "#d32f2f", paddingLeft: "26px" }}
                    >
                      {timeErrorMessage}
                    </FormHelperText>
                  )}
                </Grid>
                {dayError && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Please Select All Fields
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </div>
          <Box className="stepperForm__footer">
            {!isEditClient && !isEdit ? (
              <Button
                type="submit"
                color="inherit"
                disabled={activeStep === 0 ? true : false}
                className="border-button"
                onClick={() => dispatch(setActiveSteps(activeStep - 1))}
              >
                Previous
              </Button>
            ) : null}

            <Box sx={{ flex: "1 1 auto" }} />
            <Button type="submit" className="button">
              {isEditClient || isEdit ? "Save Changes" : "Submit"}
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </>
  );
};

export default ClientAvailabilityDetailsNew;
