import { createSlice } from "@reduxjs/toolkit";
import { ProviderFilters } from "../../../types/provider";

const initialState: ProviderFilters = {
  businessUnit: [],
  insuranceProvider: [],
  providerType: [],
  employmentStatus: "",
  onboardingStatus: [],
  availabilityStatus: "",
  onBoardingStage: [],
  checkList: [],
  ScheduleStatus: "",
  startDate: null,
  endDate: null,
};

const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setFilterValues: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearFilterValues: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { setFilterValues, clearFilterValues } = filterSlice.actions;

export default filterSlice;
