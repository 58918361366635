import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Popover,
  TextareaAutosize,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  deleteClientMessageById,
  postClientMessageById,
} from "../../../../redux/features/client-main/client-list-slice";

import ClientSubEditComments from "./ClientSubEditComment";

import { dateToFromNowDaily } from "../../../../helpers/reverse-list/reverse-list";

import ClientImg from "../../../../assets/images/images-svg/client-large.svg";

interface IClientSubComments {
  clientId: string;
}

const ClientSubComments: React.FC<IClientSubComments> = ({ clientId }) => {
  // #region variables region

  //error
  const [error, setError] = useState<string>("");
  //message
  const [message, setMessage] = useState<string>("");

  //store commentId for dispatch
  const [commentId, setCommentId] = useState<string>("");

  //client comments
  const comments = useAppSelector((state) => state.client.clientMessages);

  //dispatch for redux
  const dispatch = useAppDispatch();

  //function that returns one. It's used to set the position of the popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  //hadle edit popup
  const [isActive, setIsActive] = useState<boolean>(false);
  // #endregion

  // #region methods region

  //Line saperator
  const saperator = (text: string) => {
    return (
      <div>
        {text.split("\n").map((item: string) => {
          return <div key={item}>{item}</div>;
        })}
      </div>
    );
  };

  //post message handler
  const postMessage = () => {
    if (message.trim().length === 0) {
      setError("Please Enter Message");
      return;
    }
    if (message) {
      dispatch(postClientMessageById({ message, clientId }));
    }
    setMessage("");
  };

  //hadler update comments
  const EditMessageHandle = () => {
    setAnchorEl(null);
    setIsActive(true);
  };

  //hadler delete comments
  const deleteMessageHandle = () => {
    dispatch(deleteClientMessageById({ commentId }));
    setAnchorEl(null);
  };

  //handle the popover
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    commentId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setCommentId(commentId);
    setIsActive(false);
  };

  //component requests to be closed
  const handleClose = () => {
    setAnchorEl(null);
  };

  //if true Popover component show
  const open = Boolean(anchorEl);

  // #endregion

  return (
    <>
      <Container maxWidth={"md"}>
        <span style={{ color: "#d32f2f" }}>{error}</span>
        <Box className="commentForm">
          <TextareaAutosize
            value={message}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setMessage(e.target.value);
              setError("");
            }}
            placeholder="Leave a notice..."
            minRows={2}
          />
          <Button
            onClick={() => postMessage()}
            variant="contained"
            className="button"
          >
            Add Notice
          </Button>
        </Box>
        <Box className="commentListBox" id="scroll">
          {comments.map((comment) => {
            return (
              <div key={comment.id}>
                {comment.commentType === "User" ? (
                  <Box className="commentListItem">
                    <Box className="commentListItem__user">
                      <span className="commentListItem__user-img">
                        <img
                          src={
                            comment.fromUser?.profilePictureUrl
                              ? comment.fromUser?.profilePictureUrl
                              : ClientImg
                          }
                          className="userSmallCircle"
                          alt=""
                        />
                      </span>
                      <div className="commentListItem__user-info">
                        <div className="commentListItem__user-heading">
                          <h5 className="commentListItem__user-name">
                            {comment.fromUser?.firstName
                              ? comment.fromUser?.firstName +
                                " " +
                                comment.fromUser?.lastName
                              : comment.fromName}
                          </h5>
                          <h6 className="commentListItem__user-time">
                            <span>{dateToFromNowDaily(comment.updatedOn)}</span>
                          </h6>
                        </div>
                        {isActive && commentId === comment.id ? (
                          <ClientSubEditComments
                            commentObj={comment}
                            isActiveClose={setIsActive}
                            clientId={clientId}
                          />
                        ) : (
                          <div className="commentListItem__user-comment">
                            {saperator(comment.message)}
                          </div>
                        )}
                      </div>
                    </Box>
                  </Box>
                ) : (
                  <Box className="commentListItem generated">
                    <Box className="commentListItem__user">
                      <span className="commentListItem__user-img">
                        <img
                          src={
                            comment.fromUser?.profilePictureUrl
                              ? comment.fromUser?.profilePictureUrl
                              : ClientImg
                          }
                          className="userSmallCircle"
                          alt=""
                        />
                      </span>
                      <div className="commentListItem__user-info">
                        <div className="commentListItem__user-heading">
                          <h5 className="commentListItem__user-name">
                            {comment.fromUser?.firstName
                              ? comment.fromUser?.firstName +
                                " " +
                                comment.fromUser?.lastName
                              : comment.fromName}
                          </h5>
                          <h6 className="commentListItem__user-time">
                            <span>{dateToFromNowDaily(comment.createdOn)}</span>
                          </h6>
                        </div>
                        {isActive && commentId === comment.id ? (
                          <ClientSubEditComments
                            commentObj={comment}
                            isActiveClose={setIsActive}
                            clientId={clientId}
                          />
                        ) : (
                          <div className="commentListItem__user-comment">
                            {saperator(comment.message)}
                          </div>
                        )}
                      </div>
                    </Box>
                    <button
                      className="commentListItem__moreBtn"
                      style={{
                        backgroundColor: "white",
                        border: "1px solid white",
                      }}
                      onClick={(
                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleClick(e, comment.id)}
                    >
                      <MoreVert />
                    </button>

                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <ul className="popoverLinkList">
                        <li onClick={EditMessageHandle}>Edit</li>
                        <li onClick={deleteMessageHandle}>Delete</li>
                      </ul>
                    </Popover>
                  </Box>
                )}
              </div>
            );
          })}
        </Box>

        {comments.length > 5 ? (
          <Box className="commentList-btn">
            <button
              onClick={() => {
                document.getElementById("scroll")?.scrollBy(0, 100);
              }}
            >
              Load More
            </button>
          </Box>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};
export default ClientSubComments;
