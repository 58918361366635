import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import FileUpload from "react-material-file-upload";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  addAuthCodeInAuthorization,
  deleteAuthCodeAuthorization,
  deleteAuthorizationFiles,
  setAuthorizationAuthCodes,
  setAuthorizationAuthCodeUnits,
  setAuthorizationDateRange,
  setAuthorizationFiles,
  setAuthorizationInsuranceProvider,
  setAuthorizationName,
  setAuthorizationNumber,
} from "../../../redux/features/client-main/client-slice";
import {
  GetAuthcode,
  getInsuranceProviders,
} from "../../../redux/features/client-main/client-insurance-slice";

import {
  AuthCode,
  ClientAuth,
  ClientAuthPayload,
  InsuranceProviders,
} from "../../../types/client";
import { InsuranceProvider } from "../../../types/response";
import { IHashObj } from "../../../types/Common";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ClientEditAuthorizationFormProps {
  onSubmit: () => void;
  authorizations: ClientAuthPayload[];
  deleteSingleAuthorization: (id: string) => void;
  activeTabFromParent: string;
  authorizationIndex: number;
  clientInsurance: InsuranceProviders[];
  /*deleteAuthorizationFile: (
    id: string,
    key: string,
    index: number,
    activeTab: string
  ) => void;*/
}

const ClientEditAuthorizationForm: React.FC<
  ClientEditAuthorizationFormProps
> = ({
  // authorizations,
  onSubmit,
  authorizationIndex,
  deleteSingleAuthorization,
  activeTabFromParent,
  clientInsurance,
  //deleteAuthorizationFile,
}) => {
  // #region variable region

  //handle the Error to show the warnings
  const [hasError, setHasError] = useState<boolean>(false);

  //show for warning message
  const [errorMessage, setErrorMessage] = useState<string>("");

  //insurance Providers Data
  const insuranceProviders = useAppSelector(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //duplicate error image
  const [duplicateErrorImage] = useState<boolean>(false);

  //duplicate error image
  const [duplicateErrorImageMessage] = useState<string>("");

  // active tab
  const [activeTab, setActiveTab] = useState<string>(
    activeTabFromParent ? activeTabFromParent : "basic"
  );

  //store authorization from slice
  const authorizations = useAppSelector((state) => state.client.authorizations);

  // store date from start
  const [startDate, setStartDate] = useState<Dayjs | any>(
    authorizations
      ? authorizations[authorizationIndex]?.authPeriod?.startDate
      : new Date()
  );
  // store sate to end
  const [endDate, setEndDate] = useState<Dayjs | any>(
    authorizations
      ? authorizations[authorizationIndex]?.authPeriod?.endDate
      : new Date()
  );

  // store insurance temporary
  const [selectedInsuranceName, setSelectedInsuranceName] =
    useState<string>("");

  // store authorization name
  const [authName, setAuthName] = useState<string>(
    authorizations ? authorizations[authorizationIndex].authorizationName : ""
  );

  const [authNumber,setAuthNumber] = useState<string>(authorizations[authorizationIndex].authorizationNumber)

  //duplication error insurance
  const [isInsuranceError, setIsInsuranceError] = useState<boolean>(false);

  //dispatch for redux
  const dispatch = useAppDispatch();

  //auth code error handler
  const [duplicateAuthCodeError, setDuplicateAuthCodeError] =
    useState<boolean>(false);

  // stores authcode
  const authcode = useAppSelector(
    (state) => state.clientInsuranceSlice.clientAuthcode
  );

  // #region methods region

  //handle start date of auth period
  const handleStartDate = (newValue: Dayjs | null) => {
    setStartDate(newValue);
  };

  //handle end date of auth period
  const handleEndDate = (newValue: Dayjs | null) => {
    setEndDate(newValue);
  };

  //remove focus on input field
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // function to used the checked the auth object
  const last = (array: ClientAuth[]): boolean => {
    if (array.length < 1) return false;
    return !Boolean(
      array[array.length - 1].authCode && array[array.length - 1].units
    );
  };

  //make sure AuthInsurance found in existing ClientInsurance
  const validateInsurance = (e: any) => {
    //Note: since we use tenant-level Insurance from data-catalog,
    //currentInsurance.id is from datacatalog id, not global-level InsuranceProvider!!
    const currentInsurance = insuranceProviders.find(
      (insurance) => insurance.insuranceProviderName === e.target.value
    );
    const found = clientInsurance.find(
      (item: any) =>
        item.insuranceProviderName === currentInsurance?.insuranceProviderName
    );

    if (!currentInsurance || found === undefined) {
      //NotFound: in client existing inurance
      setIsInsuranceError(true);
      setSelectedInsuranceName("");
    } else {
      setIsInsuranceError(false);

      setSelectedInsuranceName(e.target.value);
      dispatch(
        setAuthorizationInsuranceProvider({
          index: authorizationIndex,
          value: currentInsurance.id, //this becomes data-catalog id now, backend can handle both!!
        })
      );
    }
  };

  //protect Duplication of insurance
  /*const getInsurance = (e: any) => {
      const New = authorizations.find(
        (item) => item.insuranceId === e.target.value
      );
      setInsurance(e.target.value);
      if (New === undefined) {
        setIsInsuranceError(false);
        dispatch(
          setAuthorizationInsuranceProvider({
            index: authorizationIndex,
            value: e.target.value,
          })
        );
      } else {
        setIsInsuranceError(true);
      }
    };*/

  //handle submitting the data
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let error = false;

    const obj = {
      startDate: dayjs(startDate).format("MM/DD/YYYY"),
      endDate: dayjs(endDate).format("MM/DD/YYYY"),
    };

    dispatch(
      setAuthorizationDateRange({
        index: authorizationIndex,
        startDate: obj.startDate,
        endDate: obj.endDate,
      })
    );
    dispatch(
      setAuthorizationName({ index: authorizationIndex, value: authName })
    );
    dispatch(
      setAuthorizationNumber({ index: authorizationIndex, value: authNumber })
    );
    if (isInsuranceError) {
      error = true;
    }

    //turn off by Hui... relax logic to allow partial save
    authorizations[authorizationIndex].auth.forEach((item: ClientAuth) => {
      if (!item.authCode || !item.units) {
        error = true;
      }
    });

    if (error) {
      setHasError(true);
    } else {
      onSubmit();
    }
  };

  useEffect(() => {
    dispatch(getInsuranceProviders());
    dispatch(GetAuthcode());
  }, []);
  // #endregion

  console.log("ClientEditAuthorizationForm.tsx: authorizations", authorizations);

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm onSubmit={handleSubmit}>
          <div className="stepperForm__inner" style={{ marginBottom: 0 }}>
            {/* <Grid container spacing={2} className="InsuranceHeader">
              <Grid item xs={12} lg={6}>
                <h1 className="stepperForm__title">Edit Authorization</h1>
              </Grid>
            </Grid> */}
            {errorMessage && hasError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                {errorMessage}
              </FormHelperText>
            )}
          </div>

          <div className="stepperForm__inner">
            <Grid container spacing={3} style={{ position: "relative" }}>
              <Grid item xs={12} lg={4}>
                <div className="authListBox">
                  <div className="authListBox__item">
                    <div className="authListBox__head">
                      <p className="authListBox__title">{authName}</p>
                      <span className="authListBox__removeBtn">
                        <Delete
                          onClick={() => {
                            if (authorizations.length > 0) {
                              deleteSingleAuthorization(
                                authorizations[authorizationIndex].id
                              );
                            } else {
                              setHasError(true);
                              setErrorMessage(
                                "At least one authorization should be present"
                              );
                              setTimeout(() => {
                                setHasError(false);
                                setErrorMessage("");
                              }, 3000);
                            }
                          }}
                        />
                      </span>
                    </div>
                    <div className="authListBox__body">
                      <span
                        className={
                          activeTab === "basic"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("basic");
                        }}
                      >
                        Basic
                      </span>
                      <span
                        className={
                          activeTab === "files"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("files");
                        }}
                      >
                        Files
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>

              {activeTab === "basic" && (
                <Grid item xs={12} lg={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Authorization Name
                        </InputLabel>
                        <TextValidator
                          onChange={(e: any) => {
                            setAuthName(e.target.value);
                          }}
                          name="authorizationName"
                          value={authName}
                          validators={["required"]}
                          errorMessages={["Please enter authorization name"]}
                          id="authorizationName"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authorizationName");
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="fromGroup">
                        <InputLabel
                          id="demo-simple-select-label"
                          className="fromLabel"
                        >
                          Insurance Provider
                        </InputLabel>
                        <Select
                          className="form-control"
                          value={
                            selectedInsuranceName
                              ? selectedInsuranceName
                              : insuranceProviders.find(
                                  (inc) =>
                                    inc.insuranceProviderName ===
                                    authorizations[authorizationIndex].insurance
                                      .insuranceProviderName
                                )?.insuranceProviderName
                            // insurance.id
                          }
                          placeholder="Select"
                          id="authInsuranceProvider"
                          onChange={(e: any) => validateInsurance(e)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authInsuranceProvider");
                            }
                          }}
                          MenuProps={MenuProps}
                        >
                          {insuranceProviders.map((item: InsuranceProvider) => {
                            return (
                              <MenuItem
                                key={item.id}
                                value={item.insuranceProviderName}
                              >
                                {item.insuranceProviderName}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        {!errorMessage &&
                        hasError &&
                        !authorizations[authorizationIndex].insurance ? (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select insurance provider
                          </FormHelperText>
                        ) : null}
                        {isInsuranceError ? (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select another insurance provider
                          </FormHelperText>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Auth Number
                        </InputLabel>
                        <TextValidator
                          onChange={(e: any) => {
                            setAuthNumber(e.target.value);
                          }}
                          name="authorizationNumber"
                          value={authNumber}
                          id="authorizationNumber"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authorizationNumber");
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel className="fromLabel">Auth Period</InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <DesktopDatePicker
                              className="singleDatePicker"
                              label="Start Date"
                              inputFormat="MM/DD/YYYY"
                              value={startDate}
                              onChange={handleStartDate}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <DesktopDatePicker
                              className="singleDatePicker"
                              label="End Date"
                              inputFormat="MM/DD/YYYY"
                              value={endDate}
                              onChange={handleEndDate}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ position: "relative", textAlign: "right" }}
                    >
                      <Button
                        disabled={
                          authorizations
                            ? last(authorizations[authorizationIndex].auth)
                            : true
                        }
                        className={
                          authorizations
                            ? last(authorizations[authorizationIndex].auth)
                              ? "addBtnDisable"
                              : "addBtn"
                            : "addBtn"
                        }
                        onClick={() => {
                          dispatch(
                            addAuthCodeInAuthorization({
                              index: authorizationIndex,
                            })
                          );
                        }}
                      >
                        + Add Auth Code
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      {duplicateAuthCodeError && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          Please select different auth code
                        </FormHelperText>
                      )}
                      {/* <InputLabel className="fromLabel">Auth Code</InputLabel> */}
                    </Grid>
                    <Grid item xs={6}>
                      {/* <InputLabel className="fromLabel"># of Units</InputLabel> */}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "-20px" }}>
                      {authorizations &&
                        authorizations[authorizationIndex].auth.map(
                          (authCodeItem: ClientAuth, authCodeIndex: number) => {
                            let maxUnits = 0;
                            if (startDate && endDate && authCodeItem.authCode) {
                              const weeks =
                                moment(
                                  endDate["$d"] || new Date(endDate),
                                  "DD-MM-YYYY"
                                ).diff(
                                  moment(
                                    startDate["$d"] || new Date(startDate),
                                    "DD-MM-YYYY"
                                  ),
                                  "week"
                                ) * 5;
                              maxUnits =
                                (authcode.find(
                                  (item) =>
                                    item.conceptValue === authCodeItem.authCode
                                )?.data?.unitsPerDay || 0) * weeks;
                            }
                            return (
                              <div key={authCodeIndex}>
                                <Grid container spacing={3}>
                                  <Grid item xs={5}>
                                    {authCodeIndex === 0 ? (
                                      <InputLabel className="fromLabel">
                                        Auth Code
                                      </InputLabel>
                                    ) : (
                                      ""
                                    )}
                                    <Box className="fromGroup">
                                      <Select
                                        labelId="multiple-select-label"
                                        name="authCode"
                                        id="authCode"
                                        value={authCodeItem.authCode}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("authCode");
                                          }
                                        }}
                                        onChange={(e: any) => {
                                          const selectedAuthorization =
                                            authorizations[authorizationIndex]
                                              .auth;
                                          const authorizationAuthCodes: IHashObj =
                                            {};
                                          selectedAuthorization.forEach(
                                            (element) => {
                                              if (element.authCode) {
                                                authorizationAuthCodes[
                                                  element.authCode.toString()
                                                ] = 1;
                                              }
                                            }
                                          );
                                          if (
                                            authorizationAuthCodes[
                                              e.target.value
                                            ]
                                          ) {
                                            setDuplicateAuthCodeError(true);
                                            return;
                                          }
                                          if (duplicateAuthCodeError) {
                                            setDuplicateAuthCodeError(false);
                                          }
                                          dispatch(
                                            setAuthorizationAuthCodes({
                                              index: authorizationIndex,
                                              authCodeIndex: authCodeIndex,
                                              value: e.target.value,
                                            })
                                          );
                                        }}
                                        MenuProps={MenuProps}
                                      >
                                        {authcode.map((item: AuthCode) => {
                                          return (
                                            <MenuItem
                                              key={item.id}
                                              value={item.conceptValue}
                                            >
                                              {item.conceptValue}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>

                                      {!errorMessage &&
                                      hasError &&
                                      !authorizations[authorizationIndex].auth[
                                        authCodeIndex
                                      ].authCode ? (
                                        <FormHelperText
                                          style={{ color: "#d32f2f" }}
                                        >
                                          Please select auth code
                                        </FormHelperText>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    {authCodeIndex === 0 &&
                                      (maxUnits > 0 ? (
                                        <InputLabel className="fromLabel">
                                          # of Units (max: {maxUnits})
                                        </InputLabel>
                                      ) : (
                                        <InputLabel className="fromLabel">
                                          # of Units
                                        </InputLabel>
                                      ))}
                                    <Box className="fromGroup">
                                      <TextValidator
                                        placeholder="##"
                                        className="form-control"
                                        name="units"
                                        id="units"
                                        type="number"
                                        max={maxUnits}
                                        value={
                                          authorizations[authorizationIndex]
                                            .auth[authCodeIndex].units
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("units");
                                          }
                                        }}
                                        onChange={(e: any) => {
                                          // if (
                                          //   parseInt(e.target.value) > maxUnits
                                          // )
                                          //   return;
                                          dispatch(
                                            setAuthorizationAuthCodeUnits({
                                              index: authorizationIndex,
                                              authCodeIndex: authCodeIndex,
                                              value: e.target.value,
                                            })
                                          );
                                        }}
                                        validators={[
                                          "required",
                                          "matchRegexp:^[1-9][0-9]*$",
                                        ]}
                                        errorMessages={[
                                          "Please enter unit",
                                          "unit must be a positive number",
                                        ]}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    m={10}
                                    className="time-group__space"
                                  >
                                    <Delete
                                      className="removeTimeBtn"
                                      onClick={() => {
                                        dispatch(
                                          deleteAuthCodeAuthorization({
                                            index: authorizationIndex,
                                            authindex: authCodeIndex,
                                          })
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          }
                        )}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Button onClick={() => setActiveTab("files")}>
                        Upload Files
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {activeTab === "files" && (
                <Grid item xs={12} lg={8}>
                  <Box className="fromGroup">
                    <InputLabel
                      id="demo-simple-select-label"
                      className="fromLabel"
                    >
                      {duplicateErrorImage && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {duplicateErrorImageMessage}
                        </FormHelperText>
                      )}
                    </InputLabel>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} className="fileUploadBlock">
                        <FileUpload
                          multiple
                          value={authorizations[authorizationIndex].files}
                          onChange={(e: File[]) => {
                            let temp: any = [
                              ...authorizations[authorizationIndex].files,
                              ...e,
                            ];

                            dispatch(
                              setAuthorizationFiles({
                                index: authorizationIndex,
                                files: temp,
                              })
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="uploadProfile__img selectedIn-fileBox">
                          {authorizations[authorizationIndex]?.files?.length >
                          0 ? (
                            authorizations[authorizationIndex]?.files?.map(
                              (item: any, index: number) => {
                                return (
                                  <div className="selectedIn-file" key={index}>
                                    <h1>
                                      {
                                        item.fileName
                                          ? item.fileName //for existing file
                                          : item.name //for just uploaded one
                                      }
                                    </h1>
                                    <span>
                                      <Delete
                                        onClick={() => {
                                          /*if (
                                              authorizations[authorizationIndex]
                                                .files[index]?.containerName
                                            ) {
                                              deleteAuthorizationFile(
                                                authorizations[authorizationIndex]
                                                  .id,
                                                item.key,
                                                authorizationIndex,
                                                activeTab
                                              );
                                            } else {*/
                                          dispatch(
                                            deleteAuthorizationFiles({
                                              index: authorizationIndex,
                                              fileIndex: index,
                                            })
                                          );
                                          //}
                                        }}
                                      />
                                    </span>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <p className="selectedIn-fileBlank">
                              Upload insurance and assessment documents here.
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
          <Box className="stepperForm__footer">
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              className="button"
              // onClick={() => setHasError(true)} //relax the logic to allow partial save
            >
              {"Save Changes"}
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </>
  );
};

export default ClientEditAuthorizationForm;
