import { AvailabilityDetails } from "../types/availability";
import { formattedDate } from "../constants/common";
import { getDifferenceBetweenFromTo } from "./DateUtils";

//handle the prevent availiability overlaping
export const availabilityOverlap = (d1: any, data: AvailabilityDetails[]) => {
    let overlap: any[] = [];

    data?.forEach((a) => {
      if (
        new Date(d1) <= new Date(formattedDate(a.endDate)) &&
        new Date(d1) >= new Date(formattedDate(a.beginDate))
      ) {
        overlap = [...a.availabilitySelectedDays];
      }
    });

    return overlap;
};

// sortData availability details
export function sortDataArray<T>(item: AvailabilityDetails[]) {
    let data: AvailabilityDetails[] = [];
    if (item?.length > 0) {
      data = [...item].sort(
        (a, b) =>
          (new Date(formattedDate(a.beginDate)) as any) -
          (new Date(formattedDate(b.beginDate)) as any)
      );
    }
    return data as T[];
}

export const getOneWeekAvailability = (availabilityDetails: any[], availabilityIndex: number) => {
  const availability = availabilityDetails[availabilityIndex];
  let totalHours = 0;
  availability?.selectedDays.forEach((item: any) => {
    totalHours += getDifferenceBetweenFromTo({
      fromTime: item.startTime,
      toTime: item.endTime,
    });
  });
  return totalHours;
}
