import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ProviderForms } from "../../../types/provider";
import ProviderCards from "../provider-cards/ProviderCards";
import ProviderList from "../provider-list/ProviderList";
import ProviderModal from "../add-provider/ProviderModal";
import AvailabilityDetailsNew from "../providers-forms/AvailabilityDetailsFormNew";
import EmploymentDetailsForm from "../providers-forms/EmploymentDetailsForm";
import ContactDetailsForm from "../providers-forms/ContactDetailsForm";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  setInitialState,
  updateProviderAvailabilityById,
  updateProviderContactById,
  updateProviderEmploymentById,
} from "../../../redux/features/providers-main/provider-slice";
import {
  GetAllProviders,
  GetInactiveProviders,
} from "../../../redux/features/providers-main/provider-list-slice";

const Providers: React.FC = () => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //stores provider lists
  const data = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.listData
  );

  //handles updated provider data
  const updatedData = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.utilizationProviderData
  );

  //stores boolean value to show all data
  const utilization = useSelector<RootState, boolean>(
    (state) => state.providerListSlice.dataUpdate
  );

  //in-active list from redux
  const inactiveData = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.inactiveListData
  );

  //variable to check for data change
  const dataChange: boolean = useSelector<RootState, boolean>(
    (state) => state.providerListSlice.listDataChange
  );

  //employment details model handler
  const [isEditEmploymentModal, setIsEditEmploymentModal] =
    useState<boolean>(false);

  //contact details modal handler
  const [isEditContactModal, setIsEditContactModal] = useState<boolean>(false);

  //availability details modal handler
  const [isEditAvailabilityModal, setIsEditAvailabilityModal] =
    useState<boolean>(false);

  //manages provider forms popups
  const [addNewDialog, setAddNewDialog] = useState<boolean>(false);

  //binding action creators and dispatch
  const dispatchAction = bindActionCreators(
    {
      GetAllProviders,
      GetInactiveProviders,
      setInitialState,
    },
    dispatch
  );

  //same dispatch ref between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);

  // #endregion

  // #region methods region

  //employment details update handler
  const editEmploymentDetailsUpdater = () => {
    dispatch(updateProviderEmploymentById({}));
    setIsEditEmploymentModal(false);
  };

  //contact details update handler
  const editContactDetailsUpdater = () => {
    dispatch(updateProviderContactById());
    setIsEditContactModal(false);
  };

  //availability details update handler
  const editAvailabilityDetailsUpdater = () => {
    dispatch(updateProviderAvailabilityById());
    setIsEditAvailabilityModal(false);
  };

  //#endregion

  //#region useEffect region

  //clears search value when landing on this page
  useEffect(() => {
    dispatchActionRef.current.setInitialState(null);
    dispatchActionRef.current.GetAllProviders();
    dispatchActionRef.current.GetInactiveProviders();
  }, [dataChange]);

  // #endregion

  return (
    <div className="pageTemplate">
      <Grid item xs={12} sm={9}>
        <Box className="detailsPageBody">
          <Grid container spacing={0}>
            <Grid item xs={12} mb={"48px"}>
              <ProviderCards
                mergedData={
                  utilization === true
                    ? updatedData
                    : [...data, ...inactiveData]
                }
                setAddNewDialog={setAddNewDialog}
              />
            </Grid>
            {/* show "All Employees" table */}
            <Grid item xs={12}>
              <ProviderList
                mergedData={
                  utilization === true
                    ? updatedData
                    : [...data, ...inactiveData]
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {addNewDialog && (
        <ProviderModal
          selectedTab={"All Employees"}
          addNewDialog={addNewDialog}
          setAddNewDialog={setAddNewDialog}
        />
      )}

      {isEditEmploymentModal && (
        <Dialog
          className="largeModel"
          open={isEditEmploymentModal}
          onClose={() => {
            setIsEditEmploymentModal(false);
            // dispatch(setInitialState(null));
          }}
        >
          <Box className="stepperForm">
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Employee
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setIsEditEmploymentModal(false);
                  // dispatch(setInitialState(null));
                }}
              >
                <Close />
              </Button>
            </Box>
          </Box>
          <EmploymentDetailsForm
            isNew={false}
            updateHandler={() => editEmploymentDetailsUpdater()}
          />
        </Dialog>
      )}
      {isEditContactModal && (
        <Dialog
          className="largeModel"
          open={isEditContactModal}
          onClose={() => {
            setIsEditContactModal(false);
            dispatch(setInitialState(null));
          }}
        >
          <Box className="stepperForm">
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Employee
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setIsEditContactModal(false);
                  dispatch(setInitialState(null));
                }}
              >
                <Close />
              </Button>
            </Box>
          </Box>
          <ContactDetailsForm
            isNew={false}
            updateHandler={() => {
              editContactDetailsUpdater();
            }}
          />
        </Dialog>
      )}
      {isEditAvailabilityModal && (
        <Dialog
          className="largeModel"
          open={isEditAvailabilityModal}
          onClose={() => {
            setIsEditAvailabilityModal(false);
            dispatch(setInitialState(null));
          }}
        >
          <Box className="stepperForm">
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Employee
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setIsEditAvailabilityModal(false);
                  dispatch(setInitialState(null));
                }}
              >
                <Close />
              </Button>
            </Box>
          </Box>
          <AvailabilityDetailsNew
            isNew={false}
            updateHandler={() => editAvailabilityDetailsUpdater()}
            isBoardPage={true}
          />
        </Dialog>
      )}
    </div>
  );
};

export default Providers;
