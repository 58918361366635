import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postDataCatalogForm } from "../../../redux/features/data-catalog/data-catalog-slice";
import { updateDataCatalogById } from "../../../redux/features/data-catalog/data-catalog-slice";
import { NewDataCatalog, SpecificRules } from "../../../types/businessSettings";
import { NewDataCatalogs } from "../../../constants/parent-settings";
import { DataCatalogConcepts } from "../../../constants/catalog";
import DeleteIcon from "@mui/icons-material/Delete";
import "./business-settings.scss";
import { InsuranceProvider } from "../../../types/response";
import { RootState } from "../../../redux/store";
import { AuthCode } from "../../../types/client";
import { GetAuthcode } from "../../../redux/features/client-main/client-insurance-slice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

// eslint-disable-next-line
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// Custom Table
const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

// Custom Table Col style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

// Custom Table Row style
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "30px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "40px",
}));
interface IdataCatalog {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCatalogData: React.Dispatch<React.SetStateAction<NewDataCatalog>>;
  isEdit: boolean;
  selectedCatalogData: NewDataCatalog;
  filtercatalogname: string;
}

const CatalogTemplate: React.FC<IdataCatalog> = ({
  setOpenDialog,
  setSelectedCatalogData,
  isEdit,
  selectedCatalogData,
  filtercatalogname,
}) => {
  //#region variable region

  //store value
  const [value, setValue] = useState<string>(
    isEdit ? selectedCatalogData.conceptValue : ""
  );

  //stores insurance values
  const insuranceProviders = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //stores auth codes
  const authCodes = useSelector<RootState, AuthCode[]>(
    (state) => state.clientInsuranceSlice.clientAuthcode
  );

  //store selected category
  const [category, setCategory] = useState<string>(
    isEdit
      ? selectedCatalogData.catalogName
      : filtercatalogname
      ? filtercatalogname
      : "Auth Code"
  );

  //stores selected insurance min per day and unit per day
  const [authSubData, setAuthSubData] = useState<{
    minsPerUnit: number;
    unitsPerDay: number;
    rules: SpecificRules[];
  }>({
    minsPerUnit: 15,
    unitsPerDay: 0,
    rules: [],
  });

  //store message description
  const [description, setDescription] = useState<string>(
    isEdit ? selectedCatalogData.description : ""
  );

  //handle Error
  const [error, setError] = useState<boolean>(false);

  //used to dispatch
  const dispatch = useDispatch();

  //#endregion

  //#region methods region

  //handles deletion of chip
  const handleChipDelete = (
    index: number,
    dataindex: number,
    type: "insurance" | "parallelCodes"
  ) => {
    const data = authSubData.rules[index][type].filter(
      (_, insuranceIndex) => insuranceIndex !== dataindex
    );
    setAuthSubData((prevState) => ({
      ...prevState,
      rules: prevState.rules.map((rule, ruleIndex) => {
        if (ruleIndex === index) {
          return {
            ...rule,
            [type]: data,
          };
        }
        return rule;
      }),
    }));
  };

  const handleOnChangeRules = (
    value: string[] | string | boolean,
    index: number,
    type:
      | "insurance"
      | "parallelCodes"
      | "noOfClients"
      | "groupCode"
      | "doubleBookings"
  ) => {
    setAuthSubData((prevState) => ({
      ...prevState,
      rules: prevState.rules.map((rule, ruleIndex) => {
        if (ruleIndex === index) {
          return {
            ...rule,
            [type]: value,
          };
        }
        return rule;
      }),
    }));
  };

  //handle Delete Rule
  const handleDeleteRule = (index: number) => {
    setAuthSubData((prevState) => {
      const ruleArr = prevState.rules.filter((_, i) => i !== index);
      return {
        ...prevState,
        rules: ruleArr,
      };
    });
  };

  //handle save catalog
  const handleSave = () => {
    if (!value.trimStart()) {
      setError(true);
      return;
    }

    if (isEdit) {
      if (authSubData) {
        dispatch(
          updateDataCatalogById({
            id: selectedCatalogData.id,
            catalogName: category,
            conceptValue: value,
            isActive: selectedCatalogData.isActive,
            description: description,
            data: authSubData,
          })
        );
      } else {
        dispatch(
          updateDataCatalogById({
            id: selectedCatalogData.id,
            catalogName: category,
            conceptValue: value,
            isActive: selectedCatalogData.isActive,
            description: description,
          })
        );
      }
    } else {
      if (authSubData) {
        dispatch(
          postDataCatalogForm({
            catalogName: category,
            conceptValue: value,
            isActive: false,
            description: description,
            data: authSubData,
          })
        );
      } else {
        dispatch(
          postDataCatalogForm({
            catalogName: category,
            conceptValue: value,
            isActive: false,
            description: description,
          })
        );
      }
    }
    setOpenDialog(false);
    setSelectedCatalogData(NewDataCatalogs);
  };

  //#endregion

  //#region UseEffect region
  useEffect(() => {
    if (
      isEdit &&
      selectedCatalogData?.data &&
      selectedCatalogData.data?.rules
    ) {
      setAuthSubData({
        minsPerUnit: selectedCatalogData.data?.minsPerUnit,
        unitsPerDay: selectedCatalogData.data?.unitsPerDay,
        rules: selectedCatalogData.data?.rules,
      });
    }
  }, [isEdit, selectedCatalogData?.data]);

  //used to fetch auth codes
  useEffect(() => {
    dispatch(GetAuthcode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  return (
    <div className=" p-40px message-templateBclobk">
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="fromGroup-chips">
            <Typography className="fromLabel" style={{ marginTop: "10px" }}>
              Catalog
            </Typography>
          </div>
          <div className="fromGroup-chips">
            {isEdit ? (
              <Card className="fromGroup-chip-active">
                {selectedCatalogData.catalogName}
              </Card>
            ) : filtercatalogname ? (
              <Card className="fromGroup-chip-active">{filtercatalogname}</Card>
            ) : (
              Object.values(DataCatalogConcepts).map((item) => (
                <Card
                  className={category === item ? "fromGroup-chip-active" : ""}
                  onClick={() => {
                    setCategory(item);
                    setValue("");
                  }}
                >
                  {item}
                </Card>
              ))
            )}
          </div>

          {!category && error && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              Please select category
            </FormHelperText>
          )}
        </Grid>

        {/* {(filtercatalogname || selectedCatalogData.catalogName || category) !== */}
        {/* "Auth Code" && ( */}
        <Grid item xs={12} md={4}>
          <Box className="fromGroup">
            <InputLabel className="fromLabel" style={{ marginTop: "10px" }}>
              {"Record Value"}
            </InputLabel>
            <TextField
              placeholder="Enter new value"
              value={value.trimStart()}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            {!value.trimStart() && error && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                item value cannot be empty
              </FormHelperText>
            )}
          </Box>
        </Grid>
        {/* // )} */}

        {(filtercatalogname || selectedCatalogData.catalogName || category) ===
          "Auth Code" && (
          <>
            <Grid item xs={12} md={4}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel" style={{ marginTop: "10px" }}>
                  Per unit Type
                </InputLabel>
                <Select
                  labelId="PerUnitType"
                  id="perUnit"
                  value={authSubData.minsPerUnit}
                  onChange={(e) => {
                    setAuthSubData((pre) => {
                      return { ...pre, minsPerUnit: e.target.value as number };
                    });
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={15}>Per 15 minutes</MenuItem>
                  <MenuItem value={30}>Per 30 minutes</MenuItem>
                  <MenuItem value={60}>Per 1 Hour</MenuItem>
                  <MenuItem value={0}>Per Assessment</MenuItem>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel" style={{ marginTop: "10px" }}>
                  Max Units Per Day
                </InputLabel>
                <Select
                  labelId="UnitPerDay"
                  id="unitperday"
                  value={authSubData.unitsPerDay}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    setAuthSubData((pre) => {
                      return { ...pre, unitsPerDay: e.target.value as number };
                    });
                  }}
                >
                  <MenuItem value={8}>8 Units</MenuItem>
                  <MenuItem value={16}>16 Units</MenuItem>
                  <MenuItem value={18}>18 Units</MenuItem>
                  <MenuItem value={24}>24 Units</MenuItem>
                  <MenuItem value={32}>32 Units</MenuItem>
                </Select>
              </Box>
            </Grid>
          </>
        )}

        {(filtercatalogname || selectedCatalogData.catalogName || category) !==
          "Auth Code" && (
          <Grid item xs={12} md={12}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel" style={{ marginTop: "10px" }}>
                Description (optional)
              </InputLabel>
            </Box>
            <Box className="commentForm">
              <TextareaAutosize
                placeholder="Enter Description "
                minRows={5}
                aria-label="minimum height"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {(filtercatalogname || selectedCatalogData.catalogName || category) ===
        "Auth Code" && (
        <Grid item xs={12} marginTop={2}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography style={{ fontSize: "18ps", fontWeight: "600" }}>
              Special Rules:
            </Typography>
            <Typography
              sx={{ cursor: "pointer" }}
              color={"#096dd9"}
              onClick={() => {
                setAuthSubData((prevState) => {
                  return {
                    ...prevState,
                    rules: [
                      ...prevState.rules,
                      {
                        insurance: [],
                        groupCode: false,
                        noOfClients: 0,
                        doubleBookings: false,
                        parallelCodes: [],
                      },
                    ],
                  };
                });
              }}
            >
              + Add Rule
            </Typography>
          </div>

          <Grid item xs={12} className="listingTable-catalog">
            <TableContainer component={Paper} className="tableResponsive">
              <StyledTable
                sx={{
                  minWidth: 600,
                  borderCollapse: "separate",
                  cursor: "pointer",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Insurance</StyledTableCell>
                    <StyledTableCell align="center">
                      Group Code?
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      # of Clients
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Double Booking?
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Other Auth Codes
                    </StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {authSubData?.rules.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Box className="fromGroup" sx={{ mt: 1 }}>
                          <FormControl>
                            <Select
                              multiple
                              className="form-control"
                              value={item.insurance}
                              renderValue={() => ""}
                              MenuProps={MenuProps}
                              onChange={(e) =>
                                handleOnChangeRules(
                                  e.target.value,
                                  index,
                                  "insurance"
                                )
                              }
                            >
                              {insuranceProviders.map((unit, ind) => (
                                <MenuItem
                                  key={ind}
                                  value={unit.insuranceProviderName}
                                >
                                  <Checkbox
                                    checked={
                                      item.insurance.indexOf(
                                        unit.insuranceProviderName
                                      ) > -1
                                    }
                                  />
                                  <ListItemText
                                    primary={unit.insuranceProviderName}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              mt={0.5}
                            >
                              {item.insurance.map((ele, dataindex) => (
                                <Chip
                                  size="small"
                                  key={dataindex}
                                  label={ele}
                                  onDelete={() =>
                                    handleChipDelete(
                                      index,
                                      dataindex,
                                      "insurance"
                                    )
                                  }
                                  style={{ margin: "2px" }}
                                />
                              ))}
                            </Box>
                          </FormControl>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Checkbox
                          color="primary"
                          checked={item.groupCode}
                          onChange={() =>
                            handleOnChangeRules(
                              !item.groupCode,
                              index,
                              "groupCode"
                            )
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className="settingBox__field">
                          <input
                            disabled={!item.groupCode}
                            type="number"
                            min="1"
                            value={item.noOfClients}
                            onChange={(e) =>
                              handleOnChangeRules(
                                e.target.value,
                                index,
                                "noOfClients"
                              )
                            }
                          />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Checkbox
                          color="primary"
                          checked={item.doubleBookings}
                          onChange={(e) =>
                            handleOnChangeRules(
                              !item.doubleBookings,
                              index,
                              "doubleBookings"
                            )
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box className="fromGroup" mt={1}>
                          <FormControl>
                            <Select
                              disabled={!item.doubleBookings}
                              multiple
                              className="form-control"
                              value={item.parallelCodes}
                              renderValue={(_) => ""}
                              MenuProps={MenuProps}
                              onChange={(e) =>
                                handleOnChangeRules(
                                  e.target.value as string[],
                                  index,
                                  "parallelCodes"
                                )
                              }
                            >
                              {authCodes.map((unit, ind) => (
                                <MenuItem key={ind} value={unit.conceptValue}>
                                  <Checkbox
                                    checked={
                                      item?.parallelCodes.indexOf(
                                        unit.conceptValue
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={unit.conceptValue} />
                                </MenuItem>
                              ))}
                            </Select>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              mt={0.5}
                            >
                              {item.parallelCodes.map((ele, dataindex) => (
                                <Chip
                                  size="small"
                                  key={dataindex}
                                  label={ele}
                                  onDelete={() =>
                                    handleChipDelete(
                                      index,
                                      dataindex,
                                      "parallelCodes"
                                    )
                                  }
                                  style={{ margin: "2px" }}
                                />
                              ))}
                            </Box>
                          </FormControl>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <DeleteIcon
                          color="primary"
                          onClick={() => handleDeleteRule(index)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      <Box className="stepperForm__footer b-0">
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleSave} className="button">
          {isEdit ? "Save Changes" : "Save Item"}
        </Button>
      </Box>
    </div>
  );
};

export default CatalogTemplate;
