import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

interface Props {
  name: string;
  setModal: (value: boolean) => void;
  title?: string;
}

const DeleteConfirmation: React.FC<Props> = ({ name, setModal, title }) => {
  return (
    <>
      <Dialog open={true} className="smallModel">
        <div>
          {title ? (
            <DialogContent>
              {title}
            </DialogContent>
          ) : (
            <DialogContent>
              Are you sure you want to delete this {name}?
            </DialogContent>
          )}
          <DialogActions>
            <Button
              className="border-button"
              variant="contained"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                setModal(false);
              }}
              color="secondary"
            >
              No
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                setModal(true);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteConfirmation;
