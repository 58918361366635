import React from 'react'
import FormList from './FormList'
import FormRequests from './FormRequests'

const FormTracking: React.FC = () => {
  return (
    <div className="pageTemplate">
      <FormList />
      <FormRequests />
    </div>
  );
};

export default FormTracking;
