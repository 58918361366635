import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import { KeyboardBackspace, Delete } from "@mui/icons-material/";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  deleteClientById,
  setAuthorizationDetails,
  setClientInsuranceDetails,
  setEditClient,
  setInitialState,
  setInitialStateForAuthorizationEdit,
} from "../../../redux/features/client-main/client-slice";
import {
  getClientFilesById,
  getClientMessagesById,
  setSingleClientData,
} from "../../../redux/features/client-main/client-list-slice";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import { setSelectedAvailabiltyValues } from "../../../redux/features/Common/availability-slice";
import { clearSchedulesMatchValues } from "../../../redux/features/schedules-main/match-slice";
import { GetAllProviders } from "../../../redux/features/providers-main/provider-list-slice";

import ClientSubBasicDetails from "./sub-details/ClientSubBasicDetails";
import ClientSubStatus from "./sub-details/ClientSubStatus";
import ClientSubContactDetails from "./sub-details/ClientSubContactDetails";
import ClientSubAdminDetails from "./sub-details/ClientSubAdminDetails";
import ClientSubInsuranceDetails from "./sub-details/ClientSubInsuranceDetails";
import ClientSubAvailability from "./sub-details/ClientSubAvailability";
import ClientSubAuthorizations from "./sub-details/ClientSubAuthorizations";
import ClientSubFilesList from "./sub-details/ClientSubFilesList";
import ClientSubComments from "./sub-details/ClientSubComments";
import ClientParentDetail from "./sub-details/ClientParentDetail";
import SubSchedulerNew from "../../providers/provider-details/sub-details/SubScheduleNew";

import ClientService from "../../../service/client.service";

import { sortDataArray } from "../../../utils/AvailabilityUtils";

import { ClientForms } from "../../../types/client";
import { AvailabilityDetails } from "../../../types/availability";
import { ClientInitialState } from "../../../constants/client";
import { defaultClientAvailabilityDetail } from "../../../constants/availability";

import "./ClientDetails.scss";

const ClientDetails: React.FC = () => {
  // #region variables region

  //messages change notifier
  const messagesChange = useAppSelector(
    (state) => state.clientListSlice.clientMessagesChange
  );

  // eslint-disable-next-line
  //authorizations
  // const authorizations = useAppSelector<RootState, ClientAuthPayload[]>(
  //   (state) => state.client.authorizations
  // );

  //
  // const clientInsurance = useAppSelector<RootState, InsuranceProviders[]>(
  //   (state) => state.client.clientInsurance
  // );

  //id for the client
  const { id } = useParams();

  //variable for reopening client insurances
  const [reOpenInsuranceInfo, setReOpenInsuranceInfo] =
    useState<boolean>(false);

  //keeps track of index for particular authorization to edit and delete authorization or authorization file
  const [authIndex, setAuthIndex] = useState<number | null>(null);

  //keeps track of activeTab for particular authorization to edit and delete authorization or authorization file
  const [activeTab, setActiveTab] = useState<string | null>(null);

  //handles the edit for client insurance details
  const [, setEditInsuranceInfo] = useState<boolean>(false);

  //handles the edit for client authorizations details
  const [editAuthorizationsInfo, setEditAuthorizationsInfo] =
    useState<boolean>(false);

  //form handler for edit or new
  const { isEditClient } = useAppSelector((state) => state.client);

  //handles the deletePopup
  const [deleteClient, setDeleteClient] = useState<boolean>(false);

  //variable to refetchData if data is updated
  const dataChange = useAppSelector(
    (state) => state.clientListSlice.listDataChange
  );

  //change to handle booking delete and edit
  // eslint-disable-next-line
  const [bookingChange, setBookingChange] = useState<boolean>(false);

  // change availability
  const [availabilityChange, setAvailabilityChange] = useState<boolean>(false);

  //variable to refetchFiles if files is updated
  const filesChange = useAppSelector(
    (state) => state.clientListSlice.clientFilesChange
  );

  //provider list
  const providerlist = useAppSelector(
    (state) => state.providerListSlice.listData
  );
  //
  const navigate = useNavigate();

  //dispatch for redux
  const dispatch = useAppDispatch();

  //action creators binding
  const dispatchAction = bindActionCreators(
    {
      getClientMessagesById,
      setEditClient,
      setResponseValue,
      setAuthorizationDetails,
      setSingleClientData,
      setInitialState,
      setInitialStateForAuthorizationEdit,
      setClientInsuranceDetails,
      getClientFilesById,
      GetAllProviders,
    },
    dispatch
  );

  //dispatch ref between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);

  //client data from redux
  const clientData = useAppSelector(
    (state) => state.clientListSlice.singleClient
  );

  //selected tab to display proper pages
  const [selectedTab, setSelectedTab] = useState<string>("Summary");

  //handle show error
  const [hasError, setHasError] = useState<boolean>(false);

  //store index of selected availability
  const [selectedAvailability, setSelectedAvailability] =
    useState<AvailabilityDetails>(defaultClientAvailabilityDetail);
  // #endregion

  // #region methods region

  //Handle match scheduele provider
  const MatchScheduleHandle = () => {
    if (
      clientData.availabilityDetails.length > 0 &&
      clientData.clientContactDetails &&
      clientData.authorizations.length > 0
    ) {
      //must to clean previous match slice data
      dispatch(clearSchedulesMatchValues({}));
      navigate(`/schedule/${id}`, {
        state: {
          counter: 1,
          entityType: "Client",
        },
      });
    } else {
      setHasError(true);
    }
  };

  //handles deleting client by id
  const deleteById = async (id: string) => {
    dispatch(deleteClientById({ clientId: id }));
    navigate("/clients");
    // const { data, message }: any = await ClientService.deleteClientById(id);

    // if (data) {
    //   dispatch(setResponseValue({ name: "success", value: true }));
    //   dispatch(setResponseValue({ name: "message", value: message }));
    // } else {
    //   dispatch(setResponseValue({ name: "error", value: true }));
    //   dispatch(setResponseValue({ name: "message", value: message }));
    // }
  };

  //handles the re-opening of authorization edit form when a file or authorization is deleted
  const handleReopenAuthorizations = useCallback(
    (data: ClientForms) => {
      if (activeTab === "files" && authIndex !== null) {
        dispatchActionRef.current.setInitialStateForAuthorizationEdit({
          isEditClient: isEditClient,
        });
        dispatchActionRef.current.setAuthorizationDetails({
          authorization: [...data.authorizations],
        });
        setEditAuthorizationsInfo(true);
      } else if (reOpenInsuranceInfo) {
        dispatchActionRef.current.setInitialStateForAuthorizationEdit({
          isEditClient: isEditClient,
        });
        dispatchActionRef.current.setClientInsuranceDetails({
          clientInsurance: [...data.clientInsurance],
        });
        // dispatchActionRef.current.setEditClient(true);
        setReOpenInsuranceInfo(false);
        setEditInsuranceInfo(true);
      }
    },
    [activeTab, authIndex, isEditClient, reOpenInsuranceInfo]
  );

  // #endregion

  // #region useEffect region

  //effect for refetching client details data when data is updated
  useEffect(() => {
    const fetchClientById = async () => {
      try {
        dispatchActionRef.current.setResponseValue({
          name: "pending",
          value: true,
        });
        const data = await ClientService.getClientById(id);

        if (data.status) {
          dispatchActionRef.current.setSingleClientData({ data: data.data });
          dispatchActionRef.current.setAuthorizationDetails({
            authorization: data.data.authorizations,
          });
          handleReopenAuthorizations(data.data);
        }
      } catch (e: any) {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(setResponseValue({ name: "message", value: e?.message }));
      } finally {
        dispatchActionRef.current.setResponseValue({
          name: "pending",
          value: false,
        });
      }
    };

    if (id) {
      fetchClientById();

      const newDispatchRef = dispatchActionRef.current;
      return () => {
        newDispatchRef.setSingleClientData(ClientInitialState);
        newDispatchRef.getClientFilesById({ clientId: id });

        dispatchActionRef.current.getClientMessagesById({ clientId: id });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dataChange, bookingChange]);

  useEffect(() => {
    if (id) {
      dispatchActionRef.current.getClientFilesById({ clientId: id });
    }
  }, [filesChange]);

  useEffect(() => {
    if (id) {
      dispatchActionRef.current.getClientMessagesById({ clientId: id });
    }
  }, [messagesChange]);

  useEffect(() => {
    if (clientData?.availabilityDetails?.length > 0) {
      const defaultDate = new Date();
      const availabilityForToday = clientData.availabilityDetails.find(
        (availability) =>
          new Date() >=
            (availability.beginDate
              ? new Date(availability.beginDate)
              : defaultDate) &&
          new Date() <=
            (availability.endDate
              ? new Date(availability.endDate)
              : defaultDate)
      );

      if (availabilityForToday) {
        dispatch(
          setSelectedAvailabiltyValues({
            name: "availability",
            value: availabilityForToday,
          })
        );
        setSelectedAvailability(availabilityForToday);
      } else {
        const defaultSelectedAvailablility = sortDataArray<AvailabilityDetails>(
          clientData?.availabilityDetails
        )[0];

        dispatch(
          setSelectedAvailabiltyValues({
            name: "availability",
            value: defaultSelectedAvailablility,
          })
        );
        setSelectedAvailability(defaultSelectedAvailablility);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, selectedTab]);

  //fetches provider list
  useEffect(() => {
    if (providerlist.length === 0) {
      dispatchActionRef.current.GetAllProviders();
    }
  }, []);
  // #endregion

  return (
    <div className="pageTemplate">
      <Box className="detailsPageHeader">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={9}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                className="backBtn"
                startIcon={<KeyboardBackspace />}
                style={{ marginBottom: 0 }}
              >
                Back
              </Button>
              <Typography
                variant="h6"
                className="DetailsPageTitle"
                margin={"0px"}
              >
                Client Details -{" "}
                {clientData?.clientBasicDetails?.childFirstName}{" "}
                {clientData?.clientBasicDetails?.childLastName}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box className="detailsPageHeader__btn">
              <Button
                type="submit"
                variant="contained"
                startIcon={<Delete />}
                className="button"
                onClick={() => setDeleteClient(true)}
              >
                Delete Client
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginTop: "25px",
            }}
          >
            <Box>
              <ul className="innerTabs" style={{ marginTop: 0 }}>
                <li className="innerTabs__item">
                  <button
                    onClick={() => setSelectedTab("Summary")}
                    className={selectedTab === "Summary" ? "active" : ""}
                  >
                    Summary
                  </button>
                </li>
                <li className="innerTabs__item">
                  <button
                    onClick={() => setSelectedTab("Schedules")}
                    className={selectedTab === "Schedules" ? "active" : ""}
                  >
                    Schedules
                  </button>
                </li>
                <li className="innerTabs__item">
                  <button
                    onClick={() => setSelectedTab("Files")}
                    className={selectedTab === "Files" ? "active" : ""}
                  >
                    Files
                  </button>
                </li>
                <li className="innerTabs__item">
                  <button
                    onClick={() => setSelectedTab("News")}
                    className={selectedTab === "News" ? "active" : ""}
                  >
                    News
                  </button>
                </li>
              </ul>
            </Box>
            {selectedTab === "Schedules" ? (
              <div style={{ textAlign: "right" }}>
                <Button onClick={MatchScheduleHandle} variant="contained">
                  Match Providers
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
        {hasError && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={hasError}
            autoHideDuration={6000}
            onClose={() => {
              setHasError(false);
            }}
          >
            <Alert severity={"error"} sx={{ width: "100%" }}>
              Missing availability, contact, or authorization data, cannot
              match.
            </Alert>
          </Snackbar>
        )}
      </Box>

      <Box className="detailsPageBody">
        {selectedTab === "Summary" && (
          <Grid container spacing={3}>
            <ClientSubBasicDetails
              clientBasicDetails={clientData.clientBasicDetails}
            />
            <ClientParentDetail
              clientBasicDetails={clientData.clientBasicDetails}
            />
            <ClientSubContactDetails
              clientContactDetails={clientData.clientContactDetails}
              clientId={clientData?.clientBasicDetails?.id}
            />
            <ClientSubInsuranceDetails
              clientInsurance={clientData.clientInsurance}
              clientId={clientData?.clientBasicDetails?.id}
              setReOpenInsuranceInfo={setReOpenInsuranceInfo}
              reOpenInsuranceInfo={reOpenInsuranceInfo}
              setActiveTab={setActiveTab}
              setAuthIndex={setAuthIndex}
            />
            <ClientSubAdminDetails
              clientBasicDetails={clientData.clientBasicDetails}
            />
            <ClientSubStatus
              clientBasicDetails={clientData.clientBasicDetails}
            />
            <ClientSubAuthorizations
              authorizations={clientData?.authorizations}
              clientInsurance={clientData?.clientInsurance}
              clientId={clientData?.clientBasicDetails?.id}
              authIndex={authIndex}
              setAuthIndex={setAuthIndex}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              editAuthorizationsInfo={editAuthorizationsInfo}
              setEditAuthorizationsInfo={setEditAuthorizationsInfo}
            />
          </Grid>
        )}
        {selectedTab === "Schedules" && (
          <>
            {/* <Button onClick={MatchScheduleHandle}>Match Schedule</Button>
            {hasError &&
              !(
                clientData.availabilityDetails &&
                clientData.clientContactDetails &&
                clientData.authorizations.length > 0
              ) && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Please Select Complete Data
                </FormHelperText>
              )} */}
            {/* <BookedServices
              clientData={clientData}
              bookedSchedules={clientData?.bookedServices}
              isDataEdited={bookingChange}
              setIsDataEdited={setBookingChange}
            /> */}
            <ClientSubAvailability
              clientData={clientData}
              clientId={clientData?.clientBasicDetails?.id}
              setSelectedAvailability={setSelectedAvailability}
              setIsDataEdited={setAvailabilityChange}
            />
            <SubSchedulerNew
              selectedClients={[clientData]} //show selected client
              selectedProviders={[]}
              isDataEdited={availabilityChange}
              setIsDataEdited={setAvailabilityChange}
              providerList={providerlist} //populate initial provider list
            />

            {/* <ClientScheduleComponentNew
              // clientId={id ? id : ""}
              clientData={clientData}
              isDataEdited={availabilityChange}
              setIsDataEdited={setAvailabilityChange}
              selectedAvailabiltyID={selectedAvailabiltyID}
            // Bookeddata={clientData?..bookedServices}
            /> */}
          </>
        )}
        {selectedTab === "Files" && (
          <ClientSubFilesList clientId={id as string} />
        )}
        {selectedTab === "News" && (
          <ClientSubComments clientId={id as string} />
        )}
      </Box>
      <Dialog open={deleteClient} className="smallModel">
        <div>
          <DialogContent>
            Are you sure want to delete this client ?
          </DialogContent>
          <DialogActions>
            <Button
              className="border-button"
              variant="contained"
              onClick={() => setDeleteClient(false)}
              color="secondary"
            >
              No
            </Button>
            <Button
              className="button"
              variant="contained"
              onClick={() => {
                deleteById(id as string);
                setDeleteClient(false);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ClientDetails;
