//Stepper Label constant for Tools
export const ImportSteps = [
  { label: "Upload" },
  { label: "Review & Import" },
] as const;

export const ExportType = [{ type: "Clients" }, { type: "Employees" }] as const;

export const ScheduleType = [
  { label: "All" },
  { label: "Future Only" },
  { label: "Custom" },
] as const;

//Tools Export Form constant
export const options = [
  { key: 0, value: "East Orlando Clinic" },
  { key: 1, value: "West Orlando Clinic" },
] as const;

//Menu props for the select Mui component or element
export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// export enum HistoryStatus {
//   Completed = "Completed",
//   InProgress = "In Progress",
//   Failed = "Failed",
// }

export const exportDataSets = [
  {
    entityType: "Client",
    value: "Client",
    version: "1.0",
  },
  {
    entityType: "Provider",
    value: "Provider",
    version: "1.0",
  },
  {
    entityType: "ServiceSchedule",
    value: "ServiceSchedule",
    version: "1.0",
  },
];

export enum JobStatus {
  Pending = "Pending",
  Started = "Started",
  Complete = "Completed",
  Failed = "Failed",
  InReview = "In Review", //support optional review process
}