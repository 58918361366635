import { createSlice } from "@reduxjs/toolkit";

interface ColorsStateInitialState {
  colors: string[];
}

const initialState: ColorsStateInitialState = {
  colors: [
    "#2ecc71",
    "#e67e22",
    "#e74c3c",
    "#2c3e50",
    "#8e44ad",
    "#16a085",
    "#f39c12",
  ],
};

const colorsStateSlice = createSlice({
  name: "draggingState",
  initialState: initialState,
  reducers: {},
});

// export const {} = colorsStateSlice.actions;

export default colorsStateSlice;
