import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Container, TextareaAutosize } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { EditClientMessageById } from "../../../../redux/features/client-main/client-list-slice";
import { ClientMessage } from "../../../../types/client";

interface IClientSubComments {
  commentObj: ClientMessage;
  isActiveClose: Dispatch<SetStateAction<boolean>>;
  clientId: string;
}

const ClientSubEditComments: React.FC<IClientSubComments> = ({
  commentObj,
  isActiveClose,
  clientId,
}) => {
  // #region variables region

  //message
  const [message, setMessage] = useState<string>(commentObj.message);

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  // #endregion

  // #region methods region

  //hadler update comments
  const editMessageHandle = () => {
    dispatch(
      EditClientMessageById({
        clientId: clientId,
        selectedMessage: message,
        commentId: commentObj.id,
      })
    );
    isActiveClose(false);
  };

  //handle Cancel Edit
  const cancelMessageHandle = () => {
    isActiveClose(false);
  };
  // #endregion
  return (
    <>
      <Container maxWidth={"md"}>
        <Box className="commentForm">
          <TextareaAutosize
            className="textarea-box"
            value={message}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setMessage(e.target.value)
            }
            placeholder="Leave a comment..."
            minRows={2}
          />
          <Box className="textarea-btn-box">
            <Button
              className="button cancel-btn"
              onClick={() => cancelMessageHandle()}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "success" }}
              onClick={() => editMessageHandle()}
              variant="contained"
              className="button"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default ClientSubEditComments;
