import axios from "../axios/axios";
import { IImportSettings } from "../types/tools";

export const postExportRequest = async (
  entityTypes: string[],
  fileFormat: string
) => {
  try {
    const data = await axios.get("/export", {
      params: {
        entityTypes,
        fileFormat,
      },
    });

    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const postImportRequest = async (formData: FormData) => {
  try {
    const data = await axios.post("/import", formData);
    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const getExportTemplates = async () => {
  try {
    const data = await axios.get("/export/templates");
    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const getImportExoportRequest = async () => {
  try {
    const data = await axios.get("/importExportRequest");
    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const getImportSetting = async () => {
  try {
    const data = await axios.get("/importSettings");
    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};
export const updateImportSetting = async (payload: {
  importSettings: IImportSettings;
}) => {
  try {
    const data = await axios.put("/importSettings", payload);
    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};

export const applyReviewAction = async (payload: {
  reviewRequest: { id: string; reviewAction: string }[];
}) => {
  try {
    const data = await axios.post("/apply/reviewActions", payload);
    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};
export const getReviewContext = async (id:string) => {
  try {
    const data = await axios.get(`/reviewContext?id=${id}`);
    return data.data;
  } catch (error: any) {
    throw error?.response.data;
  }
};
const ImportExportService = {
  postExportRequest,
  postImportRequest,
  getExportTemplates,
  getImportExoportRequest,
  getImportSetting,
  updateImportSetting,
  applyReviewAction,
  getReviewContext
};

export default ImportExportService;
