import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { GetFormCards } from "../../../../redux/features/forms/form-slice";
import {
  getAllUserTemplate,
  setAllAutomation,
} from "../../../../redux/features/custom-board/custom-board-slice";
import {
  GetAllProject,
  GetProjectById,
} from "../../../../redux/features/project/taskManager-slice";

import BoardTitle from "./BoardTitle";

import { Board, automation } from "../../../../types/board";
import { IFormCard } from "../../../../types/forms";
import { AlertType, defaultAutomation } from "../../../../constants/automation";

import { ReactComponent as Edit } from "../../../../assets/images/images-svg/Edit.svg";
import DeleteOutlined from "../../../../assets/images/images-svg/DeleteOutlined.svg";
import Vector from "../../../../assets/images/images-svg/Vector.svg";

import "./automationCard.scss";
import { ClientType } from "../../../../constants/client-status";
import { ProviderType } from "../../../../constants/provider-status";
import { BusinessFormType } from "../../../../constants/forms";

const AutomationCard: React.FC<{
  setSaveAutomation: Dispatch<SetStateAction<boolean>>;
  setSelectedTab: Dispatch<
    SetStateAction<{
      id: string;
      type: string;
    }>
  >;
  saveAutomationDialog: boolean;
  validation: (value: boolean) => void;
  taskValidation: boolean;
}> = ({
  setSaveAutomation,
  setSelectedTab,
  saveAutomationDialog,
  validation,
  taskValidation,
}) => {
  //#region variable region
  //handle currect rules
  const [currentRule, setCurrentRule] = useState<automation>();
  //handle selected Rules
  const [selectedRule, setSelectedRule] = useState<{
    id: string;
    index: number;
  }>({ id: "", index: 0 });

  const [stage, setStage] = useState<any[]>([]);

  const [show, setShow] = useState({
    moveCard: false,
    sendMessage: false,
    sendForm: false,
    createTask: false,
    updateStatus: false,
  });
  //handle all boards data
  const [allBoard, setAllBoard] = useState<Board[]>([]);
  //record selected rule's board
  const [selectedRuleBoard, setSelectedRuleBoard] = useState<Board | undefined>(
    allBoard && selectedRule
      ? allBoard.find((board) => board.id === selectedRule.id)
      : undefined
  );
  const dispatch = useAppDispatch();
  // store templates data
  const templates = useAppSelector((state) => state.boardSlice.templates);
  // store all automation board data
  const allAutomation = useAppSelector(
    (state) => state.boardSlice.allAutomation
  );
  //handle save automation data
  const saveAutomation = useAppSelector(
    (state) => state.boardSlice.saveAutomation
  );
  //handle intake Board Information
  const intakeBoardInfo = useAppSelector((state) => state.clientIntakeStage);
  //handle OnBoarding Information
  const onBoardingInfo = useAppSelector(
    (state) => state.providerOnBoardingStage
  );

  //used to store custom board data
  const customBoard = useAppSelector((state) => state.boardSlice.allBoard);

  //used to store intakeform cards
  const intakeForms = useAppSelector(
    (state) => state.formSlice.IntakeForms
  )?.filter((form) => form.active);

  //used to store onboarding form cards
  const providerForms = useAppSelector(
    (state) => state.formSlice.ProviderForms
  )?.filter((form) => form.active);

  // store all automation board data
  const projectList = useAppSelector((state) => state.taskManagerSlice.project);
  const tasks = useAppSelector((state) => state.taskManagerSlice.tasks);
  const currentTaskIds = tasks
    ?.filter((task: any) =>
      currentRule?.cardAction?.sourceTaskIds?.includes(task.id)
    )
    .map((task: any) => task.id);

  //hande varify delete or not popup
  const [varifyRemoveRule, setVarifyRemoveRule] = useState<boolean>(false);

  //error message handler
  const [hasError, setHasError] = useState<boolean>(false);

  //name diaglog pop up
  const [editName, setEditName] = useState<boolean>(false);

  //edit rule name
  const [ruleName, setRuleName] = useState<string>("");

  //trigger Type
  const [triggerType, setTriggerType] = useState<string>("card");
  //#endregion

  //#region method region
  //handle apply handler
  const applyHandler = () => {
    const updatedAutomation = allAutomation?.map((board) => {
      if (board.id === selectedRule.id) {
        const automation = board.automation?.map((auto, index) =>
          index === selectedRule.index
            ? { ...auto, apply: !currentRule?.apply }
            : auto
        );

        return { ...board, automation };
      } else {
        return board;
      }
    });
    dispatch(setAllAutomation(updatedAutomation));
  };
  //handle name handler
  const nameHandler = () => {
    if (ruleName && hasError) {
      const updatedAutomation = allAutomation?.map((board) => {
        if (board.id === selectedRule.id) {
          const automation = board.automation?.map((auto, index) =>
            index === selectedRule.index ? { ...auto, name: ruleName } : auto
          );

          return { ...board, automation };
        } else {
          return board;
        }
      });
      dispatch(setAllAutomation(updatedAutomation));
      setHasError(false);
    } else {
      setHasError(true);
      return;
    }
    setRuleName("");
    setEditName(false);
  };

  //Update automation details
  const updateAutomationDetails = (
    action: boolean,
    property: string,
    value: any
  ) => {
    const updatedAutomation = allAutomation?.map((board) => {
      if (board.id === selectedRule.id) {
        const automation = board.automation?.map((auto, index) =>
          index === selectedRule.index
            ? action
              ? {
                  ...auto,
                  cardAction: { ...auto.cardAction, [property]: value },
                }
              : {
                  ...auto,
                  cardDetails: { ...auto.cardDetails, [property]: value },
                }
            : auto
        );
        return { ...board, automation };
      } else {
        return board;
      }
    });
    dispatch(setAllAutomation(updatedAutomation));
  };
  //handle add Rule logic
  const addRule = (boardId: string) => {
    const updatedAutomation = allAutomation?.map((board) => {
      if (board.id === boardId) {
        return {
          ...board,
          automation: [...board.automation, defaultAutomation],
        };
      } else {
        return board;
      }
    });
    dispatch(setAllAutomation(updatedAutomation));
  };
  //handle delete dropdown values
  const handleChipDelete = (formIdToDelete: any, event: any) => {
    // Remove the formIdToDelete from the currentRule?.cardAction?.form array
    const updatedForms = currentRule?.cardAction?.form?.filter(
      (formId) => formId !== formIdToDelete
    );
    // Update the state with the new array
    updateAutomationDetails(true, "form", updatedForms);
  };

  const getFormName = (board: Board, formId: string) => {
    return board?.boardType === "client"
      ? intakeForms.find((form) => form.id === formId)?.name
      : providerForms.find((form) => form.id === formId)?.name;
  };

  //#endregion

  //#region useEffect region
  useEffect(() => {
    dispatch(getAllUserTemplate());
    dispatch(GetFormCards({ formType: BusinessFormType.IntakePacketForm }));
    dispatch(GetFormCards({ formType: BusinessFormType.EmployeePacketForm }));
    dispatch(GetAllProject());
  }, [dispatch]);

  useEffect(() => {
    // dispatch(getAllAutomation());
    if (customBoard) {
      setAllBoard([...customBoard, intakeBoardInfo, onBoardingInfo]);
    }
  }, [customBoard, intakeBoardInfo, onBoardingInfo]);

  //populate stages for selected board
  useEffect(() => {
    if (currentRule?.cardAction.board) {
      const stages = allBoard.find(
        (board) => board.id === currentRule?.cardAction.board
      )?.stages;
      if (stages) {
        setStage(stages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRule?.cardAction?.board]);

  //populate tasks for source project
  useEffect(() => {
    if (currentRule?.cardAction?.sourceProjectId) {
      dispatch(GetProjectById(currentRule?.cardAction?.sourceProjectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRule?.cardAction?.sourceProjectId]);

  //populate show flag based on data
  useEffect(() => {
    if (currentRule !== undefined) {
      validateCurrentRule();
      //show send message
      if (
        currentRule?.cardAction?.email ||
        currentRule?.cardAction?.inmail ||
        currentRule?.cardAction?.sms
      ) {
        setShow((pre) => {
          return { ...pre, sendMessage: true };
        });
      } else {
        setShow((pre) => {
          return { ...pre, sendMessage: false };
        });
      }

      //show send form
      if (currentRule?.cardAction.form && currentRule?.cardAction.form.length) {
        setShow((pre) => {
          return { ...pre, sendForm: true };
        });
      } else {
        setShow((pre) => {
          return { ...pre, sendForm: false };
        });
      }

      //show move card
      if (currentRule?.cardAction.newLocation) {
        setShow((pre) => {
          return { ...pre, moveCard: true };
        });
      } else {
        setShow((pre) => {
          return { ...pre, moveCard: false };
        });
      }

      //show create task
      if (currentRule?.cardAction.sourceProjectId) {
        setShow((pre) => {
          return { ...pre, createTask: true };
        });
      } else {
        setShow((pre) => {
          return { ...pre, createTask: false };
        });
      }

      //show update field
      if (currentRule?.cardAction.conceptValue) {
        setShow((pre) => {
          return { ...pre, updateStatus: true };
        });
      } else {
        setShow((pre) => {
          return { ...pre, updateStatus: false };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRule]);

  //assign current rule, selected board
  useEffect(() => {
    if (selectedRule.id) {
      const currentAuto = allAutomation?.find(
        (board) => board.id === selectedRule.id
      )?.automation[selectedRule.index];
      setCurrentRule(currentAuto);
      setSelectedRuleBoard(
        allBoard.find((board) => board.id === selectedRule.id)
      );
      if (
        currentAuto?.cardDetails?.event === "alert" ||
        triggerType === "alert"
      ) {
        setTriggerType("alert");
        if (
          allAutomation.length > 0 &&
          currentAuto?.cardDetails?.event === ""
        ) {
          updateAutomationDetails(false, "event", "alert");
        }
      } else if (
        triggerType === "card" &&
        (currentAuto?.cardDetails?.event === "inside" ||
          currentAuto?.cardDetails?.event === "outside" ||
          currentAuto?.cardDetails?.event === "same")
      ) {
        setTriggerType("card");
      } else {
        setTriggerType("card");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRule, allAutomation, allBoard]);

  //Turn ON/OFF
  useEffect(() => {
    return () => {
      if (saveAutomation && !saveAutomationDialog) {
        setSaveAutomation(saveAutomation);
        setSelectedTab({ id: "Automation", type: "Automation" });
      }
    };
  }, [saveAutomation, setSaveAutomation, setSelectedTab, saveAutomationDialog]);

  //#endregion

  const validateCurrentRule = () => {
    if (!currentRule) return;
    if (
      currentRule?.cardAction?.sourceProjectId &&
      !currentRule?.cardAction?.sourceTaskIds?.length
    ) {
      return validation(false);
    }
    return validation(true);
  };

  return (
    <Grid
      container
      // rowSpacing={2}
      // columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}
    >
      <Grid item xs={12} md={4} xl={3}>
        <Box
          border={"1px solid #D9D9D9"}
          minHeight={"60vh"}
          maxHeight={"80vh"}
          overflow={"auto"}
          borderRadius={"4px"}
          height={"100%"}
          className="automationSidebar"
        >
          {allAutomation?.map((board) => (
            <BoardTitle
              board={board}
              key={board.id}
              addRule={addRule.bind(null, board.id)}
              selectedRule={selectedRule}
              setRule={(boardId: string, index: number) =>
                setSelectedRule({ id: boardId, index })
              }
            />
          ))}
        </Box>
      </Grid>
      {currentRule && (
        <Grid item xs={12} md={8} xl={9}>
          <Box className="automationCardBlock">
            <Box className="automationCardBlock__head">
              <h4 className="automationCardBlock__title">
                {
                  allAutomation?.find((board) => board.id === selectedRule.id)
                    ?.boardName
                }
                {" / "}
                {currentRule?.name
                  ? ` ${currentRule?.name}`
                  : ` Rule ${selectedRule.index + 1}`}
                <Edit
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (currentRule?.name) {
                      setRuleName(currentRule?.name);
                    } else {
                      setRuleName(`Rule ${selectedRule.index + 1}`);
                    }
                    setEditName(true);
                  }}
                />
              </h4>
              <div style={{ display: "flex", gap: "10px" }}>
                <FormControlLabel
                  className="customToggler"
                  control={
                    <Switch
                      onClick={applyHandler}
                      checked={currentRule.apply}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={currentRule.apply ? "ON" : "OFF"}
                />
                <button
                  style={{
                    cursor: "pointer",
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <img
                    alt="delete icon"
                    src={DeleteOutlined}
                    className="mainHeader__logo"
                    onClick={() => {
                      setVarifyRemoveRule(true);
                    }}
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                  />
                </button>
              </div>
            </Box>
            <Box className="automationCard">
              <Box className="automationCard__inner">
                <Typography className="automationCard__title">
                  Trigger
                </Typography>
                <Box className="automationCard__fields">
                  <InputLabel>Trigger Type</InputLabel>
                  <div className="fromGroup-chips">
                    <Card
                      className={
                        triggerType === "card" ? "fromGroup-chip-active" : ""
                      }
                      onClick={() => {
                        updateAutomationDetails(false, "event", "card");
                        setTriggerType("card");
                      }}
                    >
                      Board Cards
                    </Card>
                    <Card
                      className={
                        triggerType === "alert" ? "fromGroup-chip-active" : ""
                      }
                      onClick={() => {
                        updateAutomationDetails(false, "event", "alert");
                        setTriggerType("alert");
                      }}
                    >
                      Expiration Alert
                    </Card>
                  </div>
                  {triggerType === "card" && (
                    <>
                      <InputLabel>When The Card:</InputLabel>
                      <FormControl
                        fullWidth
                        style={{ maxWidth: "400px", width: "100%" }}
                      >
                        <Select
                          id="demo-simple-select"
                          value={currentRule?.cardDetails?.event}
                          sx={{ height: "30px" }}
                          onChange={(e) =>
                            updateAutomationDetails(
                              false,
                              "event",
                              e.target.value
                            )
                          }
                        >
                          <MenuItem value={"inside"}>
                            Entering Into Stage
                          </MenuItem>
                          <MenuItem value={"outside"}>
                            Moving Out of Stage
                          </MenuItem>
                          <MenuItem value={"same"}>Remaining At Stage</MenuItem>
                        </Select>
                      </FormControl>
                      <InputLabel>At Stage:</InputLabel>
                      <FormControl
                        fullWidth
                        style={{ maxWidth: "400px", width: "100%" }}
                      >
                        <Select
                          id="demo-simple-select"
                          value={currentRule?.cardDetails?.stage}
                          onChange={(e) =>
                            updateAutomationDetails(
                              false,
                              "stage",
                              e.target.value
                            )
                          }
                          sx={{ height: "30px", width: "30%" }}
                        >
                          {allBoard
                            .find((board) => board.id === selectedRule.id)
                            ?.stages?.map((stage) => (
                              <MenuItem key={stage.id} value={stage.id}>
                                {stage.stageName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <>
                        <InputLabel>For (days):</InputLabel>
                        <FormControl
                          fullWidth
                          style={{ maxWidth: "350px", width: "90%" }}
                        >
                          <TextField
                            type="number"
                            onChange={(e) =>
                              updateAutomationDetails(
                                false,
                                "for",
                                e.target.value
                              )
                            }
                            value={currentRule.cardDetails?.for}
                            InputProps={{
                              inputProps: {
                                max: 90,
                                min: 1,
                              },
                            }}
                            size="small"
                            placeholder="Enter days"
                          />
                        </FormControl>
                      </>
                    </>
                  )}
                  {triggerType === "alert" && (
                    <>
                      <InputLabel>Alert Type</InputLabel>
                      <FormControl
                        fullWidth
                        style={{ maxWidth: "400px", width: "100%" }}
                      >
                        <Select
                          id="demo-simple-select"
                          value={currentRule.cardDetails?.alertType}
                          sx={{ height: "30px", width: "38%" }}
                          onChange={(e) => {
                            setTriggerType("alert");
                            updateAutomationDetails(
                              false,
                              "alertType",
                              e.target.value
                            );
                          }}
                        >
                          {AlertType.map((item) => (
                            <MenuItem key={item.key} value={item.value}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontWeight: "bold",
                            fontSize: "15px",
                          },
                        }}
                        className="ml-0 heckBox-group vertical-group"
                        control={
                          <Checkbox
                            onClick={() => {
                              updateAutomationDetails(
                                false,
                                "alertConfig",
                                "before"
                              );
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            checked={
                              currentRule.cardDetails.alertConfig === "before"
                            }
                          />
                        }
                        label="Before Expire"
                      />
                      <FormControl
                        fullWidth
                        style={{ maxWidth: "350px", width: "90%" }}
                      >
                        <TextField
                          disabled={
                            currentRule.cardDetails?.alertConfig === "after"
                          }
                          type="number"
                          onChange={(e) =>
                            updateAutomationDetails(
                              false,
                              "for",
                              e.target.value
                            )
                          }
                          value={
                            currentRule.cardDetails?.alertConfig !== "after"
                              ? currentRule.cardDetails.for
                              : ""
                          }
                          InputProps={{
                            inputProps: {
                              max: 90,
                              min: 1,
                            },
                          }}
                          size="small"
                          placeholder="Enter days"
                        />
                      </FormControl>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontWeight: "bold",
                            fontSize: "15px",
                          },
                        }}
                        className="ml-0 heckBox-group vertical-group"
                        control={
                          <Checkbox
                            onClick={() => {
                              updateAutomationDetails(
                                false,
                                "alertConfig",
                                "after"
                              );
                              // updateAutomationDetails(false, "for", "0");
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            checked={
                              currentRule.cardDetails?.alertConfig === "after"
                            }
                          />
                        }
                        label="After Expire "
                      />
                      <FormControl
                        fullWidth
                        style={{ maxWidth: "350px", width: "90%" }}
                      >
                        <TextField
                          disabled={
                            currentRule.cardDetails?.alertConfig === "before"
                          }
                          type="number"
                          onChange={(e) => {
                            updateAutomationDetails(
                              false,
                              "for",
                              e.target.value
                            );
                          }}
                          value={
                            currentRule.cardDetails?.alertConfig !== "before"
                              ? currentRule.cardDetails?.for
                              : ""
                          }
                          InputProps={{
                            inputProps: {
                              max: 90,
                              min: 1,
                            },
                          }}
                          size="small"
                          placeholder="Enter days"
                        />
                      </FormControl>
                    </>
                  )}
                </Box>
              </Box>
              <span className="automationCard__arrow">
                <img
                  alt="delete icon"
                  src={Vector}
                  className="mainHeader__logo"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
            </Box>
            {show.moveCard && (
              <Box className="automationCard">
                <Box className="automationCard__inner">
                  <Typography className="automationCard__title">
                    Action: Move Card
                  </Typography>
                  <Box className="automationCard__fields">
                    <InputLabel>Move Your Card:</InputLabel>
                    <FormControlLabel
                      className="ml-0 heckBox-group vertical-group"
                      control={
                        <Checkbox
                          onChange={(e) =>
                            updateAutomationDetails(
                              true,
                              "newLocation",
                              e.target.checked
                            )
                          }
                          inputProps={{ "aria-label": "controlled" }}
                          checked={currentRule?.cardAction?.newLocation}
                        />
                      }
                      label=""
                    />
                    {currentRule?.cardAction?.newLocation && (
                      <>
                        <InputLabel>Destination Board: </InputLabel>
                        <FormControl
                          fullWidth
                          style={{ maxWidth: "350px", width: "90%" }}
                        >
                          <Select
                            id="demo-simple-select"
                            value={currentRule?.cardAction?.board}
                            onChange={(e) =>
                              updateAutomationDetails(
                                true,
                                "board",
                                e.target.value
                              )
                            }
                            sx={{ height: "30px", width: "30%" }}
                          >
                            {allAutomation?.map((board) => (
                              <MenuItem key={board.id} value={board.id}>
                                {board.boardName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <InputLabel>Destination Stage: </InputLabel>
                        <FormControl
                          fullWidth
                          style={{ maxWidth: "350px", width: "90%" }}
                        >
                          <Select
                            id="demo-simple-select"
                            value={currentRule?.cardAction?.stage}
                            onChange={(e) =>
                              updateAutomationDetails(
                                true,
                                "stage",
                                e.target.value
                              )
                            }
                            sx={{ height: "30px", width: "30%" }}
                          >
                            {stage?.map((stage) => (
                              <MenuItem key={stage.id} value={stage.id}>
                                {stage.stageName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Box>
                </Box>
                <span className="automationCard__arrow">
                  <img
                    alt="added icon"
                    src={Vector}
                    className="mainHeader__logo"
                    style={{ width: "20px", height: "20px" }}
                  />
                </span>
              </Box>
            )}
            {show.sendMessage && (
              <Box className="automationCard">
                <Box className="automationCard__inner">
                  <Typography className="automationCard__title">
                    Action: Send Message
                  </Typography>
                  <Box className="automationCard__fields">
                    <FormControlLabel
                      className="ml-0 heckBox-group vertical-group"
                      control={
                        <Checkbox
                          onClick={() =>
                            updateAutomationDetails(
                              true,
                              "inmail",
                              !currentRule?.cardAction?.inmail
                            )
                          }
                          inputProps={{ "aria-label": "controlled" }}
                          checked={currentRule?.cardAction?.inmail}
                        />
                      }
                      label="Send In-Mail"
                    />
                    {currentRule?.cardAction?.inmail ? (
                      <FormControl
                        fullWidth
                        style={{ maxWidth: "400px", width: "100%" }}
                      >
                        <Select
                          id="demo-simple-select"
                          onChange={(e) =>
                            updateAutomationDetails(
                              true,
                              "inmailTemplate",
                              e.target.value
                            )
                          }
                          value={currentRule?.cardAction?.inmailTemplate}
                          sx={{ height: "30px" }}
                        >
                          {templates?.map((temp) => (
                            <MenuItem key={temp.id} value={temp.id}>
                              {temp.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <br />
                    )}
                    <FormControlLabel
                      className="ml-0"
                      control={
                        <Checkbox
                          onClick={(e) =>
                            updateAutomationDetails(
                              true,
                              "email",
                              !currentRule?.cardAction?.email
                            )
                          }
                          inputProps={{ "aria-label": "controlled" }}
                          checked={currentRule?.cardAction?.email}
                        />
                      }
                      label="Send E-Mail"
                    />
                    {currentRule?.cardAction?.email ? (
                      <FormControl
                        fullWidth
                        style={{ maxWidth: "400px", width: "100%" }}
                      >
                        <Select
                          id="demo-simple-select"
                          onChange={(e) =>
                            updateAutomationDetails(
                              true,
                              "emailTemplate",
                              e.target.value
                            )
                          }
                          value={currentRule?.cardAction?.emailTemplate}
                          sx={{ height: "30px" }}
                        >
                          {templates?.map((temp) => (
                            <MenuItem value={temp.id} key={temp.id}>
                              {temp.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <br />
                    )}
                    <FormControlLabel
                      className="ml-0"
                      control={
                        <Checkbox
                          onClick={() =>
                            updateAutomationDetails(
                              true,
                              "sms",
                              !currentRule?.cardAction?.sms
                            )
                          }
                          inputProps={{ "aria-label": "controlled" }}
                          checked={currentRule?.cardAction?.sms}
                        />
                      }
                      label="Send SMS"
                    />
                    {currentRule?.cardAction?.sms ? (
                      <FormControl
                        fullWidth
                        style={{ maxWidth: "400px", width: "100%" }}
                      >
                        <Select
                          id="demo-simple-select"
                          onChange={(e) =>
                            updateAutomationDetails(
                              true,
                              "smsTemplate",
                              e.target.value
                            )
                          }
                          value={currentRule?.cardAction?.smsTemplate}
                          sx={{ height: "30px" }}
                        >
                          {templates?.map((temp) => (
                            <MenuItem value={temp.id} key={temp.id}>
                              {temp.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <br />
                    )}
                  </Box>
                </Box>
                <span className="automationCard__arrow">
                  <img
                    alt="added icon"
                    src={Vector}
                    className="mainHeader__logo"
                    style={{ width: "20px", height: "20px" }}
                  />
                </span>
              </Box>
            )}
            {show.sendForm && (
              <Box className="automationCard">
                <Box className="automationCard__inner">
                  <Typography className="automationCard__title">
                    Action: Send Form
                  </Typography>
                  <Box className="automationCard__fields">
                    <InputLabel>Select Form:</InputLabel>
                    <FormControl
                      fullWidth
                      style={{ maxWidth: "350px", width: "90%" }}
                    >
                      <Select
                        multiple
                        id="demo-simple-select"
                        value={
                          currentRule?.cardAction?.form
                            ? currentRule?.cardAction?.form
                            : []
                        }
                        renderValue={(value) => {
                          switch (selectedRuleBoard?.boardType?.toLowerCase()) {
                            case "client":
                              return intakeForms
                                ?.filter((form: IFormCard) =>
                                  value.includes(form.id)
                                )
                                .map((form) => form.name)
                                .join(", ");
                            case "provider":
                              return providerForms
                                ?.filter((form: IFormCard) =>
                                  value.includes(form.id)
                                )
                                .map((form) => form.name)
                                .join(", ");
                            default:
                              return "";
                          }
                        }}
                        sx={{ height: "30px", width: "48%" }}
                        onChange={(e) =>
                          updateAutomationDetails(true, "form", e.target.value)
                        }
                      >
                        {selectedRuleBoard?.boardType === "client" &&
                          intakeForms?.map((form: IFormCard) => (
                            <MenuItem value={form.id}>
                              <Checkbox
                                checked={currentRule?.cardAction?.form?.includes(
                                  form.id
                                )}
                              />
                              <ListItemText primary={form.name} />
                            </MenuItem>
                          ))}

                        {selectedRuleBoard?.boardType === "provider" &&
                          providerForms?.map((form: IFormCard) => (
                            <MenuItem value={form.id}>
                              <Checkbox
                                checked={currentRule?.cardAction?.form?.includes(
                                  form.id
                                )}
                              />
                              <ListItemText primary={form.name} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ paddingLeft: "60px", margin: "10px 0 1% 12%" }}>
                    {currentRule?.cardAction?.form?.map((formId) => {
                      return (
                        getFormName(selectedRuleBoard!, formId) && (
                          <Chip
                            key={formId}
                            label={getFormName(selectedRuleBoard!, formId)}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            onDelete={(event) => {
                              handleChipDelete(formId, event);
                            }}
                            style={{ margin: "2px" }}
                          />
                        )
                      );
                    })}
                  </Box>
                </Box>
                <span className="automationCard__arrow">
                  <img
                    alt="added icon"
                    src={Vector}
                    className="mainHeader__logo"
                    style={{ width: "20px", height: "20px" }}
                  />
                </span>
              </Box>
            )}
            {show.createTask && (
              <Box className="automationCard">
                <Box className="automationCard__inner">
                  <Typography className="automationCard__title">
                    Action: Create Task
                  </Typography>
                  <Box className="automationCard__fields">
                    <InputLabel>Source Project: </InputLabel>
                    <FormControl
                      fullWidth
                      style={{ maxWidth: "350px", width: "100%" }}
                    >
                      <Select
                        id="demo-simple-select"
                        value={currentRule?.cardAction?.sourceProjectId}
                        onChange={(e) => {
                          updateAutomationDetails(
                            true,
                            "sourceProjectId",
                            e.target.value
                          );
                        }}
                        sx={{ height: "30px", width: "100%" }}
                      >
                        {projectList?.map((project, index) => (
                          <MenuItem key={index} value={project.id}>
                            {project.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <InputLabel>Source Task(s):</InputLabel>
                    <FormControl
                      fullWidth
                      style={{ maxWidth: "350px", width: "100%" }}
                    >
                      {!taskValidation &&
                        currentRule?.cardAction.sourceTaskIds?.length === 0 && (
                          <FormHelperText
                            style={{ color: "#d32f2f", marginLeft: "0px" }}
                          >
                            Source task cannot be empty.
                          </FormHelperText>
                        )}
                      <Select
                        multiple
                        id="demo-simple-select"
                        value={
                          //make sure currentRule?.cardAction?.sourceTaskIds also in tasks
                          //filter tasks based on sourceTaskIds
                          currentTaskIds?.length > 0 ? currentTaskIds : []
                        }
                        renderValue={(value) => {
                          return tasks
                            ?.filter((task: any) => value.includes(task.id))
                            ?.map((task) => task.name)
                            ?.join(", ");
                        }}
                        sx={{ height: "30px", width: "100%" }}
                        onChange={(e) => {
                          updateAutomationDetails(
                            true,
                            "sourceTaskIds",
                            e.target.value
                          );
                        }}
                      >
                        {tasks?.map((task: any) => (
                          <MenuItem value={task.id}>
                            <Checkbox
                              checked={currentRule?.cardAction?.sourceTaskIds?.includes(
                                task.id
                              )}
                            />
                            <ListItemText primary={task.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <InputLabel>Target Project: </InputLabel>
                    <FormControl
                      fullWidth
                      style={{ maxWidth: "350px", width: "100%" }}
                    >
                      <Select
                        id="demo-simple-select"
                        value={currentRule?.cardAction?.targetProjectId}
                        onChange={(e) =>
                          updateAutomationDetails(
                            true,
                            "targetProjectId",
                            e.target.value
                          )
                        }
                        sx={{ height: "30px", width: "100%" }}
                      >
                        {projectList?.map((project, index) => (
                          <MenuItem key={index} value={project.id}>
                            {project.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <InputLabel>Due in (days):</InputLabel>
                    <FormControl
                      fullWidth
                      style={{ maxWidth: "350px", width: "90%" }}
                    >
                      <TextField
                        type="number"
                        onChange={(e) =>
                          updateAutomationDetails(
                            true,
                            "dueInDays",
                            e.target.value
                          )
                        }
                        value={currentRule?.cardAction?.dueInDays}
                        InputProps={{
                          inputProps: {
                            max: 90,
                            min: 1,
                          },
                        }}
                        size="small"
                        placeholder="Enter days"
                      />
                    </FormControl>
                  </Box>
                </Box>
                <span className="automationCard__arrow">
                  <img
                    alt="added icon"
                    src={Vector}
                    className="mainHeader__logo"
                    style={{ width: "20px", height: "20px" }}
                  />
                </span>
              </Box>
            )}
            {show.updateStatus && (
              <Box className="automationCard">
                <Box className="automationCard__inner">
                  <Typography className="automationCard__title">
                    Action: Update Field
                  </Typography>
                  <Box className="automationCard__fields">
                    <InputLabel>Concept</InputLabel>
                    <FormControl
                      fullWidth
                      style={{ maxWidth: "350px", width: "100%" }}
                    >
                      <Select
                        id="demo-simple-select"
                        value={currentRule?.cardAction?.concept}
                        onChange={(e) =>
                          updateAutomationDetails(
                            true,
                            "concept",
                            e.target.value
                          )
                        }
                        sx={{ height: "30px", width: "100%" }}
                      >
                        {selectedRuleBoard?.boardType === "client" && (
                          <MenuItem value={"clientStatus"}>
                            Client Status
                          </MenuItem>
                        )}
                        {selectedRuleBoard?.boardType === "provider" && (
                          <MenuItem value={"employeeStatus"}>
                            Employee Status
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <InputLabel>Concept Value: </InputLabel>
                    <FormControl
                      fullWidth
                      style={{ maxWidth: "350px", width: "100%" }}
                    >
                      <Select
                        id="demo-simple-select"
                        value={currentRule?.cardAction?.conceptValue}
                        onChange={(e) =>
                          updateAutomationDetails(
                            true,
                            "conceptValue",
                            e.target.value
                          )
                        }
                        sx={{ height: "30px", width: "100%" }}
                      >
                        {currentRule?.cardAction?.concept === "clientStatus" &&
                          ClientType.map((item) => (
                            <MenuItem key={item.key} value={item.value}>
                              {item.value}
                            </MenuItem>
                          ))}
                        {currentRule?.cardAction?.concept ===
                          "employeeStatus" &&
                          ProviderType.map((item) => (
                            <MenuItem key={item.key} value={item.value}>
                              {item.value}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <span className="automationCard__arrow">
                  <img
                    alt="added icon"
                    src={Vector}
                    className="mainHeader__logo"
                    style={{ width: "20px", height: "20px" }}
                  />
                </span>
              </Box>
            )}
            <Box
              className="automationCard"
              style={{ border: "1px solid #096DD9", borderRadius: "4px" }}
            >
              <Box className="automationCard__inner">
                <Box
                  style={{
                    display: "grid",
                    gridTemplateColumns: " 1fr 1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <Button
                    style={{
                      margin: "0 10px",
                      background: "white",
                      color: "#096DD9",
                      border: "1px solid #096DD9",
                    }}
                    onClick={() => {
                      //cleanup
                      if (show.moveCard) {
                        setCurrentRule({
                          ...currentRule,
                          cardAction: {
                            ...currentRule.cardAction,
                            newLocation: false,
                            board: "",
                            stage: "",
                          },
                        });
                        const updatedAutomation = allAutomation?.map(
                          (board) => {
                            if (board.id === selectedRule.id) {
                              const automation = board.automation?.map(
                                (auto, index) =>
                                  index === selectedRule.index
                                    ? {
                                        ...auto,
                                        cardAction: {
                                          ...auto.cardAction,
                                          newLocation: false,
                                          board: "",
                                          stage: "",
                                        },
                                      }
                                    : auto
                              );
                              return { ...board, automation };
                            } else {
                              return board;
                            }
                          }
                        );
                        dispatch(setAllAutomation(updatedAutomation));
                      }
                      setShow({ ...show, moveCard: !show.moveCard });
                    }}
                  >
                    {show.moveCard ? "Remove Action: " : "Add Action: "} Move
                    Card
                  </Button>
                  <Button
                    style={{
                      margin: "0 10px",
                      background: "white",
                      color: "#096DD9",
                      border: "1px solid #096DD9",
                    }}
                    onClick={() => {
                      //cleanup
                      if (show.sendMessage) {
                        setCurrentRule({
                          ...currentRule,
                          cardAction: {
                            ...currentRule.cardAction,
                            inmail: false,
                            email: false,
                            sms: false,
                            inmailTemplate: "",
                            emailTemplate: "",
                            smsTemplate: "",
                          },
                        });
                        const updatedAutomation = allAutomation?.map(
                          (board) => {
                            if (board.id === selectedRule.id) {
                              const automation = board.automation?.map(
                                (auto, index) =>
                                  index === selectedRule.index
                                    ? {
                                        ...auto,
                                        cardAction: {
                                          ...auto.cardAction,
                                          inmail: false,
                                          email: false,
                                          sms: false,
                                          inmailTemplate: "",
                                          emailTemplate: "",
                                          smsTemplate: "",
                                        },
                                      }
                                    : auto
                              );
                              return { ...board, automation };
                            } else {
                              return board;
                            }
                          }
                        );
                        dispatch(setAllAutomation(updatedAutomation));
                      }
                      setShow({ ...show, sendMessage: !show.sendMessage });
                    }}
                  >
                    {show.sendMessage ? "Remove Action: " : "Add Action: "} Send
                    Message
                  </Button>
                  <Button
                    style={{
                      margin: "0 10px",
                      background: "white",
                      color: "#096DD9",
                      border: "1px solid #096DD9",
                    }}
                    onClick={() => {
                      //cleanup
                      if (show.sendForm) {
                        setCurrentRule({
                          ...currentRule,
                          cardAction: {
                            ...currentRule.cardAction,
                            form: [],
                          },
                        });
                        const updatedAutomation = allAutomation?.map(
                          (board) => {
                            if (board.id === selectedRule.id) {
                              const automation = board.automation?.map(
                                (auto, index) =>
                                  index === selectedRule.index
                                    ? {
                                        ...auto,
                                        cardAction: {
                                          ...auto.cardAction,
                                          form: [],
                                        },
                                      }
                                    : auto
                              );
                              return { ...board, automation };
                            } else {
                              return board;
                            }
                          }
                        );
                        dispatch(setAllAutomation(updatedAutomation));
                      }
                      setShow({ ...show, sendForm: !show.sendForm });
                    }}
                  >
                    {show.sendForm ? "Remove Action: " : "Add Action: "}
                    Send Form
                  </Button>
                  <Button
                    style={{
                      margin: "0 10px",
                      background: "white",
                      color: "#096DD9",
                      border: "1px solid #096DD9",
                    }}
                    onClick={() => {
                      //cleanup
                      if (show.createTask) {
                        setCurrentRule({
                          ...currentRule,
                          cardAction: {
                            ...currentRule.cardAction,
                            sourceProjectId: "",
                            sourceTaskIds: [],
                            targetProjectId: "",
                            dueInDays: 0,
                          },
                        });
                        const updatedAutomation = allAutomation?.map(
                          (board) => {
                            if (board.id === selectedRule.id) {
                              const automation = board.automation?.map(
                                (auto, index) =>
                                  index === selectedRule.index
                                    ? {
                                        ...auto,
                                        cardAction: {
                                          ...auto.cardAction,
                                          sourceProjectId: "",
                                          sourceTaskIds: [],
                                          targetProjectId: "",
                                          dueInDays: 0,
                                        },
                                      }
                                    : auto
                              );
                              return { ...board, automation };
                            } else {
                              return board;
                            }
                          }
                        );
                        dispatch(setAllAutomation(updatedAutomation));
                      }
                      setShow({ ...show, createTask: !show.createTask });
                    }}
                  >
                    {show.createTask ? "Remove Action: " : "Add Action: "}
                    Create Task
                  </Button>
                  <Button
                    style={{
                      margin: "0 10px",
                      background: "white",
                      color: "#096DD9",
                      border: "1px solid #096DD9",
                    }}
                    onClick={() => {
                      if (show.updateStatus) {
                        setCurrentRule({
                          ...currentRule,
                          cardAction: {
                            ...currentRule.cardAction,
                            conceptValue: "",
                            concept: "",
                          },
                        });
                        const updatedAutomation = allAutomation?.map(
                          (board) => {
                            if (board.id === selectedRule.id) {
                              const automation = board.automation?.map(
                                (auto, index) =>
                                  index === selectedRule.index
                                    ? {
                                        ...auto,
                                        cardAction: {
                                          ...auto.cardAction,
                                          conceptValue: "",
                                          concept: "",
                                        },
                                      }
                                    : auto
                              );
                              return { ...board, automation };
                            } else {
                              return board;
                            }
                          }
                        );
                        dispatch(setAllAutomation(updatedAutomation));
                      }
                      setShow({
                        ...show,
                        updateStatus: !show.updateStatus,
                      });
                    }}
                  >
                    {show.updateStatus ? "Remove Action: " : "Add Action: "}
                    Update Field
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
      {varifyRemoveRule && (
        <Dialog open={true} className="smallModel">
          <div>
            <DialogContent>
              Are you sure want to delete this Rule ?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  setVarifyRemoveRule(false);
                }}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  setVarifyRemoveRule(false);
                  const updatedAutomation = allAutomation?.map((board) => {
                    if (board.id === selectedRule.id) {
                      return {
                        ...board,
                        automation: board.automation?.filter(
                          (_, i) => i !== selectedRule.index
                        ),
                      };
                    } else {
                      return board;
                    }
                  });
                  dispatch(setAllAutomation(updatedAutomation));
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
      {editName && (
        <Dialog open={editName} className="smallModel">
          <div>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <InputLabel sx={{ fontWeight: "bold" }} variant="standard">
                  Rule Name:
                </InputLabel>
                <FormControl>
                  <TextField
                    className="ruleName"
                    value={ruleName}
                    onChange={(e) => {
                      setRuleName(e.target.value);
                      if (!ruleName) {
                        setHasError(true);
                      }
                    }}
                  />
                  {!ruleName && hasError && (
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      Please enter rule name
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  setRuleName("");
                  setEditName(false);
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={nameHandler}
              >
                Save
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </Grid>
  );
};

export default AutomationCard;
