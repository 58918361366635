import { ClientForms } from "../types/client";
import { ProviderForms } from "../types/provider";
import { MatchedProvider } from "../types/schedule";

export const searchClientText = (searchVal: string, item: ClientForms)
: boolean => {
  if (searchVal) {
    const fullName = `${item.clientBasicDetails.childFirstName} ${item.clientBasicDetails.childLastName}`;    
    if (
      searchVal && 
      !fullName.toLowerCase().includes(searchVal.toLowerCase().trim())
    ) {
      return false;
    }
  }

  return true;
}

export const searchProviderText = (
  searchVal: string, 
  item: ProviderForms | MatchedProvider)
: boolean => {
  if (searchVal) {
    const fullName = `${item.employmentDetails.firstName} ${item.employmentDetails.lastName}`;    
    if (
      searchVal && 
      !fullName.toLowerCase().includes(searchVal.toLowerCase().trim())
    ) {
      return false;
    }
  }

  return true;
}
