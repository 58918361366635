import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Add } from "@mui/icons-material";
import "../provider/Providers.scss";
import { RootState } from "../../../redux/store";
import { ProviderFilters, ProviderForms } from "../../../types/provider";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "react-multi-carousel/lib/styles.css";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import { ReactComponent as LeftArrow } from "../../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/images-svg/rightArrow.svg";
import { OrgBU } from "../../../types/company";
import InLoader from "../../../layouts/loader/InLoader";
import { filterProviders } from "../../../helpers/provider-filter-helper/provider-filter-helper";
import { getBusinessUnitName } from "../../../utils/EntityUtils";

interface ProviderCardsProps {
  mergedData: ProviderForms[];
  setAddNewDialog: Dispatch<SetStateAction<boolean>>;
}

const ProviderCards: React.FC<ProviderCardsProps> = ({
  mergedData,
  setAddNewDialog,
}) => {
  // #region variable region

  const navigate = useNavigate();

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //used to display number cards based on screen size
  const [isDesktop, setDesktop] = useState(4);

  const [filteredData, setFilteredData] = useState<ProviderForms[]>([]);

  //loader handling
  const isLoading = useSelector<RootState, boolean>(
    (state) => state.providerListSlice.loader
  );

  //set active Index for show and hide scroll buttons
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  //same ref between multiple re-renders

  //Gets the filter values from redux
  const filterValues = useSelector<RootState, ProviderFilters>(
    (state) => state.providerFilterSlice
  );

  //Gets the search value from redux
  const searchValue = useSelector<RootState, string>(
    (state) => state.activeStep.searchValue
  );

  // #endregion

  // #region methods region

  //handles routing
  const handleRoute = (id: string): void => {
    navigate("/provider/" + id);
  };

  //Left scroll button popup handle
  const funcLeft = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < isDesktop) {
      return true;
    } else {
      return true;
    }
  };

  // Right scroll button popup handle
  const funcRight = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < filteredData?.length) {
      return true;
    }
    return true;
  };

  //Right scroll button Click handle
  const handleRight = () => {
    if (activeIndex) {
      if (activeIndex + isDesktop < filteredData?.length) {
        setActiveIndex(activeIndex + isDesktop);
      } else {
        setActiveIndex(filteredData?.length - 1);
      }
    }
  };

  //Left scroll button Click handle
  const handleLeft = () => {
    if (activeIndex) {
      if (activeIndex - isDesktop + 1 >= isDesktop) {
        setActiveIndex(activeIndex - isDesktop);
      } else {
        setActiveIndex(isDesktop - 1);
      }
    }
  };

  // #endregion

  // #region useEffect region

  useEffect(() => {
    setFilteredData(filterProviders(filterValues, searchValue, mergedData));
  }, [filterValues, searchValue, mergedData]);

  useEffect(() => {
    if (mergedData && mergedData.length > isDesktop) {
      setActiveIndex(isDesktop - 1);
    }
    // eslint-disable-next-line
  }, [mergedData.length, isDesktop]);

  useEffect(() => {
    if (window.innerWidth > 1240) {
      setDesktop(4);
    } else if (window.innerWidth > 991) {
      setDesktop(3);
    } else if (window.innerWidth > 650) {
      setDesktop(2);
    } else {
      setDesktop(1);
    }

    const updateMedia = () => {
      if (window.innerWidth > 1240) {
        setDesktop(4);
      } else if (window.innerWidth > 991) {
        setDesktop(3);
      } else if (window.innerWidth > 650) {
        setDesktop(2);
      } else {
        setDesktop(1);
      }
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  // #endregion

  return (
    <>
      <div className="cardBlock">
        <div className="cardBlock__header">
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="cardBlock__title"
          >
            Recent Added Employees
          </Typography>
          <Box>
            <Button
              type="submit"
              variant="contained"
              startIcon={<Add />}
              onClick={() => setAddNewDialog(true)}
              className="button"
            >
              Add New
            </Button>
          </Box>
        </div>
        <div style={{ position: "relative" }}>
          {isLoading ? (
            <InLoader isShow={isLoading} />
          ) : filteredData && filteredData.length > 0 ? (
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={filteredData?.length}
              visibleSlides={isDesktop}
              step={isDesktop}
              orientation={"horizontal"}
              className="cardSlider"
            >
              <Slider>
                {filteredData?.map((item: ProviderForms, index: number) => {
                  return (
                    <Slide index={index} key={index}>
                      <Card
                        key={index}
                        className="cardBox"
                        onClick={() => handleRoute(item.employmentDetails.id)}
                      >
                        <CardMedia
                          component="img"
                          alt="green iguana"
                          image={
                            item?.employmentDetails?.providerProfile?.url ||
                            ProviderImg
                          }
                          className="cardBox__image"
                        />
                        <Typography
                          gutterBottom
                          variant="h5"
                          className="cardBox__title"
                        >
                          {item?.employmentDetails?.firstName}{" "}
                          {item?.employmentDetails?.lastName}
                        </Typography>
                        <CardContent className="cardBox__info">
                          <div className="cardBox__infoList">
                            <div className="cardBox__infoList-item">
                              <Typography className="cardBox__info-title">
                                Employment
                              </Typography>
                              <Typography className="cardBox__info-text">
                                {item?.employmentDetails?.employmentStatus}
                              </Typography>
                            </div>
                            <div className="cardBox__infoList-item">
                              <Typography className="cardBox__info-title">
                                Type
                              </Typography>
                              <Typography className="cardBox__info-text">
                                {item?.employmentDetails?.type}
                              </Typography>
                            </div>
                          </div>
                          <div className="cardBox__infoList">
                            <div className="cardBox__infoList-item">
                              <Typography className="cardBox__info-title">
                                Business Unit
                              </Typography>
                              <Typography className="cardBox__info-text">
                                {getBusinessUnitName(
                                  businessUnits,
                                  item?.employmentDetails?.businessUnit
                                )?.map((res: string, index: number) => {
                                  return (
                                    <Typography key={index}>
                                      {`${index ? "," : ""} ${res}`}{" "}
                                    </Typography>
                                  );
                                })}
                              </Typography>
                            </div>
                            <div className="cardBox__infoList-item">
                              <Typography className="cardBox__info-title">
                                Status
                              </Typography>
                              <Typography className="cardBox__info-text">
                                {item?.employmentDetails?.providerType}
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Slide>
                  );
                })}
              </Slider>
              {funcLeft() && (
                <ButtonBack className="sliderNavBtn" onClick={handleLeft}>
                  <LeftArrow />
                </ButtonBack>
              )}
              {funcRight() ? (
                <ButtonNext className="sliderNavBtn" onClick={handleRight}>
                  <RightArrow />
                </ButtonNext>
              ) : (
                ""
              )}
            </CarouselProvider>
          ) : (
            <div className="notFound-Block">No Employee Found</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProviderCards;
