import React, {
  Dispatch,
  useRef,
  SetStateAction,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import usePlacesAutocomplete, { getLatLng } from "use-places-autocomplete";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import {
  CancelOptions,
  UpdateOptions,
  WeekDaysFirstletter,
  WeekDaysFullName,
  WhoCancelled,
} from "../../../constants/schedule";
import { MenuProps } from "../../../constants/providers";
import { AvailabilityTimesForSchedulesBars } from "../../../constants/schedules-filters";

import {
  bookService,
  cancelService,
  editService,
} from "../../../service/schedules.service";

import {
  addOtherAddresses,
  setAvailabilityDetails as setClientAvailability,
  setClientContactDetailsWithOtherAddresses,
  updateClientContactDetailById,
} from "../../../redux/features/client-main/client-slice";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import {
  GetAllClientData,
  GetClientById,
  setSingleClientData,
} from "../../../redux/features/client-main/client-list-slice";
import { getAllBookingsData } from "../../../redux/features/schedules-main/schedule-list-slice";
import { getDataCatalog } from "../../../redux/features/data-catalog/data-catalog-slice";
import { GetProviderById } from "../../../redux/features/providers-main/provider-list-slice";
import { NewDataCatalog } from "../../../types/businessSettings";
import { ProviderForms } from "../../../types/provider";
import { OrgBU } from "../../../types/company";
import { IShouldBeComponent, IAddress } from "../../../types/Common";
import { ClientForms, OtherAddresses } from "../../../types/client";
import {
  BaseClient,
  BookedSchedule,
  MatchedProvider,
  MatchOption,
} from "../../../types/schedule";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import "./BookingEditModal.scss";
import { returnAuthorizationValue } from "../../../utils/ScheduleUtils";
import { Label } from "@mui/icons-material";
import { useUser } from "@descope/react-sdk";
import CalendarHeatmap from "react-calendar-heatmap";
import {
  AvailabilityDates,
  AvailabilityDetails,
} from "../../../types/availability";
import ReactTooltip from "react-tooltip";
import { setAvailabilityDetails as setProviderAvailability } from "../../../redux/features/providers-main/provider-slice";
import { sortDataArray } from "../../../utils/AvailabilityUtils";

export const serviceCategory = [
  { key: 0, value: "Billable" },
  { key: 1, value: "Non Billable" },
] as const;
interface BookingModalProps {
  isOpen: boolean;
  pId?: number;
  closePopup: Dispatch<SetStateAction<boolean>>;
  isNew?: boolean;
  pageTitle: string;
  dayIndex?: number;
  provider?: ProviderForms | MatchedProvider; //allow nullable
  isDelete?: boolean;
  isEdit?: boolean;
  argsdata: Record<string, any> | undefined;
  // specificDate?: IDaysOfWeek; //this matches user picked specific date
  isDataEdited?: boolean;
  setIsDataEdited?: Dispatch<SetStateAction<boolean>>;
  bookedSchedule?: BookedSchedule;
  clientData?: ClientForms | BaseClient; //allow nullable
  // Bookeddata: BookedSchedule[];
  allSelectedClients: any[];
  providersData: any[];
  fromPlanning: boolean;
  isAutoPlanned: boolean;
  autoPlanData?: any;
}

const DiscoverBookingEditModal: React.FC<BookingModalProps> = ({
  isNew,
  isEdit,
  isDelete,
  closePopup,
  pageTitle,
  argsdata,
  allSelectedClients,
  providersData,
  setIsDataEdited,
  provider,
  clientData,
  // Bookeddata,
  fromPlanning,
  isAutoPlanned,
  autoPlanData,
}) => {
  //#region variable region
  // console.log("specificDate:", argsdata?.specificDate);

  //dispatch for redux
  const dispatch: AppDispatch = useDispatch();

  //descope
  const { user } = useUser();

  //binding action creators and dispatch
  const dispatchAction = bindActionCreators(
    { GetClientById, GetProviderById, getAllBookingsData },
    dispatch
  );
  //same value between re-renders
  const dispatchActionRef = useRef(dispatchAction);

  //----------------------------------------------------------------
  //Get dropdowns from data catalog
  //store all billable data
  const BillableCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.BillableCatalog
  );
  //store all non billable data
  const NonBillableCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.NonBillableCatalog
  );
  // all cancellation reasons
  const ProviderReasonCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.ProviderReasonCatalog
  );

  const ClientReasonCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.ClientReasonCatalog
  );
  //----------------------------------------------------------------

  //Checked Box handle
  const [repeatInput, setRepeatInput] = useState<boolean>(
    argsdata?.Repeat
      ? true
      : isAutoPlanned
      ? autoPlanData?.repeatService > 0
      : false
  );
  const [selectedClient, setSelectedClient] = useState<
    ClientForms | BaseClient
  >();
  const [selectedProvider, setSelectedProvider] = useState<
    ProviderForms | MatchedProvider
  >();

  const [isClient, setIsClient] = useState(false);

  //store initial selected days as user picked one
  const [daysSelect, setDaysSelect] = useState<string[]>(
    argsdata
      ? (WeekDaysFullName[moment(argsdata?.StartTime).day()].split(
          " "
        ) as string[])
      : isAutoPlanned
      ? autoPlanData?.selectedDays
      : []
  );

  // store service date
  const [serviceDates, setServiceDates] = useState<string[]>(
    argsdata
      ? [moment(argsdata.StartTime).format("MM/DD/YYYY")]
      : isAutoPlanned
      ? autoPlanData?.selectedDate
      : ""
  );

  //store initial client start time
  const clientStartTime: string =
    argsdata &&
    moment(argsdata?.EndTime).format("hh:mm A") !==
      moment(argsdata.StartTime).format("hh:mm A")
      ? moment(argsdata.StartTime).format("hh:mm A")
      : "07:00 AM";
  //changed start time for client
  const [startTime, setStartTime] = useState<string>(clientStartTime);

  //store initial client end time
  const clientEndTime: string = argsdata
    ? moment(argsdata.EndTime).format("hh:mm A")
    : "06:00 PM";
  //changed end time for client
  const [endTime, setEndTime] = useState<string>(clientEndTime);

  // handler addresses display or not
  const [displayOtherAddress, setDisplayOtherAddress] =
    useState<boolean>(false);

  const [userAddress, setUserAddress] = useState<string[]>([]);

  // validation handler
  const [hasError, setHasError] = useState(false);

  //handles appointment error
  const [appointmentError, setAppointmentError] = useState<boolean>(false);

  //handles reason error
  const [reasonError, setReasonError] = useState<boolean>(false);

  //handling the start time
  const setServiceFromTime = (value: string): void => {
    setStartTime(value);
  };
  // store client Address
  const [address, setAddress] = useState<string>(
    argsdata && argsdata.Location ? argsdata.Location : ""
  );

  //description variable
  const [description, setDescription] = useState<string>(
    argsdata ? argsdata.Description : ""
  );

  // store cancellation reasion
  const [cancellationReason, setCancellationReason] = useState<string>(
    argsdata?.cancelReason ? argsdata?.cancellationReason : ""
  );

  // store who's cancelled
  const [whoCancelled, setWhoCancelled] = useState<string>(
    argsdata?.whoCancelled ? argsdata?.whoCancelled : "Client"
  );

  // store value Custom Date
  const [valueDate, setValueDate] = useState<Dayjs | null>(
    argsdata
      ? argsdata?.repeatCustomEnd
      : autoPlanData?.isEndMonth
      ? autoPlanData.customEnd
      : null //new Date(), confusing to use today!
  );

  //stored the repeat service
  const [repeatService, setRepeatService] = useState<number>(
    argsdata?.Repeat
      ? argsdata.Repeat
      : isAutoPlanned
      ? autoPlanData.repeatService
      : 0
  );

  //stored the repeat service
  const [cancelOption, setCancelOption] = useState<number>(
    argsdata?.cancelOption ? argsdata.cancelOption : 0
  );

  //stored the repeat service
  const [updateOption, setUpdateOption] = useState<number>(
    argsdata?.updateOption ? argsdata.updateOption : 0
  );

  //service type
  const [serviceType, setServiceType] = useState<string>(
    argsdata ? argsdata.ServiceType : ""
  );

  //handling the end time
  const setServiceToTime = (value: string): void => {
    setEndTime(value);
  };

  //match values
  const matchValues = useSelector<RootState, MatchOption>(
    (state) => state.matchSlice
  );

  //store delete Dialog value
  const [permanentDelete, setPermanentDelete] = useState<boolean>(false);

  // set model value
  const [model, setModal] = useState<boolean>(false);

  //Dialog open popup
  // eslint-disable-next-line
  const [open, setOpen] = useState<boolean>(true);
  const [clientAuths, setClientAuths] = useState({
    error: false,
    msg: "",
    auth: [],
  });
  const [selectedAuth, setSelectedAuth] = useState<any>({
    id: "",
    code: "",
    authCodes: [],
  });
  // store lat lng school address
  const [schoolAddressObj, setSchoolAddressObj] = useState<OtherAddresses>({
    address: {
      fullAddress: "",
      lat: 0,
      lng: 0,
    },
    state: "",
    city: "",
    zipCode: "",
  });

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //store selected category
  const [category, setCategory] = useState<string>(
    isEdit
      ? BillableCatalog?.includes(argsdata?.ServiceType)
        ? "Billable"
        : NonBillableCatalog?.includes(argsdata?.ServiceType)
        ? "Non Billable"
        : ""
      : "Billable"
  );

  //stores billable data
  const [billableData, setBillableData] = useState<string>(
    argsdata ? argsdata.ServiceType : BillableCatalog[0]?.conceptValue
  );
  // console.log("billableData:", billableData, BillableCatalog);

  //store non billable data
  const [nonBillableData, setNonBillableData] = useState<string>(
    argsdata ? argsdata.ServiceType : NonBillableCatalog[0]?.conceptValue
  );

  const clientAvailability = useSelector<RootState, AvailabilityDetails[]>(
    (state) => state.client.availabilityDetails
  );

  const providerAvailability = useSelector<RootState, AvailabilityDetails[]>(
    (state) => state.providerSlice.availabilityDetails
  );

  const [selectedAvailability, setSelectedAvailability] = useState<{
    client: AvailabilityDetails;
    provider: AvailabilityDetails;
  }>({
    client: clientAvailability[0],
    provider: providerAvailability[0],
  });

  //function for billable data
  const billableTest = (serviceType: string): string => {
    let result = BillableCatalog.find(
      (item: any) => item.conceptValue === serviceType
    );
    if (result !== undefined) {
      setCategory("Billable");
      return result.conceptValue;
    } else {
      return "";
    }
  };

  //function for non billable data
  const nonBillableTest = (serviceType: string): string => {
    let result = NonBillableCatalog.find(
      (item: any) => item.conceptValue === serviceType
    );
    if (result !== undefined) {
      setCategory("Non Billable");
      // eslint-disable-next-line no-lone-blocks
      {
        isEdit ? setNonBillableData(serviceType) : setNonBillableData("");
      }
      return result.conceptValue;
    } else {
      return "";
    }
  };

  //Function for Date Calender
  const handleDateChange = (newValue: Dayjs | null) => {
    setValueDate(newValue);
    setRepeatService(3);
  };

  //store gmt value
  // eslint-disable-next-line
  const [gmt, setGmt] = useState<number>(0);

  //#endregion

  //#region methods region

  //remove focus from input feilds
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  /* temporary turn off by CTO. Keep this, need to revisit! 
  //handle split time zone
  const splitTimeZone = () => {
    if (
      clientData &&
      businessUnits?.length > 0 &&
      clientData.clientBasicDetails?.businessUnit?.length > 0
    ) {
      let arr: any[] = [];
      businessUnits?.forEach((item) => {
        clientData.clientBasicDetails?.businessUnit?.forEach((res) => {
          if (item?.id === res) {
            arr.push(item);
          }
        });
      });
      setBusiness(arr);

      let val = business[0]?.businessUnitTimeZone?.label?.split(")");
      // businessUnitTimeZone.split(")");

      if (val !== undefined) {
        let s = val[0];
        let secondval = s.slice(4);
        let GMT = parseInt(secondval);
        if (GMT < 0) {
          setGmt(Math.abs(GMT));
        } else {
          GMT = GMT * -1;
          setGmt(GMT);
        }
      }
    }
  };
  */

  //use for place autocomplete
  const {
    suggestions: { data },
    value,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      //types: ["address"],
      componentRestrictions: {
        country: "us",
      },
    },
    debounce: 300,
  });

  const getClientAddressesAndAuths = (newOptionSelect: any) => {
    const addresses: string[] = [];
    let client: any;
    //figure out selected lient
    if (isClient) {
      client = selectedClient;
    } else {
      if (newOptionSelect) {
        const result = allSelectedClients.filter(
          (item) => newOptionSelect === item.clientBasicDetails.id
        );
        client = result[0];
      }
    }

    //populate valid addreses
    if (
      client?.clientContactDetails?.address?.fullAddress &&
      client?.clientContactDetails?.state &&
      client?.clientContactDetails?.city &&
      client?.clientContactDetails?.zipCode
    ) {
      addresses.push(
        `Home ${client?.clientContactDetails?.address?.fullAddress}, ${client?.clientContactDetails?.city}, ${client?.clientContactDetails?.state} ${client?.clientContactDetails?.zipCode}`
      );
    }
    if (
      client?.clientContactDetails?.schoolAddress?.fullAddress &&
      client?.clientContactDetails?.schoolState &&
      client?.clientContactDetails?.schoolCity &&
      client?.clientContactDetails?.schoolZipCode
    ) {
      addresses.push(
        `School  ${client?.clientContactDetails?.schoolAddress?.fullAddress}, ${client?.clientContactDetails?.schoolCity}, ${client?.clientContactDetails?.schoolState} ${client?.clientContactDetails?.schoolZipCode}`
      );
    }
    client?.clientContactDetails?.otherAddresses?.forEach((item: any) => {
      if (
        item?.address?.fullAddress &&
        item?.state &&
        item?.city &&
        item?.zipCode
      ) {
        addresses.push(
          `Other  ${item?.address?.fullAddress}, ${item?.city}, ${item?.state} ${item?.zipCode}`
        );
      }
    });
    setUserAddress(addresses);
    setAddress(addresses[0]);

    //populate auth
    if (client) {
      // console.log("selectedClient:", selectedClient);
      // ----------------------------------------------
      //set client auths
      if (client?.authorizations?.length === 0) {
        setClientAuths({
          error: true,
          msg: "Selected client doesn't have auth yet.",
          auth: [],
        });
      } else {
        setClientAuths({
          error: false,
          msg: "",
          auth: client?.authorizations,
        });

        // ----------------------------------------------
        // auto-select auth
        //intialization
        setSelectedAuth({
          id: "",
          code: "",
          authCodes: [],
        });

        let foundAuths: any[];
        if (isAutoPlanned) {
          foundAuths = client?.authorizations?.filter(
            (auth: any) => auth.id === autoPlanData?.authId
          );
          if (foundAuths?.length > 0) {
            setSelectedAuth({
              id: autoPlanData?.authId,
              code: autoPlanData?.authCode,
              authCodes: foundAuths[0].auth,
            });
          }
        } else {
          //find auth for today
          const today = new Date();
          foundAuths = client?.authorizations?.filter(
            (auth: any) =>
              auth?.authPeriod &&
              auth?.authPeriod?.startDate &&
              auth?.authPeriod?.endDate &&
              new Date(auth.authPeriod.startDate).getTime() <=
                today.getTime() &&
              new Date(auth.authPeriod.endDate).getTime() >= today.getTime()
          );
          if (foundAuths?.length > 0) {
            setSelectedAuth({
              id: foundAuths[0].id,
              code: foundAuths[0].auth[0].authCode,
              authCodes: foundAuths[0].auth,
            });
          }
        }
      }
    }
  };

  const handleAuthChange = (newAuthId: string) => {
    let foundAuths: any[];
    foundAuths = clientAuths.auth.filter((auth: any) => auth.id === newAuthId);

    if (foundAuths.length > 0) {
      setSelectedAuth({
        id: foundAuths[0].id,
        code: foundAuths[0].auth[0].authCode,
        authCodes: foundAuths[0]?.auth,
      });
    } else {
      setSelectedAuth({
        id: "",
        code: "",
        authCodes: [],
      });
    }
  };

  // //gets index from time
  const getIndexFromTimeValidation = (val: string): number => {
    for (let i = 0; i < AvailabilityTimesForSchedulesBars.length; i++) {
      if (AvailabilityTimesForSchedulesBars[i] === val) return i;
    }
    return -1;
  };

  //Returns the drop down values for start and end time
  const getAvailabilityTimes = (index: number): JSX.Element[] => {
    const data: JSX.Element[] = [];
    for (let i = index; i < AvailabilityTimesForSchedulesBars.length; i++) {
      data.push(
        <MenuItem key={i} value={AvailabilityTimesForSchedulesBars[i]}>
          {AvailabilityTimesForSchedulesBars[i]}
        </MenuItem>
      );
    }
    return data;
  };

  //address objet suggest google address
  const getAddressObject = (
    address_components: google.maps.GeocoderAddressComponent[]
  ) => {
    const ShouldBeComponent: IShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
        "administrative_area_level_6",
        "administrative_area_level_7",
      ],
      city: [
        "colloquial_area",
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
        "sublocality_level_5",
      ],
      country: ["country"],
    };

    let address: IAddress = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }

          if (shouldBe === "province") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    return address;
  };

  //Call Google API to get GoogleGeoCodeReul
  const handleSelect = (e: any) => {
    let description = e?.label;
    if (!description) return;

    // Get latitude and longitude via utility functions
    //getGeocode({ address: description }).then(async (results: any) => {
    geocodeByAddress(description).then(
      async (results: google.maps.GeocoderResult[]) => {
        const addressObject = getAddressObject(results[0].address_components);

        //Get lat & Long Data
        const { lat, lng } = getLatLng(results[0]);

        let addressStr = description.split(",")[0];
        setSchoolAddressObj({
          address: {
            fullAddress: addressStr,
            lat: lat,
            lng: lng,
          },
          state: addressObject?.province,
          city: addressObject?.city,
          zipCode: addressObject?.postal_code,
        });
      }
    );
  };

  // school address accress
  const handleChange = (e: any) => {
    setSchoolAddressObj((prevState: any) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  //suggested school address
  const renderSuggestionsForSchool = () => {
    return data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={() => handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  };

  //add one more address
  const addAddressesOtherAddresses = (): void => {
    dispatch(
      setClientContactDetailsWithOtherAddresses({
        ...clientData?.clientContactDetails,
      })
    );
    dispatch(addOtherAddresses({ ...schoolAddressObj }));
    setSchoolAddressObj({
      address: {
        fullAddress: "",
        lat: 0,
        lng: 0,
      },
      state: "",
      city: "",
      zipCode: "",
    });
  };

  //save Other address
  const saveOtherAddresses = (): void => {
    if (
      schoolAddressObj.address.fullAddress &&
      schoolAddressObj.city &&
      schoolAddressObj.state &&
      schoolAddressObj.zipCode
    ) {
      dispatch(addOtherAddresses({ ...schoolAddressObj }));
      const otherAddress = `Other ${schoolAddressObj.address.fullAddress} ${schoolAddressObj.city} ${schoolAddressObj.state} ${schoolAddressObj.zipCode}`;
      setUserAddress([...userAddress, otherAddress]);
    }
    setDisplayOtherAddress(false);
    setSchoolAddressObj({
      address: {
        fullAddress: "",
        lat: 0,
        lng: 0,
      },
      state: "",
      city: "",
      zipCode: "",
    });

    dispatch(updateClientContactDetailById());
    if (fromPlanning) {
      dispatch(GetAllClientData());
    }
  };
  //#endregion

  //#region useEffect region

  //fetch and handle all selected clients
  useEffect(() => {
    let findedUser: any[] = [];
    if (isAutoPlanned) {
      setIsClient(true);
      const [fromTime, toTime] = autoPlanData?.selectedSlot.split(" - ");
      getClientAddressesAndAuths(clientData?.clientBasicDetails?.id);
      setStartTime(fromTime);
      setEndTime(toTime);
      setSelectedClient(clientData);
      if (provider) setSelectedProvider(provider);
    } else {
      const client = allSelectedClients?.find(
        (client) =>
          (isEdit ? argsdata?.bookedClientId?.id : argsdata?.id) ===
          client?.clientBasicDetails?.id
      );
      const provider = providersData?.find(
        (provider) =>
          (isEdit ? argsdata?.bookedProviderId?.id : argsdata?.id) ===
          provider?.employmentDetails?.id
      );
      if (provider) {
        setIsClient(false);
      } else {
        setIsClient(true);
      }
      getClientAddressesAndAuths(client?.clientBasicDetails?.id);
      setSelectedClient(client);
      setSelectedProvider(provider);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [argsdata, isAutoPlanned, isEdit]);

  //used to set clientData to fetch service location correctly
  useEffect(() => {
    if (fromPlanning) {
      if (isClient && selectedClient !== undefined) {
        dispatchActionRef.current.GetClientById({
          clientId: selectedClient.clientBasicDetails?.id,
        });
        dispatch(setSingleClientData(selectedClient));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, allSelectedClients.length]);

  /* temporary turn off by CTO. Keep this, need to revisit! 
  //handle timezone
  useEffect(() => {
    splitTimeZone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnits, clientData?.clientBasicDetails?.id]);
  */

  //#endregion

  //#region API calling

  //on submitting the form API calling
  const onSubmitBooking = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    // ----------------------------------------------
    //1a: New booking must have client and provider
    if (isNew) {
      if (!category) {
        setHasError(true);
        return;
      }
      
      if (!serviceType) {
        setHasError(true);
        return;
      }

      if (billableData?.length > 0 && !selectedClient && !selectedProvider) {
        dispatch(
          setResponseValue({
            name: "message",
            value: "Billable service must have client and provider.",
          })
        );
        return;
      }
      if (nonBillableData?.length > 0 && !selectedProvider) {
        dispatch(
          setResponseValue({
            name: "message",
            value: "Non billable service must have provider.",
          })
        );
        return;
      }
    }

    //1b. Edit booking must have category and service type
    if (isEdit) {
      if (!category) {
        setHasError(true);
        return;
      }
      if (!serviceType) {
        setHasError(true);
        return;
      }
    }

    //1c. Delete booking must have whoCancelled and cancellation
    if (isDelete && !permanentDelete) {
      if (whoCancelled?.length === 0) {
        setAppointmentError(true);
        return;
      }
      if (cancellationReason?.length === 0) {
        setReasonError(true);
        return;
      }
    }

    //based on calendar mouse selection!
    let date = moment(argsdata?.StartTime).format("YYYY-MM-DD");
    let DaysInname = moment(date).day();

    dispatch(setResponseValue({ name: "pending", value: true }));
    closePopup(false);
    let bookingData;
    let result;
    try {
      //------------------------------------------------
      /* a. add new booking
         - can support both client and provider
         - can support calendar book, table book (auto-plan)
      */
      if (isNew) {
        bookingData = {
          bookedClientId: "",
          bookedProviderId: "",
          userTodayDate: moment(new Date()).format("ddd YYYY-MM-DD"),

          serviceType: serviceType,
          serviceLocation: address,
          description: description,
          authId: selectedAuth.id || "",
          authCode: selectedAuth.code || "",
          category: category,

          fromTime: startTime,
          toTime: endTime,
          specificDate: serviceDates[0], //must pass it as local dtm!!!

          repeatService: repeatService,
          repeatWorkDays: daysSelect,
          repeatCustomEnd: repeatService
            ? dayjs(valueDate).format("ddd YYYY-MM-DD")
            : null,

          createdBy: user?.email,
        };

        if (isClient && selectedClient) {
          bookingData.bookedClientId = selectedClient?.clientBasicDetails.id;
          bookingData.bookedProviderId = selectedProvider?.employmentDetails
            .id as string;
        } else if (selectedProvider) {
          bookingData.bookedClientId =
            category === "Billable"
              ? (selectedClient?.clientBasicDetails.id as string)
              : "";
          bookingData.bookedProviderId =
            selectedProvider?.employmentDetails?.id;
        }
        result = await bookService(bookingData, fromPlanning);
      }

      //------------------------------------------------
      //b. edit booking
      if (isEdit) {
        bookingData = {
          serviceId: argsdata?.serviceID,
          fromTime: startTime,
          toTime: endTime,
          //userTodayDate: moment(new Date()).format("ddd YYYY-MM-DD"),
          updateOption: updateOption,
          specificDate: argsdata?.specificDate, //must pass it as local dtm!!!
          bookedClientId:
            category === "Billable" ? argsdata?.bookedClientId?.id : "",
          bookedProviderId: argsdata?.bookedProviderId?.id,
          serviceType: serviceType,
          serviceLocation: address,
          description: description,
          authId:
            argsdata && category === "Billable"
              ? argsdata.authId
              : matchValues.clientAuthId,
          authCode:
            argsdata && category === "Billable"
              ? argsdata.authCode
              : matchValues.clientAuthCode,
          category: category,
        };
        result = await editService(bookingData, fromPlanning);
      }
      //------------------------------------------------
      //c. delete booking
      if (isDelete) {
        bookingData = {
          serviceId: argsdata?.serviceID,
          cancelOption: cancelOption,
          specificDate: argsdata?.specificDate,
          bookedClientId: argsdata
            ? argsdata.bookedClientId?.id
            : category === "Billable"
            ? clientData?.clientBasicDetails.id
            : "",
          bookedProviderId: argsdata?.bookedProviderId?.id,
          whoCancelled: whoCancelled,
          cancellationReason: cancellationReason,
          isDelete: permanentDelete ? true : false,
          billableOption: category === "Billable" ? true : false,
        };

        result = await cancelService(bookingData, fromPlanning);
      }

      //------------------------------------------------
      //d. update data
      if (bookingData && result && result.data && result.data.status) {
        if (bookingData.bookedClientId) {
          dispatchActionRef.current.GetClientById({
            clientId: bookingData.bookedClientId,
          });
        }

        //update booked provider
        // console.log("calling GetProviderById");
        dispatchActionRef.current.GetProviderById({
          providerId: bookingData.bookedProviderId,
        });

        //refetch booking data
        // console.log("calling getAllBookingsData");
        setTimeout(() => {
          batch(() => {
            // console.log("refresh booking");
            dispatchActionRef.current.getAllBookingsData();
            if (setIsDataEdited) setIsDataEdited(true);
          });
        }, 1000);

        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({ name: "message", value: result?.data?.message })
        );
      } else {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({ name: "message", value: result?.data?.message })
        );
      }
      closePopup(false);
    } catch (e: any) {
      console.log("scheduling error:", e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      if (isAutoPlanned) {
        if (setIsDataEdited) setIsDataEdited(true);
      }
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };
  //#endregion

  //returns authorization value

  useEffect(() => {
    if (argsdata !== undefined) {
      billableTest(argsdata.ServiceType);
      nonBillableTest(argsdata.ServiceType);
    }
    // eslint-disable-next-line
  }, [argsdata]);

  useEffect(() => {
    dispatch(getDataCatalog());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (selectedProvider) {
      setSelectedAvailability({
        ...selectedAvailability,
        provider: selectedProvider?.availabilityDetails[0],
      });
      dispatch(setProviderAvailability(selectedProvider?.availabilityDetails!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, selectedProvider?.employmentDetails.id]);

  useEffect(() => {
    if (selectedClient) {
      setSelectedAvailability({
        ...selectedAvailability,
        client: selectedClient?.availabilityDetails[0],
      });
      dispatch(
        setClientAvailability({ data: selectedClient?.availabilityDetails })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  //#endregion

  return (
    <>
      <Dialog
        open={open}
        className={
          isDelete || isEdit || isNew
            ? "largeModel schedule-detail-model deleteSchedule"
            : "largeModel schedule-detail-model"
        }
      >
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            {pageTitle}: {argsdata?.Subject}
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => {
              closePopup(false);
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <DialogContent>
          <Box className="largeModel__body">
            <div className="bookingModel">
              <div className="bookingModel__form">
                <Grid container spacing={4}>
                  {/* New Booking View */}
                  {isNew && (
                    <>
                      <Grid item xs={12} md={6}>
                        {isClient ? (
                          <div className="userList-name">
                            <img
                              src={
                                selectedClient?.clientBasicDetails
                                  ?.clientProfile?.url || ClientImg
                              }
                              className="userSmallCircle"
                              alt=""
                            />
                            <span style={{ marginLeft: 10 }}>
                              {selectedClient?.clientBasicDetails
                                .childFirstName +
                                " " +
                                selectedClient?.clientBasicDetails
                                  .childLastName}
                            </span>
                          </div>
                        ) : (
                          <div>
                            {allSelectedClients.length > 0 ? (
                              <Select
                                fullWidth={true}
                                sx={{ height: "40px" }}
                                id="client-select"
                                value={selectedClient?.clientBasicDetails.id}
                                onChange={(e) => {
                                  setSelectedClient(
                                    allSelectedClients.find(
                                      (item: ClientForms) =>
                                        item.clientBasicDetails.id ===
                                        e.target.value
                                    )
                                  );
                                  getClientAddressesAndAuths(e.target.value);
                                }}
                              >
                                {allSelectedClients.map(
                                  (client: any, index: number) => (
                                    <MenuItem
                                      key={index}
                                      value={client.clientBasicDetails?.id}
                                    >
                                      <div className="optionsMenu">
                                        <span>
                                          {`${client.clientBasicDetails.childFirstName} ${client.clientBasicDetails.childLastName}`}
                                        </span>
                                      </div>
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            ) : (
                              <FormControl sx={{ width: "100%" }} disabled>
                                <Select
                                  sx={{ height: "40px" }}
                                  id="demo-simple-select"
                                  label="selectClient"
                                ></Select>
                                <FormHelperText>
                                  Please Select the Client
                                </FormHelperText>
                              </FormControl>
                            )}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {!isClient || isAutoPlanned ? (
                          selectedProvider?.employmentDetails && (
                            <div className="userList-name">
                              <img
                                src={ProviderImg}
                                className="userSmallCircle"
                                alt=""
                              />
                              <span style={{ marginLeft: 10 }}>
                                {selectedProvider?.employmentDetails.firstName}{" "}
                                {selectedProvider?.employmentDetails.lastName}
                              </span>
                            </div>
                          )
                        ) : (
                          <div>
                            {providersData.length > 0 && isClient ? (
                              <FormControl sx={{ width: "100%" }}>
                                <Select
                                  sx={{ height: "40px" }}
                                  fullWidth={true}
                                  id="demo-simple-select-label"
                                  value={
                                    selectedProvider?.employmentDetails?.id
                                  }
                                  placeholder="provider list"
                                  onChange={(e) => {
                                    setSelectedProvider(
                                      providersData.find(
                                        (item) =>
                                          item.employmentDetails.id ===
                                          e.target.value
                                      )
                                    );
                                    setHasError(false);
                                  }}
                                >
                                  {providersData.map((provider) => (
                                    <MenuItem
                                      key={provider.employmentDetails.id}
                                      value={provider.employmentDetails.id}
                                    >
                                      <div className="optionsMenu">
                                        <span>
                                          {`${provider.employmentDetails.firstName} ${provider.employmentDetails.lastName}`}
                                        </span>
                                      </div>
                                    </MenuItem>
                                  ))}
                                </Select>
                                {!selectedProvider?.employmentDetails?.id &&
                                  hasError && (
                                    <FormHelperText
                                      style={{ color: "#d32f2f" }}
                                    >
                                      Please select provider
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            ) : (
                              <FormControl sx={{ width: "100%" }} disabled>
                                <Select
                                  sx={{ height: "40px" }}
                                  id="demo-simple-select"
                                  label="selectProvider"
                                ></Select>
                                <FormHelperText>
                                  Please Select the Provider
                                </FormHelperText>
                              </FormControl>
                            )}
                          </div>
                        )}
                      </Grid>
                    </>
                  )}

                  {/* Edit Booking View */}
                  {isEdit && (
                    <>
                      <Grid item xs={12} md={6}>
                        <div className="userList-name">
                          {category === "Billable" && (
                            <img
                              src={
                                clientData?.clientBasicDetails?.clientProfile
                                  ?.url || ClientImg
                              }
                              className="userSmallCircle"
                              alt=""
                            />
                          )}
                          <span style={{ marginLeft: 10 }}>
                            {argsdata?.bookedClientId?.childFirstName}{" "}
                            {argsdata?.bookedClientId?.childLastName}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="userList-name">
                          <img
                            src={
                              provider?.employmentDetails?.providerProfile
                                ?.url || ProviderImg
                            }
                            className="userSmallCircle"
                            alt=""
                          />
                          {isNew ? (
                            <span style={{ marginLeft: 10 }}>
                              {argsdata?.bookedProviderId?.childFirstName}{" "}
                              {argsdata?.bookedProviderId?.childLastName}
                            </span>
                          ) : (
                            <span style={{ marginLeft: 10 }}>
                              {argsdata
                                ? argsdata?.bookedProviderId?.firstName
                                : ""}{" "}
                              {argsdata
                                ? argsdata?.bookedProviderId?.lastName
                                : ""}
                            </span>
                          )}
                        </div>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} className={"fromGroup"}>
                    <Grid container spacing={3}>
                      {
                        //Delete Booking View
                        isDelete && (
                          <>
                            <Grid item xs={12} md={6}>
                              <InputLabel className="fromLabel">
                                Service Type
                              </InputLabel>
                              <p className="detailsCard-part__text">
                                {serviceType}
                              </p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <InputLabel className="fromLabel">
                                Service Time
                              </InputLabel>
                              <p className="detailsCard-part__text">
                                {startTime} - {endTime}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <InputLabel className="fromLabel">
                                Service Location
                              </InputLabel>
                              <p className="detailsCard-part__text">
                                {address}
                              </p>
                            </Grid>
                          </>
                        )
                      }
                      {(isNew || isEdit) && (
                        <>
                          {clientAuths?.error ? (
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "start",
                                paddingLeft: "30px",
                              }}
                            >
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                {clientAuths?.msg}
                              </FormHelperText>
                            </Box>
                          ) : (
                            clientAuths?.auth?.length > 0 && (
                              <>
                                <Grid item xs={12} md={12}>
                                  <InputLabel className="fromLabel">
                                    Authorizations
                                  </InputLabel>
                                  <Select
                                    value={selectedAuth?.id}
                                    label="authId"
                                    onChange={(e) => {
                                      handleAuthChange(e.target.value);
                                    }}
                                    sx={{ height: "40px" }}
                                    id="demo-simple-select"
                                  >
                                    {clientAuths.auth.map((auth: any) => {
                                      return (
                                        <MenuItem value={auth.id}>
                                          {/* <>{console.log(auth, "auth")}</> */}
                                          {returnAuthorizationValue(auth)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <InputLabel className="fromLabel">
                                    Auth Codes
                                  </InputLabel>
                                  <Select
                                    value={selectedAuth?.code}
                                    onChange={(e) => {
                                      setSelectedAuth({
                                        ...selectedAuth,
                                        code: e.target.value,
                                      });
                                    }}
                                    sx={{ height: "40px" }}
                                    id="demo-simple-select"
                                    label="authcode"
                                  >
                                    {selectedAuth.authCodes &&
                                      selectedAuth.authCodes.map(
                                        (code: any) => (
                                          <MenuItem value={code.authCode}>
                                            {code.authCode}
                                          </MenuItem>
                                        )
                                      )}
                                  </Select>
                                </Grid>
                              </>
                            )
                          )}

                          <Grid item xs={12}>
                            <InputLabel className="fromLabel">
                              Service Type
                            </InputLabel>
                            <Box
                              className="fromGroup-chips flexNowrap"
                              display={"flex"}
                              flexWrap={"nowrap"}
                              style={{ gap: "10px" }}
                              mb={"20px"}
                            >
                              <>
                                {serviceCategory.map((item) => (
                                  <Card
                                    className={
                                      category === item.value
                                        ? "fromGroup-chip-active"
                                        : "fromGroup-chip"
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={() => {
                                      setCategory(item.value);

                                      if (item.value === "Billable") {
                                        setNonBillableData("");
                                        setBillableData("");
                                      } else if (
                                        item.value === "Non Billable"
                                      ) {
                                        setBillableData("");
                                        setNonBillableData("");
                                        setSelectedAuth({
                                          id: "",
                                          code: "",
                                          authCodes: [],
                                        });
                                      }
                                      setHasError(false);
                                    }}
                                    key={item.key}
                                  >
                                    {item.value}
                                  </Card>
                                ))}
                              </>
                              <Box width={"100%"}>
                                {category === "Billable" && (
                                  <Box className="fromGroup">
                                    <Select
                                      sx={{
                                        minWidth: 300,
                                        width: "100%",
                                        marginTop: "5px",
                                      }}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label=""
                                      MenuProps={MenuProps}
                                      value={serviceType ? serviceType : ""}
                                      onChange={(
                                        e: SelectChangeEvent<string>
                                      ) => {
                                        setServiceType(e.target.value);
                                        // setBillableData(e.target.value);
                                        setHasError(false);
                                      }}
                                    >
                                      {BillableCatalog?.map((item: any) => {
                                        return (
                                          <MenuItem
                                            value={item.conceptValue}
                                            key={item.id}
                                          >
                                            {item.conceptValue}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    {!serviceType && hasError && (
                                      <FormHelperText
                                        style={{ color: "#d32f2f" }}
                                      >
                                        Please select service value
                                      </FormHelperText>
                                    )}
                                  </Box>
                                )}
                                {category === "Non Billable" && (
                                  <Box className="fromGroup">
                                    <Select
                                      defaultValue={
                                        argsdata && argsdata.ServiceType
                                      }
                                      sx={{ minWidth: 460, marginTop: "5px" }}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label=""
                                      MenuProps={MenuProps}
                                      value={serviceType}
                                      onChange={(
                                        e: SelectChangeEvent<string>
                                      ) => {
                                        setServiceType(e.target.value);
                                        setHasError(false);
                                      }}
                                    >
                                      {NonBillableCatalog?.map((item: any) => {
                                        return (
                                          <MenuItem
                                            value={item.conceptValue}
                                            key={item.id}
                                          >
                                            {item.conceptValue}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    {!serviceType && hasError && (
                                      <FormHelperText
                                        style={{ color: "#d32f2f" }}
                                      >
                                        Please select service value
                                      </FormHelperText>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel className="fromLabel">
                              Service Location
                            </InputLabel>
                            <div className="fromGroup-chips">
                              <Select
                                defaultValue={argsdata && argsdata?.Location}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                MenuProps={MenuProps}
                                value={address}
                                onChange={(e: SelectChangeEvent<string>) => {
                                  setAddress(e.target.value);
                                }}
                              >
                                {isNew ? (
                                  userAddress.map(
                                    (item: string, index: number) => {
                                      return (
                                        <MenuItem value={item} key={index}>
                                          {item}
                                        </MenuItem>
                                      );
                                    }
                                  )
                                ) : (
                                  <MenuItem value={address}>{address}</MenuItem>
                                )}
                              </Select>
                            </div>
                          </Grid>

                          {!displayOtherAddress && (
                            <Grid item xs={12} style={{ position: "relative" }}>
                              <Button
                                onClick={() => setDisplayOtherAddress(true)}
                                className="addAddressBtn"
                              >
                                + Add Another Address
                              </Button>
                            </Grid>
                          )}
                          {displayOtherAddress && (
                            <Grid item xs={12}>
                              <ValidatorForm onSubmit={() => {}}>
                                <Grid item xs={12}>
                                  <Box className="fromGroup">
                                    <InputLabel className="fromLabel">
                                      Address
                                    </InputLabel>
                                    <GooglePlacesAutocomplete
                                      apiKey="AIzaSyBltIfej576bf5xww8OmmdFT93sw45a5mE"
                                      selectProps={{
                                        defaultInputValue:
                                          schoolAddressObj.address.fullAddress,
                                        setValue:
                                          schoolAddressObj.address.fullAddress,
                                        onChange: handleSelect,
                                        debounce: 300,
                                        styles: {
                                          input: (provided: any) => ({
                                            ...provided,
                                            fontSize: 16,
                                          }),
                                          option: (provided: any) => ({
                                            ...provided,
                                            fontSize: 16,
                                          }),
                                          singleValue: (provided: any) => ({
                                            ...provided,
                                            fontSize: 16,
                                          }),
                                        },
                                      }}
                                      autocompletionRequest={{
                                        componentRestrictions: {
                                          country: ["us"],
                                        },
                                      }}
                                      onLoadFailed={(error: any) =>
                                        console.error(
                                          "Could not inject Google script",
                                          error
                                        )
                                      }
                                    />
                                    {schoolAddressObj?.address?.fullAddress
                                      .length > 0 && (
                                      <ul className="addressSuggestionList">
                                        {renderSuggestionsForSchool()}
                                      </ul>
                                    )}

                                    {hasError &&
                                    value === "" &&
                                    !schoolAddressObj.address.fullAddress ? (
                                      <FormHelperText
                                        style={{ color: "#d32f2f" }}
                                      >
                                        Please select address
                                      </FormHelperText>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={6}>
                                    <Box className="fromGroup">
                                      <InputLabel className="fromLabel">
                                        City
                                      </InputLabel>
                                      <TextValidator
                                        className="form-control"
                                        onChange={handleChange}
                                        name="city"
                                        id="city"
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("schoolCity");
                                          }
                                        }}
                                        value={schoolAddressObj?.city}
                                        validators={["required"]}
                                        errorMessages={["Please enter city"]}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box className="fromGroup">
                                      <InputLabel className="fromLabel">
                                        State
                                      </InputLabel>
                                      <TextValidator
                                        className="form-control"
                                        onChange={handleChange}
                                        name="state"
                                        id="state"
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("schoolState");
                                          }
                                        }}
                                        value={schoolAddressObj?.state}
                                        validators={["required"]}
                                        errorMessages={["Please enter state"]}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box className="fromGroup">
                                      <InputLabel className="fromLabel">
                                        ZipCode
                                      </InputLabel>
                                      <TextValidator
                                        className="form-control"
                                        onChange={handleChange}
                                        name="zipCode"
                                        id={"zipCode"}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("zipCode");
                                          }
                                        }}
                                        value={schoolAddressObj?.zipCode}
                                        validators={[
                                          "required",
                                          // "matchRegexp:^[0-9]{5}(?:-[0-9]{4})?$",
                                        ]}
                                        errorMessages={[
                                          "Please enter zip code",
                                          // "zip-code  is not valid",
                                        ]}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Button
                                    onClick={() => addAddressesOtherAddresses()}
                                  >
                                    Reset
                                  </Button>
                                  <Button onClick={() => saveOtherAddresses()}>
                                    Save
                                  </Button>
                                </Grid>
                              </ValidatorForm>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Box className="fromGroup">
                              <Grid container alignItems={"center"}>
                                <Grid item xs={12} lg={6}>
                                  <InputLabel className="fromLabel">
                                    Service Time
                                  </InputLabel>
                                </Grid>
                              </Grid>

                              <div className="time-group">
                                <Box className="time-group-select">
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={{
                                      PaperProps: {
                                        style: { maxHeight: 200, width: 100 },
                                      },
                                    }}
                                    value={startTime}
                                    onChange={(e: any) => {
                                      setServiceFromTime(e.target.value);
                                      const toTimeIndex =
                                        getIndexFromTimeValidation(
                                          e.target.value
                                        ) + 4;
                                      if (
                                        toTimeIndex <
                                        AvailabilityTimesForSchedulesBars.length
                                      ) {
                                        setServiceToTime(
                                          AvailabilityTimesForSchedulesBars[
                                            toTimeIndex
                                          ]
                                        );
                                      } else {
                                        setServiceToTime(
                                          AvailabilityTimesForSchedulesBars[
                                            AvailabilityTimesForSchedulesBars.length -
                                              1
                                          ]
                                        );
                                      }
                                    }}
                                  >
                                    {getAvailabilityTimes(0)}
                                  </Select>
                                </Box>
                                <span className="time-group__space">-</span>
                                <Box className="time-group-select">
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={{
                                      PaperProps: {
                                        style: { maxHeight: 200, width: 100 },
                                      },
                                    }}
                                    value={endTime}
                                    onChange={(e: any) =>
                                      setServiceToTime(e.target.value)
                                    }
                                  >
                                    {getAvailabilityTimes(
                                      getIndexFromTimeValidation(startTime) + 1
                                    )}
                                  </Select>
                                </Box>
                              </div>
                            </Box>
                          </Grid>
                        </>
                      )}
                      <Grid container xs={12}>
                        <Grid item xs={6}>
                          <InputLabel className="fromLabel">
                            Service Date
                          </InputLabel>
                          <p className="detailsCard-part__text">
                            {serviceDates.join(", ")}
                          </p>
                        </Grid>

                        {isNew && (
                          <Grid item xs={6}>
                            <InputLabel className="fromLabel">
                              Ends On
                            </InputLabel>
                            <div className="check-AuthBlock">
                              <div style={{ display: "flex" }}>
                                <input
                                  disabled={category === "Non Billable"}
                                  type="checkbox"
                                  checked={repeatService === 2}
                                  onChange={() =>
                                    repeatService === 2
                                      ? setRepeatService(0)
                                      : setRepeatService(2)
                                  }
                                  style={{ width: "16px", height: "16px" }}
                                />
                                End of Authorization
                              </div>
                            </div>
                            <div className="check-AuthBlock">
                              <div>
                                <input
                                  type="checkbox"
                                  checked={
                                    repeatService === 3 || repeatService === 1
                                  } //handle legacy repeatService=EndOfMonth
                                  onChange={() =>
                                    repeatService === 3 || repeatService === 1
                                      ? setRepeatService(0)
                                      : setRepeatService(3)
                                  }
                                  style={{ width: "16px", height: "16px" }}
                                />
                                Custom Date
                              </div>
                              <div className="datepickerBlock">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    label="Select Date"
                                    //default={new Date(valueDate.getFullYear(), valueDate.getMonth()+1, 0)} //lastDayOfMonth
                                    value={valueDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                              {}
                            </div>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {isNew && (
                          <Grid item xs={6}>
                            <Box className="checkBoxEdit">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={repeatInput}
                                    value={repeatInput}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setRepeatInput(e.target.checked);
                                    }}
                                  />
                                }
                                label="Repeat On"
                              />
                            </Box>
                            <div className="fromGroup-repeatDay">
                              {WeekDaysFirstletter.map(
                                (item: string, index: number) => {
                                  return (
                                    <div
                                      className={
                                        daysSelect?.indexOf(
                                          WeekDaysFullName[index]
                                        ) > -1
                                          ? "fromGroup-dayName"
                                          : ""
                                      }
                                      onClick={() => {
                                        let oldSelectedDays: string[] = [
                                          ...daysSelect,
                                        ];
                                        if (
                                          oldSelectedDays?.indexOf(
                                            WeekDaysFullName[index]
                                          ) > -1
                                        ) {
                                          oldSelectedDays =
                                            oldSelectedDays.filter(
                                              (item) =>
                                                item !== WeekDaysFullName[index]
                                            );
                                        } else {
                                          oldSelectedDays.push(
                                            WeekDaysFullName[index]
                                          );
                                        }
                                        setDaysSelect(oldSelectedDays);
                                      }}
                                      key={index}
                                    >
                                      {item}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Grid>
                        )}

                        {isEdit && (
                          <Grid item xs={12}>
                            <InputLabel className="fromLabel">
                              What would you like to edit?
                            </InputLabel>
                            <div className="fromGroup-chips">
                              {UpdateOptions.map(
                                (item: string, index: number) => (
                                  <Card
                                    className={
                                      index === updateOption
                                        ? "fromGroup-chip-active"
                                        : ""
                                    }
                                    onClick={() => setUpdateOption(index)}
                                    key={item}
                                  >
                                    {item}
                                  </Card>
                                )
                              )}
                            </div>
                          </Grid>
                        )}

                        {isDelete && (
                          <Grid item xs={12}>
                            <InputLabel className="fromLabel">
                              What would you like to cancel?
                            </InputLabel>
                            <div className="fromGroup-chips">
                              {CancelOptions.map(
                                (item: string, index: number) => (
                                  <Card
                                    className={
                                      index === cancelOption
                                        ? "fromGroup-chip-active"
                                        : ""
                                    }
                                    onClick={() => setCancelOption(index)}
                                    key={item}
                                  >
                                    {item}
                                  </Card>
                                )
                              )}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                      {!isDelete && (
                        <Grid container xs={12} className="booking-heatmap">
                          {selectedClient && (
                            <Grid item xs={6} gap={5}>
                              <FormControl
                                sx={{
                                  width: 300,
                                  marginBottom: 2,
                                  marginTop: 2,
                                  marginLeft: 1,
                                }}
                                className="planning-filter-select"
                              >
                                <InputLabel id="demo-multiple-name-label">
                                  Select Date Range
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-name-label"
                                  value={selectedAvailability?.client?.id}
                                  onChange={(e) => {
                                    const data = clientAvailability.find(
                                      (item) => item.id === e.target.value
                                    );
                                    if (data)
                                      setSelectedAvailability({
                                        ...selectedAvailability,
                                        client: data,
                                      });
                                  }}
                                  input={<OutlinedInput label="Name" />}
                                  MenuProps={MenuProps}
                                >
                                  {sortDataArray<AvailabilityDetails>(
                                    clientAvailability
                                  )?.map(
                                    (
                                      item: AvailabilityDetails,
                                      index: number
                                    ) => {
                                      return (
                                        <MenuItem key={index} value={item.id}>
                                          {item?.beginDate
                                            ? moment(item?.beginDate).format(
                                                "MM/DD/YYYY"
                                              )
                                            : ""}
                                          {" - "}
                                          {item?.endDate
                                            ? moment(item?.endDate).format(
                                                "MM/DD/YYYY"
                                              )
                                            : ""}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                              <div className="userList-name m-15">
                                <img
                                  src={
                                    selectedClient?.clientBasicDetails
                                      ?.clientProfile?.url || ClientImg
                                  }
                                  className="userSmallCircle"
                                  alt=""
                                />
                                <span style={{ marginLeft: 10 }}>
                                  {selectedClient?.clientBasicDetails
                                    .childFirstName +
                                    " " +
                                    selectedClient?.clientBasicDetails
                                      .childLastName}
                                </span>
                              </div>
                              <CalendarHeatmap
                                showOutOfRangeDays={true}
                                startDate={
                                  selectedClient
                                    ? moment(
                                        selectedAvailability.client?.beginDate
                                      ).format("MM/DD/YYYY")
                                    : moment(new Date()).format("MM/DD/YYYY")
                                }
                                endDate={(selectedClient
                                  ? moment(
                                      selectedAvailability.client?.beginDate
                                    )
                                  : moment(new Date())
                                )
                                  .add(4, "M")
                                  .format("MM/DD/YYYY")}
                                values={
                                  selectedClient
                                    ? selectedAvailability.client?.heatMap
                                    : []
                                }
                                showWeekdayLabels
                                showMonthLabels
                                weekdayLabels={[
                                  "Sun",
                                  "Mon",
                                  "Tue",
                                  "Wed",
                                  "Thu",
                                  "Fri",
                                  "Sat",
                                ]}
                                gutterSize={5}
                                tooltipDataAttrs={(
                                  value: AvailabilityDates
                                ) => {
                                  if (value.date) {
                                    return {
                                      "data-tip": value.availableHours
                                        ? "date: " +
                                          moment(
                                            new Date(`${value.date}`)
                                          ).format("ddd YYYY-MM-DD") +
                                          "\n" +
                                          "available:" +
                                          `${value.availableHours}` +
                                          "\n" +
                                          "booked:" +
                                          `${value.bookedHours}` +
                                          "\n" +
                                          "remaining:" +
                                          `${value.remainingHours}` +
                                          "\n"
                                        : "date: " +
                                          moment(
                                            new Date(`${value.date}`)
                                          ).format("ddd YYYY-MM-DD"),
                                    };
                                  }
                                  return;
                                }}
                                classForValue={(value) => {
                                  if (!value) {
                                    return "color-empty";
                                  }
                                  if (value?.pending) {
                                    return `color-pending color-square-${value.count}`;
                                  } else {
                                    return `color-square-${value.count}`;
                                  }
                                }}
                              />
                              <ReactTooltip />
                            </Grid>
                          )}
                          {selectedProvider && (
                            <Grid item xs={6} gap={5}>
                              <FormControl
                                sx={{
                                  width: 300,
                                  marginBottom: 2,
                                  marginTop: 2,
                                  marginLeft: 1,
                                }}
                                className="planning-filter-select"
                              >
                                <InputLabel id="demo-multiple-name-label">
                                  Select Date Range
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-name-label"
                                  value={selectedAvailability?.provider?.id}
                                  onChange={(e) => {
                                    const data = providerAvailability.find(
                                      (item) => item.id === e.target.value
                                    );
                                    if (data)
                                      setSelectedAvailability({
                                        ...selectedAvailability,
                                        provider: data,
                                      });
                                  }}
                                  input={<OutlinedInput label="Name" />}
                                  MenuProps={MenuProps}
                                >
                                  {sortDataArray<AvailabilityDetails>(
                                    providerAvailability
                                  )?.map(
                                    (
                                      item: AvailabilityDetails,
                                      index: number
                                    ) => {
                                      return (
                                        <MenuItem key={index} value={item.id}>
                                          {item?.beginDate
                                            ? moment(item?.beginDate).format(
                                                "MM/DD/YYYY"
                                              )
                                            : ""}
                                          {" - "}
                                          {item?.endDate
                                            ? moment(item?.endDate).format(
                                                "MM/DD/YYYY"
                                              )
                                            : ""}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                              <div className="userList-name m-15">
                                <img
                                  src={
                                    selectedProvider?.employmentDetails
                                      ?.providerProfile?.url || ProviderImg
                                  }
                                  className="userSmallCircle"
                                  alt=""
                                />
                                <span style={{ marginLeft: 10 }}>
                                  {selectedProvider?.employmentDetails
                                    .firstName +
                                    " " +
                                    selectedProvider?.employmentDetails
                                      .lastName}
                                </span>
                              </div>
                              <CalendarHeatmap
                                showOutOfRangeDays={true}
                                startDate={
                                  selectedProvider
                                    ? moment(
                                        selectedAvailability.provider?.beginDate
                                      ).format("MM/DD/YYYY")
                                    : moment(new Date()).format("MM/DD/YYYY")
                                }
                                endDate={(selectedProvider
                                  ? moment(
                                      selectedAvailability.provider?.beginDate
                                    )
                                  : moment(new Date())
                                )
                                  .add(4, "M")
                                  .format("MM/DD/YYYY")}
                                values={
                                  selectedProvider
                                    ? selectedAvailability.provider?.heatMap
                                    : []
                                }
                                showWeekdayLabels
                                showMonthLabels
                                weekdayLabels={[
                                  "Sun",
                                  "Mon",
                                  "Tue",
                                  "Wed",
                                  "Thu",
                                  "Fri",
                                  "Sat",
                                ]}
                                gutterSize={5}
                                tooltipDataAttrs={(
                                  value: AvailabilityDates
                                ) => {
                                  if (value.date) {
                                    return {
                                      "data-tip": value.availableHours
                                        ? "date: " +
                                          moment(
                                            new Date(`${value.date}`)
                                          ).format("ddd YYYY-MM-DD") +
                                          "\n" +
                                          "available:" +
                                          `${value.availableHours}` +
                                          "\n" +
                                          "booked:" +
                                          `${value.bookedHours}` +
                                          "\n" +
                                          "remaining:" +
                                          `${value.remainingHours}` +
                                          "\n"
                                        : "date: " +
                                          moment(
                                            new Date(`${value.date}`)
                                          ).format("ddd YYYY-MM-DD"),
                                    };
                                  }
                                  return;
                                }}
                                classForValue={(value) => {
                                  if (!value) {
                                    return "color-empty";
                                  }
                                  if (value?.pending) {
                                    return `color-pending color-square-${value.count}`;
                                  } else {
                                    return `color-square-${value.count}`;
                                  }
                                }}
                              />
                            </Grid>
                          )}
                          <div style={{ width: "100%", display: "flex" }}>
                            <div className="availabilityStatus">
                              <span className="unAvailableBox">
                                Unavailable
                              </span>
                              <span className="availableBox">Available</span>
                              <span className="partiallyBookBox">
                                Partially Booked
                              </span>
                              <span className="fullyBookBox">Fully Booked</span>
                            </div>
                          </div>
                        </Grid>
                      )}

                      {isDelete && (
                        <>
                          <Grid item xs={12}>
                            <InputLabel className="fromLabel">
                              Who cancelled the appointment?
                            </InputLabel>
                            <div className="fromGroup-chips">
                              {WhoCancelled.map(
                                (item: string, index: number) => (
                                  <Card
                                    className={
                                      whoCancelled === item
                                        ? "fromGroup-chip-active"
                                        : ""
                                    }
                                    onClick={() => {
                                      setWhoCancelled(item);
                                      setAppointmentError(false);
                                    }}
                                    key={item}
                                  >
                                    {item}
                                  </Card>
                                )
                              )}
                            </div>
                            {appointmentError ? (
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                Please Select at least open option
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel className="fromLabel">
                              Cancel Reason:
                            </InputLabel>
                            <div className="fromGroup-chips">
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="reason of cancellation"
                                MenuProps={MenuProps}
                                value={cancellationReason}
                                onChange={(e: SelectChangeEvent<string>) => {
                                  setCancellationReason(e.target.value);
                                  setReasonError(false);
                                }}
                              >
                                {(whoCancelled === "Client"
                                  ? ClientReasonCatalog
                                  : whoCancelled === "Employee"
                                  ? ProviderReasonCatalog
                                  : []
                                )?.map((item: any) => {
                                  return (
                                    <MenuItem
                                      key={item.id}
                                      value={item.conceptValue}
                                    >
                                      {item.conceptValue}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </div>
                            {reasonError ? (
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                Please select cancel reason
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </>
                      )}
                      {(isNew || isEdit) && (
                        <Grid item xs={12}>
                          <InputLabel>Description</InputLabel>
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Leave some comment here..."
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              background: "#FFFFFF",
                              border: "1px solid #D9D9D9",
                              borderRadius: "2px",
                            }}
                            value={description}
                            onChange={(e: any) => {
                              setDescription(e.target.value.reverse);
                            }}
                            //autoFocus
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className="largeModel__footer">
            <div>
              {isDelete !== undefined
                ? isDelete && (
                    <Button
                      className="button"
                      onClick={(e) => {
                        setPermanentDelete(true);
                        setModal(true);
                      }}
                    >
                      Permanent Delete
                    </Button>
                  )
                : ""}
            </div>
            {argsdata?.status !== "Cancelled" && (
              <Button
                className="button"
                onClick={(e) => { onSubmitBooking(e); }}
              >
                {isNew
                  ? "Add Booking"
                  : isEdit
                  ? "Save Booking"
                  : "Cancel Booking"}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      {isDelete && permanentDelete && (
        <Dialog open={model} className="smallModel">
          <div>
            <DialogContent>
              {"Do you want to permenantly Delete the Booking?"}
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  setModal(false);
                  setPermanentDelete(false);
                }}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  setModal(false);
                  onSubmitBooking(e);
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default DiscoverBookingEditModal;
