import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  InputLabel,
  Card,
  Checkbox,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import CalendarHeatmap, {
  ReactCalendarHeatmapValue,
} from "react-calendar-heatmap";
import ReactTooltip from "react-tooltip";
import {
  AvailabilityDetails,
  SelectedDay,
  ISelectedAvailability,
} from "../../../types/availability";
import {
  ClientAuthPayload,
  ClientForms,
  ClientAuth,
  AuthCode,
} from "../../../types/client";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AvailabilityDates } from "../../../types/availability";

import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import "react-calendar-heatmap/dist/styles.css";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  setSchedulesMatchValues,
  clearSchedulesMatchValues,
} from "../../../redux/features/schedules-main/match-slice";
import { OrgBU } from "../../../types/company";
import { setSelectedAvailabiltyValues } from "../../../redux/features/Common/availability-slice";
import { getBoardAgingDays } from "../../../helpers/reverse-list/reverse-list";
import { sortDataArray } from "../../../utils/AvailabilityUtils";
import { useNavigate } from "react-router-dom";
// import Rediect from "../../../assets/images/images-svg/Rediect.svg";
// import redirectDetails from "../../../assets/images/images-png/rediectdetails.png";
import ReplyIcon from "@mui/icons-material/Reply";
import {
  ProviderStatuses,
  XMiles,
  OnboardingStage,
  XDurations,
  earliestStartTime,
} from "../../../constants/schedule";
import { InsuranceProvider } from "../../../types/response";
import { ProviderType, MatchOption } from "../../../types/schedule";
import { getAge } from "../../../utils/DateUtils";
import {
  GetProviderSpecialty,
  GetProviderTypes,
} from "../../../redux/features/providers-main/provider-list-slice";
import { getBusinessUnitName } from "../../../utils/EntityUtils";
import { ProviderForms } from "../../../types/provider";
import { GetAuthcode } from "../../../redux/features/client-main/client-insurance-slice";

//Component Props Interface
interface Props {
  entityType: string;
  clientData: ClientForms;
  providerData: ProviderForms;
  counter: number;
  setCounter(val: number): void;

  selectedDays: number[];
  setSelectedDays: Dispatch<SetStateAction<number[]>>;
  selectedAvailabilty: AvailabilityDetails;
  setSelectedAvailability: Dispatch<SetStateAction<AvailabilityDetails>>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
      width: 200,
      // marginTop:"20px"
    },
  },
};

// const PageOne: React.FC<Props> = ({ clientData, counter, setCounter }) => {
const PageOne: React.FC<Props> = ({
  entityType,
  clientData,
  providerData,
  counter,
  setCounter,

  selectedDays,
  setSelectedDays,
  selectedAvailabilty,
  setSelectedAvailability,
}) => {
  // #region variables region

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //match values
  const matchValues = useSelector<RootState, MatchOption>(
    (state) => state.matchSlice
  );

  //insurance Providers Data
  const insuranceProviders = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //Provider dropdown list
  const ProviderTypeList = useSelector<RootState, ProviderType[]>(
    (state) => state.providerListSlice.ProviderType
  );

  //provider speciality dropdown list
  const ProviderSpecialtyList = useSelector<RootState, ProviderType[]>(
    (state) => state.providerListSlice.ProviderSpecialty
  );

  //get auth Code
  const authCode = useSelector<RootState, AuthCode[]>(
    (state) => state.clientInsuranceSlice.clientAuthcode
  );
  // const availabiltyValues = useSelector<RootState, ISelectedAvailability>(
  //   (state) => state.availabilitySlice
  // );

  //validations error
  const [validationError, setValidationError] = useState<boolean>(false);

  //used to store redirect tab
  const navigate = useNavigate();

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //track auth expire error
  const [hasAuthExpireError, setHasAuthExpireError] = useState<boolean>(false);

  //track auth unit zero error
  const [hasNoAuthUnitError, setHasNoAuthUnitError] = useState<boolean>(false);

  //handle avaailabilty error
  const [hasNoAvailability, setHasNoAvailability] = useState<boolean>(false);

  //tract availabilty
  const [hasAvailabiltyExpiered, setHasAvailabilityExpired] =
    useState<boolean>(false);
  //--------------------------------------------------------------
  //updates selected days for matching providers
  const clientAvailabilityDays = (item: SelectedDay, index: number) => {
    const newSelectedDays: number[] | null[] = [...selectedDays];
    const newArrayForRedux: (SelectedDay | null)[] = [
      ...matchValues.selectedAvailability,
    ];
    // matchValues.clientAvailability.forEach((item) =>
    //   newArrayForRedux.push(item)
    // );
    let indexPresent = selectedDays[index];

    setSelectedDays(newSelectedDays);
    if (indexPresent) {
      newSelectedDays[index] = 0;
      newArrayForRedux[index] = null;

      dispatch(
        setSchedulesMatchValues({
          name: "selectedAvailability",
          value: newArrayForRedux,
        })
      );
    } else {
      newSelectedDays[index] = 1;
      newArrayForRedux[index] = item;
      dispatch(
        setSchedulesMatchValues({
          name: "selectedAvailability",
          value: newArrayForRedux,
        })
      );
    }
  };

  //validation check
  /* relax match algorithm: only time is required, others are optional match */
  const checkValidation = (): void => {
    if (
      matchValues.selectedAvailability.filter((item) => item).length === 0 ||
      new Date() > new Date(matchValues?.clientAuthEndDate) ||
      matchValues.duration === 0 ||
      hasAvailabiltyExpiered ||
      hasAuthExpireError
    ) {
      setValidationError(true);
    } else {
      if (validationError) {
        setValidationError(false);
      }
      setCounter(2);
    }
    window.localStorage.setItem("MatchValue", JSON.stringify(matchValues));
  };

  // #endregion

  // #region methods region

  //returns authorization value
  const returnAuthorizationValue = (item: ClientAuthPayload): string => {
    if (item.insuranceId) {
      if (item.authPeriod) {
        return `${item.insurance.insuranceProviderName} Auth ${
          moment(item.authPeriod.startDate).month() + 1
        }.${moment(item.authPeriod.startDate).date()}.${moment(
          item.authPeriod.endDate
        ).year()} - ${moment(item.authPeriod.endDate).month() + 1}.${moment(
          item.authPeriod.endDate
        ).date()}.${moment(item.authPeriod.endDate).year()}`;
      } else {
        return `${item.insurance.insuranceProviderName} Auth (no date)`; //no date
      }
    }
    return "<bad auth code>";
  };

  //returns the selected authorization by Id
  const returnSelectedAuthorizationById = (id: string): ClientAuthPayload => {
    const selectedAuthorizationById = clientData?.authorizations?.find(
      (item: ClientAuthPayload) => id === item?.id
    ) as ClientAuthPayload;

    return selectedAuthorizationById;
  };

  // console.log("clientData?.clientAuthUtilization:", clientData?.clientAuthUtilization);

  const getClientAuthUtilizationRemaining = (
    authId: string,
    code: string
  ): number => {
    const authUtilization = clientData?.clientAuthUtilization.timeTables.filter(
      (timeTable: any) =>
        timeTable.timeTableId === authId && timeTable.timeTableCode === code
    );
    return authUtilization[0]?.remainingAuthHours;
  };

  // returns total hours for a particular auth item
  const returnTotalHours = (units: string): number => {
    const numOfUnits = parseInt(units);
    //const result = String(Number(numOfUnits * 15 / 60).toFixed(2));
    const result = (numOfUnits * 15) / 60;
    return result;
  };

  //returns authorization name by id
  const returnAuthorizationValueById = (id: string): string => {
    const item = clientData?.authorizations?.find((item) => item?.id === id);

    if (item) {
      if (item.authPeriod) {
        return `${item.insurance.insuranceProviderName} Auth ${
          moment(item.authPeriod.startDate).month() + 1
        }.${moment(item.authPeriod.startDate).date()}.${moment(
          item.authPeriod.startDate
        ).year()} - ${moment(item.authPeriod.endDate).month() + 1}.${moment(
          item.authPeriod.endDate
        ).date()}.${moment(item.authPeriod.endDate).year()}`;
      } else {
        return `${item.insurance.insuranceProviderName} Auth (no date)`; //no date
      }
    }
    return "No auth found";
  };

  //store multi availabilitydata
  const availabiltyValues = useSelector<RootState, ISelectedAvailability>(
    (state) => state.availabilitySlice
  );

  //handle availability data
  const handleChangeAvailability = (value: string) => {
    let availability: AvailabilityDetails[];

    if (entityType === "Client") {
      availability = clientData.availabilityDetails.filter(
        (item) => item.id === value
      );
    } else {
      availability = providerData.availabilityDetails.filter(
        (item) => item.id === value
      );
    }

    dispatch(
      setSelectedAvailabiltyValues({
        name: "availability",
        value: availability[0],
      })
    );
    if (matchValues.selectedAvailability.length !== 0) {
      const newClientAvailability = new Array(
        availability[0]?.selectedDays.length
      ).fill(0);

      setSelectedDays(newClientAvailability);
      const newClientAvailabilityArrayForRedux = new Array(
        availability[0]?.selectedDays.length
      ).fill("");

      dispatch(
        setSchedulesMatchValues({
          name: "clientAvailability",
          value: newClientAvailabilityArrayForRedux,
        })
      );
    }
    setSelectedAvailability(availability[0]);

    dispatch(
      setSchedulesMatchValues({
        name: "availability",
        value: availability[0],
      })
    );

    return availability[0];
  };

  //redirect to the Details Page
  const handleRedirect = (id: string) => {
    if (entityType === "Client") navigate(`/client/${id}`);
    if (entityType === "Provider") navigate(`/provider/${id}`);
  };

  //handle Onboarding Status change
  const handleBoardChange = (e: any) => {
    dispatch(
      setSchedulesMatchValues({
        name: "providerOnboardingStages",
        value: e.target.value,
      })
    );
  };
  // #endregion

  // #region useEffect region

  //rebuilds tooltip
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  //clientData populated --> populate match value for auth
  useEffect(() => {
    if (
      entityType === "Client" &&
      clientData &&
      clientData?.authorizations?.length > 0
    ) {
      if (!(matchValues?.clientAuthId && matchValues?.clientAuthCode)) {
        //loop existing clientData.authorizations, use today's date to find the first match
        const today = new Date();
        const authForToday = clientData.authorizations.find((clientAuth) => {
          return (
            clientAuth.authPeriod &&
            clientAuth.authPeriod.startDate &&
            clientAuth.authPeriod.endDate &&
            new Date(clientAuth.authPeriod.startDate).getTime() <=
              today.getTime() &&
            new Date(clientAuth.authPeriod.endDate).getTime() >=
              today.getTime() &&
            clientAuth.auth.length > 0
          );
        });
        // console.log("authForToday", authForToday);
        if (authForToday) {
          dispatch(
            setSchedulesMatchValues({
              name: "clientAuthId",
              value: authForToday?.id,
            })
          );
          dispatch(
            setSchedulesMatchValues({
              name: "clientAuthCode",
              value: authForToday.auth[0].authCode,
            })
          );
        }
      }
    } else if (entityType === "Provider") {
      dispatch(
        setSchedulesMatchValues({
          name: "clientAuthCode",
          value: authCode[0]?.conceptValue,
        })
      );
      dispatch(
        setSchedulesMatchValues({
          name: "availability",
          value: availabiltyValues.availability,
        })
      );
    }
  }, [
    clientData,
    counter,
    authCode?.length,
    availabiltyValues?.availability?.id,
  ]);

  //client auth match value populated --> populate rest auth match values
  useEffect(() => {
    if (
      entityType === "Client" &&
      clientData?.authorizations?.length > 0 &&
      matchValues.clientAuthId
    ) {
      dispatch(
        setSchedulesMatchValues({
          name: "clientSelectedAuthorizationName",
          value: returnAuthorizationValueById(matchValues.clientAuthId),
        })
      );

      const findAuthorizaions = clientData?.authorizations?.find(
        (item) => item?.id === matchValues?.clientAuthId
      );

      if (findAuthorizaions) {
        const oldSelectedAuthCode = findAuthorizaions.auth.find(
          (item) => item.authCode.toString() === matchValues?.clientAuthCode
        );
        if (oldSelectedAuthCode) {
          dispatch(
            setSchedulesMatchValues({
              name: "clientAuthUnits",
              value: oldSelectedAuthCode.units,
            })
          );
          dispatch(
            setSchedulesMatchValues({
              name: "clientAuthCode",
              value: oldSelectedAuthCode.authCode,
            })
          );
          dispatch(
            setSchedulesMatchValues({
              name: "clientAuthCodeHours",
              value: returnTotalHours(oldSelectedAuthCode.units),
            })
          );
          dispatch(
            setSchedulesMatchValues({
              name: "clientAuthCodeRemainingHours",
              value: getClientAuthUtilizationRemaining(
                matchValues?.clientAuthId,
                oldSelectedAuthCode.authCode
              ),
            })
          );
        } else {
          dispatch(
            setSchedulesMatchValues({
              name: "clientAuthCode",
              value: findAuthorizaions.auth[0].authCode,
            })
          );
          dispatch(
            setSchedulesMatchValues({
              name: "clientAuthCodeHours",
              value: returnTotalHours(findAuthorizaions.auth[0].units),
            })
          );
          dispatch(
            setSchedulesMatchValues({
              name: "clientAuthCodeRemainingHours",
              value: getClientAuthUtilizationRemaining(
                matchValues?.clientAuthId,
                findAuthorizaions.auth[0].authCode
              ),
            })
          );
        }

        dispatch(
          setSchedulesMatchValues({
            name: "clientAuthStartDate",
            value: findAuthorizaions?.authPeriod?.startDate,
          })
        );
        dispatch(
          setSchedulesMatchValues({
            name: "clientAuthEndDate",
            value: findAuthorizaions?.authPeriod?.endDate,
          })
        );
        //check Auth Expire or not
        if (findAuthorizaions.authPeriod.endDate) {
          if (new Date() > new Date(findAuthorizaions?.authPeriod.endDate)) {
            setHasAuthExpireError(true);
          } else {
            setHasAuthExpireError(false);
          }
        }

        //check Auth Unit Zero or not
        if (
          matchValues?.clientAuthUnits &&
          Number(matchValues?.clientAuthUnits) === 0
        ) {
          setHasNoAuthUnitError(true);
        } else {
          setHasNoAuthUnitError(false);
        }
      }
    }

    if (availabiltyValues.availability?.id === "") {
      setHasNoAvailability(true);
    } else {
      setHasNoAvailability(false);
    }
    if (
      new Date(`${availabiltyValues.availability.endDate} 0:00:00`) < new Date()
    ) {
      setHasAvailabilityExpired(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    matchValues.clientAuthId,
    matchValues.clientAuthUnits,
    clientData,
    counter,
    availabiltyValues?.availability?.endDate,
    availabiltyValues?.availability?.id,
  ]);

  useEffect(() => {
    dispatch(GetProviderTypes());
    dispatch(GetProviderSpecialty());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (entityType === "Provider") dispatch(GetAuthcode());
  }, [entityType]);
  // console.log(clientData, "clientData🔥🔥🔥");

  // #endregion

  return (
    <div>
      <Box className="detailsPageHeader">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {counter === 1 && entityType === "Client" && clientData && (
              <Typography variant="h6" className="DetailsPageTitle">
                Client Match -{" "}
                {clientData.clientBasicDetails?.id !== "" ? (
                  clientData?.clientBasicDetails?.childFirstName +
                  " " +
                  clientData?.clientBasicDetails?.childLastName
                ) : (
                  <span style={{ fontSize: "15px", paddingLeft: "5px" }}>
                    {" "}
                    Loading...
                  </span>
                )}
              </Typography>
            )}
            {counter === 1 && entityType === "Provider" && providerData && (
              <Typography variant="h6" className="DetailsPageTitle">
                Employee Match -
                {providerData?.employmentDetails?.id !== "" ? (
                  providerData?.employmentDetails?.firstName +
                  " " +
                  providerData?.employmentDetails?.lastName
                ) : (
                  <span style={{ fontSize: "15px", paddingLeft: "5px" }}>
                    {" "}
                    Loading...
                  </span>
                )}
                {} {}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}></Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="matchBtnBlock text-right"
          >
            <Button
              color="inherit"
              type="button"
              //  className="border-button"
              className="button button--large button-border"
              onClick={() => {
                setValidationError(false);
                dispatch(clearSchedulesMatchValues({}));
              }}
            >
              Clear Match
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className="detailsPageBody">
        <Grid container spacing={2} mb={"48px"}>
          <>
            {/* client basic card */}
            {counter === 1 && entityType === "Client" && clientData && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box className="detailsCard">
                  <Box className="detailsCard__header">
                    <Typography className="detailsCard__title">
                      Client Basic -
                      {clientData?.clientBasicDetails?.childFirstName}{" "}
                      {clientData?.clientBasicDetails?.childLastName}
                    </Typography>

                    <IconButton
                      className="detailsCard__title"
                      style={{ padding: "0", transform: "rotateY(180deg)" }}
                      onClick={() =>
                        handleRedirect(clientData.clientBasicDetails?.id)
                      }
                    >
                      <ReplyIcon />
                    </IconButton>
                  </Box>
                  <Box className="detailsCard__body">
                    <div className="userBasicInfoBox">
                      <div className="userBasicInfoBox__img">
                        <img
                          src={
                            clientData?.clientBasicDetails?.clientProfile
                              ?.url || ClientImg
                          }
                          className="img-fluid"
                          alt="image_not_found"
                        />
                      </div>
                      <div className="userBasicInfoBox__details ">
                        {/* <h6 className="userBasicInfoBox__title">
                          {clientData?.clientBasicDetails?.childFirstName}{" "}
                          {clientData?.clientBasicDetails?.childLastName}
                        </h6> */}
                        <Grid
                          container
                          spacing={0}
                          justifyContent={"space-between"}
                        >
                          <Grid item xs={2} sm={2}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Business Unit
                              </Typography>
                              <div className="detailsCard-part__text">
                                {getBusinessUnitName(
                                  businessUnits,
                                  clientData?.clientBasicDetails?.businessUnit
                                )?.map((res: string, index: number) => {
                                  return (
                                    <div key={index}>{`${
                                      index ? "," : ""
                                    } ${res}`}</div>
                                  );
                                })}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Age
                              </Typography>
                              <Typography className="detailsCard-part__text">
                                {clientData?.clientBasicDetails
                                  ?.childDateOfBirth
                                  ? getAge(
                                      clientData?.clientBasicDetails
                                        ?.childDateOfBirth
                                    )
                                  : null}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Initial Start Date
                              </Typography>
                              <Typography className="detailsCard-part__text">
                                {/* {clientData?.availabilityDetails?.length > 0 &&
                                moment(
                                  clientData?.availabilityDetails[0]?.beginDate
                                ).format("MM/DD/YYYY")} */}
                                {/*moment(availabiltyValues.availability.beginDate).format("MM/DD/YYYY") */}
                                {moment(
                                  clientData?.clientBasicDetails?.createdAt
                                ).format("MM/DD/YYYY")}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Time in Stage
                              </Typography>
                              <Typography className="detailsCard-part__text">
                                {getBoardAgingDays(
                                  clientData?.clientBasicDetails?.createdAt
                                )}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Intake Stage
                              </Typography>
                              <Typography className="detailsCard-part__text">
                                {clientData?.clientBasicDetails?.intakeStage}
                              </Typography>
                            </div>
                          </Grid>
                          {/* <Grid item xs={6} sm={6}>
                          <div className="detailsCard-part">
                            <Typography className="detailsCard-part__title">
                              Location
                            </Typography>
                            <Typography className="detailsCard-part__text">
                              {clientData?.clientContactDetails?.state}
                            </Typography>
                          </div>
                        </Grid> */}
                        </Grid>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            )}

            {/* provider basic card */}
            {counter === 1 && entityType === "Provider" && providerData && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box className="detailsCard">
                  <Box className="detailsCard__header">
                    <Typography className="detailsCard__title">
                      Employee Basic -
                      {providerData?.employmentDetails?.firstName}{" "}
                      {providerData?.employmentDetails?.lastName}
                    </Typography>

                    <IconButton
                      className="detailsCard__title"
                      style={{ padding: "0", transform: "rotateY(180deg)" }}
                      onClick={() =>
                        handleRedirect(providerData?.employmentDetails?.id)
                      }
                    >
                      <ReplyIcon />
                    </IconButton>
                  </Box>
                  <Box className="detailsCard__body">
                    <div className="userBasicInfoBox">
                      <div className="userBasicInfoBox__img">
                        <img
                          src={
                            providerData?.employmentDetails?.providerProfile
                              ?.url || ProviderImg
                          }
                          className="img-fluid"
                          alt="image_not_found"
                        />
                      </div>
                      <div className="userBasicInfoBox__details">
                        <Grid
                          container
                          spacing={0}
                          justifyContent={"space-between"}
                        >
                          <Grid item xs={2} sm={2}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Business Unit
                              </Typography>
                              <div className="detailsCard-part__text">
                                {getBusinessUnitName(
                                  businessUnits,
                                  providerData?.employmentDetails?.businessUnit
                                )?.map((res: string, index: number) => {
                                  return (
                                    <div key={index}>{`${
                                      index ? "," : ""
                                    } ${res}`}</div>
                                  );
                                })}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Employee Status
                              </Typography>
                              <Typography className="detailsCard-part__text">
                                {
                                  providerData?.employmentDetails
                                    ?.employmentStatus
                                }
                              </Typography>
                            </div>
                          </Grid>
                          {/* <Grid item xs={2} sm={2}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Initial Start Date
                              </Typography>
                              <Typography className="detailsCard-part__text">
                                {moment(
                                  clientData?.clientBasicDetails?.createdAt
                                ).format("MM/DD/YYYY")}
                              </Typography>
                            </div>
                          </Grid> */}
                          {/* <Grid item xs={2} sm={2}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Direct Team
                              </Typography>
                              <Typography className="detailsCard-part__text">
                                {providerData?.employmentDetails?.supervisor?.team?.join(", ")}
                              </Typography>
                            </div>
                          </Grid> */}
                          <Grid item xs={2} sm={4}>
                            <div className="detailsCard-part">
                              <Typography className="detailsCard-part__title">
                                Onboarding Stage
                              </Typography>
                              <Typography className="detailsCard-part__text">
                                {
                                  providerData?.employmentDetails
                                    ?.onBoardingStage
                                }
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2} sm={4}>
                            <div className="detailsCard-part speciality">
                              <Typography className="detailsCard-part__title">
                                Speciality
                              </Typography>

                              {providerData?.employmentDetails?.specialities.map(
                                (item) => (
                                  <Typography className="detailsCard-part__text">
                                    {item},
                                  </Typography>
                                )
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            )}

            {/* client authorization */}
            {counter === 1 && entityType === "Client" && clientData && (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <div className="detailsCard">
                  <div className="detailsCard__header">
                    <Typography className="detailsCard__title">
                      Client Authorization (Required)
                    </Typography>
                    <IconButton
                      className="detailsCard__title"
                      style={{ padding: "0", transform: "rotateY(180deg)" }}
                      onClick={() =>
                        handleRedirect(clientData.clientBasicDetails?.id)
                      }
                    >
                      <ReplyIcon />
                    </IconButton>
                  </div>
                  <div className="detailsCard__body">
                    <Box className="fromGroup">
                      <div className="detailsCard-part">
                        <Typography
                          className="fromLabel"
                          style={{
                            fontWeight: 500,
                            fontSize: "18px",
                            lineHeight: "24px",
                          }}
                        >
                          Current Authorizations
                        </Typography>

                        <Select
                          className="form-control"
                          value={matchValues?.clientAuthId}
                          onChange={(e: SelectChangeEvent<string>) => {
                            dispatch(
                              setSchedulesMatchValues({
                                name: "clientAuthId",
                                value: e.target.value,
                              })
                            );

                            dispatch(
                              setSchedulesMatchValues({
                                name: "clientSelectedAuthorizationName",
                                value: returnAuthorizationValueById(
                                  e.target.value
                                ),
                              })
                            );
                          }}
                          MenuProps={MenuProps}
                        >
                          {clientData?.authorizations?.map(
                            (item: ClientAuthPayload, index: number) => {
                              return (
                                item.authPeriod &&
                                item.auth.length > 0 && (
                                  <MenuItem key={index} value={item.id}>
                                    {returnAuthorizationValue(item)}
                                  </MenuItem>
                                )
                              );
                            }
                          )}
                        </Select>
                      </div>
                      {hasAuthExpireError ? (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          This auth period is already expired. Please pick
                          another one or update auth.
                        </FormHelperText>
                      ) : hasNoAuthUnitError ? (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          No auth units specified. Please pick update auth.
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                      {/* Expired Authperiod Please select Another One */}
                      <div className="detailsCard-part">
                        <div className="selectAuthRadio">
                          <div className="selectAuthRadio__title">
                            <div className="text-center insurance-block">
                              Insurance
                            </div>
                            <div className="text-center">Code</div>
                            <div>Units</div>
                            <div>Auth Hours</div>
                            <div>Remaining</div>
                          </div>
                          <div>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={returnSelectedAuthorizationById(
                                  matchValues?.clientAuthId
                                )?.auth.map(
                                  (
                                    authItem: ClientAuth,
                                    authItemIndex: number
                                  ) =>
                                    returnTotalHours(authItem.units) === 0 && 1
                                )} //default to be first item
                                name="radio-buttons-group"
                              >
                                {returnSelectedAuthorizationById(
                                  matchValues?.clientAuthId
                                )?.auth.map(
                                  (
                                    authItem: ClientAuth,
                                    authItemIndex: number
                                  ) => {
                                    return (
                                      <div
                                        className="selectAuthRadio__item"
                                        key={authItemIndex}
                                      >
                                        {/* EDIT THIS */}
                                        <div className="text-center insurance-block">
                                          <FormControlLabel
                                            style={{ alignItems: "center" }}
                                            checked={
                                              authItem.authCode ===
                                              matchValues?.clientAuthCode
                                            }
                                            control={<Radio />}
                                            label={
                                              returnSelectedAuthorizationById(
                                                matchValues?.clientAuthId
                                              ).insurance.insuranceProviderName
                                            }
                                            onChange={(e) => {
                                              dispatch(
                                                setSchedulesMatchValues({
                                                  name: "clientAuthCodeHours",
                                                  value: returnTotalHours(
                                                    authItem?.units
                                                  ),
                                                })
                                              );
                                              dispatch(
                                                setSchedulesMatchValues({
                                                  name: "clientAuthCodeRemainingHours",
                                                  value:
                                                    getClientAuthUtilizationRemaining(
                                                      matchValues?.clientAuthId,
                                                      authItem?.authCode
                                                    ),
                                                })
                                              );

                                              dispatch(
                                                setSchedulesMatchValues({
                                                  name: "clientAuthCode",
                                                  value:
                                                    authItem?.authCode.toString(),
                                                })
                                              );
                                              dispatch(
                                                setSchedulesMatchValues({
                                                  name: "clientAuthUnits",
                                                  value:
                                                    authItem?.units.toString(),
                                                })
                                              );
                                              setHasNoAuthUnitError(false);
                                            }}
                                          />
                                        </div>
                                        {/* EDIT THIS */}
                                        <div className="text-center">
                                          {" "}
                                          {authItem.authCode}{" "}
                                        </div>
                                        <div className="text-center">
                                          {authItem.units}
                                        </div>
                                        <div className="text-center">
                                          {returnTotalHours(authItem.units)}
                                        </div>

                                        <div className="remainingHours-data">
                                          <div>
                                            <span>
                                              {getClientAuthUtilizationRemaining(
                                                matchValues?.clientAuthId,
                                                authItem.authCode
                                              )}
                                              {/* {returnTotalHours(
                                              authItem.authApprovalUnit
                                            )} */}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </div>
                </div>
              </Grid>
            )}

            {/* authorization preference */}
            {counter === 1 && entityType === "Provider" && providerData && (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <div className="detailsCard">
                  <div className="detailsCard__header">
                    <Typography className="detailsCard__title">
                      Filter Authorization (Required)
                    </Typography>
                    <IconButton
                      className="detailsCard__title"
                      style={{ padding: "0", transform: "rotateY(180deg)" }}
                      onClick={() =>
                        handleRedirect(clientData.clientBasicDetails?.id)
                      }
                    >
                      <ReplyIcon />
                    </IconButton>
                  </div>
                  <div className="detailsCard__body">
                    <Box className="fromGroup">
                      <div className="detailsCard-part">
                        <Typography
                          className="fromLabel"
                          style={{
                            fontWeight: 500,
                            fontSize: "18px",
                            lineHeight: "24px",
                          }}
                        >
                          Select Auth Code
                        </Typography>

                        <Select
                          className="form-control"
                          value={matchValues?.clientAuthCode}
                          onChange={(e: SelectChangeEvent<string>) => {
                            dispatch(
                              setSchedulesMatchValues({
                                name: "clientAuthCode",
                                value: e.target.value,
                              })
                            );
                          }}
                          MenuProps={MenuProps}
                        >
                          {authCode.map((item: AuthCode, index: number) => {
                            return (
                              <MenuItem key={index} value={item.conceptValue}>
                                {item.conceptValue}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </Box>
                  </div>
                </div>
              </Grid>
            )}

            {/* filter employees */}
            {counter === 1 && entityType === "Client" && clientData && (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <div className="detailsCard">
                  <div className="detailsCard__header">
                    <Typography className="detailsCard__title">
                      Filter Employees (Optional)
                    </Typography>
                  </div>
                  <div className="detailsCard__body">
                    {/* preference - Employee Type */}
                    <Box className="fromGroup">
                      <div className="detailsCard-part">
                        <InputLabel className="fromLabel">
                          Employee Type
                        </InputLabel>
                        <FormControl>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={matchValues.providerType}
                            onChange={(e) => {
                              dispatch(
                                setSchedulesMatchValues({
                                  name: "providerType",
                                  value: e.target.value,
                                })
                              );
                            }}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {ProviderTypeList.map((unit, index) => (
                              <MenuItem key={index} value={unit.conceptValue}>
                                <Checkbox
                                  checked={
                                    matchValues.providerType.indexOf(
                                      unit.conceptValue
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={unit.conceptValue} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Box>

                    {/* preference - Employee Specialty */}
                    <Box className="fromGroup">
                      <div className="detailsCard-part">
                        <InputLabel className="fromLabel">
                          Employee Specialty
                        </InputLabel>
                        <FormControl>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={matchValues.specialities}
                            onChange={(e) => {
                              dispatch(
                                setSchedulesMatchValues({
                                  name: "specialities",
                                  value: e.target.value,
                                })
                              );
                            }}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {ProviderSpecialtyList.map((unit, index) => (
                              <MenuItem key={index} value={unit.conceptValue}>
                                <Checkbox
                                  checked={matchValues.specialities.includes(
                                    unit.conceptValue
                                  )}
                                />
                                <ListItemText primary={unit.conceptValue} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Box>

                    {/* preference - Onboarding Status */}
                    <Box className="fromGroup">
                      <div className="detailsCard-part">
                        <InputLabel className="fromLabel">
                          Onboarding Status
                        </InputLabel>
                        <div className="fromGroup-chips">
                          {ProviderStatuses.map((item) => {
                            return (
                              <Card
                                className={
                                  matchValues.providerOnboardingStatus ===
                                  item.value
                                    ? "fromGroup-chip-active"
                                    : ""
                                }
                                onClick={() =>
                                  dispatch(
                                    setSchedulesMatchValues({
                                      name: "providerOnboardingStatus",
                                      value: item.value,
                                    })
                                  )
                                }
                                key={item.key}
                              >
                                {item.value}
                              </Card>
                            );
                          })}
                        </div>
                      </div>
                    </Box>

                    {/* preference - Onboarding Stage */}
                    <Box className="fromGroup">
                      <InputLabel className="fromLabel">
                        Onboarding Stage
                      </InputLabel>
                      <div className="detailsCard-part">
                        <FormControl>
                          <Select
                            multiple
                            className="form-control"
                            value={matchValues.providerOnboardingStages}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            onChange={handleBoardChange}
                          >
                            {OnboardingStage.map((item) => {
                              return (
                                <MenuItem value={item.value} key={item.key}>
                                  <Checkbox
                                    checked={
                                      matchValues.providerOnboardingStages.indexOf(
                                        item.value
                                      ) > -1
                                    }
                                  />
                                  {item.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                </div>
              </Grid>
            )}

            {/* filter BU, Distance, Insurance */}
            {counter === 1 &&
              ((entityType === "Client" && clientData) ||
                (entityType === "Provider" && providerData)) && (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <div className="detailsCard">
                    <div className="detailsCard__header">
                      <Typography className="detailsCard__title">
                        Other Filters (Optional)
                      </Typography>
                    </div>
                    <div className="detailsCard__body">
                      {/* preference - BU */}
                      <Box className="fromGroup">
                        <div className="detailsCard-part">
                          <InputLabel className="fromLabel">
                            Business Unit
                          </InputLabel>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            value={matchValues.businessUnit}
                            name="businessUnit"
                            onChange={(e: SelectChangeEvent<string>) => {
                              dispatch(
                                setSchedulesMatchValues({
                                  name: "businessUnit",
                                  value: e.target.value, //id version
                                })
                              );
                              dispatch(
                                setSchedulesMatchValues({
                                  name: "businessUnitName",
                                  value: getBusinessUnitName(businessUnits, [
                                    e.target.value,
                                  ]), //name version
                                })
                              );
                            }}
                            MenuProps={MenuProps}
                          >
                            {businessUnits.map((item: OrgBU) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.businessUnitName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </Box>

                      {/* preference - distance  */}
                      <Box className="fromGroup">
                        <div className="detailsCard-part">
                          <InputLabel className="fromLabel">
                            Within X Miles
                          </InputLabel>
                          <div className="fromGroup-chips">
                            <Box
                              width={"100%"}
                              display={"flex"}
                              alignItems={"center"}
                              flexWrap={"wrap"}
                            >
                              {XMiles.map((item) => (
                                <Card
                                  className={
                                    matchValues.miles === item.value
                                      ? "fromGroup-chip-active"
                                      : ""
                                  }
                                  onClick={() =>
                                    dispatch(
                                      setSchedulesMatchValues({
                                        name: "miles",
                                        value: item.value,
                                      })
                                    )
                                  }
                                  key={item.key}
                                >
                                  {"within "}
                                  {item.value}
                                  {" miles"}
                                </Card>
                              ))}
                            </Box>
                            <Card key={45678}>
                              {" within "}
                              <input
                                style={{ margin: "0 20px", maxWidth: "100px" }}
                                type="number"
                                value={
                                  XMiles.find(
                                    (item) => item.value === matchValues.miles
                                  )
                                    ? ""
                                    : matchValues.miles
                                }
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  dispatch(
                                    setSchedulesMatchValues({
                                      name: "miles",
                                      value: e.target.value,
                                    })
                                  )
                                }
                                placeholder={"0"}
                              />
                              {"  miles"}
                            </Card>
                          </div>
                        </div>
                      </Box>

                      {/* preference - provider insurance */}
                      <Box className="fromGroup">
                        <InputLabel
                          id="demo-simple-select-label"
                          className="fromLabel"
                        >
                          Insurance Provider
                        </InputLabel>
                        <Select
                          className="form-control"
                          value={matchValues.insuranceProvider}
                          placeholder="Select"
                          id="authInsuranceProvider"
                          onChange={(e: any) => {
                            dispatch(
                              setSchedulesMatchValues({
                                name: "insuranceProvider",
                                value: e.target.value,
                              })
                            );
                          }}
                          MenuProps={MenuProps}
                        >
                          {insuranceProviders.map((item: InsuranceProvider) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.insuranceProviderName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Box>
                    </div>
                  </div>
                </Grid>
              )}

            {/* client/provider availability range */}
            {counter === 1 &&
              ((entityType === "Client" && clientData) ||
                (entityType === "Provider" && providerData)) && (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <div className="detailsCard">
                    <div className="detailsCard__header">
                      {entityType === "Client" && (
                        <Typography className="detailsCard__title">
                          Client Availability (Required)
                        </Typography>
                      )}
                      {entityType === "Provider" && (
                        <Typography className="detailsCard__title">
                          Provider Availability (Required)
                        </Typography>
                      )}
                    </div>
                    <div className="detailsCard__body">
                      <div className="fromGroup matchScheduleBox-time">
                        <Select
                          className="form-control"
                          value={availabiltyValues?.availability?.id}
                          onChange={(e) => {
                            handleChangeAvailability(e.target.value);
                          }}
                          MenuProps={MenuProps}
                        >
                          {entityType === "Client" &&
                            sortDataArray<AvailabilityDetails>(
                              clientData?.availabilityDetails
                            )?.map(
                              (item: AvailabilityDetails, index: number) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item?.beginDate
                                      ? moment(item?.beginDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      : ""}
                                    {" - "}
                                    {item?.endDate
                                      ? moment(item?.endDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      : ""}
                                  </MenuItem>
                                );
                              }
                            )}
                          {entityType === "Provider" &&
                            sortDataArray<AvailabilityDetails>(
                              providerData?.availabilityDetails
                            )?.map(
                              (item: AvailabilityDetails, index: number) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item?.beginDate
                                      ? moment(item?.beginDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      : ""}
                                    {" - "}
                                    {item?.endDate
                                      ? moment(item?.endDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      : ""}
                                  </MenuItem>
                                );
                              }
                            )}
                        </Select>
                        {hasAvailabiltyExpiered && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            This availability period is already expired. Please
                            pick another one or update availability.
                          </FormHelperText>
                        )}
                        {hasNoAvailability &&
                          (clientData.clientBasicDetails?.id !== "" ||
                            providerData.employmentDetails?.id !== "") && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              This user does not have any Availability.
                            </FormHelperText>
                          )}
                      </div>

                      <CalendarHeatmap
                        showOutOfRangeDays={true}
                        startDate={
                          availabiltyValues?.availability?.beginDate
                            ? new Date(
                                availabiltyValues?.availability
                                  ?.beginDate as string
                              )
                            : new Date()
                        }
                        endDate={
                          availabiltyValues?.availability?.beginDate
                            ? moment(availabiltyValues?.availability?.beginDate)
                                .add(6, "M")
                                .format("MM/DD/YYYY")
                            : moment().add(6, "M").format("MM/DD/YYYY")
                        }
                        values={
                          entityType === "Client" &&
                          clientData?.availabilityDetails
                            ? ([
                                ...availabiltyValues?.availability?.heatMap,
                              ] as ReactCalendarHeatmapValue<string>[])
                            : entityType === "Provider" &&
                              providerData?.availabilityDetails
                            ? ([
                                ...availabiltyValues?.availability?.heatMap,
                              ] as ReactCalendarHeatmapValue<string>[])
                            : []
                        }
                        showWeekdayLabels
                        showMonthLabels
                        weekdayLabels={[
                          "Sun",
                          "Mon",
                          "Tue",
                          "Wed",
                          "Thu",
                          "Fri",
                          "Sat",
                        ]}
                        gutterSize={5}
                        tooltipDataAttrs={(value: AvailabilityDates) => {
                          if (value.date) {
                            return {
                              "data-tip": value.availableHours
                                ? "date: " +
                                  moment(new Date(`${value.date}`)).format(
                                    "ddd YYYY-MM-DD"
                                  ) +
                                  "\n" +
                                  "available:" +
                                  `${value.availableHours}` +
                                  "\n" +
                                  "booked:" +
                                  `${value.bookedHours}` +
                                  "\n" +
                                  "remaining:" +
                                  `${value.remainingHours}` +
                                  "\n"
                                : "date: " +
                                  moment(new Date(`${value.date}`)).format(
                                    "ddd YYYY-MM-DD"
                                  ),
                            };
                          }
                        }}
                        classForValue={(
                          value: ReactCalendarHeatmapValue<string> | undefined
                        ) => {
                          if (!value) {
                            return "color-empty";
                          }
                          if (value?.pending) {
                            return `color-pending color-square-${value.count}`;
                          } else {
                            return `color-square-${value.count}`;
                          }
                        }}
                      />
                      <ReactTooltip />
                      <div className="availabilityStatusHorizontal">
                        <span className="unAvailableBox">Unavailable</span>
                        <span className="availableBox">Available</span>
                        <span className="partiallyBookBox">
                          Partially Booked
                        </span>
                        <span className="fullyBookBox">Fully Booked</span>
                      </div>
                    </div>
                  </div>
                </Grid>
              )}

            {/* book weekdays */}
            {counter === 1 &&
              ((entityType === "Client" && clientData) ||
                (entityType === "Provider" && providerData)) && (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <div className="detailsCard">
                    <div className="detailsCard__header">
                      <Typography className="detailsCard__title">
                        Service Weekdays (Required)
                      </Typography>
                    </div>
                    <div className="detailsCard__body">
                      <Box className="fromGroup matchScheduleBox-time mb-6">
                        <InputLabel className="fromLabel">
                          {moment(selectedAvailabilty?.beginDate).format(
                            "MM/DD/YYYY"
                          )}{" "}
                          -{" "}
                          {moment(selectedAvailabilty?.endDate).format(
                            "MM/DD/YYYY"
                          )}
                        </InputLabel>
                        {selectedAvailabilty.id !== "" && (
                          <div className="fromGroup-chips">
                            {/* {matchValues?.availability?.selectedDays?.map( */}
                            {selectedAvailabilty?.selectedDays.map(
                              (item, index: number) => (
                                <Card
                                  className={
                                    selectedDays[index]
                                      ? "fromGroup-chip-active"
                                      : ""
                                  }
                                  onClick={() => {
                                    clientAvailabilityDays(item, index);
                                  }}
                                  key={index}
                                >
                                  {item?.dayOfWeek} ({item.startTime} -{" "}
                                  {item.endTime})
                                </Card>
                              )
                            )}
                          </div>
                        )}

                        {validationError &&
                          matchValues.selectedAvailability.filter(
                            (item) => item
                          ).length === 0 && (
                            <FormHelperText
                              style={{ color: "#d32f2f", fontSize: 18 }}
                            >
                              Please select at least 1 available day
                            </FormHelperText>
                          )}
                        {hasNoAvailability &&
                          (clientData.clientBasicDetails?.id !== "" ||
                            providerData.employmentDetails?.id !== "") && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              This user does not have any Availability.
                            </FormHelperText>
                          )}
                      </Box>
                    </div>
                  </div>
                </Grid>
              )}

            {/* book sessions */}
            {counter === 1 &&
              ((entityType === "Client" && clientData) ||
                (entityType === "Provider" && providerData)) && (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <div className="detailsCard">
                    <div className="detailsCard__header">
                      <Typography className="detailsCard__title">
                        Service Time (Required)
                      </Typography>
                    </div>
                    <div className="detailsCard__body">
                      {/* <Box className="fromGroup">
                      <div className="detailsCard-part">
                        <InputLabel className="fromLabel">
                          Sessions Per Day:
                        </InputLabel>
                        <div className="fromGroup-chips">
                          <Card key={45678}>
                            {" book "}
                            <input
                              style={{ margin: "0 20px", maxWidth: "100px" }}
                              type="number"
                              value={matchValues.newSessionsPerDay}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                dispatch(
                                  setSchedulesMatchValues({
                                    name: "newSessionsPerDay",
                                    value: e.target.value,
                                  })
                                )
                              }
                              placeholder={"0"}
                            />
                            {" sessions per day"}
                          </Card>
                        </div>
                      </div>
                    </Box> */}
                      <Box className="fromGroup">
                        <div className="detailsCard-part">
                          <InputLabel className="fromLabel">
                            Book Hours:
                          </InputLabel>
                          <div className="fromGroup-chips">
                            <Box
                              width={"100%"}
                              display={"flex"}
                              alignItems={"center"}
                              flexWrap={"wrap"}
                            >
                              {XDurations.map((item) => (
                                <Card
                                  className={
                                    matchValues.duration === item.value
                                      ? "fromGroup-chip-active"
                                      : ""
                                  }
                                  onClick={() =>
                                    dispatch(
                                      setSchedulesMatchValues({
                                        name: "duration",
                                        value: item.value,
                                      })
                                    )
                                  }
                                  key={item.key}
                                >
                                  {"book "}
                                  {item.value}
                                  {" hours"}
                                </Card>
                              ))}
                            </Box>
                            <Card key={45678}>
                              {" book "}
                              <input
                                min={0}
                                style={{ margin: "0 20px", maxWidth: "100px" }}
                                type="number"
                                value={matchValues.duration}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  dispatch(
                                    setSchedulesMatchValues({
                                      name: "duration",
                                      value: parseInt(e.target.value),
                                    })
                                  )
                                }
                                placeholder={"0"}
                              />
                              {"  hours"}
                            </Card>
                          </div>
                          {validationError && matchValues.duration === 0 && (
                            <FormHelperText
                              style={{ color: "#d32f2f", fontSize: 18 }}
                            >
                              Please select duration
                            </FormHelperText>
                          )}
                        </div>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>Earliest Start Time:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={matchValues.earliestStartTime}
                            onChange={(e) => {
                              console.log(e);
                              dispatch(
                                setSchedulesMatchValues({
                                  name: "earliestStartTime",
                                  value: e.target.value,
                                })
                              );
                            }}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 150 } },
                            }}
                          >
                            {/* <div style={{height:"150px"}}> */}
                            {earliestStartTime.map((item, index) => (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            ))}
                            {/* </div> */}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                  </div>
                </Grid>
              )}

            {/* begin to match button */}
            <Grid item xs={12} className="text-center" mt={"24px"}>
              <Button
                className="button button--large"
                variant="contained"
                onClick={() => checkValidation()}
              >
                Begin To Match
              </Button>
            </Grid>
          </>
        </Grid>
      </Box>
    </div>
  );
};

export default PageOne;
