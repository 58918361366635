import { Grid } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { AllUserMessage, AllUserMessageI } from "../../../types/message";
import InternalMessage from "../message-form/InternalMessage";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  GetMessageByEntityId,
  setReadMessage,
  UpdateMessageStatusById,
} from "../../../redux/features/messages/message-slice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useUser } from "@descope/react-sdk";
import MessageFieldHeader from "./MessageFieldHeader";
import ExternalMessage from "../message-form/ExternalMessage";
import SmsMessage from "../message-form/SmsMessage";

interface MessageFieldProps {
  selectedTab: string;
  selectedUser: {
    id: string;
    name: string;
    entityType: string;
  };
}

const MessageField: React.FC<MessageFieldProps> = ({
  selectedUser,
  selectedTab,
}) => {
  const [{ internalMessage, externalMessage, smsMessage }, setMessageData] =
    useState<{
      internalMessage: AllUserMessage[];
      externalMessage: AllUserMessage[];
      smsMessage: AllUserMessage[];
    }>({
      internalMessage: [],
      externalMessage: [],
      smsMessage: [],
    });

  const [selectedCommunicationTab, setSelectedCommunicationTab] =
    useState<string>("External");

  //user from Descope
  const {
    user: { userId },
  } = useUser();

  const dispatch = useAppDispatch();
  const dispatchAction = bindActionCreators(
    {
      GetMessageByEntityId,
    },
    dispatch
  );
  const dispatchActionRef = useRef(dispatchAction);
  const { allUserMessage } = useAppSelector<AllUserMessageI>(
    (state) => state.messageSlice.allMessageData
  );

  //handles read-unread messages
  const handleReadInternalMessage = useCallback(() => {
    if (selectedCommunicationTab !== "Internal") return;
    if (internalMessage.length > 0) {
      let activeMessageIds: string[] = [];
      internalMessage.forEach((item) => {
        if (!item.seenUserId.includes(userId)) {
          activeMessageIds.push(item.id);
          dispatch(setReadMessage({ id: item.entityId, type: "internal" }));
        }
        return activeMessageIds;
      });
      if (activeMessageIds.length > 0) {
        dispatch(
          UpdateMessageStatusById({
            id: activeMessageIds,
            type: "internalCount",
            userId,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunicationTab, selectedUser?.id, selectedTab]);

  useEffect(() => {
    if (selectedUser.id) {
      dispatchActionRef.current.GetMessageByEntityId({
        entityId: selectedUser.id,
      });
    }
  }, [selectedUser.id]);

  useEffect(() => {
    let internalArr: AllUserMessage[] = [];
    let externalArr: AllUserMessage[] = [];
    let smsArr: AllUserMessage[] = [];

    allUserMessage.forEach((item) => {
      if (item.type === "internal") {
        internalArr.push(item);
      }
      if (item.type === "external") {
        externalArr.push(item);
      }
      if (item.type === "sms") {
        smsArr.push(item);
      }
    });

    if (internalArr.length > 0) {
      let arr: string[] = [];
      internalArr.map((item) => {
        return arr.push(item.id);
      });
    }
    setMessageData({
      internalMessage: internalArr,
      externalMessage: externalArr,
      smsMessage: smsArr,
    });
  }, [allUserMessage]);

  useEffect(() => handleReadInternalMessage(), [handleReadInternalMessage]);

  return (
    <>
      <MessageFieldHeader
        selectedUser={selectedUser}
        setSelectedCommunicationTab={setSelectedCommunicationTab}
        selectedCommunicationTab={selectedCommunicationTab}
      />
      <Grid item xs={16} md={16} className="scroll-message">
        {selectedCommunicationTab === "Internal" && (
          <InternalMessage
            internalComments={internalMessage}
            selectedUser={selectedUser}
          />
        )}
        {selectedCommunicationTab === "External" && (
          <ExternalMessage
            externalMessage={externalMessage}
            selectedUser={selectedUser}
          />
        )}
        {selectedCommunicationTab === "SMS" && (
          <SmsMessage smsComment={smsMessage} />
        )}
      </Grid>
    </>
  );
};

export default MessageField;
