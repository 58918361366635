import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Close } from "@mui/icons-material";
interface Props {
  setModal: (value: boolean, type: boolean) => void;
  userDetails: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  }[];
}

const UserManagementDeleteConfirmation: React.FC<Props> = ({
  setModal,
  userDetails,
}) => {
  return (
    <Dialog open={true} className="smallModel">
      <div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "-20px",
          }}
        >
          <ClearIcon
            onClick={() => setModal(false, false)}
            sx={{
              cursor: "pointer",
              paddingTop: "10px",
              paddingRight: "10px",
            }}
            fontSize="large"
          />
        </div> */}
        
        <Grid>
        <Box className="smallModel__head">
          <Typography variant="h6" className="smallModel__title">
            Disable or Delete User
          </Typography>
          <Button
            className="smallModel__closeBtn"
            onClick={() => setModal(false, false)}
          >
            <Close />
          </Button>
        </Box>
        </Grid>

        <DialogContent>
          <Typography variant="h6">Do you want to disable or delete</Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {userDetails.length &&
              userDetails.map((user) => (
                <>
                  {`${user.firstName} ${user.lastName} (${user.email})`}
                  {userDetails.length > 1 ? `, `: ``}
                </>
              ))}
            <Typography sx={{ fontWeight: "none" }} variant="h6">
              {" "}
              {userDetails.length > 1 ? `accounts?`: `account?`}
            </Typography>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className="border-button"
            variant="contained"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              setModal(true, true)
            }
          >
            Permanently Delete
          </Button>
          <Button
            className="button"
            variant="contained"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              setModal(true, false)
            }
          >
            Disable User
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default UserManagementDeleteConfirmation;
