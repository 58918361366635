import React from "react";
import AssessmentForms from "./assessmentForm/AssessmentForms";
import AssessmentClientList from "./assessmentClientList/AssessmentClientList";

const Assessments: React.FC = () => {
  return (
    <>
      <div className="pageTemplate settingPage">
        <AssessmentForms />
        <AssessmentClientList />
      </div>
    </>
  );
};

export default Assessments;
