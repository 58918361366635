import React, { Dispatch, SetStateAction } from "react";
import AddClient from "./AddClient";
import { ClientBasicDetails } from "../../../types/client";

interface IClientModal {
  selectedTab: string;
  isOpenClientModal: boolean;
  setIsOpenClientModal: Dispatch<SetStateAction<boolean>>;
  clientBasicDetails: ClientBasicDetails;
}

const ClientModal: React.FC<IClientModal> = ({
  selectedTab,
  isOpenClientModal,
  setIsOpenClientModal,
  clientBasicDetails,
}) => {
  return (
    <>
      {selectedTab === "Intake" ? (
        <div className="cardBlock">
          <AddClient
            isOpenClientModal={isOpenClientModal}
            setOpenClientModal={setIsOpenClientModal}
            clientBasicDetails={clientBasicDetails}
            isBoardPage={true}
          />
        </div>
      ) : (
        <AddClient
          isOpenClientModal={isOpenClientModal}
          setOpenClientModal={setIsOpenClientModal}
          clientBasicDetails={clientBasicDetails}
          isBoardPage={false}
        />
      )}
    </>
  );
};

export default ClientModal;
