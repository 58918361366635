import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  PostProject,
  UpdateProjectById,
} from "../../../../redux/features/project/taskManager-slice";

const NewProjectModal: React.FC<{
  setBoardModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTab?: React.Dispatch<
    React.SetStateAction<{
      id: string;
      type: string;
      index?: number | null | undefined;
    }>
  >;
  isEdit: boolean;
}> = ({ setBoardModal, isEdit, setSelectedTab }) => {
  const dispatch = useAppDispatch();

  //stores current project
  const currentProject = useAppSelector(
    (item) => item.taskManagerSlice.currentProject
  );

  //stores project name
  const [name, setName] = useState(
    isEdit && currentProject ? currentProject.name : ""
  );

  //stores project description
  const [description, setDescription] = useState(
    isEdit && currentProject ? currentProject.name : ""
  );

  //handles error
  const [hasError, setHasError] = useState<boolean>(false);

  //handle create and update project
  const handleSubmit = async () => {
    if (name !== "") {
      if (isEdit && currentProject) {
        dispatch(
          UpdateProjectById({
            id: currentProject.id,
            name: name,
            description: description,
          })
        );
      } else {
        const data: any = await dispatch(PostProject({ name, description }));
        if (data?.payload !== undefined && setSelectedTab !== undefined) {
          setSelectedTab({ id: data.payload?.id, type: "Projects" });
        }
      }
      setBoardModal(false);
    } else {
      setHasError(true);
      return;
    }
  };

  return (
    <>
      <Grid>
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            {isEdit ? "Edit Project" : "Create Project"}
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => setBoardModal(false)}
          >
            <Close />
          </Button>
        </Box>
      </Grid>
      <div className="create-new-board p-30px">
        <Grid className="create-new-board-inner">
          <Grid item xs={12} md={6}>
            <Box className="fromGroup mb-8">
              <InputLabel className="fromLabel" variant="standard">
                Project Name
              </InputLabel>
              <FormControl>
                <TextField
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                />
              </FormControl>
              {name === "" && hasError && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Please enter name
                </FormHelperText>
              )}
            </Box>
            <Box className="fromGroup mb-8">
              <InputLabel className="fromLabel">Description</InputLabel>
              <FormControl>
                <TextField
                  value={description}
                  onChange={({ target: { value } }) => setDescription(value)}
                  rows={3}
                  multiline
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box className="create-new-board-footer">
          <Button onClick={handleSubmit} variant="contained">
            Save Project
          </Button>
        </Box>
      </div>
    </>
  );
};

export default NewProjectModal;
