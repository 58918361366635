const usePaginatedData = <T,>(
  data: T[],
  pageNumber: number,
  postPerPage: number
): T[] => {

  let tempData: T[] = [];
  const startIndex = (pageNumber - 1) * postPerPage;
  const endIndex = startIndex + postPerPage;
  tempData = data.slice(startIndex, endIndex);
  return tempData;

  // let tempData: T[] = [];
  // for (
  //   let i = (pageNumber - 1) * postPerPage;
  //   i < pageNumber * postPerPage && i < data.length;
  //   i++
  // ) {
  //   tempData.push(data[i]);
  // }
};

export default usePaginatedData;
