import React, { useEffect, useState } from "react";
import { ReactComponent as LeftArrow } from "../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/images-svg/rightArrow.svg";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";

import "pure-react-carousel/dist/react-carousel.es.css";
import "react-multi-carousel/lib/styles.css";
import "./Pagination.scss";

interface PaginationProps {
  postPerPage: number;
  totalData: number;
  paginate: (number: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  postPerPage,
  totalData,
  paginate,
}) => {
  //#region variables region

  //set active Index for show and hide scroll buttons
  const [activeIndex, setActiveIndex] = useState<number>(1);

  //set page numbers for show pagination bar
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);

  //#endregion

  //#region methods region
  const handleNumber = (number: number) => {
    paginate(number);
    setActiveIndex(number);
  };

  //Left scroll button popup handle
  const funcLeft = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < 2) {
      return false;
    } else {
      return true;
    }
  };

  // Right scroll button popup handle
  const funcRight = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < pageNumbers.length) {
      return true;
    }
    return false;
  };

  //Right scroll button Click handle
  const handleRight = () => {
    if (activeIndex <= pageNumbers.length) {
      paginate(activeIndex + 1);
      setActiveIndex(activeIndex + 1);
    }
  };

  //Left scroll button Click handle
  const handleLeft = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
      paginate(activeIndex - 1);
    }
  };
  //#endregion

  //#region useEffects region

  useEffect(() => {
    const newPageNumber: number[] = [];
    for (let i = 1; i <= Math.ceil(totalData / postPerPage); i++) {
      newPageNumber.push(i);
    }
    if (newPageNumber.length >= 1) {
      if (newPageNumber.length < activeIndex) {
        setActiveIndex(newPageNumber.length);
        paginate(newPageNumber.length);
      }
    }

    setPageNumbers(newPageNumber);
  }, [totalData, postPerPage, activeIndex, paginate]);

  //#endregion

  return totalData > postPerPage ? (
    <ul
      className="pagination"
      style={{
        width:
          pageNumbers.length < 10 ? `${pageNumbers.length * 40}px` : "400px",
      }}
    >
      <CarouselProvider
        naturalSlideWidth={
          pageNumbers.length < 10 ? pageNumbers.length * 40 : 400
        }
        naturalSlideHeight={125}
        totalSlides={pageNumbers.length}
        visibleSlides={pageNumbers.length < 10 ? pageNumbers.length : 10}
        orientation={"horizontal"}
      >
        <Slider>
          {pageNumbers.map((number) => {
            return (
              <Slide index={number} key={number}>
                <li className="pagination__item">
                  <button
                    onClick={() => handleNumber(number)}
                    className={
                      activeIndex === number
                        ? "pagination__item-link active"
                        : "pagination__item-link"
                    }
                  >
                    {number}
                  </button>
                </li>
              </Slide>
            );
          })}
        </Slider>
        <ButtonBack
          onClick={handleLeft}
          className="pagination__item-link"
          disabled={!funcLeft() && true}
        >
          <LeftArrow />
        </ButtonBack>
        <ButtonNext
          onClick={handleRight}
          className="pagination__item-link"
          disabled={!funcRight() && true}
        >
          <RightArrow />
        </ButtonNext>
      </CarouselProvider>
    </ul>
  ) : (
    <></>
  );
};

export default Pagination;
