import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";

type CustomThemeProp = {
  children: JSX.Element;
};

const theme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        style: {
          textTransform: "unset",
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        style: {
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        style: {
          lineHeight: 1,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        style: {
          width: "auto",
          margin: 0,
        },
      },
    },
  },
});

export const CustomThemeProvider: React.FC<
  React.PropsWithChildren<CustomThemeProp>
> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
