import {
  Box,
  Button,
  Dialog,
  Step,
  StepLabel,
  Stepper,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { providerSteps } from "../../../constants/providers";
import { setActiveSteps } from "../../../redux/features/active-steps/active-slice";
import { 
  submitProvider,
  setInitialState 
} from "../../../redux/features/providers-main/provider-slice";
import { AppDispatch, RootState } from "../../../redux/store";
// import AvailabilityDetailsForm from "../providers-forms/AvailabilityDetailsForm";
import ContactDetailsForm from "../providers-forms/ContactDetailsForm";
import EmploymentDetailsForm from "../providers-forms/EmploymentDetailsForm";
import CloseIcon from "@mui/icons-material/Close";
// import ClientAvailabilityDetailsNew from "../providers-forms/AvailabilityDetailsFormNew";
import AvailabilityDetailsNew from "../providers-forms/AvailabilityDetailsFormNew";

interface IAddProvider {
  addNewDialog: boolean;
  setAddNewDialog: Dispatch<SetStateAction<boolean>>;
  isBoardPage: boolean;
}

const AddProvider: React.FC<IAddProvider> = ({
  addNewDialog,
  setAddNewDialog,
  isBoardPage,
}) => {
  // #region variable region

  //active step for the stepper
  const { activeStep } = useSelector((state: RootState) => state.activeStep);

  //Open and Close Popup for Dialog
  const [popup, setPopup] = useState<boolean>(false);

  //handles the SavePopup
  const [saveProvider, setSaveProvider] = useState<boolean>(false);

  //app dispatch for redux
  const dispatch = useDispatch<AppDispatch>();
  // #endregion

  // #region methods region

  //handles which forms to display
  const Forms = (number: number): JSX.Element => {
    switch (number) {
      case 0:
        return <EmploymentDetailsForm isNew={true} />;
      case 1:
        return <ContactDetailsForm isNew={true} />;
      case 2:
        return (
          // <AvailabilityDetailsForm
          //   isBoardPage={isBoardPage}
          //   isNew={true}
          //   modalHandler={(value: boolean) => setAddNewDialog(value)}
          // />
          <AvailabilityDetailsNew
            isBoardPage={isBoardPage}
            isNew={true}
            modalHandler={(value: boolean) => setAddNewDialog(value)}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  // #endregion
  return (
    <>
      <div>
      {activeStep <= 3 && (
        <Dialog
          open={addNewDialog}
          //onClose={() => setAddNewDialog(false)}
          className="largeModel"
        >
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Add New Employee
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => {
                console.log("enter here");
                setPopup(true);
                setSaveProvider(true);

                // dispatch(setActiveSteps(0));
                // dispatch(setInitialState(null));
                // setAddNewDialog(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box className="largeModel__body">
            <Box className="largeModel__stepperHead">
              <Stepper activeStep={activeStep} sx={{ py: 3 }} alternativeLabel>
                {providerSteps.map((label) => (
                  <Step key={label.label}>
                    <StepLabel>{label.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box className="stepperForm">{Forms(activeStep)}</Box>
          </Box>
        </Dialog>
        )}
        {popup && (
        <Dialog open={saveProvider} className="smallModel">
          <div>
            <DialogContent>
              Do you want to save current provider data?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => {
                  dispatch(setActiveSteps(0));
                  setAddNewDialog(false);
                  dispatch(setInitialState(null));
                  setSaveProvider(false);
                }}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  dispatch(submitProvider({ isBoardPage: isBoardPage }));
                  setSaveProvider(false);
                  dispatch(setActiveSteps(0));
                  setAddNewDialog(false);
                  // dispatch(setListDataChange(!dataChange));
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
      </div>
    </>
  );
};

export default AddProvider;
