import { Box, Typography } from "@mui/material";
import plus from "../../../../assets/images/images-svg/plus.svg";
import { ReactComponent as Align } from "../../../../assets/images/images-svg/align-justify.svg";
import React, { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { boardAutomation } from "../../../../types/board";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useAppDispatch } from "../../../../redux/store";
import { setAutomationOrder } from "../../../../redux/features/custom-board/custom-board-slice";

interface IBoardTitle {
  board: boardAutomation;
  setRule: (boardId: string, index: number) => void;
  selectedRule: { id: string; index: number };
  addRule: () => void;
}
const BoardTitle: React.FC<IBoardTitle> = ({
  board,
  setRule,
  selectedRule,
  addRule,
}) => {
  //dispatch
  const dispatch = useAppDispatch();

  //handle show or not show
  const [show, setShow] = useState(false);

  //handles drag and drop
  const dragHandler = ({ source, destination }: DropResult) => {
    if (destination?.index === undefined) return;
    if (source.index === destination.index) return;
    const dragItem = board.automation[source.index];
    let newBoard = board.automation.filter((_, i) => source.index !== i);
    newBoard.splice(destination.index, 0, dragItem);
    const data = newBoard.map((item, index) => {
      return {
        ...item,
        order: index,
      };
    });
    dispatch(
      setAutomationOrder({
        id: board.id,
        automation: data,
      })
    );
  };

  return (
    <Box className="automationSidebar__item">
      <Typography className="automationSidebar__item-title">
        <span
          style={{ display: "flex", alignItems: "flex-start", cursor: "pointer" }}
          onClick={() => {
            setShow(!show);
            setRule(board.id, 0);
          }}
        >
          {!show ? <ArrowForwardIcon /> : <ArrowDownwardIcon />}{" "}
          {board.boardName}
        </span>
        <span
          style={{
            width: "24px",
            height: "24px",
            border: "1px solid #096DD9",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            cursor: "pointer",
            flex:"none"
          }}
        >
          <img
            alt="added icon"
            src={plus}
            onClick={() => {
              setShow(true);
              addRule();
            }}
            className="mainHeader__logo"
            style={{ width: "12px", height: "12px" }}
          />
        </span>
      </Typography>

      {show && (
        <DragDropContext onDragEnd={dragHandler}>
          <Droppable
            droppableId="droppableId"
            direction="vertical"
            children={(provider) => (
              <ul
                className="automationSidebar__innerItem"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {board.automation.length > 0 &&
                  board.automation?.map((data, index) => (
                    <Draggable
                      index={index}
                      key={index}
                      draggableId={JSON.stringify(index)}
                      children={(provider) => (
                        <li
                          ref={provider.innerRef}
                          {...provider.dragHandleProps}
                          {...provider.draggableProps}
                          onClick={() => setRule(board.id, index)}
                          key={index}
                          className={
                            selectedRule.id === board.id &&
                            selectedRule.index === index
                              ? "automationSidebar__innerItem-selected"
                              : "automationSidebar__innerItem-title"
                          }
                        >
                          <Align className="icon" />
                          <Typography>
                            {data.name ? data.name : `Rule ${index + 1}`}
                          </Typography>
                        </li>
                      )}
                    />
                  ))}
                {provider.placeholder}
              </ul>
            )}
          />
        </DragDropContext>
      )}
    </Box>
  );
};

export default BoardTitle;
