import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

//User & Tenant & BU Management
import descopeSlice from "./features/descope/descope-slice";
import authSlice from "./features/auth-slice";
import userSlice from "./features/user-slice";
import activeSlice from "./features/active-steps/active-slice";
import companyUserSlice from "./features/user/add-user-slice";
import organizationSlice from "./features/organization/organization-slice";
import businessUnitsSlice from "./features/business-unit/business-unit";

//API Response
import responseSlice from "./features/api-response/api-response";

//Client Data Management
import clientSlice from "./features/client-main/client-slice";
import clientListSlice from "./features/client-main/client-list-slice";
import clientFilterSlice from "./features/client-main/client-filter-slice";
import clientInsuranceSlice from "./features/client-main/client-insurance-slice";

//Provider Data Management
import providerSlice from "./features/providers-main/provider-slice";
import providerListSlice from "./features/providers-main/provider-list-slice";
import providerFilterSlice from "./features/providers-main/provider-filter-slice";

//Match & Schedule Management
import matchSlice from "./features/schedules-main/match-slice";
import scheduleListSlice from "./features/schedules-main/schedule-list-slice";
import schedulesFilterSlice from "./features/schedules-main/schedules-filter-slice";

//Maps
import mapsFilterSlice from "./features/maps/maps-filter-slice";
import colorsStateSlice from "./features/trello-board/colors-slice";

//Stage Management
import draggingStateSlice from "./features/trello-board/dragging-state-slice";
import cardsStateSlice from "./features/trello-board/cards-slice";
import clientIntakeStage from "./features/client-main/client-intake-stage";
import clientBoardSlice from "./features/client-main/client-board-slice";
import providerOnBoardingStage from "./features/providers-main/provider-onboardingstage-slice";
import providerBoardSlice from "./features/providers-main/provider-board-slice";
import availabilitySlice from "./features/Common/availability-slice";
import providerSupervisorSlice from "./features/providers-main/provider-supervisor-slice";
import customBoardSlice from "./features/custom-board/custom-board-slice";
import planSlice from "./features/plannings/planning-slice";

//message center
import communicationSlice from "./features/communication/messageTemplate-slice";
import messageSlice from "./features/messages/message-slice";
import messageFilterSlice from "./features/messages/message-filter-slice";
import dataCatalogSlice from "./features/data-catalog/data-catalog-slice";
import planningFilterSlice from "./features/plannings/planning-filter-slice";
// import assessmentformSlice from "./features/assessment/assessment-form-slice";
import intakeFormSlice from "./features/intake/intake-form-slice";
import formSlice from "./features/forms/form-slice";
import bookingSchedulerSlice from "./features/schedules-main/booking-scheduler-slice";

import planBillingSlice from "./features/plan-billing/plan-billing-slice";
import taskManagerSlice from "./features/project/taskManager-slice";
import integrationSettingSlice from "./features/integration-setting/integration-setting-slice";

import importExportSlice from "./features/import-export/import-export-slice";
import runsSlice from "./features/runs/runs-slice";
import sessionSlice from "./features/session/session-slice";

// Configure Store Here
const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    // assessmentformSlice:assessmentformSlice.reducer,
    formSlice: formSlice.reducer,
    user: userSlice.reducer,
    descopeSlice: descopeSlice.reducer,
    activeStep: activeSlice.reducer,
    companyUserSlice: companyUserSlice.reducer,
    organizationSlice: organizationSlice.reducer,
    businessUnitsSlice: businessUnitsSlice.reducer,
    communicationSlice: communicationSlice.reducer,
    dataCatalogSlice: dataCatalogSlice.reducer,
    intakeFormSlice: intakeFormSlice.reducer,
    messageSlice: messageSlice.reducer,
    messageFilterSlice: messageFilterSlice.reducer,
    responseSlice: responseSlice.reducer,

    client: clientSlice.reducer,
    clientListSlice: clientListSlice.reducer,
    clientInsuranceSlice: clientInsuranceSlice.reducer,
    clientFilterSlice: clientFilterSlice.reducer,

    providerSlice: providerSlice.reducer,
    providerListSlice: providerListSlice.reducer,
    providerFilterSlice: providerFilterSlice.reducer,
    providerSupervisorSlice: providerSupervisorSlice.reducer,
    planningFilterSlice: planningFilterSlice.reducer,

    matchSlice: matchSlice.reducer,
    availabilitySlice: availabilitySlice.reducer,
    schedulesFilterSlice: schedulesFilterSlice.reducer,
    scheduleListSlice: scheduleListSlice.reducer,
    bookingSchedulerSlice: bookingSchedulerSlice.reducer,
    mapsFilterSlice: mapsFilterSlice.reducer,
    planSlice: planSlice.reducer,
    colorsStateSlice: colorsStateSlice.reducer,

    draggingStateSlice: draggingStateSlice.reducer,
    cardsStateSlice: cardsStateSlice.reducer,
    clientIntakeStage: clientIntakeStage.reducer,
    clientBoardSlice: clientBoardSlice.reducer,
    providerBoardSlice: providerBoardSlice.reducer,
    providerOnBoardingStage: providerOnBoardingStage.reducer,

    boardSlice: customBoardSlice.reducer,

    planBillingSlice: planBillingSlice.reducer,
    taskManagerSlice: taskManagerSlice.reducer,
    integrationSettingSlice: integrationSettingSlice.reducer,
    importExportSlice: importExportSlice.reducer,
    runsSlice:runsSlice.reducer,
    sessionSlice:sessionSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
