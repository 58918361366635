import React, { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import { ProviderForms } from "../../../types/provider";
import { useSelector } from "react-redux";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import { ReactComponent as UserVerified } from "../../../assets/images/images-svg/verified.svg";
interface SupervisorProps {
  supervisorId: string;
  showSupervisor: boolean;
  providerList: string[] | null;
}

const PlannerSupervisorList: React.FC<SupervisorProps> = ({
  supervisorId,
  showSupervisor,
  providerList,
}) => {
  //#region variable region

  //variable to store supervisor
  const [supervisor, setSupervisor] = useState<ProviderForms | null>(null);

  //variable to store providerdata
  let providerData = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.listData
  );

  if (providerList !== null) {
    providerData = providerData.filter((provider) =>
      provider.employmentDetails.isSupervisor
        ? true
        : providerList?.includes(provider.employmentDetails.id)
    );
  } else {
    providerData = providerData.filter(
      (provider) => provider.employmentDetails.isSupervisor
    );
  }

  //#endregion

  //#region useEffect region

  //handle supervisor and supervising provider data
  useEffect(() => {
    const supervisorData = providerData.find((provider) => {
      return provider.employmentDetails.id === supervisorId;
    });
    if (supervisorData) {
      setSupervisor(supervisorData);
    }
  }, [supervisorId, providerData]);

  return (
    <>
      {showSupervisor && supervisor && (
        <tr>
          <td></td>
          {/* <td>
            <span className="userMapCart__img">
              <img
                src={
                  supervisor?.employmentDetails?.providerProfile?.url ||
                  ProviderImg
                }
                className="userSmallCircle"
                alt=""
              />
              <span className="verifiedIcon">
                <UserVerified />
              </span>
            </span>
          </td> */}
          <td style={{ minWidth: "132px" }}>
            <div className="userMapCart__statusBox">
              <span className="top-level-title">
                {supervisor?.providerUtilization?.status}
              </span>
              <strong>
                {supervisor?.employmentDetails?.firstName}{" "}
                {supervisor?.employmentDetails?.lastName}{" "}
              </strong>
            </div>
          </td>
          <td>
            <div className="userMapCart__statusBox">
              <span className="top-level-title"> </span>
              <strong>
                {supervisor?.providerUtilization?.weeklyAvgAvailability?.toFixed(
                  1
                )}
              </strong>
            </div>
          </td>
          <td>
            <div className="userMapCart__statusBox">
              <span className="top-level-title"> </span>
              <strong>
                {supervisor?.providerUtilization?.weeklyAvgBooked?.toFixed(1)}
              </strong>
            </div>
          </td>
          <td>
            <div className="userMapCart__statusBox">
              <span className="top-level-title"> </span>
              <strong>
                {supervisor?.providerUtilization?.weeklyAvgRemainedByAvailability?.toFixed(
                  1
                )}
              </strong>
            </div>
          </td>
          {/* must have to reserve space */}
          <td>
            <div className="userMapCart__statusBox">
              <strong className="text-center"></strong>
            </div>
          </td>
        </tr>
      )}
      {providerData.map((e) => {
        if (
          supervisor?.employmentDetails.providerList.includes(
            e.employmentDetails.id
          )
        ) {
          let availableHours = e.providerUtilization.weeklyAvgAvailability;
          let bookedHours = e.providerUtilization.weeklyAvgBooked;
          let remainingHours =
            e.providerUtilization.weeklyAvgRemainedByAvailability;
          return (
            <tr key={e?.employmentDetails?.id}>
              <td></td>
              {/* <td key={e?.employmentDetails?.id}>
                <span className="userMapCart__img">
                  <img
                    src={
                      e?.employmentDetails?.providerProfile?.url || ProviderImg
                    }
                    className="userSmallCircle"
                    alt=""
                  />
                </span>
              </td> */}
              <td>
                <div className="userMapCart__statusBox">
                  <span className="top-level-title">
                    {e?.providerUtilization?.status}
                  </span>
                  <strong>
                    {e?.employmentDetails?.firstName}{" "}
                    {e?.employmentDetails?.lastName}{" "}
                  </strong>
                </div>
              </td>
              <td>
                <div className="userMapCart__statusBox">
                  <span className="top-level-title"> </span>
                  <strong>
                    {isNaN(bookedHours) ? 0.0 : availableHours.toFixed(1)}
                  </strong>
                </div>
              </td>
              <td>
                <div className="userMapCart__statusBox">
                  <span className="top-level-title"> </span>
                  <strong>
                    {isNaN(bookedHours) ? 0.0 : bookedHours.toFixed(1)}
                  </strong>
                </div>
              </td>
              <td>
                <div className="userMapCart__statusBox">
                  <span className="top-level-title"> </span>
                  <strong>
                    {isNaN(remainingHours) ? 0.0 : remainingHours.toFixed(1)}
                  </strong>
                </div>
              </td>
              {/* must have to reserve space */}
              <td>
                <div className="userMapCart__statusBox">
                  <strong className="text-center"></strong>
                </div>
              </td>
            </tr>
          );
        }
      })}
    </>
  );
};

export default PlannerSupervisorList;
