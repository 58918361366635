import moment from "moment";
import { formattedDate } from "../../constants/common";
import { ISchedules } from "../../types/client";
import {
  Credentials,
  ProviderFilters,
  ProviderForms,
} from "../../types/provider";
import { AvailabilityDetails } from "../../types/availability";
import { searchProviderText } from "../../utils/FilterUtils";

//checks if any value is selected filter filter or search
export const canFilterProviders = (
  filterValues: ProviderFilters,
  searchVal: string
): boolean => {
  if (
    filterValues.providerType.length > 0 ||
    filterValues.businessUnit.length > 0 ||
    filterValues.availabilityStatus ||
    filterValues.insuranceProvider.length > 0 ||
    filterValues.onBoardingStage.length > 0 ||
    filterValues.checkList.length > 0 ||
    filterValues.onboardingStatus.length > 0 ||
    filterValues.employmentStatus ||
    filterValues.ScheduleStatus ||
    filterValues.startDate ||
    filterValues.endDate ||
    searchVal
  ) {
    return true;
  }
  return false;
};

//to sorting availabilitydeatails according to begindate
export const sortingAvailability = (item: ProviderForms) => {
  let sorted = [...item.availabilityDetails].sort(function (a, b) {
    return (
      (new Date(formattedDate(a?.beginDate)) as any) -
      (new Date(formattedDate(b?.beginDate)) as any)
    );
  });

  return sorted;
};

//to sorting availabilityDetails according to endDates
export const sortingAvailabilityEndDate = (item: ProviderForms) => {
  let sorted = [...item.availabilityDetails].sort(function (a, b) {
    return (
      (new Date(formattedDate(b?.endDate)) as any) -
      (new Date(formattedDate(a?.endDate)) as any)
    );
  });

  return sorted;
};

//logic for filtering a single provider and Client
export const filterSingleProvider = (
  item: ProviderForms,
  filterValues: ProviderFilters,
  searchVal: string
): boolean => {
  //need to implement filtering on insurance provider, checklist and onBoardingStage
  // var startDate = new Date(item.availabilityDetails[0]?.beginDate as string);
  var startDate =
    item.availabilityDetails?.length > 0
      ? new Date(sortingAvailability(item)[0]?.beginDate as string)
      : null;
  // var endDate = new Date(item.availabilityDetails[0]?.endDate as string);
  var endDate =
    item.availabilityDetails?.length > 0
      ? new Date(sortingAvailabilityEndDate(item)[0]?.endDate as string)
      : null;
  var today = new Date();

  const searchText = searchProviderText(searchVal, item);
  if (!searchText) {
    return false;
  }

  if (
    !filterValues.businessUnit.includes("All Units") &&
    filterValues.businessUnit.length > 0 &&
    // !filterValues.businessUnit.includes(item.employmentDetails?.businessUnit)
    !filterValues.businessUnit.some((ele) =>
      item.employmentDetails.businessUnit.includes(ele)
    )
  ) {
    return false;
  }
  if (
    !filterValues.providerType.includes("All Types") &&
    filterValues.providerType.length > 0 &&
    !filterValues.providerType.includes(item.employmentDetails.type)
  ) {
    return false;
  }
  if (filterValues.onboardingStatus.length > 0) {
    if (
      !filterValues.onboardingStatus.includes(
        item.employmentDetails.providerType
      )
    ) {
      return false;
    }
  }
  // if (
  //   filterValues.onboardingStatus &&
  //   !(item.employmentDetails.providerType === filterValues.onboardingStatus)
  // ) {
  //   return false;
  // }
  if (
    filterValues.employmentStatus &&
    !(item.employmentDetails.employmentStatus === filterValues.employmentStatus)
  ) {
    return false;
  }
  if (
    filterValues.availabilityStatus === "Current" &&
    startDate &&
    endDate &&
    !(today >= startDate && today <= endDate)
  ) {
    return false;
  } else if (
    filterValues.availabilityStatus === "Expired" &&
    endDate &&
    !(today > endDate)
  ) {
    return false;
  }

  if (
    !filterValues.insuranceProvider.includes("All Employees") &&
    filterValues.insuranceProvider.length > 0
  ) {
    if (item.providerInsurance.length > 0) {
      let count = 0;
      item.providerInsurance.forEach((item: Credentials) => {
        if (filterValues.insuranceProvider.includes(item.name)) {
          count++;
        }
      });
      if (count === 0) {
        return false;
      }
    } else {
      return false;
    }
  }

  let onBoardingFlag = true;
  while (onBoardingFlag) {
    if (filterValues.onBoardingStage.length > 0) {
      if (filterValues.onBoardingStage.includes("All Stages")) {
        onBoardingFlag = false;
      } else {
        let count = 0;
        filterValues.onBoardingStage.forEach((matchValue: string) => {
          if (item.employmentDetails.onBoardingStage === matchValue) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        onBoardingFlag = false;
      }
    }
    onBoardingFlag = false;
  }

  let checkListFlag = true;
  while (checkListFlag) {
    if (filterValues.checkList.length > 0) {
      if (filterValues.checkList.includes("All Stages")) {
        checkListFlag = false;
      } else {
        let count = 0;
        filterValues.checkList.forEach((matchValue: string) => {
          if (item.employmentDetails.checklistHistory.includes(matchValue)) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        checkListFlag = false;
      }
    }
    checkListFlag = false;
  }
  if (filterValues.ScheduleStatus) {
    if (item.providerUtilization.status === filterValues.ScheduleStatus) {
      return true;
    } else {
      return false;
    }
  }
  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  return true;
};

//return evaluate schedule array
export const evaluateSchedulerData = (
  item: ProviderForms,
  filterValues: { startDate: string | null; endDate: string | null }
) => {
  let arr: AvailabilityDetails[] = [];
  item.availabilityDetails.filter((item: AvailabilityDetails) => {
    return filterSingleScheduleListItem(item, filterValues) && arr.push(item);
  });

  return arr;
};

//logic for filtering a single provider and Client
export const filterSingleScheduleListItem = (
  item: AvailabilityDetails,
  filterValues: { startDate: string | null; endDate: string | null }
): boolean => {
  if (filterValues.startDate && filterValues.endDate) {
    // if (
    //   new Date(moment(filterValues.startDate).format("YYYY-MM-DD")) <=
    //     new Date(item.specificDate) &&
    //   new Date(item.specificDate) <=
    //     new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
    // ) {
    //   return true;
    // }
    if (
      new Date(moment(filterValues.endDate).format("YYYY-MM-DD")) >=
      new Date(moment(item.endDate).format("YYYY-MM-DD"))
    ) {
      if (
        new Date(moment(item.endDate).format("YYYY-MM-DD")) >=
        new Date(moment(filterValues.startDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        new Date(moment(item.beginDate).format("YYYY-MM-DD")) <=
        new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
};

//logic for handling the search and filter
export const filterProviders = (
  filterValues: ProviderFilters,
  searchVal: string,
  data: ProviderForms[]
): ProviderForms[] => {
  return canFilterProviders(filterValues, searchVal)
    ? data.filter((item: ProviderForms) => {
        return filterSingleProvider(item, filterValues, searchVal);
      })
    : data;
};
//
