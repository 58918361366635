import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { BusinessUnitInitialState, OrgBU } from "../../../types/company";
import { setResponseValue } from "../api-response/api-response";
import CompanyUserService from "../../../service/org.service";

const initialState: BusinessUnitInitialState = {
  businessUnits: [],
  dataChange: false,
};

const businessUnitsSlice = createSlice({
  name: "businessUnitsSlice",
  initialState: initialState,
  reducers: {
    setBusinessUnitsDataChange: (state, action: PayloadAction<null>) => {
      state.dataChange = !state.dataChange;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllBusinessUnits.fulfilled,
      (state, action: PayloadAction<OrgBU[] | undefined>) => {
        if (action.payload) {
          return {
            ...state,
            businessUnits: action.payload,
          };
        }
      }
    );
  },
});

//fetches the business units
export const getAllBusinessUnits = createAsyncThunk(
  "businessUnitsSlice/getAllBusinessUnits",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, data } = await CompanyUserService.getAllBusinessUnits();
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setBusinessUnitsDataChange } = businessUnitsSlice.actions;

export default businessUnitsSlice;
