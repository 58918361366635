import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { resetServerContext } from "react-beautiful-dnd";
import { ReverseList } from "../../../helpers/reverse-list/reverse-list";
import ClientService from "../../../service/client.service";
import SchedulesService from "../../../service/schedules.service";
import { ClientForms } from "../../../types/client";
import { BookedSchedule, 
  // IBillableActivity, 
  // ICancellationReasons, 
  // INonBillableActivity 
} from "../../../types/schedule";
import { setResponseValue } from "../api-response/api-response";
import moment from "moment";

export interface ScheduleList {
  listData: ClientForms[];
  listDataChange: boolean;
  bookedListData: BookedSchedule[];
  BookedStore: {
    Resetdata: boolean;
    localBookedStore: any[];
  };
  loader:boolean;
  // CancelReason: ICancellationReasons[];
  // BillableActivity: IBillableActivity[];
  // NonBillableActivity: INonBillableActivity[];
}

const initialState: ScheduleList = {
  listData: [],
  bookedListData: [],
  listDataChange: false,
  BookedStore: {
    Resetdata: false,
    localBookedStore: [],
  },
  loader:false,
  // CancelReason: [],
  // BillableActivity: [],
  // NonBillableActivity: [],
};

const scheduleListSlice = createSlice({
  name: "scheduleList",
  initialState: initialState,
  reducers: {
    setLocalBookedStore: (state, action) => {
      let localBooked;
      let BookedData = [];


    
      if (action.payload.resetLocal) {
        
        let data = [...state.BookedStore.localBookedStore].filter(
          (item) => item.id
        );
        BookedData = [...data];
        // BookedData = [...state.bookedListData];
      } else {
        localBooked = {
          bookedClientId: action.payload.payload.bookedClientId,
          bookedProviderId: action.payload.payload.bookedProviderId,
          // userTodayDate: action.payload.userTodayDate,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          repeatService: 0,
          fromTime: action.payload.payload.fromTime,
          toTime: action.payload.payload.toTime,
          specificDate: moment(action.payload.payload.specificDate).format(
            "YYYY-MM-DD"
          ),
          createdBy: action.payload.payload.createdBy,
          repeatWorkDays: action.payload.payload.repeatWorkDays.toString(),
          serviceType: action.payload.payload.serviceType,
          serviceLocation: action.payload.payload.serviceLocation,
          description: action.payload.payload.description,
          authId: action.payload.payload.authId,
          authCode: action.payload.payload.authCode,
          status: "Created",
          whoCancelled: null,
          cancellationReason: null,
          repeatCustomEnd:
            action.payload.payload.repeatCustomEnd ||
            moment(action.payload.payload.specificDate).format("YYYY-MM-DD"),
        };
        BookedData = [...state.BookedStore.localBookedStore, localBooked];
      }

      return {
        ...state,

        BookedStore: {
          Resetdata: action.payload.resetLocal,
          localBookedStore: [...BookedData],
        },
      };
    },
    setLoader :(state,action)=>{
      return{
        ...state,
        loader : !state.loader
       }
    },

    // setResetBookedData: (state, action) => {
    //   if (action.payload) {
    //     const dataBooked = state.bookedListData.filter((item) => 
    //       !action.payload.includes(item.id)
    //     );
    //     return {...state,bookedListData:dataBooked};
    //   }
    //   return {...state};
    // },

  },
  extraReducers: (builder) => {
    builder.addCase(
      GetScheduleList.fulfilled,
      (
        state: ScheduleList,
        action: PayloadAction<ClientForms[] | undefined>
      ) => {
        if (action.payload) {
          const scheduleList: ClientForms[] = action.payload.filter(
            (item: ClientForms) =>
              item.clientContactDetails &&
              item.availabilityDetails &&
              item.authorizations.length > 0 &&
              item.clientInsurance.length > 0
          );

          return {
            ...state,
            listData: scheduleList,
          };
        }
        return state;
      }
    );
    builder.addCase(
      getAllBookingsData.fulfilled,
      (state, action: PayloadAction<BookedSchedule[] | undefined>) => {
        if (action.payload) {
          const reversedList = ReverseList<BookedSchedule>(action.payload);
          return {
            ...state,
            bookedListData: reversedList,
          };
        }
        return state;
      }
    );
    /* Deprecate this!! 
    builder.addCase(getAllCancelReasons.fulfilled, (state,action)=>{
      if(action.payload !== undefined){
        return {
          ...state,
          CancelReason: action.payload
        }
      }
    });
    builder.addCase(getAllBillableActivity.fulfilled, (state,action)=>{
      if(action.payload !== undefined){
        return {
          ...state,
          BillableActivity: action.payload
        }
      }
    });
    builder.addCase(getAllNonBillableActivity.fulfilled, (state,action)=>{
      if(action.payload !== undefined){
        return {
          ...state,
          NonBillableActivity: action.payload
        }
      }
    });
    */
  },
});

export const GetScheduleList = createAsyncThunk(
  "scheduleList/getScheduleList",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    dispatch(setLoader({}));
    try {
      const { data } = await ClientService.getAllClient();

      if (data.status) {
        dispatch(setLoader({}))
        return data.data;
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//For all schedules for all clients & all employees
export const getAllBookingsData = createAsyncThunk(
  "schedulesList/GetAllBookings",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));

    try {
      const { data, status } = await SchedulesService.getAllBookings();
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

/* deprecate this! use DataCatalog Slice!!
//For all Cancel Reasons
export const getAllCancelReasons = createAsyncThunk(
  "scheduleList/getAllCancelReasons",
  async(_, { getState, dispatch }) =>{
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const {data } = await DataCatalogService.GetAllClientCancelReasons();
      return data;      
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//For All Billable Activity
export const getAllBillableActivity = createAsyncThunk(
  "scheduleList/GetAllBillableActivity",
  async(_, { getState, dispatch }) =>{
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const {data} = await DataCatalogService.GetAllBillableActivities();     
      return data;      
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//For All Non Billable Activity
export const getAllNonBillableActivity = createAsyncThunk(
  "scheduleList/GetAllNonBillableActivity",
  async(_, { getState, dispatch }) =>{
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const {data} = await DataCatalogService.GetAllNonBillableActivities();     
      return data;      
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);*/

export const { 
  setLocalBookedStore, 
  setLoader,
  // setResetBookedData 
} = scheduleListSlice.actions;

export default scheduleListSlice;
