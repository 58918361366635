import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useUser } from "@descope/react-sdk";
import * as Sentry from "@sentry/react";
import { CustomThemeProvider } from "./config/ThemeConfig";
import AppRoutes from "./AppRoutes";
import "./assets/style/App.scss";

const App = () => {
  const { user } = useUser();
  useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user?.email });
    }
  }, [user]);

  return (
    <React.Fragment>
      <CustomThemeProvider>
        <Router>
          <AppRoutes />
        </Router>
        {/* {inviteModal && (
            <InviteModal onClose={() => dispatch(setInviteModal({}))} />
          )} */}
      </CustomThemeProvider>
    </React.Fragment>
  );
};

export default App;
