import { createSlice } from "@reduxjs/toolkit";

interface DraggingStateInitialState {
  dragging: boolean;
}

const initialState: DraggingStateInitialState = {
  dragging: false,
};

const draggingStateSlice = createSlice({
  name: "draggingState",
  initialState: initialState,
  reducers: {
    setDraggingAtomState: (state, action) => {
      return {
        dragging: action.payload,
      };
    },
  },
});

export const { setDraggingAtomState } = draggingStateSlice.actions;

export default draggingStateSlice;
