export enum DefaultSessionForm {
  SIGN_IN_NOTE = "Sign In Note",
  SIGN_OUT_NOTE = "Sign Out Note",
}

export enum FormRequestStatus {
  Sent = "Sent",
  Started = "Started",
  Saved = "Saved",
  Reminded = "Reminded", //if reminder step is added: reminded
  Submitted = "Submitted",
  Evaluated = "Evaluated", //if evaluation step is added: evaluated
  Cancelled = "Cancelled", //for admin only, to mark it as cancell
  Mapped = "Mapped", //if mapping step is added; mapped
  Error = "Error", // exception default status
  Complete = "Complete",
  InReview = "In Review",
}

export enum SessionType {
  OneOnOne = "1-on-1",
  GroupSession = "Group Session",
  EmployeeSession = "Employee Session",
}

// export enum SessionStatus {
//   NOT_STARTED = "Not Started",
//   SESSION_STARTED = "Session Started",
//   SIGN_OUT_PROCEDURE = "Sign Out Procedure",
//   FINALIZE_SESSION_NOTE = "Finalize Session Note",
//   REVIEW_COMPLETE = "Review Complete"
// }

//must same as in the backend
export enum SessionStatus {
  NOT_STARTED = "Not Started",
  STARTED = "Started",
  CLOSED = "Closed",
  INREVIEW = "In Review",
  FINALIZED = "Finalized",
}

