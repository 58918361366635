export const isValidFileUploaded = (file: any) => {
  const validExtensions = ["png", "jpeg", "jpg"];
  const fileExtension = file.type.split("/")[1];
  return validExtensions.includes(fileExtension);
};

export const returnFileName = (name: string): string => {
  let count: number = 0;
  let returnVal = "";
  for (let i = 0; i < name?.length; i++) {
    if (name.charCodeAt(i) > 47 && name.charCodeAt(i) < 58 && count === 0) {
    } else {
      returnVal += name[i];
      count++;
    }
  }
  return returnVal;
};
