import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

type LoaderProps = {
  isShow: boolean;
};

const Loader: React.FC<LoaderProps> = ({ isShow }) => {
  return (
    <div>
      <Backdrop
        sx={{ color: "skyBlue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShow}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loader;
