// surveyComponent code

export const surveyComponent = {
  markup: ` <!DOCTYPE html>
  <html>
    <body>
    <embed id="embedFrame" src="" />
    <script>
      let embedFrame = document.getElementById("embedFrame");
      embedFrame.setAttribute("width", window.innerWidth);
      embedFrame.setAttribute("height", window.innerHeight);
    </script>
    </body>
  </html>`,
};
