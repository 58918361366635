import { createSlice } from "@reduxjs/toolkit";
import { MessageFilter } from "../../../types/message";


const initialState: MessageFilter = {
  businessUnit: [],
  insuranceProvider: [],
  clientStatus: [],
  providerStatus: [],
  ScheduleStatus: "",
  intakeStage: [],
  clientConcerns: [],
  onBoardingStage: [],
  providerTasks: [],
  startDate: null,
  endDate: null,
  // age:null
};

const messageFilterSlice = createSlice({
  name: "messageFilterSlice",
  initialState: initialState,
  reducers: {
    setMessageFilterValues: (state, action) => {
      
      return {
        ...action.payload,
      };
    },
  },
});

export const { setMessageFilterValues } = messageFilterSlice.actions;

export default messageFilterSlice;