import { createSlice } from "@reduxjs/toolkit";
import {availability} from "../../../constants/availability";
import { ISelectedAvailability } from "../../../types/availability";

const initialState: ISelectedAvailability = {
  ...availability,
};

const availabilitySlice = createSlice({
  name: "availability",
  initialState: initialState,
  reducers: {
    setSelectedAvailabiltyValues: (state, action) => {
  
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
  },
});

export const { setSelectedAvailabiltyValues } = availabilitySlice.actions;

export default availabilitySlice;
