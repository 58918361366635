import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IUserProfile}  from "../../../types/user";

const initialState: IUserProfile= {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  emailVerified: false,
  businessUnit: "",
  // authSystemName: "",
  authTenantId: "",
  authUserId: "",
  authRole: "",
  createdBy: "",
  updatedBy: "",
  profilePictureUrl: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  status: "",
  clientIds:[]
};

const userSlice = createSlice({
  name: "companyUserSlice",
  initialState: initialState,
  reducers: {
    setUserValue: (
      state,
      action: PayloadAction<{ name: string; value: string | string[] }>
    ) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    setUserStateValue: (state, action: PayloadAction<IUserProfile>) => {
      return {
        ...action.payload,
      };
    },
    resetInitialState: (state, action: PayloadAction<null>) => {
      return {
        ...initialState,
      };
    },
    setRole: (state, action) => {
      state.authRole = action.payload;
    },
  },
});

// export const postCompanyUser = createAsyncThunk(
//   "companyUserSlice/postCompanyUser",
//   async ({ orgId }: { orgId: string }, { getState, dispatch }) => {
//     dispatch(setResponseValue({ name: "pending", value: true }));
//     const { companyUserSlice } = getState() as { companyUserSlice: User };
//     try {
//       const { message, status } = await CompanyUserService.postUser(
//         companyUserSlice,
//         orgId
//       );
//       if (status) {
//         dispatch(setOrganizationDataChange(null));
//         dispatch(setResponseValue({ name: "success", value: true }));
//         dispatch(setResponseValue({ name: "message", value: message }));
//       }
//     } catch (e: any) {
//       dispatch(setResponseValue({ name: "error", value: true }));
//       dispatch(
//         setResponseValue({
//           name: "message",
//           value: e.message,
//         })
//       );
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   }
// );

// export const updateCompanyUser = createAsyncThunk(
//   "companyUserSlice/updateCompanyUser",
//   async ({ orgId }: { orgId: string }, { getState, dispatch }) => {
//     dispatch(setResponseValue({ name: "pending", value: true }));
//     const { companyUserSlice } = getState() as { companyUserSlice: User };
//     try {
//       const { status, message } = await CompanyUserService.updateCompanyUser(
//         companyUserSlice,
//         orgId
//       );
//       if (status) {
//         dispatch(setOrganizationDataChange(null));
//         dispatch(setResponseValue({ name: "success", value: true }));
//         dispatch(setResponseValue({ name: "message", value: message }));
//       }
//     } catch (e: any) {
//       dispatch(setResponseValue({ name: "error", value: true }));
//       dispatch(
//         setResponseValue({
//           name: "message",
//           value: e.message,
//         })
//       );
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   }
// );

// export const deleteCompanyUser = createAsyncThunk(
//   "companyUserSlice/deleteCompanyUser",
//   async ({ userId }: { userId: string }, { getState, dispatch }) => {
//     dispatch(setResponseValue({ name: "pending", value: true }));
//     try {
//       const { status, message } = await CompanyUserService.deleteCompanyUser(
//         userId
//       );
//       if (status) {
//         dispatch(setOrganizationDataChange(null));
//         dispatch(setResponseValue({ name: "success", value: true }));
//         dispatch(setResponseValue({ name: "message", value: message }));
//       }
//     } catch (e: any) {
//       dispatch(setResponseValue({ name: "error", value: true }));
//       dispatch(
//         setResponseValue({
//           name: "message",
//           value: e.message,
//         })
//       );
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   }
// );

export const {
  resetInitialState,
  setUserValue,
  setUserStateValue,
  setRole,
} = userSlice.actions;

export default userSlice;
