import React, { useState, Dispatch } from "react";
import { ProviderForms } from "../../../types/provider";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { filterMaps } from "../../../helpers/maps-filter-helpers/maps-filter-helper";
import { MapsFilter } from "../../../types/map";

interface MapProviderListProps {
  providerListData: ProviderForms[];
  providerFunc: (item: ProviderForms) => void;
  activeTab: boolean;
  setSelectedProviderIds: Dispatch<React.SetStateAction<string[]>>;
  setBookingChange: Dispatch<React.SetStateAction<boolean>>;
  providerSelectdData: (item: ProviderForms[]) => void;
  selectedProvider: ProviderForms[];
}


const MapProviderList: React.FC<MapProviderListProps> = ({
  providerListData,
  providerFunc,
  activeTab,
  setSelectedProviderIds,
  setBookingChange,
  providerSelectdData,
  selectedProvider,
}) => {
  //#region variable region

  // eslint-disable-next-line
  const [selectedList, setSelectedList] = useState<string[]>([]);

  //Variable stores filtervalues
  const filterValues = useSelector<RootState, MapsFilter>(
    (state) => state.mapsFilterSlice
  );

  //Variable stores searchValues
  const searchValue = useSelector<RootState, string>(
    (state) => state.activeStep.searchValue
  );

  // selectedProvider, providerSelectdData
  // eslint-disable-next-line
  const [selectedProviderlist, setSelectedProviderlist] = useState<string[]>(
    []
  );
  //#endregion

  // #region methods region

  //handles provider Selection
  const handleClick = (item: ProviderForms) => {
   
    setSelectedProviderlist([item.employmentDetails.id]);
    providerFunc(item);
    // }
    setBookingChange(true);

    if (!activeTab) {
      const index = selectedProvider.findIndex(
        (obj) => obj.employmentDetails.id === item.employmentDetails?.id
      );
      if (index >= 0) {
        providerSelectdData(selectedProvider.filter((o, i) => i !== index));
      } else {
        providerSelectdData([...selectedProvider, item]);
      }
    }

  };

  //#endregion


  return (
    <div className="mapUserList">
      {providerListData.length > 0 &&
        filterMaps<ProviderForms>(
          filterValues,
          searchValue,
          providerListData
        ).map((item: ProviderForms, index: number) => {
          let remainingHours = 0;
          let bookedHours = 0;
          item.availabilityDetails.forEach((details: any) => {
            remainingHours = details.totalRemainingHours
              ? remainingHours + details.totalRemainingHours
              : remainingHours;

            bookedHours = details.totalBookedHours
              ? bookedHours + details.totalBookedHours
              : bookedHours;
          });

          const providerIndex = selectedProvider.findIndex((obj) => {
            return obj.employmentDetails?.id === item.employmentDetails?.id;
          });

          return (
            <div
              className={
                !activeTab
                  ? providerIndex >= 0
                    ? "fromGroup-chip-active"
                    : "userMapCart"
                  : selectedProviderlist.includes(item.employmentDetails.id)
                  ? "fromGroup-chip-active"
                  : "userMapCart "
              }
         
              key={index}
              onClick={() => handleClick(item)}
            >
              <div className="cardLevelBlock">
                <table>
                  <thead className="map-thead">
                    <tr>
                      <th>
                        <span className="userMapCart__img">
                          <img
                            src={
                              item?.employmentDetails?.providerProfile?.url ||
                              ProviderImg
                            }
                            className="userSmallCircle"
                            alt=""
                          />
                        </span>
                      </th>
                      <th>
                        <div className="userMapCart__name">
                          <span>
                            {item?.employmentDetails?.firstName}{" "}
                            {item?.employmentDetails?.lastName}{" "}
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MapProviderList;
