import {
  Insurance,
  Miles,
  ProviderType,
  MatchOption,
  // ICancellationReasons, IBillableActivity, INonBillableActivity,
  ICalenderlist,
  IBookedSchedulerMatch,
  Durations,
  ReBookingOptionsResult,
} from "../types/schedule";
import {
  defaultClientAvailabilityDetail,
  defaultUtilization,
} from "../constants/availability";
import { schedules } from "./planning";

//Distance Constant
export const XMiles: Miles[] = [
  { key: 0, value: 10 },
  { key: 1, value: 25 },
];

//Duration Constant
export const XDurations: Durations[] = [
  { key: 0, value: 1 },
  { key: 1, value: 2 },
  { key: 2, value: 4 },
];

//Providers Types Constants
// export const ProviderTypes: ProviderType[] = [
//   { key: 0, value: "BT (No Exp)" },
//   { key: 1, value: "BT (40 hours)" },
//   { key: 2, value: "BT (Sitting Exam)" },
//   { key: 3, value: "BT (Exp in field)" },
//   { key: 4, value: "RBT" },
//   { key: 5, value: "BCaBA" },
//   { key: 6, value: "BCBA" },
//   { key: 7, value: "BCBA-D" },
//   { key: 8, value: "ABAT" },
//   { key: 9, value: "QASP-S" },
//   { key: 10, value: "QBA" },
//   { key: 11, value: "BCAT" },
//   { key: 12, value: "BCAP" },
//   { key: 13, value: "Operations/Admin" },
// ];

export const ProviderTypes: ProviderType = {
  id: "",
  conceptValue: "",
};

//provider status
export const ProviderStatuses = [
  { key: 0, value: "Onboarding" },
  { key: 1, value: "Established" },
];

//Timings For Client Availability constant
export const Timings: string[] = [
  "Sundays (07:00 AM - 06:00 PM)",
  "Mondays (07:00 AM - 06:00 PM)",
  "Tuesdays (07:00 AM - 06:00 PM)",
  "Wednesdays (07:00 AM - 06:00 PM)",
  "Thursdays (07:00 AM - 06:00 PM)",
  "Fridays (07:00 AM - 06:00 PM)",
  "Saturdays (07:00 AM - 06:00 PM)",
];

//Insurance Authorization Constant
export const InsuranceAuthorization: Insurance[] = [
  { key: 0, value: "Aetna (91573)" },
  { key: 1, value: "Medicaid (91571)" },
];

//Schedules Slice Initial State Constant
export const SchedulesInitialState: MatchOption = {
  insuranceAuthorization: "",
  selectedAvailability: [],
  availability: defaultClientAvailabilityDetail,
  insuranceProvider: "",
  providerOnboardingStages: [],
  providerOnboardingStatus: "",
  miles: 0,
  providerType: [],
  specialities: [],
  selectedProvider: [],
  businessUnit: "", //id version
  businessUnitName: "", // name version

  //Allocation Preference
  duration: 0, //in hours
  timeOfDay: "Any", //morning, afternoon, any

  // newSessionsPerWeek: 0,  //# of sessions per week we can allocate
  newSessionsPerDay: 1, //# of sessions we can allocate per day
  newSessionsPerDayMorning: 0, //# of sessions we can allocate in the morning
  newSessionsPerDayAfternoon: 0, //# of sessions we can allocate in the morning

  //client auth matching params
  clientAuthorization: "",
  clientAuthStartDate: "",
  clientAuthEndDate: "",
  clientAuthId: "",
  clientAuthInsuranceName: "",
  clientSelectedAuthorizationName: "",
  clientAuthCode: "",
  clientAuthUnits: "",
  clientAuthCodeHours: 0, //for visibility purpose
  clientAuthCodeRemainingHours: 0, //for visibility purpose

  clientAuthUtilization: defaultUtilization,
  clientUtilization: defaultUtilization,
  providerUtilization: defaultUtilization,
  earliestStartTime: "08:00 AM",
};

// export const CancellationReasons = [
//   "Sick",
//   "Less than 24 Hours Notice",
//   "Planned Cancellation",
//   "No Show",
// ];

// //Cancellation reasons
// export const CancellationReasons: ICancellationReasons = {
//   id: "",
//   conceptValue: "",
// }

// //Billable Activities
// export const BillableActivities: IBillableActivity = {
//   id: "",
//   conceptValue: "",
// }

// //Non Billable Activities
// export const NonBillableActivities: INonBillableActivity = {
//   id: "",
//   conceptValue: "",
// }

export const WeekDaysFirstletter = ["S", "M", "T", "W", "T", "F", "S"];

export const WeekDaysFullName = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const WeekDays3Letters = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

export const RepeatServices: string[] = [
  "Single Appointment",
  "For Current Month",
  "For Auth Period",
];

export const CancelOptions: string[] = [
  "Current Event",
  "Current & Future Events",
];

export const UpdateOptions: string[] = [
  "Current Event",
  "Current & Future Events",
];

export const RepeatOptions = ["End of Authorization", "Custom Date"];

export const WhoCancelled: string[] = ["Client", "Employee"];

// export const editServices: string[] = ["For Current Month", "For Auth Period"];

export const ServiceTypes: string[] = [
  "Assessment",
  "Direct Therapy",
  "Parent Training",
  "Protocol modification",
];

export const OnboardingStage = [
  { key: 1, value: "New Hire/Signed Offer Letter" },
  { key: 2, value: "HR Paperwork Pending" },
  { key: 3, value: "Credentialing Paperwork Pending" },
  { key: 4, value: "Credentialing Packet: Complete" },
  { key: 5, value: "Credentialing: Apps Submitted to All Payers" },
  { key: 6, value: "Onboarding/Training in Process" },
  { key: 7, value: "Credentialing: Complete" },
];

//Enum for Colors
/*let ServiceColor = {
  Assessment: 0,
  DirectTherapy: 1,
  ParentTraining: 2,
  ProtocolModification: 3,
};*/

//convertTime
export const convertTime = (time: string): string => {
  let newTime = time.split(" ");

  let newHours = newTime[0].split(":");
  let retHou: number = parseInt(newHours[0]);
  let retMin: number = parseInt(newHours[1]);

  if (
    (newTime[1] === "PM" || newTime[1] === "pm") &&
    parseInt(newHours[0]) < 12
  ) {
    retHou = 12 + parseInt(newHours[0]);
    // retMin = 30 + parseInt(newHours[1]);
    if (retMin === 30) {
      retHou = retHou + 1;
      retMin = 0;
    } else {
      retMin = 30 + parseInt(newHours[1]);
    }
  }
  return `${retHou}:${retMin < 10 ? "00" : retMin}`;
};

export const convertTime_1 = (time: string): string => {
  let newTime = time.split(" ");

  let newHours = newTime[0].split(":");
  let retHou: number = parseInt(newHours[0]);
  let retMin: number = parseInt(newHours[1]);

  retMin = 30 + parseInt(newHours[1]);
  // }
  if (retHou === 0 && retMin === 30) {
    retHou = retHou + 18;
    retMin = retMin + 30;
  }
  if (retMin >= 60) {
    retHou = retHou - 5;
    retMin = 0;
  } else {
    retHou = retHou - 6;
  }
  return `${retHou}:${retMin < 10 ? "00" : retMin}`;
};

export const convertTime_2 = (time: string): string => {
  let newTime = time.split(" ");

  let newHours = newTime[0].split(":");
  let retHou: number = parseInt(newHours[0]);
  let retMin: number = parseInt(newHours[1]);
  if (
    (newTime[1] === "PM" || newTime[1] === "pm") &&
    parseInt(newHours[0]) < 12
  ) {
    retHou = 12 + parseInt(newHours[0]);
    retMin = 30 + parseInt(newHours[1]);
  }
  return `${retHou - 5}:${retMin}`;
};

// convert Time format 12 to 24
export const convertTime12to24 = (time12h: any) => {
  const [time, modifier] = time12h?.split(" ");

  let [hours, minutes] = time?.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}:00`;
};

//convert Time 12 to 24 adjest UTC format 6 hours
export const convertTime12to24AM_PM = (time12h: any) => {
  const [time, modifier] = time12h?.split(" ");

  let [hours, minutes] = time?.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  minutes = parseInt(minutes) + 30;
  if (minutes >= 60) {
    hours = parseInt(hours) - 5;
    minutes = "00";
  } else {
    hours = parseInt(hours) - 6;
  }

  return `${hours}:${minutes}:00`;
};

//comparing Time
export const checkTimes = (left: string, right: string): boolean => {
  let leftOne = left.split(":");
  let rightOne = right.split(":");

  let l1 = parseInt(leftOne[0]);
  let r1 = parseInt(rightOne[0]);
  let l2 = parseInt(leftOne[1]);
  let r2 = parseInt(rightOne[1]);
  if (l1 > r1) {
    return true;
  } else if (l1 === r1 && l2 >= r2) {
    return true;
  }
  return false;
};

export const calenderList: ICalenderlist = {
  name: "",
  id: "",
  GroupId: 0,
  color: "",
  type: "",
};
export const DefaultRebookingOptionsResult: ReBookingOptionsResult = {
  errorMessage: "",
  baseProvidersCount: 0,
  beginDate: "",
  endDate: "",
  authStartDate: "",
  authEndDate: "",
  options: {
    // providerOptions: [],
    dateOptions: [],
    uniqueProviderIdsCount: 0,
    uniqueLimitProviderIdsCount: 0,
    uniqueDatesCount: 0,
  },
};

export const bookingScheduler: IBookedSchedulerMatch = {
  listForCalendar: [calenderList],
  clientBooked: [schedules],
  providerBooked: [schedules],
  rebookingOptions: DefaultRebookingOptionsResult,
  isLoading: false,
};

export const NotAvailWork = [
  {
    DayNo: 1, //Sunday
    EndTime: "24:00",
    StartTime: "24:00",
  },
  {
    DayNo: 2,
    EndTime: "21:00",
    StartTime: "06:00",
  },
  {
    DayNo: 3,
    EndTime: "21:00",
    StartTime: "06:00",
  },
  {
    DayNo: 4,
    EndTime: "21:00",
    StartTime: "06:00",
  },
  {
    DayNo: 5,
    EndTime: "21:00",
    StartTime: "06:00",
  },
  {
    DayNo: 6,
    EndTime: "21:00",
    StartTime: "06:00",
  },
  {
    DayNo: 7, //Sat
    EndTime: "24:00",
    StartTime: "24:00",
  },
];

export const earliestStartTime = [
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 pm",
];
