import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { ITaskState } from "../../../types/trello-board";
import Board from "../../trello-board/Board";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import {
  moveClientCardToAnotherBoard,
  setClientTasks,
} from "../../../redux/features/client-main/client-board-slice";
import { OrgData } from "../../../types/company";
import { OrgService } from "../../../constants/company-user";
import { Board as BoardI } from "../../../types/board";

const Boards = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
`;

interface ClientBoardProps {
  callbacks: ((clientId: string) => Promise<void>)[];
  chatCommand: (id: string) => void;
}

const BoardStage: React.FC<ClientBoardProps> = ({ callbacks, chatCommand }) => {
  // #region variables region
  const org = useSelector<RootState, OrgData>(
    (state) => state.organizationSlice.orgData
  );

  //intake stages
  const initIntakeStages = useSelector<RootState, any[]>(
    (state) => state.clientIntakeStage.stages
  );
  const [intakeStages, setIntakeStages] = useState(initIntakeStages);

  //client boards
  const inittasks = useSelector<RootState, ITaskState>(
    (state) => state.clientBoardSlice
  );
  const intakeBoardInfo = useSelector<RootState, BoardI>(
    (state) => state.clientIntakeStage
  );

  const [tasks, setTasks] = useState(inittasks);

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //handles drop logic
  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    if (!destination) return;

    // Move Tasks
    if (source.droppableId === destination?.droppableId) {
      // Move a task in same board.
      let reOrderedTasks = [...tasks[destination.droppableId]];
      const sourceTask = {
        ...reOrderedTasks[source.index],
        // boardColumnUpdatedAt: new Date().toString(),
      };
      reOrderedTasks.splice(source.index, 1);
      reOrderedTasks.splice(destination?.index, 0, sourceTask);
      dispatch(
        setClientTasks({
          ...tasks,
          [destination?.droppableId]: reOrderedTasks,
        })
      );
      const fromBoardId = intakeStages.find(
        (item) => item.stageName === source.droppableId
      );

      if (fromBoardId) {
        dispatch(
          moveClientCardToAnotherBoard({
            fromBoardId: fromBoardId.id,
            toBoardId: fromBoardId.id, //null,
            cardId: draggableId,
            index: destination.index,
          })
        );
      }
    } else if (source?.droppableId !== destination?.droppableId) {
      // Move task to different board
      let modifiedSourceTasks = [...tasks[source.droppableId]];
      let modifiedDestinationTasks = [...tasks[destination?.droppableId]];
      const sourceTask = {
        ...modifiedSourceTasks[source.index],
        boardColumnUpdatedAt: new Date().toString(),
      };
      modifiedSourceTasks.splice(source.index, 1);
      modifiedDestinationTasks.splice(destination?.index, 0, sourceTask);
      dispatch(
        setClientTasks({
          ...tasks,
          [source.droppableId]: modifiedSourceTasks,
          [destination?.droppableId]: modifiedDestinationTasks,
        })
      );
      const fromBoardId = intakeStages.find(
        (item) => item.stageName === source.droppableId
      );
      const toBoardId = intakeStages.find(
        (item) => item.stageName === destination.droppableId
      );

      if (fromBoardId && toBoardId) {
        dispatch(
          moveClientCardToAnotherBoard({
            fromBoardId: fromBoardId.id,
            toBoardId: toBoardId.id,
            cardId: draggableId,
            index: destination.index,
          })
        );
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (
      org.orgService &&
      org.orgService.length === 1 &&
      org.orgService.includes(OrgService.MentalHealth)
    ) {
      const singleStage = initIntakeStages[0];
      setIntakeStages([singleStage]);
      setTasks({ [singleStage.stageName]: inittasks[singleStage.stageName] });
    } else {
      setIntakeStages(initIntakeStages); //must have!!
      setTasks(inittasks);
    }
  }, [initIntakeStages, org, inittasks]);

  // #endregion

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={"boards"}
        type={"boards"}
        direction={"horizontal"}
      >
        {({ innerRef, droppableProps, placeholder }) => (
          <Boards ref={innerRef} {...droppableProps}>
            <div className="allStageCards">
              {Object.keys(tasks).map((stageName, index) => (
                <Board
                  key={stageName}
                  index={index}
                  //boardName={stageName}
                  stage={intakeStages?.find(
                    (stage: any) => stage.stageName === stageName
                  )}
                  tasks={tasks[stageName]}
                  callbacks={callbacks}
                  chatCommand={chatCommand}
                />
              ))}
            </div>
            {placeholder}
          </Boards>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default BoardStage;
