import React, { useEffect, useState } from "react";
import { ProviderForms } from "../../../types/provider";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import VerifiedAddressImg from "../../../assets/images/images-svg/verifiedAddress.svg";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { OrgBU } from "../../../types/company";
import { useNavigate } from "react-router-dom";
import { IHashObj } from "../../../types/Common";
import { getBusinessUnitName } from "../../../utils/EntityUtils";

interface MapProviderProfileProps {
  providerDatapopUP: ProviderForms;
}
const MapProviderProfile: React.FC<MapProviderProfileProps> = ({
  providerDatapopUP,
}) => {
  //#region variable region
  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //stores insurance
  const [insurance, setInsurance] = useState<string[]>([]);

  const navigate = useNavigate();

  //#endregion

  // #region methods region

  //displays provider data on onClick
  const handleChange = () => {
    navigate(`/provider/${providerDatapopUP.employmentDetails.id}`);
  };

  //#endregion

  // #region useEffect region
  useEffect(() => {
    if (providerDatapopUP?.providerInsurance) {
      let newProviderInsurance: IHashObj = {};
      providerDatapopUP.providerInsurance.forEach((item) => {
        if (newProviderInsurance[item.name]) {
          newProviderInsurance[item.name] += 1;
        } else {
          newProviderInsurance[item.name] = 1;
        }
      });
      setInsurance(Object.keys(newProviderInsurance));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerDatapopUP.providerInsurance.length]);

  //#endregion

  return (
    <>
      <div className="mapUserInfo__img">
        <img
          src={
            providerDatapopUP?.employmentDetails?.providerProfile?.url ||
            ProviderImg
          }
          alt="profileImage"
        />
      </div>
      <div className="mapUserInfo__details">
        <div onClick={() => handleChange()}>
          <h4 className="mapUserInfo__name activeLink">
            {providerDatapopUP?.employmentDetails?.firstName}{" "}
            {providerDatapopUP?.employmentDetails?.lastName}
          </h4>
        </div>

        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">Employee Type</h6>
          <p>{providerDatapopUP?.employmentDetails?.type}</p>
        </div>
        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">Business Unit</h6>
          <p>
            {getBusinessUnitName(
              businessUnits,
              providerDatapopUP?.employmentDetails?.businessUnit
            )?.map((res: string, index: number) => {
              return <span>{`${index ? "," : ""} ${res}`} </span>;
            })}
          </p>
        </div>
        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">OnBoarding Stage</h6>
          <p>{providerDatapopUP?.employmentDetails?.onBoardingStage}</p>
        </div>
        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">Insurance</h6>
          <p>{insurance.join(", ")}</p>
        </div>
        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">
            Address
            <span>
              <img
                className="verifiedAddress"
                src={VerifiedAddressImg}
                alt="varification logo"
              />
            </span>
          </h6>
          <p>
            {providerDatapopUP?.contactDetails?.address?.fullAddress +
              " " +
              providerDatapopUP?.contactDetails?.city +
              " " +
              providerDatapopUP?.contactDetails?.state +
              " " +
              providerDatapopUP?.contactDetails?.zipCode}
          </p>
        </div>
      </div>
    </>
  );
};

export default MapProviderProfile;
