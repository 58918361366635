import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Popover, TextareaAutosize } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import { AppDispatch, RootState } from "../../../../redux/store";
import ProviderImg from "../../../../assets/images/images-svg/provider-large-vertical.svg";
import { ProviderMessages } from "../../../../types/provider";
import {
  deleteProviderMessageById,
  postProviderMessageById,
} from "../../../../redux/features/providers-main/provider-list-slice";
import ProviderSubEditComments from "./ProviderSubEditComment";
import { dateToFromNowDaily } from "../../../../helpers/reverse-list/reverse-list";

interface IProviderSubComments {
  providerId: string;
}

const ProviderSubComments: React.FC<IProviderSubComments> = ({
  providerId,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //message
  const [message, setMessage] = useState<string>("");

  //error
  const [error, setError] = useState<string>("");

  //store commentId for dispatch
  const [commentId, setCommentId] = useState<string>("");

  //client comments
  const comments = useSelector<RootState, ProviderMessages[]>(
    (state) => state.providerSlice.providerMessages
  );

  //function that returns one. It's used to set the position of the popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  //if true Popover component show
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  //hadle edit popup
  const [isActive, setIsActive] = useState<boolean>(false);

  // #endregion

  // #region methods region

  //Line saperator
  const saperator = (text: string) => {
    return (
      <div>
        {text.split("\n").map((item: string) => {
          return <div key={item}>{item}</div>;
        })}
      </div>
    );
  };

  //post message handler
  const postMessage = () => {
    if (message.trim().length === 0) {
      setError("Please Enter Message");
      return;
    }
    if (message) {
      dispatch(postProviderMessageById({ message, providerId }));
    }
    setMessage("");
  };

  //hadler update comments
  const editMessageHandle = () => {
    setAnchorEl(null);
    setIsActive(true);
  };

  //hadler delete comments
  const deleteMessageHandle = () => {
    dispatch(deleteProviderMessageById({ commentId }));
    setAnchorEl(null);
  };

  //handle the popover
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    commentId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setCommentId(commentId);
    setIsActive(false);
  };

  //component requests to be closed
  const handleClose = () => {
    setAnchorEl(null);
  };

  // #endregion

  return (
    <>
      <Container maxWidth={"md"}>
        <span style={{ color: "red" }}>{error}</span>
        <Box className="commentForm">
          <TextareaAutosize
            value={message}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setMessage(e.target.value);
              setError("");
            }}
            placeholder="Leave a notice..."
            minRows={2}
          />
          <Button
            onClick={() => postMessage()}
            variant="contained"
            className="button"
          >
            Add Notice
          </Button>
        </Box>
        <Box className="commentListBox" id="scroll">
          {comments.map((comment) => {
            return (
              <>
                {comment.commentType === "User" ? (
                  <Box className="commentListItem" key={comment.id}>
                    <Box className="commentListItem__user">
                      <span className="commentListItem__user-img">
                        <img
                          src={
                            comment.fromUser?.profilePictureUrl
                              ? comment.fromUser?.profilePictureUrl
                              : ProviderImg
                          }
                          className="userSmallCircle"
                          alt=""
                        />
                      </span>
                      <div className="commentListItem__user-info">
                        <div className="commentListItem__user-heading">
                          <h5 className="commentListItem__user-name">
                            {comment.fromUser?.firstName
                              ? comment.fromUser?.firstName +
                                " " +
                                comment.fromUser?.lastName
                              : comment.fromName}
                          </h5>
                          <h6 className="commentListItem__user-time">
                            <span>{dateToFromNowDaily(comment.updatedOn)}</span>
                          </h6>
                        </div>
                        {isActive && commentId === comment.id ? (
                          <ProviderSubEditComments
                            providerId={providerId}
                            commentObj={comment}
                            isActiveClose={setIsActive}
                          />
                        ) : (
                          <p className="commentListItem__user-comment">
                            {saperator(comment.message)}
                          </p>
                        )}
                      </div>
                    </Box>
                    <div></div>
                  </Box>
                ) : (
                  <Box className="commentListItem generated" key={comment.id}>
                    <Box className="commentListItem__user">
                      <span className="commentListItem__user-img">
                        <img
                          src={
                            comment.fromUser?.profilePictureUrl
                              ? comment.fromUser?.profilePictureUrl
                              : ProviderImg
                          }
                          className="userSmallCircle"
                          alt=""
                        />
                      </span>
                      <div className="commentListItem__user-info">
                        <div className="commentListItem__user-heading">
                          <h5 className="commentListItem__user-name">
                            {comment.fromUser?.firstName
                              ? comment.fromUser?.firstName +
                                " " +
                                comment.fromUser?.lastName
                              : comment.fromName}
                          </h5>
                          <h6 className="commentListItem__user-time">
                            <span>{dateToFromNowDaily(comment.updatedOn)}</span>
                          </h6>
                        </div>
                        {isActive && commentId === comment.id ? (
                          <ProviderSubEditComments
                            providerId={providerId}
                            commentObj={comment}
                            isActiveClose={setIsActive}
                          />
                        ) : (
                          <p className="commentListItem__user-comment">
                            {saperator(comment.message)}
                          </p>
                        )}
                      </div>
                    </Box>
                    <button
                      style={{
                        backgroundColor: "white",
                        border: "1px solid white",
                        cursor: "pointer",
                      }}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                        handleClick(e, comment.id)
                      }
                    >
                      <MoreVertIcon />
                    </button>

                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <ul className="popoverLinkList">
                        <li onClick={editMessageHandle}>Edit</li>
                        <li onClick={deleteMessageHandle}>Delete</li>
                      </ul>
                    </Popover>
                  </Box>
                )}
              </>
            );
          })}
        </Box>
        {comments.length > 5 ? (
          <Box className="commentList-btn">
            <button
              onClick={() => {
                document.getElementById("scroll")?.scrollBy(0, 100);
              }}
            >
              Load More
            </button>
          </Box>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};
export default ProviderSubComments;
