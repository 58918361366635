import { createSlice } from "@reduxjs/toolkit";
import { defaultPlanningFilter } from "../../../constants/planning";
import { IPlanningFilter } from "../../../types/planning";

export interface IPlanningFilterData {
  PlanningFilter: IPlanningFilter;
  // changedDate: Date;
}

const PlannerFilterData = {
  PlanningFilter: defaultPlanningFilter,
  // changedDate: new Date(),
};

const initialState: IPlanningFilterData = {
  ...PlannerFilterData,
};

const planningFilterSlice = createSlice({
  name: "planningFilterSlice",
  initialState: initialState,
  reducers: {
    setClearFilterValues: (state, action) => {
      return {
        ...initialState,
      };
    },
    setPlanningFilterValues: (state, action) => {
      return {
        ...state,
        PlanningFilter: action.payload,
      };
    },
    // setChangedDate: (state, action) => {
    //   state.changedDate = action.payload;
    // },
  },
});

export const { 
  setPlanningFilterValues, 
  // setChangedDate, 
  setClearFilterValues 
} =
  planningFilterSlice.actions;

export default planningFilterSlice;
