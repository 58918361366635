import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Edit, Upload, Delete, Close } from "@mui/icons-material";
import { useUser } from "@descope/react-sdk";

import BusinessUnitForm from "./business-unit-form/BusinessUnitForm";
import OrganizationEditForm from "./organization-edit-form/OrganizationEditForm";
import DeleteConfirmation from "../../layouts/delete-confirmation/DeleteConfirmation";

import CompanyUserService from "../../service/org.service";

import { AppDispatch, RootState } from "../../redux/store";
import { setResponseValue } from "../../redux/features/api-response/api-response";
import { setBusinessUnitsDataChange } from "../../redux/features/business-unit/business-unit";
import { getOrgInfo } from "../../redux/features/organization/organization-slice";

import { isValidFileUploaded } from "../../utils/Image-validation";
import { getRolesForTenant } from "../../utils/getRolesForTenant";

import { OrgBU, OrgData } from "../../types/company";

import ABAEngineLogo from "../../assets/images/logos/abaengine.png";
import "./CompanyProfile.scss";

const CompanyProfile: React.FC = () => {
  // #region variable region

  //org edit modal controller
  const [orgEditModal, setOrgEditModal] = useState<boolean>(false);

  //org delete modal controller
  const [orgDeleteModal, setOrgDeleteModal] = useState<boolean>(false);

  const org = useSelector<RootState, OrgData>(
    (state) => state.organizationSlice.orgData
  );

  //selected business unit id
  const [businessUnitId, setBusinessUnitId] = useState<string>("");

  //selectedBusinessUnit
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<OrgBU>({
    id: "",
    businessUnitName: "",
    businessUnitAddress: "",
    businessUnitCity: "",
    businessUnitState: "",
    businessUnitZipCode: "",
    businessUnitTimeZone: {
      value: "",
      label: "",
      offset: 0,
      abbrev: "",
      altName: "",
    },
    createdAt: "",
    updatedAt: "",
    tenantId: "",
  });

  //handles business unit edit
  const [isEditBU, setIsEditBU] = useState<boolean>(false);

  //business units
  const orgBuUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //handles BU add form
  const [isBuFormOpen, setIsBuFormOpen] = useState<boolean>(false);

  //handles delete confirmation popup
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  //handle image error
  const [imageError, setImageError] = useState<string>("");

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();
  // #endregion

  const { user } = useUser();
  const currentTenantId = user?.customAttributes?.currentTenantId;
  const currentRoles = useMemo(() => {
    return getRolesForTenant(user, currentTenantId);
  }, [user, currentTenantId]);

  // #region methods region

  //post business unit
  const createBusinessUnit = async (businessUnit: OrgBU) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await CompanyUserService.createBusinessUnit(
        businessUnit
      );
      if (status) {
        dispatch(setBusinessUnitsDataChange(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //update business unit
  const updateBusinessUnit = async (unit: OrgBU) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await CompanyUserService.updateBusinessUnit(
        unit
      );
      if (status) {
        dispatch(setBusinessUnitsDataChange(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //delete business unit
  const deleteBusinessUnit = async (id: string) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } =
        await CompanyUserService.deleteBusinessUnitById(id);
      if (status) {
        dispatch(setBusinessUnitsDataChange(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //handles file upload and change for profile picture.
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const logoFile = e.target.files[0];

      if (isValidFileUploaded(logoFile)) {
        setImageError("");

        try {
          dispatch(setResponseValue({ name: "pending", value: true }));
          const { status, message } =
            await CompanyUserService.updateOrganizationLogo(logoFile);
          if (status) {
            dispatch(setResponseValue({ name: "success", value: true }));
            dispatch(setResponseValue({ name: "message", value: message }));
            dispatch(getOrgInfo({ useLoader: true }));
          }
        } catch (e: any) {
          dispatch(setResponseValue({ name: "error", value: true }));
          dispatch(
            setResponseValue({
              name: "message",
              value: e?.response?.data?.message,
            })
          );
        } finally {
          dispatch(setResponseValue({ name: "pending", value: false }));
        }
      } else {
        setImageError("Please Select Valid Image");
      }
    }
  };

  // delete organization
  const deleteOrganization = async () => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      await CompanyUserService.deleteOrg();
      // window.location.reload(); note: trigger this will trigger private route re-load
      //we just need to redirect url to www.abaengine.com
      window.location.href = "https://www.abaengine.com";
      dispatch(setResponseValue({ name: "success", value: true }));
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: error?.response?.data?.message || error?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  // #endregion

  // #region useEffect region
  useEffect(() => {
    dispatch(
      getOrgInfo({
        useLoader: false,
      })
    );
  }, [dispatch]);
  // #endregion

  return (
    <div className="pageTemplate">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" className="DetailsPageTitle">
            Company Profile
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className="abaCard">
            <Box className="abaCard__header">
              <Typography className="abaCard__title">
                Basic Information
              </Typography>
              <IconButton
                className="detailsCard__headBtn"
                size="small"
                edge="start"
                onClick={() => setOrgEditModal(true)}
              >
                <Edit />
              </IconButton>
            </Box>
            <Box className="abaCard__body">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box className="uploadCompanyProfile">
                    <div className="uploadCompanyProfile__img">
                      <img
                        src={org?.orgLogo?.url || ABAEngineLogo}
                        className="img-fluid"
                        alt="ClientImage"
                      />
                    </div>
                    <Button
                      variant="contained"
                      component="label"
                      className="uploadCompanyProfile__btn"
                      startIcon={<Upload />}
                    >
                      Change Company Logo
                      <input
                        accept="image/png, image/jpeg"
                        type="file"
                        hidden
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleFileChange(e);
                        }}
                      />
                    </Button>
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                        position: "absolute",

                        left: "60px",
                      }}
                    >
                      {imageError}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="fromGroup" style={{ marginBottom: "22px" }}>
                    <InputLabel className="fromLabel">Company Name</InputLabel>
                    <TextField
                      id="CompanyName"
                      value={org?.orgName}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  <Box className="fromGroup" style={{ marginBottom: "22px" }}>
                    <InputLabel className="fromLabel">Company URL</InputLabel>
                    <TextField
                      id="CompanyURL"
                      value={org?.orgUrl}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  <Box className="fromGroup">
                    <InputLabel className="fromLabel">
                      Company Services
                    </InputLabel>
                    <TextField
                      id="CompanyURL"
                      value={org?.orgService.join(", ")}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  <Box className="fromGroup">
                    <InputLabel className="fromLabel">Support Email</InputLabel>
                    <TextField
                      id="supportEmail"
                      value={org?.supportEmail}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="abaCard">
            <Box className="abaCard__header">
              <Typography className="abaCard__title">Business Units</Typography>
            </Box>
            <Box className="abaCard__body">
              <Box style={{ textAlign: "right" }}>
                <Button
                  className="addBtn"
                  onClick={() => setIsBuFormOpen(true)}
                >
                  + Add Business Unit
                </Button>
              </Box>
              <div className="authList">
                {orgBuUnits.map((item: OrgBU, index: number) => {
                  return (
                    <Grid
                      container
                      spacing={2}
                      key={item.id}
                      alignItems={"center"}
                    >
                      <Grid item xs={12} md={2}>
                        <Box className="fromGroup">
                          <InputLabel className="fromLabel">
                            Name of Unit
                          </InputLabel>
                          <TextField
                            value={item.businessUnitName}
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Box className="fromGroup">
                          <InputLabel className="fromLabel">Address</InputLabel>
                          <TextField
                            value={item.businessUnitAddress}
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={5}>
                            <Box className="fromGroup">
                              <InputLabel className="fromLabel">
                                City
                              </InputLabel>
                              <TextField
                                value={item.businessUnitCity}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Box className="fromGroup">
                              <InputLabel className="fromLabel">
                                State
                              </InputLabel>
                              <TextField
                                value={item.businessUnitState}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Box className="fromGroup">
                              <InputLabel className="fromLabel">Zip</InputLabel>
                              <TextField
                                value={item.businessUnitZipCode}
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Box className="fromGroup">
                          <InputLabel className="fromLabel">
                            Timezone
                          </InputLabel>
                          <TextField
                            value={
                              item.businessUnitTimeZone &&
                              item.businessUnitTimeZone
                                .toString()
                                .startsWith("{")
                                ? JSON.parse(
                                    item.businessUnitTimeZone.toString()
                                  ).label
                                : ""
                            }
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={1}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          paddingBottom: "5px",
                        }}
                      >
                        <span
                          className="removeBtn"
                          onClick={() => {
                            setSelectedBusinessUnit(item);
                            setIsEditBU(true);
                          }}
                        >
                          <Edit />
                        </span>
                        <span
                          className="removeBtn"
                          onClick={() => {
                            setBusinessUnitId(item.id);
                            setIsDeletePopupOpen(true);
                          }}
                        >
                          <Delete />
                        </span>
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            </Box>
          </Box>
        </Grid>
        {currentRoles.includes("ABAEngine Admin") && (
          <Grid item xs={12}>
            <Box className="abaCard">
              <Box className="abaCard__danger">
                <Typography className="abaCard__title__danger">
                  Danger Zone
                </Typography>
              </Box>
              <Box className="abaCard__body">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Delete Organization</Typography>
                  <Button
                    className="abaCard__button__danger"
                    onClick={() => setOrgDeleteModal(true)}
                  >
                    Delete organization
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        )}

        {/* <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button>Save Changes</Button>
        </Grid> */}
      </Grid>
      {(isBuFormOpen || isEditBU) && (
        <Dialog
          open={isBuFormOpen || isEditBU}
          onClose={() => {
            if (isBuFormOpen) {
              setIsBuFormOpen(false);
            } else {
              setIsEditBU(false);
            }
          }}
          className="largeModel"
        >
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              {isEditBU ? `Edit` : `Add`} Business Unit
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (isBuFormOpen) {
                  setIsBuFormOpen(false);
                } else {
                  setIsEditBU(false);
                }
              }}
            >
              <Close />
            </Button>
          </Box>
          <Box className="largeModel__body">
            <BusinessUnitForm
              isNew={isBuFormOpen ? true : false}
              setModal={(value: boolean, unit: OrgBU) => {
                if (value) {
                  setIsBuFormOpen(false);
                  createBusinessUnit(unit);
                } else {
                  setIsEditBU(false);
                  updateBusinessUnit(unit);
                }
              }}
              unit={isEditBU ? selectedBusinessUnit : null}
            />
          </Box>
        </Dialog>
      )}
      {isDeletePopupOpen && (
        <DeleteConfirmation
          name={"Business Unit"}
          setModal={(value) => {
            if (value) {
              deleteBusinessUnit(businessUnitId);
            }
            setIsDeletePopupOpen(false);
          }}
        />
      )}
      {orgDeleteModal && (
        <DeleteConfirmation
          name={"Organization"}
          setModal={(value) => {
            if (value) {
              deleteOrganization();
            }
            setOrgDeleteModal(false);
          }}
        />
      )}
      {orgEditModal && (
        <Dialog open={orgEditModal} className="largeModel">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              {"Edit Company Info"}
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setOrgEditModal(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <Box className="stepperForm">
            <OrganizationEditForm
              name={org.orgName}
              url={org.orgUrl}
              email={org.supportEmail}
              service={org.orgService}
              closeModal={setOrgEditModal}
            />
          </Box>
        </Dialog>
      )}
    </div>
  );
};

export default CompanyProfile;
