import {
  Box,
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { NewMessageTemplate } from "../../../../types/businessSettings";
import { GetMessageTemplate } from "../../../../redux/features/communication/messageTemplate-slice";
import {
  GetCommunicationSetting,
  UpdateCommunicationSetting,
} from "../../../../redux/features/forms/form-slice";
import { ICommunicationSettings } from "../../../../types/forms";

//Note: must match backend communicationType
export const Group = [
  { key: 0, value: "Intake Client Form" },
  { key: 1, value: "Intake Packet Form" },
  { key: 2, value: "New Employee Form" },
  { key: 3, value: "Employee Packet Form" },
  { key: 4, value: "Assessment Form" },
  { key: 5, value: "Scheduling" },
] as const;

const BusinessCommunication: React.FC<{ selectedGroup: string }> = ({
  selectedGroup,
}) => {
  //#region variables region

  // used for dispatch
  const dispatch = useDispatch<AppDispatch>();

  //store selected category
  const [category, setCategory] = useState<string>(selectedGroup);

  //stores setting data
  const settings = useSelector<RootState, ICommunicationSettings[]>(
    (state) => state.formSlice.CommunicationSettings
  );

  //fetch all message templates
  const NewMessageTemplate = useSelector<RootState, NewMessageTemplate[]>(
    (state) => state.communicationSlice.MessageTemplates
  );

  //#endregion

  //#region methods region

  //handles setting active flag
  const handleUpdateSettingActive = (item: string, id: string) => {
    let data = settings.find((i) => i.id === id);
    const updateProperty: any = { ...data };
    updateProperty[item] = !updateProperty[item];

    dispatch(UpdateCommunicationSetting(updateProperty));
  };

  //handle update setting value
  const handleUpdateSettingValue = (
    item: string,
    id: string,
    value: string | number
  ) => {
    let data = settings?.find((i) => i.id === id);
    const updateProperty: any = { ...data };
    updateProperty[item] = value;
    dispatch(UpdateCommunicationSetting(updateProperty));
  };

  //handle select form group
  const handleSelectGroup = (value: string) => {
    dispatch(GetCommunicationSetting({ communicationType: value }));
    setCategory(value);
  };

  //#endregion

  //#region useEffect region

  useEffect(() => {
    dispatch(GetMessageTemplate());
    
    if (selectedGroup && Group.find((i) => i.value === selectedGroup)) {
        dispatch(GetCommunicationSetting({ communicationType: selectedGroup }));
    } else {
      setCategory(Group[0].value)
      dispatch(GetCommunicationSetting({ communicationType: Group[0].value }));
    }
  }, [NewMessageTemplate?.length]);

  //#endregion
  // console.log("category", category);

  return (
    <>
      <Grid
        item
        xs={12}
        md={8}
        style={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
          padding: "10px 0",
        }}
      >
        <Box
          sx={{
            display: { md: "flex", xs: "block" },
            gap: "10px",
            alignItems: "center",
            paddingLeft: "0",
          }}
        >
          <Typography variant="h6" component="div" className="form-tabs">
            Select Group:
          </Typography>
          <Box className="fromGroup-chips">
            {Group.map((item) => (
              <Card
                className={
                  category === item.value ? "fromGroup-chip-active" : ""
                }
                onClick={() => handleSelectGroup(item.value)}
                key={item.key}
              >
                {item.value}
              </Card>
            ))}
          </Box>
        </Box>
      </Grid>

      <Grid container spacing={3}>
        {settings?.map((item: any) => (
          <Grid item xs={12} lg={6} key={item.id}>
            <Box className="detailsCard">
              <Box className="detailsCard__header">
                {category === "Scheduling" ? (
                  <Typography className="detailsCard__title">
                    {item.name} {`(${item.role} only)`}
                  </Typography>
                ) : (
                  <Typography className="detailsCard__title">
                    {item.name}
                  </Typography>
                )}
              </Box>
              <Box className="detailsCard__body">
                <Box className="settingBox">
                  <div className="settingBox__part">
                    <FormGroup className="settingBox__field settingBox__field-part">
                      <h6 className="settingBox__field-title">
                        InMail Template
                      </h6>
                      <Box className="customSelectDrp">
                        <Select
                          className="customSelect"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Assessment Request"
                          style={{ minWidth: "190px" }}
                          value={item.inMailTemplate}
                          onChange={(e: SelectChangeEvent<string>) => {
                            handleUpdateSettingValue(
                              "inMailTemplate",
                              item.id,
                              e.target.value
                            );
                          }}
                        >
                          {/* {NewMessageTemplate.filter(
                            (i) => i.methods === "InMail"
                          ).map((item) => ( */}
                          {NewMessageTemplate?.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={item.inMailActive}
                              onChange={() =>
                                handleUpdateSettingActive(
                                  "inMailActive",
                                  item.id
                                )
                              }
                            />
                          }
                          label={item.inMailActive ? "On" : "Off"}
                        />
                      </Box>
                    </FormGroup>
                    <FormGroup className="settingBox__field settingBox__field-part">
                      <h6 className="settingBox__field-title">
                        Email Template
                      </h6>
                      <Box className="customSelectDrp">
                        <Select
                          className="customSelect"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Please log-in Parent App"
                          style={{ minWidth: "190px" }}
                          value={item.emailTemplate}
                          onChange={(e: SelectChangeEvent<string>) => {
                            handleUpdateSettingValue(
                              "emailTemplate",
                              item.id,
                              e.target.value
                            );
                          }}
                        >
                          {/* {NewMessageTemplate.filter(
                            (i) => i.methods === "Email"
                          ).map((item) => ( */}
                          {NewMessageTemplate?.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>

                        <FormControlLabel
                          control={
                            <Switch
                              checked={item.emailActive}
                              onChange={() =>
                                handleUpdateSettingActive(
                                  "emailActive",
                                  item.id
                                )
                              }
                            />
                          }
                          label={item.emailActive ? "On" : "Off"}
                        />
                      </Box>
                    </FormGroup>
                    <FormGroup className="settingBox__field settingBox__field-part">
                      <h6 className="settingBox__field-title">SMS Template</h6>
                      <Box className="customSelectDrp">
                        <Select
                          className="customSelect"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Please log-in Parent App"
                          style={{ minWidth: "190px" }}
                          value={item.smsTemplate}
                          onChange={(e: SelectChangeEvent<string>) => {
                            handleUpdateSettingValue(
                              "smsTemplate",
                              item.id,
                              e.target.value
                            );
                          }}
                        >
                          {/* {NewMessageTemplate.filter(
                            (i) => i.methods === "SMS"
                          ).map((item) => ( */}
                          {NewMessageTemplate?.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={item.smsActive}
                              onChange={() =>
                                handleUpdateSettingActive("smsActive", item.id)
                              }
                            />
                          }
                          label={item.smsActive ? "On" : "Off"}
                        />
                      </Box>
                    </FormGroup>
                    {item.status === "Reminded" && (
                      <FormGroup className="settingBox__field settingBox__field-part">
                        <h6 className="settingBox__field-title">
                          Reminder Once
                        </h6>
                        <Box className="customSelectDrp">
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              height: "35px",
                              alignItems: "center",
                            }}
                          >
                            <Typography>After</Typography>
                            <Select
                              className="customSelect"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              placeholder="Please log-in Parent App"
                              style={{
                                minWidth: "190px",
                                width: "50px",
                                maxHeight: "35px",
                              }}
                              value={item.days.toString()}
                              onChange={(e: SelectChangeEvent<string>) => {
                                handleUpdateSettingValue(
                                  "days",
                                  item.id,
                                  e.target.value
                                );
                              }}
                            >
                              <MenuItem value={0}>0 day</MenuItem>
                              <MenuItem value={1}>1 day</MenuItem>
                              <MenuItem value={2}>2 day</MenuItem>
                              <MenuItem value={3}>3 day</MenuItem>
                              <MenuItem value={4}>4 day</MenuItem>
                              <MenuItem value={5}>5 day</MenuItem>
                            </Select>
                          </div>

                          <FormControlLabel
                            control={
                              <Switch
                                checked={item.daysActive}
                                onChange={() =>
                                  handleUpdateSettingActive(
                                    "daysActive",
                                    item.id
                                  )
                                }
                              />
                            }
                            label={item.daysActive ? "On" : "Off"}
                          />
                        </Box>
                      </FormGroup>
                    )}
                  </div>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default BusinessCommunication;
