import { ReactComponent as Company } from "../assets/images/images-svg/company.svg";
import { ReactComponent as User } from "../assets/images/images-svg/users.svg";
import { ReactComponent as Tools } from "../assets/images/images-svg/tools.svg";
import { ReactComponent as Billing } from "../assets/images/images-svg/billing.svg";
import { ReactComponent as Security } from "../assets/images/images-svg/security.svg";
import { AppUrls } from "../AppUrls";
import { Settings, SyncAlt } from "@mui/icons-material";

export const SystemMenu = [
  {
    text: "Company",
    path: AppUrls.Client.CompanyProfile,
    labelICon: Company,
  },
  {
    text: "Users",
    path: AppUrls.Client.CompanyUserManagement,
    labelICon: User,
  },
  {
    text: "Billing",
    path: AppUrls.Client.Billing,
    labelICon: Billing,
  },
  {
    text: "Business Settings",
    path: AppUrls.Client.BusinessSettings,
    labelICon: Settings,
  },
  {
    text: "Tools",
    path: AppUrls.Client.Tools,
    labelICon: Tools,
  },
  {
    text: "Integration",
    path: AppUrls.Client.AppIntegrations,
    labelICon: SyncAlt,
  },
  {
    text: "Security Logs",
    path: AppUrls.Client.SecurityLogs,
    labelICon: Security,
  },
] as const;
