import React, { Dispatch, SetStateAction } from "react";
import AddProvider from "./AddProvider";

interface ProviderModalProps {
  selectedTab: string;
  addNewDialog: boolean;
  setAddNewDialog: Dispatch<SetStateAction<boolean>>;
}
const ProviderModal: React.FC<ProviderModalProps> = ({
  selectedTab,
  addNewDialog,
  setAddNewDialog,
}) => {
  return (
    <>
      <div>
        {selectedTab === "All Employees" ? (
          <div className="cardBlock">
            <AddProvider
              addNewDialog={addNewDialog}
              setAddNewDialog={setAddNewDialog}
              isBoardPage={false}
            />
          </div>
        ) : (
          <AddProvider
            addNewDialog={addNewDialog}
            setAddNewDialog={setAddNewDialog}
            isBoardPage={true}
          />
        )}
      </div>
    </>
  );
};

export default ProviderModal;
