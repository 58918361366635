import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ProviderService from "../../../service/provider.service";
import CommonService from "../../../service/common.service";
import { BoardStage, CardData } from "../../../types/board";
import { setResponseValue } from "../api-response/api-response";

interface CardsInitialState {
  [key: string]: CardData[];
}

const initialState: CardsInitialState = {};

const providerBoardSlice = createSlice({
  name: "providerBoardSlice",
  initialState: initialState,
  reducers: {
    setProviderTasks: (state, action) => {
      return { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProviderBoards.fulfilled,
      (state, action: PayloadAction<BoardStage[] | undefined>) => {
        if (action.payload) {
          let cards: CardsInitialState = {};
          action.payload.forEach((item) => {
            cards[item.stageName] = item.boardColumnNames;
          });
          return {
            ...cards,
          };
        } else {
          return { ...initialState };
        }
        // return state;
      }
    );
  },
});

export const getProviderBoards = createAsyncThunk(
  "providerBoardSlice/getProviderBoards",
  async ({ useLoader }: { useLoader: boolean }, { getState, dispatch }) => {
    try {
      if (useLoader) {
        dispatch(setResponseValue({ name: "pending", value: true }));
      }
      const { data } = await ProviderService.getProviderBoards();
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message || "Error occured" }));
    } finally {
      if (useLoader) {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    }
  }
);

export const moveProviderCardToAnotherBoard = createAsyncThunk(
  "providerBoardSlice/moveProviderToAnotherBoard",
  async (
    {
      fromBoardId,
      toBoardId,
      cardId,
      index,
    }: {
      fromBoardId: string;
      toBoardId: string | null;
      cardId: string;
      index: number;
    },
    { getState, dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      await CommonService.moveToAnotherBoard(
        fromBoardId,
        toBoardId,
        cardId,
        index,
        // "Provider"
      );
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message || "Error occured" }));
    }finally{
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setProviderTasks } = providerBoardSlice.actions;

export default providerBoardSlice;
