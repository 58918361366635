import {
  Box,
  Card,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography, 
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { GetRuns, IRuns } from "../../../../redux/features/runs/runs-slice";
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  PdfExport,
  Resize,
  Sort,
  TextWrapSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { JobStatus } from "../../../../constants/tools";

interface IRunsFilter {
  status: string[];
  entityType: string[];
  jobName: string[];
}

const Runs: React.FC = () => {
  //#region Variable region
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<IRunsFilter>({
    status: [],
    entityType: [],
    jobName: [],
  });
  const runs = useAppSelector<IRuns[]>((state) =>
    state.runsSlice.filter(
      (item) =>
        item.jobType === "Board Automation" ||
        item.jobType === "Alert Automation"
    )
  );
  const jobNames = [...new Set(runs.map((item) => item.jobName))];
  //#endregion

  let gridInstance: GridComponent;
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };
  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }

  const pdfExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  const excelExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  function dataBound() {
    if (refresh && gridInstance) {
      gridInstance.groupColumn("subject");
      refresh = false;
      gridInstance.refresh(); //must have!! otherwise, has white-screen.
    }
  }

  const TextTemplate = (props: any) => {
    return (
      <Box>
        {props.column.field === "startTime" && (
          <Typography>
            {new Date(props.createdAt).toLocaleDateString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })}
          </Typography>
        )}
        {props.column.field === "endTime" && (
          <Typography>
            {new Date(props.updatedAt).toLocaleDateString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })}
          </Typography>
        )}

        {/* {props.column.field === "duration" && (
          <Typography>
            {(
              Math.abs(
                new Date(props.updatedAt).getTime() -
                  new Date(props.createdAt).getTime()
              ) / 1000
            ).toFixed(2) + " "}
          </Typography>
        )} */}
      </Box>
    );
  };

  const statusTemplate = (props: any) => {
    return (
      <Box className="fromGroup-chips">
        <Card>{props.status}</Card>
      </Box>
    );
  };

  const filterRuns = (runs: IRuns[]) => {
    const filteredResult = runs.filter((item) => {
      let data: boolean = true;
      if (filters.entityType.length > 0 && item.entityType !== null) {
        data = filters.entityType.includes(item.entityType);
      }
      if (filters.jobName.length > 0) {
        data = filters.jobName.includes(item.jobName);
      }
      if (filters.status.length > 0) {
        data = filters.status.includes(item.status);
      }
      if (data) {
        return item;
      }
    });

    //sort by start time desc
    const sortedResult = filteredResult.sort((a, b) => {
      return (
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
    });
    return sortedResult;
  };

  //#region useEffect region

  //featch intial run data
  useEffect(() => {
    dispatch(GetRuns());
  }, []);
  //#endregion

  return (
    <div className="listingTable runs">
      <div className="runs-filters">
      <FormControl sx={{ m: 1, width: 250 }} size="small">
          <InputLabel id="shrink demo-multiple-checkbox-label">
            Job Name
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={filters.jobName}
            input={<OutlinedInput label="Job Name" />}
            onChange={(e) => {
              setFilters({ ...filters, jobName: e.target.value as string[] });
            }}
            renderValue={(selected) => selected.join(", ")}
          >
            {jobNames.map((item, index) => (
              <MenuItem value={item} key={index}>
                <Checkbox checked={filters.jobName.includes(item)} />
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 200 }} size="small">
          <InputLabel id="demo-multiple-checkbox-label">Entity Type</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={filters.entityType}
            input={<OutlinedInput label="Entity Type" />}
            onChange={(e) => {
              setFilters({
                ...filters,
                entityType: e.target.value as string[],
              });
            }}
            renderValue={(selected) => selected.join(", ")}
          >
            <MenuItem value="Client">
              <Checkbox checked={filters.entityType.includes("Client")} />
              Client
            </MenuItem>
            <MenuItem value="Provider">
              <Checkbox checked={filters.entityType.includes("Provider")} />
              Provider
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 200 }} size="small">
          <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={filters.status}
            input={<OutlinedInput label="Status" />}
            onChange={(e) => {
              setFilters({ ...filters, status: e.target.value as string[]});
            }}
            renderValue={(selected) => selected.join(", ")}
          >
            {Object.values(JobStatus)
              .filter((item) => item !== JobStatus.InReview)
              .map((item: string, index:number) => (
                <MenuItem value={item} key={index}>
                  <Checkbox checked={filters.status.includes(item)} />
                  {item}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <GridComponent
        id="grid" //must have!!
        className="gridTable"
        dataSource={filterRuns(runs)}
        dataBound={dataBound.bind(this)}
        allowPaging={true}
        ref={(grid) => (gridInstance = grid as GridComponent)}
        statelessTemplates={["directiveTemplates"]}
        load={load}
        width="auto"
        height="650"
        allowSelection={true}
        selectionSettings={{
          type: "Single",
        }}
        groupSettings={groupOptions}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowTextWrap={true}
        filterSettings={{ type: "Menu" }}
        textWrapSettings={textWrapSettings}
        allowPdfExport={true}
        allowExcelExport={true}
        pdfExportComplete={pdfExportComplete}
        excelExportComplete={excelExportComplete}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            visible={false}
            headerText="ID"
            isPrimaryKey={true}
            width="100"
          />
          <ColumnDirective
            headerText="Job Type"
            width="150"
            textAlign="Left"
            field="jobType"
            // template={Alltemplate}
          />
          <ColumnDirective
            headerText="Job Name"
            width="250"
            textAlign="Left"
            field="jobName"
            // template={TextTemplate}
          />
          <ColumnDirective
            headerText="Entity Type"
            width="150"
            textAlign="Left"
            field="entityType"
            // template={TextTemplate}
          />
          <ColumnDirective
            headerText="Entity Name"
            width="150"
            textAlign="Left"
            field="entityName"
            // template={TextTemplate}
          />
          <ColumnDirective
            headerText="Start"
            width="130"
            textAlign="Left"
            field="startTime"
            template={TextTemplate}
          />
          <ColumnDirective
            headerText="End"
            width="130"
            textAlign="Left"
            field="endTime"
            template={TextTemplate}
          />
          <ColumnDirective
            headerText="Status"
            width="150"
            textAlign="Left"
            field="status"
            template={statusTemplate}
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Group,
            ContextMenu,
            Toolbar,
            Resize,
            DetailRow,
            Filter,
            Sort,
            ExcelExport,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};
export default Runs;
