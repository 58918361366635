import { createSlice } from "@reduxjs/toolkit";
import { ClientForms } from "../../../types/client";
import { ProviderForms } from "../../../types/provider";

interface CardsStateInitialState {
  [key: string]: ProviderForms[] | ClientForms[];
}

const initialState: CardsStateInitialState = {};

const cardsStateSlice = createSlice({
  name: "draggingState",
  initialState: initialState,
  reducers: {
    setTasks: (state, action) => {
      return { ...action.payload };
    },
  },
});

export const { setTasks } = cardsStateSlice.actions;

export default cardsStateSlice;
