import moment from "moment";
import { ClientAuthPayload } from "../types/client";
import { BookedSchedule } from "../types/schedule";

//return filtered schedules based on either clientIds + providerIds, data range, whether to include cancel or not.
export const filterSchedulesByIds = (
    Bookeddata: BookedSchedule[],
    clientIds: string[],
    providerIds: string[],
    filterFromDate: Date,
    filterToDate: Date,
    includeCancel: boolean
  ) => {
    // console.log("filterSchedulesByIds: ", clientIds,
    // providerIds, filterFromDate, filterToDate, includeCancel);

    let results: BookedSchedule[];
  
    results = Bookeddata?.filter(
        (schedule: BookedSchedule) =>
          (clientIds?.includes(schedule.bookedClientId?.id) ||
            providerIds?.includes(schedule.bookedProviderId?.id)) &&
          new Date(schedule.specificDate).getTime() >= filterFromDate.getTime() &&
          new Date(schedule.specificDate).getTime() <= filterToDate.getTime() &&
          (includeCancel ||
            (!includeCancel && schedule.status.toUpperCase() !== "CANCELLED"))
      )
      .sort(
        (a: any, b: any) =>
          new Date(a.specificDate).getTime() - new Date(b.specificDate).getTime()
      );

    // console.log("results:", results?.length, clientIds?.length, providerIds?.length);
  
    return results;
};

export const returnAuthorizationValue = (item: ClientAuthPayload): string => {
  if (item.insuranceId || item.insurance.id) {
    return `${item.insurance?.insuranceProviderName} Auth 
      ${moment(item.authPeriod?.startDate).format("MM/DD/YYYY")}
      -
      ${moment(item.authPeriod?.endDate).format("MM/DD/YYYY")}`;
  } else {
    return "<bad auth code>";
  }
};
