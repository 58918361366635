import React from "react";
import { ListSubheader, MenuItem } from "@mui/material";
import { useState } from "react";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { IBoardMenu } from "../../../types/board";

interface IRenderSelectedGroup {
  board: IBoardMenu;
  moveHandler: (id: string) => {};
}
const RenderSelectGroup: React.FC<IRenderSelectedGroup> = ({
  board,
  moveHandler,
}) => {
  //#region variable region

  //handle open listsubheader
  const [open, setOpen] = useState(false);

  // intialised variable to store jsx
  const items: JSX.Element[] = board.stages.map((stage: any) => {
    return (
      <MenuItem
        onClick={() => {
          moveHandler(stage.id);
        }}
        key={stage.id}
      >
        {stage.name}
      </MenuItem>
    );
  });
  //#endregion

  return (
    <>
      <ListSubheader onClick={() => setOpen(!open)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500" }}>
            {board.name}
          </span>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
      </ListSubheader>
      {open && items}
    </>
  );
};

export default RenderSelectGroup;
