import {
  Box,
  Grid,
  IconButton,
  TextareaAutosize,
  Button,
  CircularProgress,
} from "@mui/material";
import { AllUserMessage, IComments, IMessage } from "../../../types/message";
import { memo, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  PostSubComments,
  UpdateMessageStatusById,
  openMessage,
  setReadMessage,
  setTranslation,
} from "../../../redux/features/messages/message-slice";
import {useAppDispatch, useAppSelector } from "../../../redux/store";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import { dateToFromNowDaily } from "../../../helpers/reverse-list/reverse-list";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { ReactComponent as Download } from "../../../assets/images/images-svg/Download.svg";
import { getFirstLineHtml, isHtml, sortData } from "../../../constants/message";
import { useUser } from "@descope/react-sdk";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import MessageFormatter from "../message-formatter/MessageFormatter";

interface OpenedMessageProps {
  selectedUser: {
    id: string;
    name: string;
    entityType: string;
  };
}

interface ExternalMessageComponentProps {
  handleMessageOpen: () => void;
  message: AllUserMessage;
}

interface ExternalMessageProps {
  externalMessage: AllUserMessage[];
  selectedUser: {
    id: string;
    name: string;
    entityType: string;
  };
}

const CommentComponent: React.FC<{ item: IComments }> = ({ item }) => (
  <Box className="commentListItem generated">
    <Box className="compose-block">
      <span className="commentListItem__user-img">
        <img
          src={item?.fromUser?.profilePictureUrl || ProviderImg}
          className="userSmallCircle"
          alt=""
        />
      </span>
      <div className="commentListItem__user-info">
        <div className="compose-user-heading">
          <h5 className="compose-user-name">
            {item.fromUser?.firstName
              ? item.fromUser?.firstName + " " + item.fromUser?.lastName
              : item.fromUser?.email}
          </h5>
          <h6 className="compose-user-time">
            <span>{dateToFromNowDaily(item?.createdAt)}</span>
          </h6>
        </div>
        <div
          className="compose-user-comment"
          style={{ whiteSpace: "pre-line" }}
        >
          {item?.comment}
          {/* {translation[index + 2] && isTranslated
            ? translation[index + 2]
            : item?.comment} */}
        </div>
        <div
          style={{
            textDecoration: "none",
            display: "flex",
            gap: "10px",
          }}
        >
          {item?.files?.map((file: any) => {
            return (
              <span>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${file?.url}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {["JPG", "JPEG", "PNG"].includes(
                    file?.url?.split(".").pop()?.toUpperCase() || ""
                  ) ? (
                    <ImageIcon style={{ color: "#096dd9" }} />
                  ) : (
                    <InsertDriveFileIcon style={{ color: "#096dd9" }} />
                  )}
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "150px",
                    }}
                    title={file.fileName}
                  >
                    {file.name || file.fileName}
                  </span>
                  <IconButton style={{ background: "transparent" }}>
                    <Download />
                  </IconButton>
                </a>
              </span>
            );
          })}
        </div>
      </div>
    </Box>
  </Box>
);

const OpenedMessage: React.FC<OpenedMessageProps> = memo(({ selectedUser }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [CommentError, setCommentError] = useState<string>("");
  const [subCommentFiles, setSubCommentFiles] = useState<any[]>([]);
  const [internalComment, setInternalComment] = useState<string>("");
  const dispatch = useAppDispatch();
  const {
    user: { userId },
  } = useUser();
  const { openedMessage, translatedMessage } = useAppSelector<IMessage>(
    (state) => state.messageSlice
  );
  const currentMessage = translatedMessage ? translatedMessage : openedMessage;
  if (!currentMessage) return null;

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const totalFiles = subCommentFiles.length + files.length;

    if (totalFiles > 5) {
      setErrorMessage("You can only upload up to 5 files.");
      return;
    } else {
      setErrorMessage("");
    }

    const oversizedFiles = files.filter((file) => file.size > 5 * 1024 * 1024); // 5 MB in bytes
    if (oversizedFiles.length > 0) {
      setErrorMessage("File size exceeds the limit of 5 MB.");
      return;
    } else {
      setErrorMessage("");
    }

    setSubCommentFiles([...subCommentFiles, ...files]);
  };

  const handleSubComments = async () => {
    try {
      setLoading(true);
      if (!internalComment.trim()) {
        setCommentError("Please enter message first");
        return;
      }
      await dispatch(
        PostSubComments({
          messageId: currentMessage.id,
          entityId: currentMessage.entityId,
          comment: internalComment,
          entityType: selectedUser.entityType,
          files: subCommentFiles,
        })
      );
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
      setInternalComment("");
      setSubCommentFiles([]);
    }
  };

  return (
    <>
      <Grid
        className={
          !currentMessage.seenUserId.includes(userId)
            ? "messageInternal-block light-blue-bg"
            : "messageInternal-block"
        }
        item
        xs={16}
      >
        <Box className="compose-block">
          <span className="commentListItem__user-img">
            {!currentMessage.seenUserId.includes(userId) && (
              <span className="active" />
            )}
            <img
              src={currentMessage?.fromUser?.profilePictureUrl || ClientImg}
              className="userSmallCircle"
              alt=""
            />
          </span>
          <div className="commentListItem__user-info">
            <div className="compose-user-heading">
              <h5 className="compose-user-name">{currentMessage?.fromName}</h5>
              <h6 className="compose-user-time">
                <span>{dateToFromNowDaily(currentMessage?.createdOn)}</span>
              </h6>
            </div>
          </div>
        </Box>
        <Box>
          <div className="messageTicket-number">
            <strong>Subject: {currentMessage?.subject}</strong>
          </div>
        </Box>
        <Box sx={{ maxHeight: "250px", overflowY: "auto" }}>
          <MessageFormatter message={currentMessage.message} />
        </Box>
        <Box>
          <div style={{ textDecoration: "none", display: "flex", gap: "10px" }}>
            {currentMessage?.files?.map((file) => {
              return (
                <span>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`${file.url}`}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {["JPG", "JPEG", "PNG"].includes(
                      file?.url?.split(".").pop()?.toUpperCase() || ""
                    ) ? (
                      <ImageIcon style={{ color: "#096dd9" }} />
                    ) : (
                      <InsertDriveFileIcon style={{ color: "#096dd9" }} />
                    )}
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                      }}
                      title={file.fileName}
                    >
                      {file.fileName}
                    </span>
                    <IconButton style={{ background: "transparent" }}>
                      <Download />
                    </IconButton>
                  </a>
                </span>
              );
            })}
          </div>
        </Box>
      </Grid>
      <Grid item xs={16} md={16}>
        <Grid
          className="commentForm-lavel"
          item
          xs={16}
          md={12}
          style={{ marginTop: "50px" }}
        >
          Follow-Ups
        </Grid>
        <span style={{ color: "#d32f2f" }}>{CommentError}</span>
        <span style={{ color: "#d32f2f" }}>{errorMessage}</span>
        <Box className="commentForm">
          <div
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => e.preventDefault()}
          >
            <TextareaAutosize
              disabled={translatedMessage ? true : false}
              value={loading ? "Sending..." : internalComment}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setInternalComment(e.target.value);
                setCommentError("");
              }}
              placeholder="Reply here..."
              minRows={2}
            />
            <Button
              variant="outlined"
              disabled={loading || translatedMessage ? true : false}
              onClick={handleSubComments}
            >
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Add Follow-Up"
              )}
            </Button>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {subCommentFiles &&
              subCommentFiles.map((file, index) => {
                return (
                  <div className="selectedIn-file">
                    <h1>{file.name}</h1>
                    <span>
                      <DeleteIcon
                        onClick={() => {
                          setSubCommentFiles(
                            subCommentFiles.filter(
                              (_, fileIndex) => fileIndex !== index
                            )
                          );
                        }}
                      />
                    </span>
                  </div>
                );
              })}
          </div>
        </Box>
      </Grid>
      <Box sx={{ height: "400px", overflow: "auto" }}>
        {currentMessage.comments.map((item) => {
          return <CommentComponent item={item} key={item.createdAt} />;
        })}
      </Box>
    </>
  );
});

const ExternalMessageComponent: React.FC<ExternalMessageComponentProps> = ({
  handleMessageOpen,
  message,
}) => {
  const {
    user: { userId },
  } = useUser();
  return (
    <Grid
      className={
        !message.seenUserId.includes(userId)
          ? "messageInternal-block light-blue-bg"
          : "messageInternal-block"
      }
      item
      xs={16}
      onClick={handleMessageOpen}
    >
      <Box className="compose-block">
        <span className="commentListItem__user-img">
          {!message.seenUserId.includes(userId) && (
            <span className="active"></span>
          )}
          <img
            src={message?.fromUser?.profilePictureUrl || ClientImg}
            className="userSmallCircle"
            alt=""
          />
        </span>
        <div className="commentListItem__user-info">
          <div className="compose-user-heading">
            <h5 className="compose-user-name">{message?.fromName}</h5>
            <h6 className="compose-user-time">
              <span>{dateToFromNowDaily(message?.createdOn)}</span>
            </h6>
          </div>
        </div>
      </Box>
      <Box>
        <div className="messageTicket-number">
          <strong>Subject: {message?.subject}</strong>
        </div>
      </Box>
      <Box className="message-listingBlock">
        {isHtml(message.message) ? (
          <>
            <div className="">
              <span style={{ cursor: "pointer" }}>
                {getFirstLineHtml(message.message)}
              </span>
              <p></p>
              <span style={{ color: "#096dd9", cursor: "pointer" }}>
                {"See More Details..."}
              </span>
            </div>
          </>
        ) : (
          message.message
        )}
      </Box>
      <Box>
        <div style={{ textDecoration: "none", display: "flex", gap: "10px" }}>
          {message?.files?.map((file) => {
            return (
              <span>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${file.url}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {["JPG", "JPEG", "PNG"].includes(
                    file?.url?.split(".").pop()?.toUpperCase() || ""
                  ) ? (
                    <ImageIcon style={{ color: "#096dd9" }} />
                  ) : (
                    <InsertDriveFileIcon style={{ color: "#096dd9" }} />
                  )}
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "150px",
                    }}
                    title={file.fileName}
                  >
                    {file.fileName}
                  </span>
                  <IconButton style={{ background: "transparent" }}>
                    <Download />
                  </IconButton>
                </a>
              </span>
            );
          })}
        </div>
      </Box>
    </Grid>
  );
};

const ExternalMessage: React.FC<ExternalMessageProps> = ({
  externalMessage,
  selectedUser,
}) => {
  const {
    user: { userId },
  } = useUser();
  const dispatch = useAppDispatch();
  const { openedMessage } = useAppSelector<IMessage>(
    (state) => state.messageSlice
  );

  useEffect(() => {
    dispatch(openMessage(null));
    dispatch(setTranslation(null));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser.id]);

  if (externalMessage.length === 0) return <Box>No In-Mail Found</Box>;

  if (openedMessage) {
    return <OpenedMessage selectedUser={selectedUser} />;
  }

  const handleMessageOpen = (item: AllUserMessage) => {
    if (!item.seenUserId.includes(userId)) {
      dispatch(setReadMessage({ id: item.entityId, type: "external" }));
      dispatch(
        UpdateMessageStatusById({
          id: [item.id],
          userId,
          type: "externalCount",
        })
      );
    }
    dispatch(
      openMessage({ ...item, seenUserId: [...item.seenUserId, userId] })
    );
  };

  return (
    <>
      {sortData(externalMessage).map((item) => {
        return (
          <ExternalMessageComponent
            key={item.id}
            handleMessageOpen={handleMessageOpen.bind(null, item)}
            message={item}
          />
        );
      })}
    </>
  );
};

export default memo(ExternalMessage);
