import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, ButtonGroup, Dialog, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { AppDispatch, RootState } from "../../../redux/store";
import { ReactComponent as FilterIcon } from "../../../assets/images/images-svg/filterIcon.svg";
import { getBussinessName } from "../../../constants/common";
import { OrgBU } from "../../../types/company";
import MessageFilter from "../../filter-main/MessageFilter";
import { setMessageFilterValues } from "../../../redux/features/messages/message-filter-slice";
import { MessageFilter as MessageFilters } from "../../../types/message";
import { getBusinessUnitName } from "../../../utils/EntityUtils";

type filterkey =
  | "insuranceProvider"
  | "businessUnit"
  | "clientStatus"
  | "providerStatus"
  | "intakeStage"
  | "clientConcerns"
  | "onBoardingStage"
  | "providerTasks"
  | "ScheduleStatus"
  | "startDate"
  | "endDate";

const MessageHeading: React.FC = () => {
  // #region variables region

  const dispatch = useDispatch<AppDispatch>();
  //handle filter open
  const [filterModal, setFilterModal] = useState<boolean>(false);

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //stores selected filter values
  const filterTypes = useSelector<RootState, MessageFilters>(
    (state) => state.messageFilterSlice
  );

  // #endregion

  // #region methods region
  //handles close single filter
  const handleCloseSingleFilter = (key: string, item: string) => {
    const fliterkey = key as filterkey;
    const filterData = filterTypes[fliterkey];
    if (Array.isArray(filterData)) {
      const value = filterData.filter((i) => i !== item);
      dispatch(setMessageFilterValues({ ...filterTypes, [fliterkey]: value }));
    } else {
      dispatch(setMessageFilterValues({ ...filterTypes, [fliterkey]: null }));
    }
  };
  // #region end

  return (
    <div className="listingTable">
      <div className="listingTable__header">
        <Typography className="listingTable__title" gutterBottom variant="h6">
          Contacts
        </Typography>
        <ButtonGroup
          disableElevation
          variant="contained"
          className="filterGroup-btn"
        >
          <Button
            className="filterGroup-btn"
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              setFilterModal(true);
            }}
          >
            <FilterIcon />
          </Button>
        </ButtonGroup>
      </div>
      <div className="selected-filtersButtons" style={{ position: "relative" }}>
        {Object.entries(filterTypes)
          .filter(([_, value], idx) => {
            if (value && typeof value === "string") return true;
            if (value?.length > 0 && typeof value === "object") return true;
            return false;
          })
          .map(([key, value], index) => {
            if (typeof value === "object") {
              return value.map((subItem: string, subIndex: number) => {
                if (
                  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
                    subItem
                  )
                ) {
                  return (
                    <Button className="border-button" key={index}>
                      {getBussinessName(subItem, businessUnits)}
                      <span className="closeBtn">
                        <CloseIcon
                          fontSize="small"
                          onClick={() => {
                            handleCloseSingleFilter(key, subItem);
                          }}
                        />
                      </span>
                    </Button>
                  );
                }

                return (
                  <Button className="border-button" key={subIndex}>
                    {subItem}
                    <span className="closeBtn">
                      <CloseIcon
                        fontSize="small"
                        onClick={() => {
                          handleCloseSingleFilter(key, subItem);
                        }}
                      />
                    </span>
                  </Button>
                );
              });
            } else {
              return (
                <Button className="border-button" key={index}>
                  {key === "businessUnit"
                    ? getBusinessUnitName(businessUnits, [value])
                    : key === "age"
                    ? `Age:${value}`
                    : value}
                  <span className="closeBtn">
                    <CloseIcon
                      fontSize="small"
                      onClick={() => {
                        handleCloseSingleFilter(key, value);
                      }}
                    />
                  </span>
                </Button>
              );
            }
          })}
      </div>
      <div className="select-filters-buttons">
        <Dialog open={filterModal} className="largeModel">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Set Filters
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => setFilterModal(false)}
            >
              <Close />
            </Button>
          </Box>

          <MessageFilter
            closeModal={(val: boolean, filterObject) => {
              dispatch(setMessageFilterValues({ ...filterObject }));
              setFilterModal(val);
            }}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default MessageHeading;
