import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  FormControlLabel,
  Checkbox,
  Dialog,
  Button,
} from "@mui/material";
import { Close, Edit } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  setClientId,
  setClientInsuranceDetails,
  setEditClient,
  setInitialState,
  updateClientInsuranceInfoById,
} from "../../../../redux/features/client-main/client-slice";
import { setClientInsuranceUpdated } from "../../../../redux/features/client-main/client-insurance-slice";
import { setResponseValue } from "../../../../redux/features/api-response/api-response";
import { setListDataChange } from "../../../../redux/features/client-main/client-list-slice";

import ClientService from "../../../../service/client.service";

import InsuranceDetails from "../../client-stepper/InsuranceDetails";

import { InsuranceProviders } from "../../../../types/client";

interface ClientSubInsuranceDetailsProps {
  clientInsurance: InsuranceProviders[];
  clientId: string;
  reOpenInsuranceInfo: boolean;
  setReOpenInsuranceInfo: Dispatch<SetStateAction<boolean>>;
  setAuthIndex: Dispatch<SetStateAction<number | null>>;
  setActiveTab: Dispatch<SetStateAction<string | null>>;
}

const ClientSubInsuranceDetails: React.FC<ClientSubInsuranceDetailsProps> = ({
  clientInsurance,
  clientId,
  reOpenInsuranceInfo,
  setReOpenInsuranceInfo,
  setAuthIndex,
  setActiveTab,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useAppDispatch();

  //handles the edit for client insurance details
  const [editInsuranceInfo, setEditInsuranceInfo] = useState<boolean>(false);

  //form handler for edit or new
  const { isEditClient } = useAppSelector((state) => state.client);

  // #endregion

  // #region methods region

  //handles the data setting in redux for client insurance details
  const handleEditInsuranceClick = () => {
    if (clientInsurance.length === 0) {
      const InsuranceProvidersObj = {
        clientId: "",
        isPrimary: true,
        insuranceProviderName: "",
        insuranceId: "",
        id: "",
      };
      dispatch(
        setClientInsuranceDetails({
          clientInsurance: [InsuranceProvidersObj],
          isEdit: true,
        })
      );
    } else {
      dispatch(
        setClientInsuranceDetails({
          clientInsurance: [...clientInsurance],
          isEdit: true,
        })
      );
    }
    dispatch(setClientId({ id: clientId }));
    dispatch(setEditClient(true));
    setEditInsuranceInfo(true);
  };

  //handles the close logic when save changes or close edit form for client contact details
  const handleCloseForInsuranceDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setEditInsuranceInfo(false);
    setReOpenInsuranceInfo(false);
  };

  //handles deleting insurance providers
  const deleteInsuranceProviderById = async (id: string) => {
    try {
      setEditInsuranceInfo(false);
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await ClientService.deleteClientInsuranceById(
        id
      );
      if (status) {
        dispatch(setListDataChange());
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        setReOpenInsuranceInfo(true);
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
      setActiveTab(null);
      setAuthIndex(null);
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //handles the edit form submission for client contact details
  const onSubmitInsuranceInfo = () => {
    dispatch(updateClientInsuranceInfoById());
    dispatch(setClientInsuranceUpdated(true));
    setEditInsuranceInfo(false);
    dispatch(setEditClient(false));
  };

  // #endregion
  return (
    <Grid item xs={12} lg={6} xl={4}>
      <div className="detailsCard">
        <div className="detailsCard__header">
          <Typography className="detailsCard__title">
            Insurance Information
          </Typography>
          <IconButton
            className="detailsCard__headBtn"
            size="small"
            edge="start"
            onClick={() => {
              handleEditInsuranceClick();
            }}
          >
            <Edit />
          </IconButton>
        </div>
        <div className="detailsCard__body">
          <Grid container spacing={0}>
            <Grid item xs={4} md={4}>
              <div className="detailsCard-part">
                <Typography className="detailsCard-part__title">
                  Provider
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} md={4}>
              <div className="detailsCard-part">
                <Typography
                  className="detailsCard-part__title"
                  style={{ textAlign: "center" }}
                >
                  Primary
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} md={4}>
              <div className="detailsCard-part" style={{ textAlign: "center" }}>
                <Typography className="detailsCard-part__title">
                  Secondary
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              {clientInsurance &&
                [...clientInsurance]
                  .sort((a, b) => {
                    return Number(b.isPrimary) - Number(a.isPrimary);
                  })
                  .map((item: InsuranceProviders, index: number) => {
                    return (
                      <Grid container spacing={0} key={index}>
                        <Grid item xs={4} md={4}>
                          <div className="detailsCard-part">
                            <Typography className="detailsCard-part__title">
                              <b>{item?.insuranceProviderName}</b>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <div className="detailsCard-part">
                            <Box
                              className="checkBox-group"
                              style={{ justifyContent: "center" }}
                            >
                              <FormControlLabel
                                checked={item?.isPrimary}
                                label={""}
                                control={<Checkbox value="Primary" />}
                              />
                            </Box>
                          </div>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <div className="detailsCard-part">
                            <Box
                              className="checkBox-group"
                              style={{ justifyContent: "center" }}
                            >
                              <FormControlLabel
                                checked={!item?.isPrimary}
                                label={""}
                                control={<Checkbox value="Primary" />}
                              />
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                    );
                  })}
            </Grid>
          </Grid>
        </div>
      </div>
      {isEditClient && editInsuranceInfo && (
        <>
          <Dialog
            open={editInsuranceInfo}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client - Insurance Information
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForInsuranceDetails}
              >
                <Close />
              </Button>
            </Box>
            <InsuranceDetails
              onSubmit={onSubmitInsuranceInfo}
              deleteInsuranceProviderById={deleteInsuranceProviderById}
            />
          </Dialog>
        </>
      )}
    </Grid>
  );
};

export default ClientSubInsuranceDetails;
