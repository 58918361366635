import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  PdfExport,
  Resize,
  Sort,
  TextWrapSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
// import { HistoryStatus } from "../../constants/tools";
import { Close } from "@mui/icons-material";
import {useAppSelector } from "../../redux/store";
import moment from "moment";
import { IImportExportRequest } from "../../types/tools";
const History = () => {
  const [open, setOpen] = useState<boolean>(false);
  const requestLogs = useAppSelector<IImportExportRequest[]>(
    (state) => state.importExportSlice.requestLogs
  );
  //#region grid region
  let gridInstance: GridComponent;
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };
  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }
  const pdfExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  const excelExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  function dataBound() {
    // console.log("[dataBound]");
    if (refresh && gridInstance) {
      gridInstance.groupColumn("subject");
      refresh = false;
      gridInstance.refresh(); //must have!! otherwise, has white-screen.
    }
  }

  const datatemplate = (props: any) => {
    return (
      <div style={{ padding: "10px 0px" }}>
        {props?.column.field === "jobType" && (
          <Typography>{props.jobType}</Typography>
        )}
        {props?.column.field === "entityType" && (
          <Typography>{props.entityType}</Typography>
        )}
        {props?.column.field === "startTime" && (
          <Typography>{moment(props.createdAt).calendar()}</Typography>
        )}
        {/* {props?.column.field === "endTime" && (
          <Typography>{moment(props.updatedAt).calendar()}</Typography>
        )} */}
        {props?.column.field === "duration" && (
          <Typography>
            {(
              Math.abs(
                new Date(props.updatedAt).getTime() -
                  new Date(props.createdAt).getTime()
              ) / 1000
            ).toFixed(2) + " "}
            Secs
          </Typography>
        )}

        {props?.column.field === "fileLink" && (
          <a href={props.fileUrl} style={{ color: "#1976d2" }}>
            {props.fileName}
          </a>
        )}

        {/* {props?.column.field === "jobData" &&
          props?.jobType === "Export" &&
          props?.jobData !== null && (
            <div style={{ display: "grid" }}>
              {props?.jobData?.exportFiles.map((item: any, index: number) => (
                <a href={item.url} style={{ color: "#1976d2" }} key={index}>
                  {item.entityType}
                </a>
              ))}
            </div>
          )} */}

        {props?.column.field === "errorMessage" && (
          <Typography>{props?.errorMessage}</Typography>
        )}
      </div>
    );
  };

  const StatusTemplate = (props: any) => {
    return (
      <div className={`history-status ${props.status.replace(" ", "")}`}>
        {props?.status}
      </div>
    );
  };
  // #endregion

  // useEffect(() => {
  //   dispatch(GetImportExportRequest());
  // }, []);

  return (
    <div>
      <GridComponent
        id="grid" //must have!!
        className="gridTable"
        dataSource={requestLogs}
        dataBound={dataBound.bind(this)}
        allowPaging={true}
        ref={(grid) => (gridInstance = grid as GridComponent)}
        statelessTemplates={["directiveTemplates"]}
        load={load}
        width="auto"
        height="650"
        allowSelection={false}
        allowGrouping={true}
        groupSettings={groupOptions}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowTextWrap={true}
        filterSettings={{ type: "Menu" }}
        textWrapSettings={textWrapSettings}
        allowPdfExport={true}
        allowExcelExport={true}
        pdfExportComplete={pdfExportComplete}
        excelExportComplete={excelExportComplete}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            visible={false}
            headerText="ID"
            isPrimaryKey={true}
            width="100"
          />
          <ColumnDirective
            headerText="Job Type"
            width="80"
            textAlign="Left"
            field="jobType"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="Entity Type"
            width="80"
            textAlign="Left"
            field="entityType"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="File Link"
            width="250"
            textAlign="Left"
            field="fileLink"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="Start Time"
            width="80"
            textAlign="Left"
            field="startTime"
            template={datatemplate}
          />
          {/* <ColumnDirective
            headerText="Finish Time"
            width="200"
            textAlign="Left"
            field="endTime"
            template={datatemplate}
          /> */}
          <ColumnDirective
            headerText="Duration"
            width="80"
            textAlign="Left"
            field="duration"
            template={datatemplate}
          />

          <ColumnDirective
            headerText="Error"
            width="150"
            textAlign="Left"
            field="errorMessage"
            template={datatemplate}
          />
          <ColumnDirective
            headerText="Status"
            width="80"
            textAlign="Left"
            field="status"
            template={StatusTemplate}
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Group,
            ContextMenu,
            Toolbar,
            Resize,
            DetailRow,
            Filter,
            Sort,
            ExcelExport,
            PdfExport,
          ]}
        />
      </GridComponent>
      {open && (
        <Dialog open={open} className="history-modal">
          <div className="header">
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close />
            </Button>
          </div>
          <DialogContent className="content">
            <Typography className="message-title">
              Your job request has been received.
            </Typography>
            <Typography className="message-description">
              You will soon receive your exports.
            </Typography>
          </DialogContent>
          <DialogActions className="footer">
            <Button onClick={() => setOpen(false)} variant="contained">
              Ok, got it.
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default History;
