import { AvailabilityDetails } from "../types/availability";
import { ClientForms } from "../types/client";
import { ProviderList } from "../types/provider";


//comapare dates
export const formattedDate = (item: any): string => {
  let FullDate = item.split("-");
  let Year = parseInt(FullDate[0]);
  let Month = parseInt(FullDate[1]);
  let Date = parseInt(FullDate[2]);

  return ` ${Year}${"/"}${Month}${"/"}${Date}`;
};

// export const compareDate = (item: any) => {
//   let FullDate = item.split("-");
//   let Year = parseInt(FullDate[0]);
//   let Month = parseInt(FullDate[1]);
//   let Date = parseInt(FullDate[2]);

//   return ` ${Year}${"/"}${Month}${"/"}${Date}`;
// };

// export const compareDate_2 = (item: any) => {
//   let FullDate = item.split("/");
//   let Month = parseInt(FullDate[0]);
//   let Date = parseInt(FullDate[1]);
//   let Year = parseInt(FullDate[2]);

//   return ` ${Year}${"/"}${Month}${"/"}${Date}`;
// };

//handle provider name
export const getProvidersName = (
  id: string,
  providerform: ProviderList[]
): string => {
  let firstname: {
    firstName: string;
  }[] = [];

  providerform.forEach((unit) => {

    if (unit.id === id) {
      firstname.push(unit);
    }
  });
  return firstname[0]?.firstName;
};

//handle provider name
export const getClientsName = (
  id: string,
  data: ClientForms[]
) => {
 
  let firstname:any[] = []

  data.forEach((unit) => {
    if (unit.clientBasicDetails.id === id) {
      firstname.push(unit.clientBasicDetails);
    }
  });
  return firstname[0]?.childFirstName;
};
export const getBussinessName=(id:string,businessUnits:any[]):string=>{
  let initArr: { businessUnitName: any; }[]=[];
  businessUnits.forEach((unit)=>{
    if(unit.id===id){
      initArr.push(unit);
    }
  })
  return initArr[0]?.businessUnitName
}


  // Define a function to shorten the file name
  export const shortenFileName = (fileName:string, maxLength:number) => {
    if (fileName.length <= maxLength) {
      return fileName;
    }

    const truncatedName = fileName.substring(0, maxLength - 3) + "...";
    return truncatedName;
  };