import { createSlice } from "@reduxjs/toolkit";
import { MapsFilter } from "../../../types/map";


const initialState: MapsFilter = {
  businessUnit: [],
  insuranceProvider: [],
  clientStatus: [],
  providerStatus: [],
  ScheduleStatus: "",
  intakeStage: [],
  clientConcerns: [],
  onBoardingStage: [],
  providerTasks: [],
  startDate:null,
  endDate:null,
  ageFrom: null,
  ageTo: null,
};

const mapsFilterSlice = createSlice({
  name: "mapsFilterSlice",
  initialState: initialState,
  reducers: {
    setMapsFilterValues: (state, action) => {
      return {
        ...action.payload,
      };
    },
  },
});

export const { setMapsFilterValues } = mapsFilterSlice.actions;

export default mapsFilterSlice;