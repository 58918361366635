import React from "react";
import { useDispatch } from "react-redux";
//import { GetClientById } from "../../redux/features/client-main/client-list-slice";
//import { setEditClient } from "../../redux/features/client-main/client-slice";
//import { GetProviderById } from "../../redux/features/providers-main/provider-list-slice";
import { AppDispatch } from "../../redux/store";

interface PopoverOptionsProps {
  optionName: string;
  callback: (id: string) => Promise<void>;
  key: number;
  taskId: string;
  taskType: string;
  closeAnchor: () => void;
  entityId: string;
}

const PopoverOptions: React.FC<PopoverOptionsProps> = ({
  optionName,
  callback,
  key,
  taskId,
  taskType,
  closeAnchor,
  entityId,
}) => {
  // region variables region
  const dispatch = useDispatch<AppDispatch>();

  // #endregion

  return (
    <>
      <li
        key={key}
        onClick={() => {
          /*if (taskType === "Provider") {
             dispatch(GetProviderById({ providerId: taskId }));
           } else {
             dispatch(GetClientById({ clientId: taskId }));
             dispatch(setEditClient(true));
           }*/
          if (optionName === "Delete Card") {
            callback(taskId);
          } else {
            callback(entityId);
          }

          closeAnchor();
        }}
      >
        {optionName}
      </li>
    </>
  );
};

export default PopoverOptions;
