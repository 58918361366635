import React, { useState, Dispatch } from "react";
import { ClientForms } from "../../../types/client";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import { filterMaps } from "../../../helpers/maps-filter-helpers/maps-filter-helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "../Map-Details/mapDetails.scss";
import SupervisorList from "./SupervisorList";
import { ProviderForms } from "../../../types/provider";
import { MapsFilter } from "../../../types/map";


interface MapClientListProps {
  clientsListData: ClientForms[];
  clientFunc: (item: ClientForms) => void;
  setBookingChange: Dispatch<React.SetStateAction<boolean>>;
  activeTab: boolean;
  setSelectedClient: Function;
  allSelectedClients: ClientForms[];
  setSelectedProvider: Function;
  providerListData: ProviderForms[];
  selectedProvider: ProviderForms[];
}

const MapClientList: React.FC<MapClientListProps> = ({
  clientsListData,
  clientFunc,
  setBookingChange,
  activeTab,
  setSelectedClient,
  allSelectedClients,
  setSelectedProvider,
  providerListData,
  selectedProvider,
}) => {
  //#region variable region

  //hanle selected list
  const [selectedlist, setSelectedList] = useState<string[]>([]);

  //Variable stores filtervalues
  const filterValues = useSelector<RootState, MapsFilter>(
    (state) => state.mapsFilterSlice
  );

  //Variable stores searchValues
  const searchValue = useSelector<RootState, string>(
    (state) => state.activeStep.searchValue
  );

  //#endregion

  // #region methods region

  const handleMultiple = (client: ClientForms, supervisorId: string) => {
    clientFunc(client);
    let arr: string[] = [];
    setBookingChange(true);
    arr.push(client?.clientBasicDetails.id);
    setSelectedList([...arr]);

    const index = allSelectedClients.findIndex(
      (obj) => obj.clientContactDetails?.id === client.clientContactDetails?.id
    );

    const providers: any[] = [];
    const providersCopy: any[] = [];
    const supervisor = providerListData.filter(
      (pro) => pro.employmentDetails.id === supervisorId
    );
    if (!selectedProvider.includes(supervisor[0])) {
      providers.push(supervisor[0]);
    }
    providersCopy.push(supervisor[0]);
    providerListData.forEach((pro) => {
      if (
        supervisor[0].employmentDetails.supervisor.team.includes(
          pro.employmentDetails.id
        )
      ) {
        if (!selectedProvider.includes(pro)) {
          providers.push(pro);
        }
        providersCopy.push(pro);
      }
    });

    if (index >= 0) {
      setSelectedClient(allSelectedClients.filter((o, i) => i !== index));
      const providersId = providersCopy.map((pro) => pro.employmentDetails.id);

      let availableClients = allSelectedClients.filter(
        (item) => item.clientBasicDetails.id !== client.clientBasicDetails.id
      );

      let flag = false;
      availableClients.forEach((item) => {
        if (
          item.clientBasicDetails?.supervisor?.supervisorId ===
          client.clientBasicDetails?.supervisor?.supervisorId
        ) {
          flag = true;
        }
      });
      if (!flag) {
        const unselectedProvider = selectedProvider.filter(
          (pro) => !providersId.includes(pro.employmentDetails.id)
        );

        setSelectedProvider(unselectedProvider);
      }
    } else {
      setSelectedClient([...allSelectedClients, client]);
      setSelectedProvider([...selectedProvider, ...providers]);
    }
  };

  //handle select client data
  const handleClick = (item: ClientForms) => {
    clientFunc(item);
    let arr: string[] = [];
    setBookingChange(true);
    arr.push(item?.clientBasicDetails.id);
    setSelectedList([...arr]);
    if (!activeTab) {
      const index = allSelectedClients.findIndex(
        (obj) => obj.clientContactDetails?.id === item.clientContactDetails?.id
      );
      if (index >= 0) {
        setSelectedClient(allSelectedClients.filter((o, i) => i !== index));
      } else {
        setSelectedClient([...allSelectedClients, item]);
      }
    }
  };
  //#endregion

  return (
    <div className="mapUserList">
      {clientsListData.length > 0 &&
        filterMaps<ClientForms>(filterValues, searchValue, clientsListData).map(
          (item: ClientForms, index: number) => {
            const clientIndex = allSelectedClients.findIndex((obj) => {
              return (
                obj.clientContactDetails?.id === item.clientContactDetails?.id
              );
            });

            let remainingHours = 0;
            let bookedHours = 0;

            item.availabilityDetails !== undefined &&
              item.availabilityDetails.forEach((details: any) => {
                remainingHours = details.totalRemainingHours
                  ? remainingHours + details.totalRemainingHours
                  : remainingHours;

                bookedHours = details.totalBookedHours
                  ? bookedHours + details.totalBookedHours
                  : bookedHours;
              });

            return (
              <div
                className={
                  !activeTab
                    ? clientIndex >= 0
                      ? "fromGroup-chip-active"
                      : "userMapCart"
                    : selectedlist.includes(item?.clientBasicDetails?.id)
                    ? "fromGroup-chip-active"
                    : "userMapCart "
                }
                key={index}
                onClick={() => {
                  if (item?.clientBasicDetails?.supervisor && !activeTab) {
                    handleMultiple(
                      item,
                      item?.clientBasicDetails?.supervisor.supervisorId
                    );
                  } else {
                    handleClick(item);
                  }
                }}
              >
                <div className="cardLevelBlock">
                  <table>
                    <thead className="map-thead">
                      <tr>
                        <th>
                          <span className="userMapCart__img">
                            <img
                              src={
                                item?.clientBasicDetails?.clientProfile?.url ||
                                ClientImg
                              }
                              className="userSmallCircle"
                              alt=""
                            />
                          </span>
                        </th>
                        <th>
                          <div className="userMapCart__name">
                            {item?.clientBasicDetails?.childFirstName}{" "}
                            {item?.clientBasicDetails?.childLastName}{" "}
                          </div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                
                {item?.clientBasicDetails?.supervisor && !activeTab && (
                  <SupervisorList
                    supervisorId={
                      item?.clientBasicDetails?.supervisor.supervisorId
                    }
                  />
                )}
              </div>
            );
          }
        )}
    </div>
  );
};

export default MapClientList;
