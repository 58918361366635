import React, { Dispatch, useState, SetStateAction, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, batch } from "react-redux";
import { Container } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import { dateToFromNowDaily } from "../../../helpers/reverse-list/reverse-list";
import {
  deleteMessage,
  getMessageByEntityId,
  postCreateMessage,
  updateMessage,
} from "../../../service/message.service";
import {
  getClientById,
  updateClientWithBasicDetails,
} from "../../../service/client.service";
import {
  GetProviderById,
  updateProviderEmploymentById,
} from "../../../service/provider.service";
import { moveToAnotherBoard } from "../../../service/common.service";
import { getClientBoards } from "../../../redux/features/client-main/client-board-slice";
import { getProviderBoards } from "../../../redux/features/providers-main/provider-board-slice";
import { getAllCustomBoard } from "../../../redux/features/custom-board/custom-board-slice";
import { setResponseValue } from "../../../redux/features/api-response/api-response";

// material ui styling
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IClientStateCards {
  closeCommand: Dispatch<SetStateAction<boolean>>;
  cardData: {
    boardName: string;
    stages: any[];
    card: any;
  };
}

const UserStateCards: React.FC<IClientStateCards> = ({
  closeCommand,
  cardData,
}) => {
  //#region variable region
  //handle current check list
  const [checklistHistory, setChecklistHistory] = useState<string[]>([]);
  //handle comments
  const [comments, setComments] = useState<any[]>([]);
  //handle message
  const [message, setMessage] = useState<string>("");
  //handle message Id
  const [messageId, setMessageId] = useState<string>("");
  //handle selected tabs
  const [selectedTab, setSelectedTab] = useState<string>("Details");
  //handle current Entity
  const [currentEntity, setCurrentEntity] = useState<any>();
  //handle current stages
  const [currentStage, setCurrentStage] = useState<string>("");
  //handle stage checklist
  const [stageChecklist, setStageChecklist] = useState<any[]>([]);

  //handle dispatch
  const dispatch = useDispatch();
  //store commentId for dispatch

  //function that returns one. It's used to set the position of the popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  // #endregion

  // #region methods region

  //submit handler
  const onSubmit = async () => {
    closeCommand(false);
    dispatch(setResponseValue({ name: "pending", value: true }));
    if (cardData.card.entityType === "Client" && currentEntity) {
      const data = await updateClientWithBasicDetails({
        ...currentEntity,
        checklistHistory: checklistHistory,
      });
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: data?.message }));
    } else {
      const data = await updateProviderEmploymentById(currentEntity?.id, {
        ...currentEntity,
        checklistHistory: checklistHistory,
      });
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: data?.message }));
    }

    if (cardData.card.stageId !== currentStage) {
      await moveToAnotherBoard(
        cardData.card.stageId,
        currentStage,
        cardData.card.id,
        0
        // "Client"
      );
      dispatch(getClientBoards({ useLoader: false }));
      dispatch(getProviderBoards({ useLoader: false }));
      dispatch(getAllCustomBoard());
    }
    dispatch(setResponseValue({ name: "pending", value: false }));
  };

  //Line saperator
  const saperator = (text: string) => {
    return (
      <div>
        {text.split("\n").map((item: string) => {
          return <div key={item}>{item}</div>;
        })}
      </div>
    );
  };

  //post message handler
  const postMessage = async () => {
    try {
      if (!message) return;
      const payload = {
        subject: "internal message",
        message,
        whoCanView: ["admin", "parent"],
        // type: category.toLowerCase(),
        type: "internal",
        from: "",
        entityType: cardData.card.entityType,
      };

      const { data } = await postCreateMessage(payload, cardData.card.entityId);
      if (data) {
        setComments([data, ...comments]);
      }
    } catch (e) {
      console.log(e);
    }
    setMessage("");
  };

  //update current messages
  const updateCurrentMessage = async () => {
    try {
      if (!message) return;
      const payload = {
        subject: "internal message",
        message,
        whoCanView: ["admin", "parent"],
        // type: category.toLowerCase(),
        type: "internal",
        from: "",
      };

      const data = await updateMessage(messageId, payload);
      if (data.status) {
        setComments(
          comments.map((msg) => {
            if (msg.id === messageId) {
              return { ...msg, message };
            } else {
              return msg;
            }
          })
        );
        setMessageId("");
      }
    } catch (e) {
      console.log(e);
    }
    setMessage("");
  };

  //hadler delete comments
  const deleteMessageHandle = async () => {
    if (!messageId) return;

    const data = await deleteMessage(messageId);
    if (data.status) {
      setComments(comments.filter((msg) => msg.id !== messageId));
    }
    setMessageId("");
    setAnchorEl(null);
  };

  //component requests to be closed
  const handleClose = () => {
    setAnchorEl(null);
  };

  //if true Popover component show
  const open = Boolean(anchorEl);

  // get Message of User
  const getMessageOfUser = async () => {
    const message = (await getMessageByEntityId(cardData.card.entityId)).data;
    setComments(
      message.allUserMessage
        .filter((item) => item.type === "internal")
        .sort(
          (a, b) =>
            new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
        )
    );
  };
  //get Client data
  const getClient = async (id: string) => {
    const { data } = await getClientById(id);
    if (data) {
      setCurrentEntity(data.clientBasicDetails);
    }
  };

  //get Provider data
  const getProvider = async (id: string) => {
    const { data } = await GetProviderById(id);
    if (data) {
      setCurrentEntity(data.employmentDetails);
    }
  };
  // #endregion

  //#region useEffect region

  useEffect(() => {
    if (cardData.card.entityType === "Client") {
      getClient(cardData.card.entityId);
    } else {
      getProvider(cardData.card.entityId);
    }
  }, [cardData]);

  //handle checkedlist data
  useEffect(() => {
    //populate initial checklist from pre-defined array
    if (currentEntity) {
      if (cardData.card.entityType === "Client") {
        setChecklistHistory(currentEntity.checklistHistory);
      }
      if (cardData.card.entityType === "Provider") {
        setChecklistHistory(currentEntity.checklistHistory);
      }
      getMessageOfUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEntity, message?.length]);

  //
  useEffect(() => {
    let initStage: any;
    if (cardData) {
      if (
        cardData.boardName === "Intake Board" ||
        cardData.boardName === "Onboarding Board"
      ) {
        const stageName = currentEntity?.intakeStage
          ? currentEntity.intakeStage
          : currentEntity?.onBoardingStage;
        initStage = cardData.stages.find((stg) => stg.stageName === stageName);
      } else {
        //custom board
        initStage = cardData.stages.find(
          (stg) => stg.id === cardData.card.stageId
        );
      }
    }

    if (initStage) {
      batch(() => {
        setCurrentStage(initStage.id);
        setStageChecklist(initStage.checkList);
      });
    }
  }, [
    currentEntity?.intakeStage,
    currentEntity?.onBoardingStage,
    cardData?.card?.stageId,
    currentEntity,
  ]);

  // //handle initial checklist
  // useEffect(() => {
  //   const initCheckList = cardData.stages.find(
  //     (stage) => stage.id === currentStage
  //   )?.checkList;

  //   if (initCheckList) {
  //     setCheckList(initCheckList);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentStage]);
  //#endregion

  return (
    <Grid container spacing={3}>
      <Dialog open={true} className="largeModel">
        <Grid>
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              {cardData.boardName} - ({cardData.card.cardTitle})
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => {
                closeCommand(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <Box className="largeModel__body p-30px">
            <Box display={"flex"}>
              <ul className="innerTabs m-0 mb-8">
                <li className="innerTabs__item">
                  <button
                    onClick={() => setSelectedTab("Details")}
                    className={selectedTab === "Details" ? "active" : ""}
                  >
                    Details
                  </button>
                </li>
                <li className="innerTabs__item">
                  <button
                    onClick={() => setSelectedTab("News")}
                    className={selectedTab === "News" ? "active" : ""}
                  >
                    News
                  </button>
                </li>
              </ul>
            </Box>
            {selectedTab === "Details" && (
              <Grid container spacing={3} mb={"30px"}>
                <Grid item lg={6} xs={12}>
                  <Box className="fromGroup" style={{ marginBottom: "22px" }}>
                    <InputLabel className="fromLabel">Current Stage</InputLabel>
                    <Select
                      className="form-control"
                      value={currentStage}
                      placeholder="Select"
                      MenuProps={MenuProps}
                      onChange={(e: SelectChangeEvent<string>) => {
                        setCurrentStage(e.target.value);
                      }}
                    >
                      {cardData.stages.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.stageName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>

                  <InputLabel
                    id="demo-simple-select-label"
                    className="fromLabel"
                  >
                    Checklist
                  </InputLabel>
                  <Box className="checkBox-group vertical-group">
                    {stageChecklist?.map((item) => {
                      return (
                        <FormControlLabel
                          label={item}
                          control={
                            <Checkbox
                              checked={checklistHistory?.includes(item)}
                              onClick={() => {
                                if (checklistHistory?.includes(item)) {
                                  setChecklistHistory((list) =>
                                    list.filter((element) => element !== item)
                                  );
                                } else {
                                  setChecklistHistory((list) => [
                                    item,
                                    ...list,
                                  ]);
                                }
                              }}
                            />
                          }
                        />
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            )}
            {selectedTab === "News" && (
              <Container>
                <Box className="commentForm">
                  <TextareaAutosize
                    value={message}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setMessage(e.target.value)
                    }
                    placeholder="Leave a notice..."
                    minRows={2}
                  />
                  <Button
                    onClick={messageId ? updateCurrentMessage : postMessage}
                    variant="contained"
                    className="button"
                  >
                    {messageId ? "Update Notice" : "Add Notice"}
                  </Button>
                </Box>
                <Box className="commentListBox">
                  {comments.map((comment: any) => {
                    return (
                      <Box className="commentListItem" key={comment.id}>
                        <Box className="commentListItem__user">
                          <span className="commentListItem__user-img">
                            <img
                              src={
                                comment.fromUser?.profilePictureUrl
                                  ? comment.fromUser?.profilePictureUrl
                                  : ClientImg
                              }
                              className="userSmallCircle"
                              alt=""
                            />
                          </span>
                          <div className="commentListItem__user-info">
                            <div
                              className="commentListItem__user-heading"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <h5 className="commentListItem__user-name">
                                  {comment.fromUser?.firstName
                                    ? comment.fromUser?.firstName +
                                      " " +
                                      comment.fromUser?.lastName
                                    : comment.fromName}
                                </h5>
                                <h6 className="commentListItem__user-time">
                                  <span>
                                    {dateToFromNowDaily(comment.createdOn)}
                                  </span>
                                </h6>
                              </div>
                              <MoreVertIcon
                                sx={{ cursor: "pointer" }}
                                onClick={({
                                  currentTarget,
                                }: {
                                  currentTarget: any;
                                }) => {
                                  setMessageId(comment.id);
                                  setAnchorEl(currentTarget);
                                }}
                              />
                            </div>
                            <p className="commentListItem__user-comment">
                              {saperator(comment.message)}
                            </p>
                          </div>
                          <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <ul className="popoverLinkList">
                              <li
                                onClick={() => {
                                  const current = comments.find(
                                    (msg) => msg.id === messageId
                                  );
                                  setMessage(current.message);
                                  setAnchorEl(null);
                                }}
                              >
                                Edit
                              </li>
                              <li onClick={deleteMessageHandle}>Delete</li>
                            </ul>
                          </Popover>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Container>
            )}
          </Box>
          <Box className="stepperForm__footer b-0">
            <div></div>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button className="button" onClick={onSubmit}>
              Save
            </Button>
          </Box>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default UserStateCards;
