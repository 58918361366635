export const roles: string[] = ["owner", "admin", "operator"];

export const businessUnitForUsers: string[] = [
  "East Orlando Clinic",
  "West Orlando Clinic",
];

export enum OrgService {
  MentalHealth = "Mental Health",
  AutismPractice = "Autism Practice",
}

export enum UserStatus {
  Activated = "enabled",
  Invited = "invited",
  Disabled = "disabled",
}
