  //returns the business unit name
  export const getBusinessUnitName = (businessUnits: any, ids: string[]) => {
    let result: any = [];
    // console.log("ids:", ids);
    //handle ids can be "" or []
    if(ids !== undefined && ids !== null && typeof ids === "object") {
      ids.forEach((ele) => {
        const arr = businessUnits.find((bu:any) => bu.id === ele);
        if (arr) {
          result.push(arr.businessUnitName);
        }
      });
  
      return result;
    }
  };