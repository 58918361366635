import { createSlice } from "@reduxjs/toolkit";

interface InitState {
  activeStep: number;
  apiStatus: boolean | null;
  searchValue: string;
}

const initialState: InitState = {
  activeStep: 0,
  apiStatus: null,
  searchValue: "",
};

const activeStepsSlice = createSlice({
  name: "ActiveSlice",
  initialState,
  reducers: {
    setActiveSteps: (state, action) => {
      state.activeStep = action.payload;
    },

    setAPIStatus: (state, action) => {
      state.apiStatus = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
  },
});

export const { setActiveSteps, setAPIStatus, setSearchValue } =
  activeStepsSlice.actions;
export default activeStepsSlice;
