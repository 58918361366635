

import React, { useEffect, useState } from "react";
import "./PlanningTwo.scss";
import { Box } from "@mui/system";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import { Close } from "@mui/icons-material";
import NewPlan from "./NewPlan";

import { useDispatch, useSelector } from "react-redux";
import { IPlanDetail } from "../../types/planning";
import {
  getAllPlanList,
  deletePlan,
  setUpdateAPI,
} from "../../redux/features/plannings/planning-slice";
import { RootState } from "../../redux/store";
import { ProviderForms } from "../../types/provider";
import { useLocation } from "react-router-dom";
import { defaultPlanDetail } from "../../constants/planning";
import PlanTable from "./PlanTable";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

const PlanningTwo = () => {
  //#region variable region

  //handle add plan dialog box
  const [openPopup, setOpenPopUp] = useState<boolean>(false);

  //used to stored location
  const location = useLocation();

  // used to dispatch
  const dispatch = useDispatch();

  //function that returns one. It's used to set the position of the popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  //Provider Data fetching
  const providerData = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.listData
  );

  //used to store plan Id
  const [planId, setPlanId] = useState<string>("");

  // Get all list
  const AllPlanList = useSelector<RootState, IPlanDetail[]>(
    (state) => state.planSlice.allPlanList
  );

  // used to handle edit popup
  const [Editpopup, setEditPopup] = useState<boolean>(false);

  // used to handle edit popup
  const [Addpopup, setAddPopup] = useState<boolean>(false);

  //used to handle delete popup
  const [deletePlanpopup, setDeletePlanpopup] = useState<boolean>(false);

  //used to store selected Plan
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPlan, setSelectedPlan] =
    useState<IPlanDetail>(defaultPlanDetail);

  //handle delete planlist
  const handleDeletePlan = (id: string) => {
    setDeletePlanpopup(false);
    dispatch(deletePlan({ Id: planId }));
    setAnchorEl(null);
    //dispatch(setUpdateAPI(true)); //comment out, already addressed in deletePlan
  };

  //sortData function
  const sortData = (data: IPlanDetail[]) => {
    let result = [...data].sort((a, b) => {
      return (
        (new Date(a.plan.createdAt) as any) -
        (new Date(b.plan.createdAt) as any)
      );
    });

    return result;
  };

  //if true Popover component show
  // const open = Boolean(anchorEl);

  //used to update state
  const updatedata = useSelector<RootState, boolean>(
    (state) => state.planSlice.updateApi
  );

  //used to store planner list
  // eslint-disable-next-line
  const currentPlan = useSelector<RootState, IPlanDetail>(
    (state) => state.planSlice.currentPlan
  );

  //#endregion

  //#region useEffect region

  //fetch data
  // useEffect(() => {
  //   // Initial load - this runs when the component mounts
  //   if (!updatedata) {
  //     console.log("Initial load: getAllPlanList...");
  //     dispatch(getAllPlanList({ useLoader: true }));
  //   }
  // }, [dispatch]);
  
  useEffect(() => {
    // This runs when updatedata becomes true
    // if (updatedata) {
      // console.log("Updated data: getAllPlanList...");
      dispatch(getAllPlanList({ useLoader: true }));
      dispatch(setUpdateAPI(false)); // Reset update flag
    // }
  }, []);
  
  // console.log("updatedata:", updatedata);

  //#endregion

  return (
    <>
      <div className="planningTable">
        <div className="planningTable__header">
          <Typography className="planningTable__title" variant="h6">
            All Plans
          </Typography>
          <InputLabel></InputLabel>
          <button
            className="btn"
            onClick={() => {
              setOpenPopUp(true);
              setAddPopup(true);
            }}
          >
            + Add Plan
          </button>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell> Plan Name </TableCell>
                <TableCell> Supervisors</TableCell>
                <TableCell>Employees</TableCell>
                <TableCell>Clients</TableCell>
                {/* <TableCell>Client Utilization</TableCell>
                <TableCell>Employee Utilization </TableCell> */}
                <TableCell>Pending </TableCell>
                <TableCell>Planner</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {AllPlanList.length > 0 ? (
                sortData(AllPlanList).map(
                  (planDetail: IPlanDetail, index: number) => {
                    return (
                      <PlanTable
                        key={planDetail.plan.id || index}
                        Data={planDetail}
                        PlanId={planId}
                        anchorEl={anchorEl}
                        setPlanId={setPlanId}
                        setEditPopup={setEditPopup}
                        setOpenPopUp={setOpenPopUp}
                        setAnchorEl={setAnchorEl}
                        setDeletePlanpopup={setDeletePlanpopup}
                      />
                    );
                  }
                )
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={12}>
                    <div className="notFound-Block">No Plannings Found</div>
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {Editpopup && (
        <Dialog open={openPopup} className="largeModel">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Plan
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setOpenPopUp(false);
                setEditPopup(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <NewPlan
            providerData={providerData}
            setOpenPopUp={setOpenPopUp}
            Editpopup={Editpopup}
            setEditPopup={setEditPopup}
            setAddPopup={setAddPopup}
            planId={planId}
            planList={AllPlanList}
          />
        </Dialog>
      )}
      {Addpopup && (
        <Dialog open={openPopup} className="largeModel">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Add Plan
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setOpenPopUp(false);
                setEditPopup(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <NewPlan
            providerData={providerData}
            setOpenPopUp={setOpenPopUp}
            setAddPopup={setAddPopup}
            Editpopup={Editpopup}
            setEditPopup={setEditPopup}
            planId={planId}
            planList={AllPlanList}
          />
        </Dialog>
      )}

      {
        <Dialog open={deletePlanpopup} className="smallModel">
          {selectedPlan?.plan.status && (
            <Box className="largeModel__head">
              <Button
                className="largeModel__closeBtn"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  setAnchorEl(null);
                  setDeletePlanpopup(false);
                }}
              >
                <Close />
              </Button>
            </Box>
          )}
          <div>
            <DialogContent>
              {selectedPlan?.plan.status
                ? "This plan cannot be deleted. "
                : `Are you sure you want to delete this plan ${selectedPlan.plan.planName}?`}
            </DialogContent>
            <DialogActions>
              {!selectedPlan?.plan.status && (
                <>
                  <Button
                    className="border-button"
                    variant="contained"
                    onClick={() => {
                      setDeletePlanpopup(false);
                      setAnchorEl(null);
                    }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button"
                    variant="contained"
                    onClick={() => {
                      handleDeletePlan(selectedPlan?.plan.id);
                    }}
                  >
                    Yes
                  </Button>
                </>
              )}
            </DialogActions>
          </div>
        </Dialog>
      }
    </>
  );
};

export default PlanningTwo;
