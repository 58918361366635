
import { IUserProfile } from "../types/user";

export const defaultUserProfile: IUserProfile = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    emailVerified: false,
    businessUnit: "",

    // authSystemName: "",
    authTenantId: "",
    authUserId: "",
    authRole: "",

    createdBy: "",
    updatedBy: "",
    profilePictureUrl: "",

    createdAt: new Date(),
    updatedAt: new Date(),
    status: ""
};