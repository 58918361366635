import React, { useEffect, useState } from "react";
import { ICenter, IMarkerData } from "../../../types/Common";
import { defaultUtilization } from "../../../constants/availability";
import {
  GoogleMap,
  InfoWindow,
  Libraries,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { ProviderForms } from "../../../types/provider";
import { ClientForms } from "../../../types/client";
import ClientMarker from "../../../assets/images/images-png/pin-green.png";
import ProviderMarker from "../../../assets/images/images-png/pin-blue.png";
import ClientHighlightMarker from "../../../assets/images/images-png/pin-red.png";
import ProviderHighlightMarker from "../../../assets/images/images-png/pin-red.png";

const libraries: Libraries | undefined = ["places"]; // Define your required libraries here
interface IPlanningMap {
  providerListData: ProviderForms[];
  clientsListData: ClientForms[];
}
const PlanningMap: React.FC<IPlanningMap> = ({
  providerListData,
  clientsListData,
}) => {
  //markers data
  const [markersData, setMarkersData] = useState<IMarkerData[]>([]);

  //store markerMap data
  const [markerMap, setMarkerMap] = useState<{
    [key: string]: google.maps.Marker;
  }>({});

  //change to handle booking delete and edit
  // eslint-disable-next-line
  const [bookingChange, setBookingChange] = useState<boolean>(false);

  //handles openInfo for clients and providers
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  //set Value for provider Profile popup
  const [activeProfile, setActiveProfile] = useState<boolean>(false);
  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (
    marker: google.maps.Marker,
    place: IMarkerData
  ) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  //selected place used for information address
  const [selectedPlace, setSelectedPlace] = useState<IMarkerData>({
    id: "",
    entityType: "",
    pos: {
      lat: 0,
      lng: 0,
    },
    fullAddress: "",
    name: "",
    utilization: defaultUtilization,
  });
  // holds the popup data displyed on the map for provider or client
  const [popUpData, setPopUpData] = useState<
    ClientForms | ProviderForms | null
  >(null);

  const { isLoaded, loadError } = useJsApiLoader({
    //id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBltIfej576bf5xww8OmmdFT93sw45a5mE",
    libraries: libraries, //googleMapLibraries, //must have here!!! since map will be loaded first.
  });

  // Function called after a delay
  const infOpen = () => {
    setInfoOpen(true);
  };

  const markerClickHandler = (place: IMarkerData) => {
    let setFlag = true;

    while (setFlag) {
      for (let i = 0; i < clientsListData.length; i++) {
        if (clientsListData[i].clientBasicDetails.id === place.id) {
          setPopUpData(clientsListData[i]);
          setActiveProfile(true);
          setFlag = false;
        }
      }
      for (let i = 0; i < providerListData.length; i++) {
        if (providerListData[i]?.employmentDetails.id === place.id) {
          setPopUpData(providerListData[i]);
          setActiveProfile(true);
          setFlag = false;
        }
      }
    }
    // Remember which place was clicked
    setSelectedPlace(place);
    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }
    setTimeout(infOpen, 0);
    // if you want to center the selected Marker
    // setCenter(place.pos);
  };

  const [center, setCenter] = useState<ICenter>({
    lat: 0,
    lng: 0,
  });

  const renderMap = () => {
    return (
      <GoogleMap
        center={center}
        zoom={12}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        options={{
          styles: [
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        {markersData.map((place: IMarkerData, index: number) => (
          <Marker
            key={place.id}
            position={place.pos}
            // icon={place.entityType === "client"? ClientMarker : ProviderMarker}
            icon={{
              url:
                place.entityType === "client"
                  ? place.id === selectedPlace.id
                    ? ClientHighlightMarker
                    : ClientMarker
                  : place.id === selectedPlace.id
                  ? ProviderHighlightMarker
                  : ProviderMarker,
              scaledSize:
                place.id === selectedPlace.id
                  ? new google.maps.Size(70, 70)
                  : new google.maps.Size(50, 50),
            }}
            onLoad={(marker: google.maps.Marker) => {
              return markerLoadHandler(marker, place);
            }}
            onMouseOver={() => markerClickHandler(place)}
            onMouseOut={() => setInfoOpen(false)}
          />
        ))}
        {infoOpen && selectedPlace && (
          <InfoWindow
            anchor={markerMap[selectedPlace.id]}
            onCloseClick={() => {
              setInfoOpen(false);
              setActiveProfile(false);
              setPopUpData(null);
            }}
          >
            <div>
              <h3>{selectedPlace.name}</h3>
              <div>{selectedPlace.fullAddress}</div>
              <div>
                Avg Wk Booked:&emsp;&emsp;
                {selectedPlace.utilization.weeklyAvgBooked.toFixed(2)}
              </div>
              <div>
                Avg Wk Available:&ensp;&ensp;&nbsp;
                {selectedPlace.utilization.weeklyAvgAvailability.toFixed(2)}
              </div>
              <div>
                Avg Wk Remaining:&ensp;
                {selectedPlace.utilization.weeklyAvgRemainedByAvailability.toFixed(
                  2
                )}
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };
  useEffect(() => {
    // eslint-disable-next-line
    const markerDesign: IMarkerData[] = [];

    let isCenterSet = false;

    clientsListData.forEach((item: ClientForms, index: number) => {
      if (
        !isCenterSet &&
        item?.clientContactDetails?.address?.fullAddress?.trim().length > 0 &&
        item?.clientContactDetails?.address?.lat !== 0
      ) {
        setCenter({
          lat: parseFloat(`${item?.clientContactDetails?.address?.lat}`),
          lng: parseFloat(`${item?.clientContactDetails?.address?.lng}`),
        });
        isCenterSet = true;
      }
      const markerDataClient: IMarkerData = {
        id: item?.clientBasicDetails?.id,
        entityType: "client",
        pos: {
          lat: parseFloat(`${item?.clientContactDetails?.address?.lat}`),
          lng: parseFloat(`${item?.clientContactDetails?.address?.lng}`),
        },
        fullAddress:
          item?.clientContactDetails?.address?.fullAddress +
          " " +
          item?.clientContactDetails?.city +
          " " +
          item?.clientContactDetails?.state +
          " " +
          item?.clientContactDetails?.zipCode,
        name:
          item?.clientBasicDetails?.childFirstName +
          " " +
          item?.clientBasicDetails?.childLastName,
        utilization: item?.clientUtilization,
      };

      markerDesign.push(markerDataClient);

      if (isCenterSet) setSelectedPlace(markerDataClient);
    });

    providerListData.forEach((item: ProviderForms) => {
      if (
        !isCenterSet &&
        item?.contactDetails?.address?.fullAddress?.trim().length > 0 &&
        item?.contactDetails?.address?.lat !== 0
      ) {
        setCenter({
          lat: parseFloat(`${item?.contactDetails?.address?.lat}`),
          lng: parseFloat(`${item?.contactDetails?.address?.lng}`),
        });
        isCenterSet = true;
      }

      const markerDataProvider: IMarkerData = {
        id: item?.employmentDetails?.id,
        entityType: "provider",
        pos: {
          lat: parseFloat(`${item?.contactDetails?.address?.lat}`),
          lng: parseFloat(`${item?.contactDetails?.address?.lng}`),
        },
        fullAddress:
          item?.contactDetails?.address?.fullAddress +
          " " +
          item?.contactDetails?.city +
          " " +
          item?.contactDetails?.state +
          " " +
          item?.contactDetails?.zipCode,
        name:
          item?.employmentDetails?.firstName +
          " " +
          item?.employmentDetails?.lastName,
        utilization: item?.providerUtilization,
      };

      markerDesign.push(markerDataProvider);

      if (isCenterSet) {
        setSelectedPlace(markerDataProvider);
        setCenter({
          lat: parseFloat(
            `${
              providerListData[providerListData.length - 1]?.contactDetails
                ?.address?.lat
            }`
          ),
          lng: parseFloat(
            `${
              providerListData[providerListData.length - 1]?.contactDetails
                ?.address?.lng
            }`
          ),
        });
      }
    });

    setMarkersData(markerDesign);

    return () => {
      setMarkersData([]);
      setCenter({ lat: 0, lng: 0 });
    };
  }, [providerListData, clientsListData]);
  return (
    <div>
      <div className="planning-map">
        {isLoaded && renderMap()}
        {loadError && <div>Map cannot be loaded right now, sorry.</div>}
      </div>
    </div>
  );
};

export default PlanningMap;
