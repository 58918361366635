import { Box, Grid } from "@mui/material";
import Stages from "./Stages";
import { Board } from "../../../../types/board";
import { Dispatch, SetStateAction } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { setNewBoard } from "../../../../redux/features/custom-board/custom-board-slice";
import { useDispatch } from "react-redux";

interface StageComponentProps {
  newBoard: Board;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<boolean>>;
}

const StageComponent: React.FC<StageComponentProps> = ({
  newBoard,
  setError,
  setErrorMsg,
}) => {
  const dispatch = useDispatch();

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!source || !destination) {
      return;
    }
    if (newBoard.primitive || source.index === destination?.index) return;

    let stages = [...newBoard.stages];

    if (source.index < destination.index) {
      let reserve: any;

      for (let index = destination.index; index >= source.index; index--) {
        let temp = stages[index];
        if (reserve) stages[index] = { ...reserve, index };
        reserve = temp;
      }
      if (reserve)
        stages[destination.index] = { ...reserve, index: destination.index };
    }

    if (source.index > destination.index) {
      let reserve: any;

      for (let index = destination.index; index <= source.index; index++) {
        let temp = stages[index];
        if (reserve) stages[index] = { ...reserve, index };
        reserve = temp;
      }
      if (reserve)
        stages[destination.index] = { ...reserve, index: destination.index };
    }

    dispatch(setNewBoard({ name: "stages", value: stages }));
  };

  return (
    <Grid>
      <Box className="stage">
        <DragDropContext onDragEnd={onDragEnd}>
          {newBoard.stages.map((stage, i) => {
            return (
              <Droppable droppableId={stage?.id} direction="vertical">
                {(provider) => (
                  <div
                    style={{ minHeight: "70px" }}
                    {...provider.droppableProps}
                    ref={provider.innerRef}
                  >
                    <Draggable key={stage.id} draggableId={stage.id} index={i}>
                      {(provider) => (
                        <Stages
                          setErrorMsg={setErrorMsg}
                          setError={setError}
                          primitive={newBoard.primitive}
                          key={i}
                          stageName={stage.stageName}
                          stageId={stage.id}
                          provider={provider}
                          permission={newBoard.permission}
                          boardType={newBoard.boardType}
                        />
                      )}
                    </Draggable>
                    {provider.placeholder}
                  </div>
                )}
              </Droppable>
            );
          })}
        </DragDropContext>
      </Box>
    </Grid>
  );
};

export default StageComponent;
