import React from "react";
import { Box } from "@mui/material";

import { Tabs } from "../../constants/tabs";

import Message from "../../assets/images/images-png/Message.png";

import "./HeaderTab.scss";

interface HeaderTabProps {
  onTabClick: (tabData: Tabs) => void;
  selectedTab: string;
  tabs: Tabs[];
}

const HeaderTab = (props: HeaderTabProps) => {
  return (
    <Box display={"flex"} className="mainHeader__menu desk-menu">
      {props.tabs.map((tab, index) => (
        <div
          onClick={() => props.onTabClick(tab)}
          key={`${tab.tabName + index}`}
          className={
            props.selectedTab === tab.tabName
              ? "menuItem--active menuItem"
              : "menuItem"
          }
        >
          <span className="menuItem__link">
            {
              // {tab.tabName}
              tab.tabName === "Message" ? (
                <img src={Message} alt="Message icon" />
              ) : (
                tab.tabName
              )
            }{" "}
          </span>
        </div>
      ))}
    </Box>
  );
};
export default HeaderTab;
