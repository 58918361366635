import React, { useEffect } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import { useUser, useSession } from "@descope/react-sdk";
import { AppUrls } from "../AppUrls";

const PublicRoute = ({ children, ...rest }: any) => {
  /* Descope */
  const { isAuthenticated } = useSession();
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  // console.log("public path:", location.pathname, user?.userTenants?.length, isAuthenticated);

  useEffect(() => {
    if (isAuthenticated && user?.verifiedEmail) {
      if (user?.userTenants?.length === 0) {
        //no tenant yet
        navigate(AppUrls.Client.OrganizationOnboard);
      } else {
        //have tenant already
        if (location.pathname === AppUrls.Client.SignUp) {
          navigate(AppUrls.Client.OrganizationOnboard);
        } else if (
          location.pathname === AppUrls.Client.SignIn ||
          location.pathname === AppUrls.Client.Home
        ) {
          navigate(localStorage.getItem("path") || AppUrls.Client.Map);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user, location]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default PublicRoute;
