import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import { IAssessmentForm } from "../../../types/assessment";
import FormService, { updateSessionFormApplicable, updateSessionFormCheckList } from "../../../service/form.service";
import { assessmentData } from "../../../constants/assessment";
import { BusinessFormType } from "../../../constants/forms"

const initialState: IAssessmentForm = {
  ...assessmentData,
};

const formSlice = createSlice({
  name: "formSlice",
  initialState: initialState,
  reducers: {
    setFormData: (state, action) => {
      return {
        ...state,
        AssessmentjsonData: action.payload,
      };
    },
    setClearForm: (state, action) => {
      return {
        ...state,
        AssessmentRequests: [],
        AllFormRequests: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetFormCards.fulfilled, (state, action) => {
      // console.log("getFormCards", action.payload);
      if (action.payload !== undefined) {
        //form specific fetch
        if (action.payload.formType === BusinessFormType.IntakePacketForm) {
          return {
            ...state,
            IntakeForms: action.payload.data.forms,
            hasSetting: action.payload.data.hasSetting,
          };
        } else if (
          action.payload.formType === BusinessFormType.EmployeePacketForm
        ) {
          return {
            ...state,
            ProviderForms: action.payload.data.forms,
            hasSetting: action.payload.data.hasSetting,
          };
        } else if (
          action.payload.formType === BusinessFormType.AssessmentForm
        ) {
          return {
            ...state,
            AssessmentCards: action.payload.data.forms,
            hasSetting: action.payload.data.hasSetting,
          };
        } else {
          //All Forms
          return {
            ...state,
            AllForms: action.payload.data.forms,
            hasSetting: action.payload.data.hasSetting,
          };
        }
      }
    });

    // builder.addCase(PostFormRequest.fulfilled, (state, action) => {
    //   if (action.payload !== undefined) {
    //     return {
    //       ...state,
    //       Assessments: action.payload,
    //     };
    //   }
    // });

    builder.addCase(GetFormRequests.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const formType = action.payload.formType;
        if (formType === "All Assessment") {
          return {
            ...state,
            AssessmentRequests: action.payload.data,
          };
        } else if (formType === "All Forms, No Assessments") {
          return {
            ...state,
            AllFormRequests: action.payload.data,
          };
        } else {
          throw new Error("Invalid form type");
        }
      }
    });

    builder.addCase(GetFormById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          AssessmentFormData: action.payload,
        };
      }
    });
    builder.addCase(GetCommunicationSetting.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          CommunicationSettings: action.payload,
        };
      }
    });
    builder.addCase(UpdateCommunicationSetting.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.CommunicationSettings = action.payload;
      }
    });
    builder.addCase(UpdateFormActiveById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        let data = state.AllForms.map((item) => {
          if (item.id === action.payload?.id) {
            return {
              ...item,
              active: action.payload.value,
              updatedAt: new Date().toISOString(),
            };
          }
          return item;
        });
        return {
          ...state,
          AllForms: data,
        };
      }
    });
    builder.addCase(UpdateSessionFormCheckList.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        let data = state.AllForms.map((item) => {
          if (item.id === action.payload?.formId) {
            return {
              ...item,
              checkList: action.payload.checkList,
            };
          }
          return item;
        });
        return {
          ...state,
          AllForms: data,
        };
      }
    });
  },
});

export const GetFormCards = createAsyncThunk(
  "/GetFormCards",
  async ({ formType }: { formType?: string }, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await FormService.getFormsByType(formType);
      return { formType, data };
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const UpdateFormActiveById = createAsyncThunk(
  "/UpdateFormActiveById",
  async (
    {
      id,
      value,
    }: {
      id: string;
      value: boolean;
    },
    { dispatch, getState }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message } = await FormService.updateFormActiveById(
        id,
        value
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { id, value };
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetFormById = createAsyncThunk(
  "getFormById",
  async ({ formId }: { formId: string }, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, status } = await FormService.getFormById(formId);
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const UpdateSessionFormCheckList = createAsyncThunk(
  "updateFormChecklist",
  async (
    { formId, checkList }: { formId: string; checkList: string[] },
    { dispatch }
  ) => {
    try {
      const { data, message } = await updateSessionFormCheckList(formId, checkList);
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { formId, checkList };
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const UpdateSessionFormApplicable = createAsyncThunk(
  "updateFormApplicable",
  async (
    { formId, insurances, authCodes }: { formId: string, insurances: string[], authCodes: string[] },
    { dispatch }
  ) => {
    try {
      const { data, message } = await updateSessionFormApplicable(formId, insurances, authCodes);
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { formId };
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//------------------Form Communication------------------//

export const GetCommunicationSetting = createAsyncThunk(
  "GetCommunicationSetting",
  async (
    { communicationType }: { communicationType: string },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await FormService.getCommunicationSetting(
        communicationType
      );
      return data;
    } catch (e: any) {
      //do something if needed
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const UpdateCommunicationSetting = createAsyncThunk(
  "/UpdateCommunicationSetting",
  async (
    {
      id,
      inMailTemplate,
      inMailActive,
      emailTemplate,
      emailActive,
      smsTemplate,
      smsActive,
      // boardValue,
      // boardActive,
      name,
      days,
      daysActive,
    }: {
      id: string;
      inMailTemplate: string;
      inMailActive: boolean;
      emailTemplate: string;
      emailActive: boolean;
      smsTemplate: string;
      smsActive: boolean;
      // boardValue: string;
      // boardActive: boolean;
      name: string;
      days: number;
      daysActive: boolean;
    },
    { dispatch }
  ) => {
    const payloadData = {
      id: id,
      inMailTemplate: inMailTemplate,
      inMailActive: inMailActive,
      emailTemplate: emailTemplate,
      emailActive: emailActive,

      smsTemplate: smsTemplate,
      smsActive: smsActive,
      // boardValue: boardValue,
      // boardActive: boardActive,
      name: name,
      days: days,
      daysActive: daysActive,
    };
    dispatch(setResponseValue({ name: "pending", value: true }));

    try {
      const { status, message, data } =
        await FormService.updateCommunicationSetting(id, payloadData);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//------------------Form Request------------------//

export const PostFormRequest = createAsyncThunk(
  "/PostFormRequest",
  async (
    {
      formId,
      entityIds,
      entityType,
      formType,
    }: {
      formId: string;
      entityIds: string[];
      entityType: string;
      formType: string;
    },
    { dispatch }
  ) => {
    const payloadData = {
      formId: formId,
      entityIds: entityIds,
      entityType: entityType,
      formType: formType,
    };
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message, data } = await FormService.createFormRequest(
        payloadData
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        if (formType === BusinessFormType.AssessmentForm || formType === BusinessFormType.ShareableAssessmentForm) {
          dispatch(GetFormRequests({ formType: "All Assessment" }));
        } else {
          dispatch(GetFormRequests({ formType: "All Forms, No Assessments" }));
        }
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetFormRequests = createAsyncThunk(
  "/getFormRequests",
  async ({ formType }: { formType: string }, { dispatch }) => {
    // dispatch(setClearForm({}));
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await FormService.getFormRequestsByType(formType);
      return { data, formType };
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetFormRequestById = createAsyncThunk(
  "/GetFormRequestById",
  async ({ assessmentId }: { assessmentId: string }, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, status } = await FormService.getFormRequestById(
        assessmentId
      );
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.message || "Error occured",
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const ReprocessFormRequest = createAsyncThunk(
  "/ReprocessFormRequest",
  async (
    {
      id,
      formType,
    }: {
      id: string;
      formType: string;
    },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    // console.log("id:", id);
    try {
      const { status, message, data } = await FormService.reprocessFormRequest(
        id
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        //same logic as PostFormRequest
        if (formType === BusinessFormType.AssessmentForm || 
          formType === BusinessFormType.ShareableAssessmentForm) {
          dispatch(GetFormRequests({ formType: "All Assessment" }));
        } else {
          dispatch(GetFormRequests({ formType: "All Forms, No Assessments" }));
        }
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setFormData, setClearForm } = formSlice.actions;
export default formSlice;
