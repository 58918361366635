import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import {
  IProject,
  ITask,
  TaskType,
  checklist,
} from "../../../types/taskManager";
import TaskManagerService from "../../../service/taskManager.service";

interface ITaskManager {
  project: IProject[];
  tasks: ITask[];
  currentProject: IProject | null;
  AllTaskType: TaskType[];
}

const initialState: ITaskManager = {
  project: [],
  tasks: [],
  currentProject: null,
  AllTaskType: [],
};

const taskManagerSlice = createSlice({
  name: "taskManagerSlice",
  initialState: initialState,
  reducers: {
    sortTask: (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          tasks: action.payload,
        };
      }
    },
    setUpdatedProject: (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          project: [...state.project, action.payload.data],
        };
      }
    },
    filterDeletedProject: (state, action) => {
      if (action.payload !== undefined) {
        const data = state.project.filter((item) => item.id !== action.payload);
        return {
          ...state,
          project: data,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllProject.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          project: action.payload,
        };
      }
    });
    builder.addCase(GetProjectById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          currentProject: action.payload,
          tasks: action.payload.tasks,
        };
      }
    });

    builder.addCase(UpdateProjectById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const data = state.project.map((item: IProject) => {
          if (item.id === action.payload?.id) {
            return (item = action.payload?.data);
          } else {
            return item;
          }
        });
        return {
          ...state,
          project: data,
        };
      }
    });

    builder.addCase(GetMyTask.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          tasks: action.payload,
        };
      }
    });

    builder.addCase(PatchTaskById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const data = state.tasks.map((item: ITask) => {
          if (item.id === action.payload?.id) {
            return (item = action.payload.data);
          } else {
            return item;
          }
        });
        return {
          ...state,
          tasks: data,
        };
      }
    });
    builder.addCase(PostTask.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        if (state?.tasks?.length > 0) {
          return {
            ...state,
            tasks: [action.payload.data, ...state?.tasks],
          };
        } else {
          return {
            ...state,
            tasks: [action.payload.data],
          };
        }
      }
    });
    builder.addCase(UpdateTaskbyId.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const data = state.tasks.map((item: ITask) => {
          if (item.id === action.payload?.id) {
            return (item = action.payload?.data);
          } else {
            return item;
          }
        });
        return {
          ...state,
          tasks: data,
        };
      }
    });

    builder.addCase(DeleteTaskById.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const data = state.tasks.filter(
          (item) => item.id !== action.payload?.id
        );
        return {
          ...state,
          tasks: data,
        };
      }
    });

    builder.addCase(GetAllTaskType.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          AllTaskType: action.payload,
        };
      }
    });
  },
});

//gets all projects
export const GetAllProject = createAsyncThunk(
  "get/project",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await TaskManagerService.getAllProject();
      return data;
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//gets Project by Id
export const GetProjectById = createAsyncThunk(
  "get/projectbyId",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await TaskManagerService.getProjectById(id);
      return data;
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//create project
export const PostProject = createAsyncThunk(
  "post/project",
  async (
    { name, description }: { name: string; description: string },
    { dispatch }
  ) => {
    const payload = {
      name: name,
      description: description,
    };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await TaskManagerService.postProject(payload);
      if (data) {
        dispatch(setUpdatedProject({ data }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//update Project by Id
export const UpdateProjectById = createAsyncThunk(
  "update/project",
  async (
    {
      id,
      name,
      description,
    }: { id: string; name: string; description: string },
    { dispatch }
  ) => {
    const payload = {
      name: name,
      description: description,
    };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await TaskManagerService.updateProjectbyId(
        payload,
        id
      );
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { data, id };
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//delete Project by Id
export const DeleteProjectByID = createAsyncThunk(
  "delete/project",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      // dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await TaskManagerService.deleteProjectById(
        id
      );
      if (status) {
        dispatch(filterDeletedProject(id));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get current user Tasks
export const GetMyTask = createAsyncThunk(
  "get/task",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await TaskManagerService.getMyTasks();
      return data;
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//create Task
export const PostTask = createAsyncThunk(
  "post/task",
  async (
    {
      name,
      description,
      type,
      priority,
      status,
      checklist,
      projectId,
      dueDate,
      assignTo,
      entityId,
      entityType,
    }: {
      name: string;
      description: string;
      type: string;
      priority: string;
      status: string;
      checklist: checklist[];
      projectId: string;
      dueDate: Date;
      assignTo: string | null;
      entityId: string | null;
      entityType: string | null;
    },
    { dispatch }
  ) => {
    const payload = {
      name: name,
      description: description,
      type: type,
      priority: priority,
      status: status,
      checklist: checklist,
      projectId: projectId,
      dueDate: dueDate,
      assignTo: assignTo,
      entityId: entityId,
      entityType: entityType,
    };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await TaskManagerService.postTask(payload);
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { data };
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//update my Tasks
export const UpdateTaskbyId = createAsyncThunk(
  "update/taskbyId",
  async (
    {
      id,
      name,
      description,
      type,
      priority,
      status,
      checklist,
      dueDate,
      assignTo,
      entityId,
      entityType,
    }: {
      id: string;
      name: string;
      description: string;
      type: string;
      priority: string;
      status: string;
      checklist: checklist[];
      dueDate: Date;
      assignTo: string | null;
      entityId: string | null;
      entityType: string | null;
    },
    { dispatch }
  ) => {
    const payload = {
      name: name,
      description: description,
      type: type,
      priority: priority,
      status: status,
      checklist: checklist,
      dueDate: dueDate,
      assignTo: assignTo,
      entityId: entityId,
      entityType: entityType,
    };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await TaskManagerService.updateTaskById(
        id,
        payload
      );
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { data, id };
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//update my Tasks
export const PatchTaskById = createAsyncThunk(
  "assign/task",
  async (
    {
      id,
      type,
      value,
    }: {
      id: string;
      type: string;
      value: string;
    },
    { dispatch }
  ) => {
    const payload = {
      type: type,
      value: value,
    };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, message } = await TaskManagerService.patchTaskById(
        id,
        payload
      );
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { data, id };
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//Delete Task
export const DeleteTaskById = createAsyncThunk(
  "delete/task",
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await TaskManagerService.deleteTaskById(id);
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { id };
      }
    } catch (error: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetAllTaskType = createAsyncThunk(
  "/getTaskType",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await TaskManagerService.getAllTaskType();
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const ReorderTask = createAsyncThunk(
  "/ReorderTask",
  async (order: Record<string, number>, { dispatch }) => {
    try {
      const { data } = await TaskManagerService.updateTaskOrder(order);
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setUpdatedProject, filterDeletedProject, sortTask } =
  taskManagerSlice.actions;
export default taskManagerSlice;
