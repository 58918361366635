class GlobalAppUrls {
  public Client = {
    Home: "/",
    Tools: "/tools",
    ImportDetails: "/import-details",
    ExportClientsProviderDetails: "/export-details",
    SignIn: "/sign-in",
    SignUp: "/sign-up",
    OrganizationOnboard: "/organization-onboard",
    Clients: "/clients",
    Billing: "/my-plan",
    ViewPlan: "/view-plan",
    PayFirst: "/pay-first",
    Employees: "/employees",
    Map: "/map",
    Planning: "/planning",
    // PlanningTwo: "/planningTwo",
    PlanningDetails: "/planning-detail/:id",
    PlanningChart: "/planning/:id",
    IntakeForm: "/publicform/:formId",
    Schedules: "/schedules",
    Provider: "/provider/:id",
    SurveyCreator: "/surveycreator/:id",
    Assessments: "/assessment",
    Forms: "/forms",
    SecurityLogs: "/security-logs",
    ScheduleDetails: "/schedule/:id",
    OrgProfile: "/org-profile/:orgId",
    SchedulesMatch: "/schedules-match",
    ClientDetails: "/client/:id",
    ClientSchedules: "/client-schedules",
    ProvidersDetails: "/providers-details",
    UserManagement: "/:userId/user-management",
    CompanyProfile: "/company-profile",
    CompanyUserManagement: "/user-management",
    BusinessSettings: "/business-settings",
    AppIntegrations: "/app-integrations",
    MessageTemplates: "/message-template",
    Board: "/board",
    DataView: "/dataview",
    Message: "/message-center",
    Dashboard: "/dashboard",
    composeInmail: "/compose-message",
    Session:"/sessions",
    SessionDetail:"/session/:id",
    SessionFormReview:"/form-review/:id",
    UnknownRoute: "*",
  };

  public Server = {
    Account: {
      Login: "/api/v1/users/login",
      Logout: "/api/v1/users/logout",
    },
  };
}

export const AppUrls = new GlobalAppUrls();
