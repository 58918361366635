//Note: same as backend


//enum
export enum FullFillResultType {
  UnFillable = "Un-Fillable",
  FillFully = "Fill Fully",
  FillPartially = "Fill Partially",
  NotStarted = "Not Started",
}

export enum StrategyType {
  NoStrategy="No Strategy",
  FillRemainingAuth = "Allocate Remaining Auth",
  FillRemainingAvailability = "Allocate Remaining Availability",
}


export enum UtilizationRequestType {
    PerAvailability="PerAvailability", //per 1 availability table
    PerAuthorization="PerAuthorization", //per 1 authorization table
    PerUserAvailabilityTable="PerUserAvailabilityTable", //per user & multi availability tables 
    PerUserAuthTable="PerUserAuthTable", //per user & multi authorization tables 
}

export enum UtilizationTimeTableType {
    AvailabilityTable="AvailabilityTable",
    AuthTable="AuthTable",
}

export interface UtilizationRequest {
    requestType: UtilizationRequestType;
    tenantId: string; //which tenant organization
    
    //requestType=PerAvailability
    availabilityId: string;

    //requestType=PerDateRange
    //requestType=PerAvailability
    //requestType=PerUser
    //requestType=PerPlan
    checkFromDate: Date;
    checkToDate: Date;

    //requestType=PerUser
    entityType: string;
    entityId: string;
    
    //requestType=PerAuth & Code
    authId: string;
    authCode: string;

    //requestType=PerPlan
    planId: string;
}

export interface UtilizationTimeTable {
    timeTableType: UtilizationTimeTableType;
    timeTableId: string; //availability table Id or auth table Id
    timeTableCode: string; //next level time table code

    //Date Range Based On Availability table(s) range
    //Or Data Range Based on Authorization table(s) range
    totalWeeksChecked: number;
    weeksHaveBooked: number;

    //unit: hours
    totalBooked: number;
    remainingHours: number;
    totalAvailability: number;
    totalAuthorization: number;
    remainingAuthHours: number;

    //weekly avg
    weeklyAvgBooked: number;
    weeklyAvgAvailability: number; //original availability
    weeklyAvgAuthorization: number; //original authorization
    weeklyAvgRemainedByAvailability: number;
    weeklyAvgRemainedByAuthorization: number; 

    //utilization%
    utilizationPcntByAvailability: number;
    utilizationPcntByAuthorization: number;

   //utilization strategies
  //  strategyResults: UtilizationStrategyResult[];
    
    error: string;
}

export interface UtilizationStrategyResult {
  askHours: number;
  strategyType: StrategyType;
  strategy: number;
  fillResultType: FullFillResultType;
  // weeksNeeded: number;
  allocatedWeeks: number;
  fillableHours: number;
  unfillableHours: number; //for unfill or partial fill, 
  afterFillRemainingHours: number; //after fill, remaining hours

  weeklyAvgToAllocate: number;
  weeklyAvgRemained: number; //by remaining auth
}

export interface UtilizationResult {
  request: UtilizationRequest;
  status: string;

  //breakdown results at Time Table (availability or authorization)
  timeTables: UtilizationTimeTable[];

  //group by level
  totalWeeksChecked: number;
  weeksHaveBooked: number;

  //aggregate level for all time tables
  totalBooked: number;
  remainingHours: number;
  totalAvailability: number;
  totalAuthorization: number;
  remainingAuthHours: number;

  //aggregate level for all time tables
  weeklyAvgBooked: number;
  weeklyAvgAvailability: number;
  weeklyAvgAuthorization: number; //original authorization
  weeklyAvgRemainedByAvailability: number;
  weeklyAvgRemainedByAuthorization: number;

  //utilization%
  utilizationPcntByAvailability: number;
  utilizationPcntByAuthorization: number;

  error: string;
}