import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { GetAllSession } from "../../redux/features/session/session-slice";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ISession } from "../../types/session";
import { useNavigate } from "react-router";
import moment from "moment";
import "./session.scss";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  TextWrapSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { SessionStatus, SessionType } from "../../constants/session";

const Session = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<{ type: string[]; status: string[] }>({
    type: [],
    status: [],
  });

  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };
  const onRowSelected = (args: { data: any; }) => {
    const selectedRecord = args.data; // Get the selected row's data
    const recordId = selectedRecord.id; // Adjust this based on your data structure

    // Navigate to another page with the record ID
    navigate(`/session/${recordId}`);
  };

  const scheduleTemplate = (props: any) => (
    <>
      <Typography>
        {moment(props.serviceSchedule.specificDate).format("dddd, M/D/YYYY")}
      </Typography>
      <Typography>
        {props.serviceSchedule.fromTime} - {props.serviceSchedule.toTime}
      </Typography>
    </>
  );

  const actualTemplate = (props: any) => (
    <>
      <Typography>
        {moment(props.sessionBeginDtm).format("dddd, M/D/YYYY")}
      </Typography>
      <Typography>
        {props.sessionBeginTime} - {props.sessionEndTime || "ongoing"}
      </Typography>
    </>
  );

  const updateDtmTemplate = (props: any) => (
    <span>{moment(props.updatedAt).format("MM/DD/YYYY hh:mm:ssa")}</span>
  );

  const personName = (props: any) => {
    return (
      <div>
        {props?.column.field === "clientName" && (
          <span>
            {" "}
            {props.serviceSchedule.bookedClientId?.childFirstName}{" "}
            {props.serviceSchedule.bookedClientId?.childLastName}
          </span>
        )}{" "}
        {props?.column.field === "providerName" && (
          <span>
            {props.serviceSchedule.bookedProviderId.firstName}{" "}
            {props.serviceSchedule.bookedProviderId.lastName}
          </span>
        )}
      </div>
    );
  };

  const allSession = useAppSelector<ISession[]>(
    (state) => state.sessionSlice.allSessions
  );

  const handleFilterChange = () => {
    let filteredSession = allSession?.filter((item) => {
      if (filters.status.length && !filters.type.length) {
        return filters.status.includes(item.status);
      } else if (!filters.status.length && filters.type.length) {
        return filters.type.includes(item.sessionType);
      } else if (filters.status.length && filters.type.length) {
        return (
          filters.type.includes(item.sessionType) &&
          filters.status.includes(item.status)
        );
      } else {
        return item;
      }
    });
    return filteredSession;
  };
  useEffect(() => {
    dispatch(GetAllSession());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="session">
      <Box className="session-header">
        <Typography>All Sessions</Typography>
        <Box className="session-filters">
          <FormControl sx={{ m: 1, width: 250 }} size="small">
            <InputLabel id="session-type-label">Type</InputLabel>
            <Select
              labelId="session-type-label"
              id="session-type-select"
              value={filters.type}
              multiple
              label="Type"
              onChange={(e) =>
                setFilters({ ...filters, type: e.target.value as string[] })
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.values(SessionType).map((type) => (
                <MenuItem key={type} value={type}>
                  <Checkbox checked={filters.type.includes(type)} />
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Session Status Dropdown */}
          <FormControl sx={{ m: 1, width: 250 }} size="small">
            <InputLabel id="session-status-label">Status</InputLabel>
            <Select
              labelId="session-status-label"
              id="session-status-select"
              multiple
              value={filters.status}
              label="Status"
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value as string[] })
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.values(SessionStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox checked={filters.status.includes(status)} />
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {/* <TableContainer component={Paper} className="tableResponsive">
        <StyledTable
          sx={{
            minWidth: 700,
            borderCollapse: "separate",
            cursor: "pointer",
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Activity</StyledTableCell>
              <StyledTableCell align="center">Session Name</StyledTableCell>
              <StyledTableCell align="center">#People</StyledTableCell>
              <StyledTableCell align="center">Schedule</StyledTableCell>
              <StyledTableCell align="center">Actual</StyledTableCell>
              <StyledTableCell align="center">Update Dtm</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {handleFilterChange()?.map((item, index) => (
              <StyledTableRow
                key={item.id}
                onClick={() => {
                  navigate(`/session/${item.id}`);
                }}
                style={{ borderRadius: 20 }}
              >
                <StyledTableCell scope="row" align="center">
                  {item.serviceSchedule.serviceType}
                </StyledTableCell>
                <StyledTableCell component="th">
                  {item.serviceSchedule.bookedProviderId.firstName}{" "}
                  {item.serviceSchedule.bookedProviderId.lastName}
                </StyledTableCell>
                <StyledTableCell align="center">1</StyledTableCell>
                <StyledTableCell align="center">
                  <Typography className="sessionTimings">
                    {moment(item.serviceSchedule.specificDate).format(
                      "dddd, M/D/YYYY"
                    )}
                  </Typography>
                  <Typography className="sessionTimings">
                    {item.serviceSchedule.fromTime} -{" "}
                    {item.serviceSchedule.toTime}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography className="sessionTimings">
                    {moment(item?.sessionBeginDtm).format("dddd, M/D/YYYY")}
                  </Typography>
                  {item.sessionEndTime ? (
                    <Typography className="sessionTimings">
                      {item.sessionBeginTime} - {item.sessionEndTime}
                    </Typography>
                  ) : (
                    <Typography className="sessionTimings">
                      {item.sessionBeginTime} - ongoing
                    </Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(item.updatedAt).format("MM/DD/YYYY hh:mm:ssa")}
                </StyledTableCell>
                <StyledTableCell align="center">{item.status}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer> */}
      <GridComponent
        id="grid" //must have!!
        className="gridTable ClientSession"
        dataSource={handleFilterChange()}
        allowPaging={true}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowTextWrap={true}
        filterSettings={{ type: "Menu" }}
        textWrapSettings={textWrapSettings}
        selectionSettings={{ persistSelection: true, type: "Single" }}
        rowSelected={onRowSelected}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="clientName"
            headerText="Client"
            width="120"
            template={personName}
          />
          <ColumnDirective
            field="providerName"
            headerText="Provider"
            width="120"
            template={personName}
          />
          <ColumnDirective
            field="serviceSchedule.serviceType"
            headerText="Activity"
            width="120"
          />
          <ColumnDirective
            headerText="#People"
            width="100"
            textAlign="Center"
            field="numberOfPeople"
            template={"1"}
          />
          <ColumnDirective
            headerText="Schedule"
            width="200"
            template={scheduleTemplate}
          />
          <ColumnDirective
            headerText="Actual"
            width="200"
            template={actualTemplate}
          />
          <ColumnDirective
            field="updatedAt"
            headerText="Update Dtm"
            width="150"
            template={updateDtmTemplate}
          />
          <ColumnDirective field="status" headerText="Status" width="120" />
        </ColumnsDirective>
        <Inject services={[Page, Sort, Filter, Group]} />
      </GridComponent>
    </Box>
  );
};

export default Session;
