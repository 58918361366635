import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { useUser } from "@descope/react-sdk";
import MapsDetails from "./Map-Details/MapsDetails";
import MapsHeading from "./maps-Heading/MapsHeading";

const Maps: React.FC = () => {
  const dispatch = useDispatch();

  //user from Descope
  const { user } = useUser();
  const currentTenantId = user?.customAttributes?.currentTenantId;

  /* Stripe Turn Off tempoariliy 
 
  useEffect(() => {
    setTimeout(() => {
      if (customerDetails) {
        if (
          (user?.verifiedEmail === true &&
            user?.roles[0]?.key === "Owner" &&
            customerDetails &&
            customerDetails?.next_invoice_sequence == 1) ||
          (user?.verifiedEmail === true &&
            user?.roles[0]?.key === "Owner" &&
            customerDetails &&
            customerDetails?.data[0]?.next_invoice_sequence == 1)
        ) {
          navigate("/pay-first");
        }
      }
    }, 1000);
  }, [user, customerDetails]);
*/

  return (
    <>
      <div className="pageTemplate mapDetailsTemplate">
        <MapsHeading />
        <MapsDetails />
      </div>
    </>
  );
};

export default Maps;
