export const getHostname = (): string =>
  typeof window !== "undefined" ? window.location.hostname : "localhost";

export const isTracking = (): boolean => {
  const hostname = getHostname();
  console.log(hostname);
  if (hostname.includes("localhost")) {
    return false;
  } else {
    return true;
  }
};

export const isProd = (): boolean => {
  const hostname = getHostname();
  console.log(hostname);
  if (hostname.includes("schedule.abaengine.com")) {
    return true;
  } else {
    return false;
  }
};
