import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import SchedulesService from "../../../service/schedules.service";
import { IBookedSchedulerMatch } from "../../../types/schedule";
import { DefaultRebookingOptionsResult } from "../../../constants/schedule";

const initialState: IBookedSchedulerMatch = {
  listForCalendar: [],
  clientBooked: [],
  providerBooked: [],
  rebookingOptions: DefaultRebookingOptionsResult,
  isLoading: false,
};

const bookingSchedulerSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    clearRebookings: (state, action) => {
      return {
        ...state,
        Rebookings: DefaultRebookingOptionsResult,
      };
    },

    setLoading: (state, action) => {
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCalenderList.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          listForCalendar: action.payload.listForCalendar,
          clientBooked: action.payload.clientBooked,
          providerBooked: action.payload.providerBooked,
        };
      }
    });
    builder.addCase(GetRebookingOptions.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          isLoading: false,
          rebookingOptions: action.payload.data,
        };
      }
    });
  },
});

export const GetCalenderList = createAsyncThunk(
  "scheduleList/match",
  async (
    {
      clientIds,
      providerIds,
    }: {
      clientIds: string[];
      providerIds: string[];
    },
    { getState, dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));

    try {
      const { data } = await SchedulesService.postCalenderList(
        clientIds,
        providerIds
      );

      if (data) {
        return data;
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetRebookingOptions = createAsyncThunk(
  "getRebookings",
  async (
    {
      bookingId,
      cancelRequestBy,
    }: { bookingId: string; cancelRequestBy: string },
    { getState, dispatch }
  ) => {
    dispatch(setLoading({}));
    dispatch(setResponseValue({ name: "pending", value: true }));
    const payloadData = {
      bookingId: bookingId,
      cancelRequestBy: cancelRequestBy,
    };
    try {
      const { data } = await SchedulesService.getRebookingOptions(payloadData);
      if (data) {
        return data;
      }
      return;
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { clearRebookings, setLoading } = bookingSchedulerSlice.actions;

export default bookingSchedulerSlice;
