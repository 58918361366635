import { createSlice } from "@reduxjs/toolkit";
import { ScheduleFilters } from "../../../types/schedule";

const initialState: ScheduleFilters = {
  businessUnit: [],
  clientStatus: [],
  providerStatus: [],
  insuranceProvider: [],
  concerns: [],
  intakeStage:[],
  onBoardingStage:[],
  availabilityStatus: "",
  startDate: null,
  endDate: null,
  ScheduleStatus: "",
  // age:null,
};

const schedulesFilterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setSchedulesFilterValues: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearSchedulesFilterValues: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { setSchedulesFilterValues, clearSchedulesFilterValues } =
  schedulesFilterSlice.actions;

export default schedulesFilterSlice;
