import React, { useState } from "react";
import { Box } from "@mui/system";
import {
  Collapse,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  setCurrentPlan,
  setUpdateAPI,
} from "../../redux/features/plannings/planning-slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Provider from "../../assets/images/images-png/provider.png";
import PlanningChart from "./PlanningChartDetails";
import { useNavigate } from "react-router-dom";
import { IPlanDetail } from "../../types/planning";
import { defaultPlanDetail } from "../../constants/planning";
import Publish from "./Publish";
import { setClearFilterValues } from "../../redux/features/plannings/planning-filter-slice";

interface IPlanningTable {
  Data: IPlanDetail;
  setEditPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setDeletePlanpopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPlanId: React.Dispatch<React.SetStateAction<string>>;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  anchorEl: HTMLButtonElement | null;
  PlanId: string;
}

const PlanTable: React.FC<IPlanningTable> = ({
  Data,
  anchorEl,
  setPlanId,
  setEditPopup,
  setOpenPopUp,
  setAnchorEl,
  setDeletePlanpopup,
}) => {
  //used to store selected drop plan IDs
  const [showpopup, setShowpopup] = useState<string[]>([]);

  //routing object
  const navigate = useNavigate();

  // used to dispatch
  const dispatch = useDispatch();

  //used to store selected Plan
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPlan, setSelectedPlan] =
    useState<IPlanDetail>(defaultPlanDetail);

  // Get all list
  const AllPlanList = useSelector<RootState, IPlanDetail[]>(
    (state) => state.planSlice.allPlanList
  );

  //if true Popover component show
  const open = Boolean(anchorEl);

  // //this is to publish plan
  // const handlePublish = (id: string) => {
  //   dispatch(setUpdateAPI(true));
  //   dispatch(publishPlan({ Id: id }));
  // };

  // handle edit pathname
  const EditMessageHandle = () => {
    setAnchorEl(null);
    dispatch(setUpdateAPI(true));
    setEditPopup(true);
    setOpenPopUp(true);
  };

  //component requests to be closed
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChartIds = (Id: string) => {
    setShowpopup([Id, ...showpopup]);
    if (showpopup.includes(Id)) {
      let val = showpopup.filter((id) => id !== Id);
      setShowpopup(val);
    }
  };

  //handle the popover
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    Id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPlanId(Id);
    let selectedItem = AllPlanList.filter(
      (unit: IPlanDetail) => unit.plan.id === Id
    )[0];
    setSelectedPlan(selectedItem);
    dispatch(setClearFilterValues({}))
    // setIsActive(false);
  };

  const handleSelectPlan = (id: string, item: IPlanDetail) => {
    dispatch(setCurrentPlan(item));
    navigate(`/planning-detail/${id}`);
  };
  // const [openArrow, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          ":hover": { cursor: "pointer", backgroundColor: "#eeeeee" },
        }}
      >
        <TableCell>
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setPlanId(Data.plan.id);
              setOpen(!openArrow);
              handleChartIds(Data.plan.id);
            }}
          >
            {openArrow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={() => {
            setPlanId(Data.plan.id);
            handleSelectPlan(Data.plan.id, Data);
          }}
        >
          {Data.plan.planName}
        </TableCell>
        <TableCell>{Data.supervisorCount}</TableCell>
        <TableCell>{Data.providerCount}</TableCell>
        <TableCell>{Data.clientCount}</TableCell>
        {/* <TableCell>{Data.bookingCount}</TableCell> */}
        {/* <TableCell>
          {" "}
          {(Data.clientAuthUtilizationPcnt * 100).toFixed(1)}%
        </TableCell>
        <TableCell>
          {" "}
          {(Data.providerUtilizationPcnt * 100).toFixed(1)}%
        </TableCell> */}
        <TableCell>{Data.pendingCount}</TableCell>
        <TableCell>
          <div className="profilePart">
            <img
              src={Data.plan.planner?.profilePictureUrl || Provider}
              alt="profilePictureUrl"
            />
            <h6>{Data.plan.planner?.name}</h6>
          </div>
        </TableCell>
        <TableCell>
          {Data.pendingCount > 0 ? <Publish data={Data} /> : "Published"}
        </TableCell>
        <TableCell>
          <div>
            <button
              style={{
                backgroundColor: "white",
                border: "1px solid white",
              }}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                handleClick(e, Data.plan.id)
              }
            >
              <MoreVertIcon />
            </button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <ul className="popoverLinkList">
                <li onClick={EditMessageHandle}>Edit</li>
                <li
                  onClick={() => {
                    setDeletePlanpopup(true);
                  }}
                >
                  Delete
                </li>
              </ul>
            </Popover>
          </div>
        </TableCell>
      </TableRow>

      {/* Note: deprecate this due to performance reason CTO 2024-08-27
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={openArrow} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                <strong>Chart</strong>
              </Typography>
              <Table aria-label="purchases">
                <TableBody>
                  {showpopup.includes(Data.plan.id) && (
                    <div style={{ height: "auto", position: "relative" }}>
                      <PlanningChart planDetail={Data} />
                    </div>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
       */}
    </React.Fragment>
  );
};

export default PlanTable;
