import { IFilterUserManagement } from "../../pages/user-management/UserManagement";
import { IUserProfile } from "../../types/user";

export const canFilterUsers = (
  filterValues: IFilterUserManagement,
  searchVal: string
): boolean => {
  if (
    (!filterValues.businessUnits?.includes("All BU") &&
      filterValues.businessUnits?.length > 0) ||
    (!filterValues.status?.includes("All Statuses") &&
      filterValues.status?.length > 0) ||
    (!filterValues.role?.includes("All Roles") &&
      filterValues.role?.length > 0) ||
    searchVal
  ) {
    return true;
  }
  return false;
};

const filterUser = (
  filterValues: IFilterUserManagement,
  searchVal: string,
  item: IUserProfile
) => {
  const fullName = `${item.firstName} ${item.lastName}`;
  if (
    searchVal && 
    fullName.toLowerCase().includes(searchVal.toLowerCase().trim())
  ) {
    return true;
  }

  // if (searchVal) {
  //   if (
  //     searchVal &&
  //     (item.firstName.toLowerCase().includes(searchVal.toLowerCase()) ||
  //     item.lastName.toLowerCase().includes(searchVal.toLowerCase()))
  //   ) {
  //     return true;
  //   }
  // }


  // check if item fits for multi-filter conditions with AND. 
  let roleMatched = false, statusMatched = false;
  if (filterValues) {
    //turn off BU filter temporarily!
    // if (filterValues.businessUnits) {
    //   if (
    //     !filterValues.businessUnits.includes("ALL BU") &&
    //     filterValues.businessUnits.some((unit) =>
    //       item.businessUnit.includes(unit)
    //     )
    //   ) {
    //     buMatched = true;
    //   }
    // }

    if (filterValues.role) {
      if (
        filterValues.businessUnits.includes("All Roles") ||
        filterValues.role.some((role) => item.authRole === role)
      ) {
        roleMatched = true;
      }
    }

    if (filterValues.status) {
      if (
        filterValues.businessUnits.includes("All Statuses") ||
        filterValues.status.some((status) => item?.status.toLowerCase() === status.toLowerCase())
      ) {
        statusMatched = true;
      }
    }
  }

  return (filterValues.role && roleMatched || 
          !searchVal && filterValues.role?.length == 0) && 
          (filterValues.status && statusMatched || 
          !searchVal && filterValues.status?.length == 0);
};

//logic for handling the search and filter
export const filterUsers = (
  filterValues: IFilterUserManagement,
  searchVal: string,
  data: IUserProfile[]
): IUserProfile[] => {
  if (data.length > 0) {
    const canFilter = canFilterUsers(filterValues, searchVal);
    if (canFilter) {
      return data.filter((item) => filterUser(filterValues, searchVal, item));
    }
  }
  return data;
};
