import axios from "../axios/axios";
import { ProviderList } from "../types/provider";
import { Response } from "../types/response";

export const GetNonSupervisor = async () => {
  try {
    const { data } = await axios.get<Response<ProviderList>>(
      `/getNonSupervisor`
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const GetSupervisor = async () => {
  try {
    const { data } = await axios.get<Response<ProviderList>>(`/getSupervisor`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

/* deprecate this!
export const GetNonSupervisedClients = async () => {
  try {
    const { data } = await axios.get<{ data: string[] }>(
      `/getNonSupervisedClients`
    );
    return data.data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};*/

export const GetPodDetail = async () => {
  try {
    const data = await axios.get<Response<any>>(`/getPodDetail`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const GetPodNames = async () => {
  try {
    const { data } = await axios.get(`/getPodNames`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const ProviderService = {
  GetNonSupervisor,
  GetSupervisor,
  GetPodDetail,
  GetPodNames,
  // GetNonSupervisedClients,
};

export default ProviderService;
