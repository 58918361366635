import { Descope } from "@descope/react-sdk";
import "../sign-in/SignIn.scss";
import { Box } from "@mui/material";
import SidePanel from "./SidePanel";
import ABAEngineLogo from "../../../assets/images/logos/abaengine.png";

const SignUp = () => {
  return (
    <Box className="auth-container">
      <SidePanel />
      <Box className="descope-container">
        <Box className="logo" component={"img"} src={ABAEngineLogo}></Box>
        <Descope
          flowId="otp-sign-up"
          theme="light"
          onError={(err) => console.log("Descope Error!", err)}
        />
      </Box>
    </Box>
  );
};

export default SignUp;
