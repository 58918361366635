export const ProviderType = [
  { key: 1, value: "Onboarding" },
  { key: 2, value: "Established" },
  { key: 3, value: "Inactive" },
]as const;



export const ChecklistPaperWork = [
  { key: 1, value: "Waiting for HR Paperwork" },
  { key: 2, value: "Waiting for Credentialing" },
  { key: 3, value: "Onboarding Discontinued" },
]as const;

export const ChecklistTraining = [
  { key: 1, value: "RBT Training in Process" },
  { key: 2, value: "RBT Training Complete" },
  { key: 3, value: "Orientation Training in Process" },
  { key: 3, value: "Orientation Training Complete" },
  { key: 3, value: "RBT Exam Scheduled" },
  { key: 3, value: "RBT Exam Passed" },
]as const;

export const ChecklistCredentialing = [
  { key: 1, value: "License Pending" },
  { key: 2, value: "CAQH Pending" },
  { key: 3, value: "NPI Pending" },
  { key: 3, value: "Paperwork Complete" },
]as const;

