import React from "react";
import * as htmlToImage from "html-to-image";
import { Box, Button, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { DefaultDiagnosisTypes } from "../../../constants/assessment";
import moment from "moment";

const DetailTemplate: React.FC = (props: any) => {
  //#endregion

  //#region methods region

  // handle update and fetch all template
  // const pdfGenerator = React.useCallback(async (id: string) => {
  //   try {
  //     // eslint-disable-next-line
  //     const data = await dispatch(GetFormRequestById({ assessmentId: id }));

  //     if (data.payload.id !== "") {

  //       survey = new Model(data.payload.form.formSchema);
  //       survey.triggerValues = data.payload.formData;
  //       survey.data = data.payload.formData;
  //       setData(data.payload.form.formSchema);
  //       if (data) savePdf(survey);
  //     }
  //   } catch (error: any) {
  //     console.log(error);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  //Create Pdf model
  // function createSurveyPdfModel(surveyModel: any) {
  //   let pdfWidth =
  //     !!surveyModel && surveyModel.pdfWidth ? surveyModel.pdfWidth : 210;
  //   let pdfHeight =
  //     !!surveyModel && surveyModel.pdfHeight ? surveyModel.pdfHeight : 297;
  //   let options = {
  //     fontSize: 14,
  //     margins: {
  //       left: 10,
  //       right: 10,
  //       top: 10,
  //       bot: 10,
  //     },

  //     format: [pdfWidth, pdfHeight],
  //   };

  //   let elements: any[] = [];
  //   let pages: any = [];
  //   let data: any = [];

  //   if (survey.jsonObj) {
  //     survey.jsonObj.pages.forEach((item: any, index: number) => {
  //       if (index !== 0) {
  //         item.elements.forEach((elm: any) => {
  //           elements.push(elm);
  //         });
  //       }
  //     });
  //     pages = [{ elements: elements }];
  //     data = survey.jsonObj;

  //     data = JSON.parse(JSON.stringify(survey.jsonObj));
  //     data.pages = [...pages];
  //   }
  //   let pdfFormSurvey = { ...data };

  //   const surveyPDF = new SurveyPDF(pdfFormSurvey, options);

  //   if (surveyModel) {
  //     surveyPDF.data = surveyModel.data;
  //   }

  //   surveyPDF.raw("dataurlstring").then(function (dataurl: any) {

  //     var pdfEmbed = document.createElement("embed");
  //     pdfEmbed.setAttribute("type", "application/pdf");
  //     pdfEmbed.setAttribute("style", "width:100%");
  //     pdfEmbed.setAttribute("height", "600");
  //     pdfEmbed.setAttribute("src", dataurl);
  //     var previewDiv = document.getElementById("pdf-preview") as HTMLElement;
  //     // previewDiv.appendChild(pdfEmbed);
  //   });

  //   return surveyPDF;
  // }

  //save survey pdf
  // function saveSurveyToPdf(filename: any, surveyModel: any) {
  //   createSurveyPdfModel(surveyModel).save(filename);
  // }

  //manage survey data for pdf
  // survey.data = {
  //   qualities: {
  //     affordable: 3,
  //     "does-what-it-claims": 4,
  //     "better-than-others": 3,
  //     "easy-to-use": 5,
  //   },
  //   "satisfaction-score": 4,
  //   recommend: 5,
  //   suggestions: "24/7 support would help a lot.",
  //   "price-comparison": "Not sure",
  //   "current-price": "correct",
  //   "price-limits": {
  //     highest: 450,
  //     lowest: 200,
  //   },
  //   email: "john.doe@example.com",
  // };

  //used to save pdf
  // const savePdf = function (survey: any) {
  //   saveSurveyToPdf("surveyResult.pdf", survey);
  // };

  //used to store evaluation keys
  let value: any;
  //checks if status is completed or not
  if (props.status === "Evaluated") {
    value = Object.keys(props?.evaluation);
    value = value?.map((i: any) => i.toLowerCase());
  } else {
    value = [];
  }
  //#endregion

  const handleConvert = () => {
    const data = document.getElementById("assessment-result");

    if (data !== null) {
      // Create a container for the header section
      const headerContainer = document.createElement("div");
      headerContainer.style.top = "10px";
      headerContainer.style.left = "10px";
      headerContainer.style.textAlign = "left";
      // headerContainer.style.right = "10px";
      headerContainer.style.width = "100%";
      headerContainer.style.borderBottom = "2px solid #19b394";
      // Create heading element
      const heading = document.createElement("h1");
      heading.innerText = props.formName;
      heading.style.margin = "0";
      heading.style.color = "#19b394";

      const details = document.createElement("div");
      details.style.display = "flex";
      details.style.justifyContent = "space-between";

      const personName = document.createElement("p");
      personName.style.fontSize = "18px";
      personName.style.color = "rgba(0, 0, 0, 0.6)";
      personName.innerText = `Submitted by : ${props?.entityName}`;

      const submitDate = document.createElement("p");
      submitDate.style.fontSize = "18px";
      submitDate.style.color = "rgba(0, 0, 0, 0.6)";
      submitDate.innerText = `Submitted On : ${moment(props?.submitDtm).format(
        "ll"
      )}`;
      details.appendChild(personName);
      details.appendChild(submitDate);

      // Append heading and logo to header container
      headerContainer.appendChild(heading);
      headerContainer.appendChild(details);

      // Append the header container to the `assessment-result` div
      data.prepend(headerContainer);
      // data.prepend(details)

      // Convert to PNG
      htmlToImage
        .toPng(data)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "assessment_report.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error converting to PNG:", error);
        })
        .finally(() => {
          // Remove the header after PNG extraction
          data.removeChild(headerContainer);
        });
    }
  };

  return props.status === "Evaluated" ? (
    <div
      style={{
        display: "flex",
        gap: "40px",
        padding: "33px",
        flexWrap: "wrap",
        background: "white",
      }}
      // ref={divRef}
      id="assessment-result"
    >
      {DefaultDiagnosisTypes.map((item) => {
        return (
          <Box
            style={{
              width: "190px",
              height: "170px",
              boxShadow: "rgb(0 0 0 / 24%) 0px 1px 4px",
              textAlign: "center",
              padding: "25px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <h2
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "32px",
                color: "#000000",
                margin: 0,
              }}
            >
              {item.displayText}
            </h2>

            {value.includes(
              item.displayText.split(" ").join("").toLowerCase()
            ) ? (
              <span
                style={{
                  background: "red",
                  padding: "5px 8px",
                  color: "white",
                  borderRadius: "12px",
                  lineHeight: "14px",
                  fontSize: "14px",
                }}
              >
                Positive
              </span>
            ) : (
              <span
                style={{
                  background: "green",
                  padding: "5px 8px",
                  color: "white",
                  borderRadius: "12px",
                  lineHeight: "14px",
                  fontSize: "14px",
                }}
              >
                Negative
              </span>
            )}
          </Box>
        );
      })}
      <>{console.log(props.pdfUrl, "url")}</>
      <Box className="buttonsCard">
        {value.length > 0 && (
          <Button
            onClick={() => {
              props.setOpenCreticalAssessment(true);
              props.setCriticalId(props.id);
            }}
          >
            Critcal Answer
            <EastIcon />
          </Button>
        )}
        <Button
          onClick={() => {
            // pdfGenerator(props.id);
            if (props.pdfUrl) {
              window.open(props.pdfUrl, "_blank");
            }
          }}
        >
          Export as PDF <FileDownloadOutlinedIcon />
        </Button>

        <Button onClick={handleConvert}>
          Export as PNG <FileDownloadOutlinedIcon />
        </Button>
      </Box>
    </div>
  ) : (
    <div style={{ color: " #586f89" }}>Waiting for assessment submission</div>
  );
};

export default DetailTemplate;
