import moment from "moment";

//reverses the list for clients, providers, schedules
export function ReverseList<T>(list: T[]): T[] {
  const reversedList: T[] = [];
  for (let i = list.length - 1; i > -1; i--) {
    reversedList.push(list[i]);
  }
  return reversedList;
}

export const getBoardsUpdatedDays = (date: any): any => {
  var fromNow = moment(moment(date).utcOffset(0).format()).fromNow();
  return String(fromNow);
};

export const getTaskOverallDays = (createDate: any, endDate: any): any => {
  const date1 = moment.utc(createDate).toDate();
  const date2 = moment.utc(endDate).toDate();

  const diffTime = Math.abs((date2 as any) - (date1 as any));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  let value = "";

  if (diffDays === 1) {
    value = "Today";
  } else {
    value = `${Math.ceil(diffDays)}d`;
  }

  return value;
};

export const getBoardAgingDays = (date: any): any => {
  //const date1 = new Date().getUTCDate();
  //const date2 = new Date(date).getUTCDate();

  const date1 = moment.utc().toDate();
  const date2 = moment.utc(date).toDate();
  const diffTime = Math.abs((date2 as any) - (date1 as any));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  let value = "";

  if (diffDays === 1) {
    value = "Today";
  } else {
    value = `${Math.ceil(diffDays)}d`;
  }

  return value;
};

export const getMessageDaysAndTime = (date: any): string => {
  const date1 = new Date().getUTCDate();
  const date2 = new Date(date).getUTCDate();
  const diffTime = Math.abs((date2 as any) - (date1 as any));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  let value = "";
  if (diffDays === 0) {
    value = `Today ${
      new Date(date).getHours() < 10
        ? "0" + new Date(date).getHours()
        : new Date(date).getHours()
    }:${
      new Date(date).getMinutes() < 10
        ? "0" + new Date(date).getMinutes()
        : new Date(date).getMinutes()
    }`;
  } else if (diffDays === 1) {
    value = `Yesterday ${
      new Date(date).getHours() < 10
        ? "0" + new Date(date).getHours()
        : new Date(date).getHours()
    }:${
      new Date(date).getMinutes() < 10
        ? "0" + new Date(date).getMinutes()
        : new Date(date).getMinutes()
    }`;
  } else {
    value = `${diffDays} days ago ${
      new Date(date).getHours() < 10
        ? "0" + new Date(date).getHours()
        : new Date(date).getHours()
    }:${
      new Date(date).getMinutes() < 10
        ? "0" + new Date(date).getMinutes()
        : new Date(date).getMinutes()
    }`;
  }
  return value;
};

// call this function, passing-in your date
export const dateToFromNowDaily = (date: any): string => {

  // Directly use the date as it is in ISO 8601 format
  const isoDate = moment(date).isValid() ? date : null;
  if (!isoDate) {
    console.error("Invalid date format");
    return "Invalid date";
  }

  const localDtm = moment(isoDate).format(); // ISO formatted date
  const localTime = moment(isoDate).format("LT"); // Local time

  // Get from-now for this date
  const fromNow = moment(localDtm).fromNow();

  // Ensure the date is displayed with today and yesterday
  const value = `${moment(date).calendar(null, {
    // When the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    // When the date is further away, use from-now functionality
    sameElse: function () {
      return `[${fromNow}]`;
    },
  })} ${localTime}`;

  return value;
};

export function getUploadedTime(date: string): string {
  return `${new Date(date).getFullYear()}-${
    new Date(date).getMonth() + 1
  }-${new Date(date).getDate()} ${new Date(date).getHours()}:${new Date(
    date
  ).getMinutes()}:${new Date(date).getSeconds()}`;
}
