import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  Box,
  Paper,
  Table,
  Dialog,
  Button,
  styled,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  ButtonGroup,
  TableContainer,
  tableCellClasses,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { AppDispatch, RootState } from "../../../redux/store";
import { ProviderFilters, ProviderForms } from "../../../types/provider";
import {
  clearFilterValues,
  setFilterValues,
} from "../../../redux/features/providers-main/provider-filter-slice";
import ProviderFilter from "../../filter-main/ProviderFilter";
import { setSearchValue } from "../../../redux/features/active-steps/active-slice";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import { ReactComponent as FilterIcon } from "../../../assets/images/images-svg/filterIcon.svg";
import VerifiedAddressImg from "../../../assets/images/images-svg/verifiedAddress.svg";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import { filterProviders } from "../../../helpers/provider-filter-helper/provider-filter-helper";
import {
  dataUpdated,
  setListData,
} from "../../../redux/features/providers-main/provider-list-slice";
import Pagination from "../../../components/common/Pagination";
import usePaginatedData from "../../../custom-hooks/pagination-hook/usePaginatedData";
import { OrgBU } from "../../../types/company";
import { getBussinessName } from "../../../constants/common";
import { getBusinessUnitName } from "../../../utils/EntityUtils";
import "../provider/Providers.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "5px 20px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ECF6FF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "50px",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
}));

interface ProviderListProps {
  mergedData: ProviderForms[];
}

const ProviderList: React.FC<ProviderListProps> = ({ mergedData }) => {
  // #region variables region

  //dispatch for redux
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //routing object
  const navigate = useNavigate();

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //stores provider data after filter
  const [filteredData, setFilteredData] = useState<ProviderForms[]>([]);

  //number of posts per page
  const [postPerPage] = useState<number>(12);

  //page number
  const [pageNumber, setPageNumber] = useState<number>(1);

  // paginated & filtered data
  const pageFilteredData = usePaginatedData<ProviderForms>(
    filteredData, //after filter
    pageNumber || 1,
    postPerPage
  );

  //controls filter modal visibility
  const [filterModal, setFilterModal] = useState<boolean>(false);

  //search value from the redux
  const searchValue = useSelector<RootState, string>(
    (state) => state.activeStep.searchValue
  );

  //filter values from the redux store
  const filterValues = useSelector<RootState, ProviderFilters>(
    (state) => state.providerFilterSlice
  );

  //binding action creators
  const dispatchAction = bindActionCreators(
    {
      // GetAllProviders,
      setResponseValue,
      clearFilterValues,
      setSearchValue,
      setListData,
    },
    dispatch
  );

  //stores the dispatch ref between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);

  // #endregion

  // #region methods region

  //handles routing when clicked on a provider list item
  const handleRoute = (id: string): void => {
    navigate("/provider/" + id);
  };

  const paginate = (page: number) => {
    setPageNumber(page);
  };

  //handles closing of single filter
  const handleCloseSingleFilter = (key: string, item: string) => {
    if (key === "insuranceProvider") {
      let value = filterValues.insuranceProvider.filter((i) => i !== item);
      dispatch(setFilterValues({ ...filterValues, insuranceProvider: value }));
    } else if (key === "businessUnit") {
      let value = filterValues.businessUnit.filter((i) => i !== item);
      dispatch(setFilterValues({ ...filterValues, businessUnit: value }));
    } else if (key === "providerType") {
      let value = filterValues.providerType.filter((i) => i !== item);
      dispatch(setFilterValues({ ...filterValues, providerType: value }));
    } else if (key === "onboardingStatus") {
      let value = filterValues.onboardingStatus.filter((i) => i !== item);
      dispatch(setFilterValues({ ...filterValues, onboardingStatus: value }));
    } else if (key === "onBoardingStage") {
      let value = filterValues.onBoardingStage.filter((i) => i !== item);
      dispatch(setFilterValues({ ...filterValues, onBoardingStage: value }));
    } else if (key === "ScheduleStatus") {
      dispatch(setFilterValues({ ...filterValues, ScheduleStatus: "" }));
    } else if (key === "startDate") {
      dispatch(setFilterValues({ ...filterValues, startDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "endDate") {
      dispatch(setFilterValues({ ...filterValues, endDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "availabilityStatus") {
      dispatch(setFilterValues({ ...filterValues, availabilityStatus: "" }));
    }
  };

  // #region useEffect region

  //re-fetches data when there is a change in the list data
  // useEffect(() => {
  //   dispatchActionRef.current.GetAllProviders();

  //   let newDispatchActionRef = dispatchActionRef;
  //   return () => {
  //     paginate(1);
  //     // newDispatchActionRef.current.setListData(null);
  //   };
  // }, [dataChange]);

  //ensure to repopulate filteredClient after filter or search.
  useEffect(() => {
    setFilteredData(filterProviders(filterValues, searchValue, mergedData));
    paginate(1);
  }, [filterValues, searchValue, mergedData]);

  // #endregion
  return (
    <div className="listingTable">
      <Box className="listingTable__header">
        <Typography className="listingTable__title" gutterBottom variant="h6">
          All Employees
        </Typography>
        <Box className="select-filters-buttons">
          <ButtonGroup
            disableElevation
            variant="contained"
            className="filterGroup-btn"
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.preventDefault();
              setFilterModal(true);
            }}
          >
            {/* <Button>All Filters</Button> */}
            <Button>
              <FilterIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Dialog open={filterModal} className="largeModel">
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            Filter Employees
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => setFilterModal(false)}
          >
            <Close />
          </Button>
        </Box>
        <ProviderFilter
          closeModal={(val: boolean, filterObject: ProviderFilters) => {
            dispatch(setFilterValues({ ...filterObject }));
            setFilterModal(val);
          }}
        />
      </Dialog>

      <Box className="selected-filtersButtons" textAlign={"right"}>
        {Object.entries(filterValues)
          .filter(([_, value], idx) => {
            if (value && typeof value === "string") return true;
            if (value?.length > 0 && typeof value === "object") return true;
            return false;
          })
          .map(([key, value], index) => {
            if (typeof value === "object") {
              return value.map((subItem: string, subIndex: number) => {
                if (
                  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
                    subItem
                  )
                ) {
                  return (
                    <Button className="border-button" key={index}>
                      {getBussinessName(subItem, businessUnits)}
                      <span className="closeBtn">
                        <CloseIcon
                          fontSize="small"
                          onClick={() => {
                            handleCloseSingleFilter(key, subItem);
                          }}
                        />
                      </span>
                    </Button>
                  );
                }
                return (
                  <Button className="border-button" key={subIndex}>
                    {subItem}
                    <span className="closeBtn">
                      <CloseIcon
                        fontSize="small"
                        onClick={() => {
                          handleCloseSingleFilter(key, subItem);
                        }}
                      />
                    </span>
                  </Button>
                );
              });
            } else {
              return (
                <Button className="border-button" key={index}>
                  {key === "businessUnit"
                    ? getBusinessUnitName(businessUnits, value)
                    : value}
                  <span className="closeBtn">
                    <CloseIcon
                      fontSize="small"
                      onClick={() => {
                        handleCloseSingleFilter(key, value);
                      }}
                    />
                  </span>
                </Button>
              );
            }
          })}
      </Box>
      <TableContainer component={Paper} className="tableResponsive">
        <StyledTable
          sx={{
            minWidth: 820,
            borderCollapse: "separate",
            cursor: "pointer",
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">BU</StyledTableCell>
              <StyledTableCell>Employee</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Location</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Onboarding Stage</StyledTableCell>
              {/* <StyledTableCell align="center">Checklist</StyledTableCell> */}
              <StyledTableCell align="center">Updated On</StyledTableCell>
            </TableRow>
          </TableHead>
          {mergedData?.length > 0 ? (
            <TableBody>
              {pageFilteredData?.map((row, idx) => (
                <StyledTableRow
                  style={{ borderRadius: 20 }}
                  key={`${row?.employmentDetails?.firstName + idx}`}
                  onClick={() => handleRoute(row.employmentDetails?.id)}
                >
                  <StyledTableCell scope="row" align="center">
                    {getBusinessUnitName(
                      businessUnits,
                      row?.employmentDetails?.businessUnit
                    )?.map((res: string, index: number) => {
                      return (
                        <span key={index}>{`${index ? "," : ""} ${res}`} </span>
                      );
                    })}
                  </StyledTableCell>
                  <StyledTableCell component="th">
                    <div className="userList-name">
                      <img
                        src={
                          row?.employmentDetails?.providerProfile?.url ||
                          ProviderImg
                        }
                        className="userSmallCircle"
                        alt=""
                      />
                      <span style={{ marginLeft: 10 }}>
                        {row?.employmentDetails?.firstName}{" "}
                        {row?.employmentDetails?.lastName}
                      </span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.employmentDetails?.type}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div>
                      {row?.contactDetails?.address?.fullAddress?.trim()
                        .length > 0 &&
                      row?.contactDetails?.address?.lat !== 0 ? (
                        <>
                          <img
                            src={VerifiedAddressImg}
                            className="verifiedAddressInList"
                            alt=""
                          />
                        </>
                      ) : null}
                      {row?.contactDetails?.address?.fullAddress}
                    </div>
                    <div>
                      {row?.contactDetails?.city} {row?.contactDetails?.state}{" "}
                      {row?.contactDetails?.zipCode}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.employmentDetails?.providerType}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.employmentDetails?.onBoardingStage}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">
                        <div>
                          {row?.employmentDetails?.checklist.map(
                            (item: string) => (
                              <div key={item}>{item}</div>
                            )
                          )}
                        </div>
                      </StyledTableCell> */}
                  <StyledTableCell align="center">
                    {moment
                      .utc(row?.employmentDetails?.updatedAt)
                      .format("MM/DD/YYYY")}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <div className="notFound-Block">No Employees Found</div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
          )}
        </StyledTable>
      </TableContainer>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Pagination
          postPerPage={postPerPage}
          totalData={filteredData?.length}
          paginate={setPageNumber}
        />
      </Box>
    </div>
  );
};

export default ProviderList;
