import { useDispatch } from "react-redux";
import {
  getPlanById,
  setUpdateAPI,
  publishPlan,
} from "../../redux/features/plannings/planning-slice";
import { IPlanDetail } from "../../types/planning";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { useState } from "react";
import { Close } from "@mui/icons-material";

const Publish: React.FC<{ data: IPlanDetail }> = ({ data }) => {
  const [openPopup, setOpenPopUp] = useState(false);
  const dispatch = useDispatch();

  const handlePublish = () => {
    if (data.pendingCount === 0) return setOpenPopUp(false);
    // dispatch(setUpdateAPI(true)); //handle inside the publishPlan action
    dispatch(publishPlan({ Id: data.plan.id }));
    // setTimeout(() => {
    //   dispatch(getPlanById({ Id: data.plan.id }));
    // }, 2000);
    setOpenPopUp(false);
  };

  return (
    <>
      <div className="statusBtnPart">
        <button 
          className="btn publishBtn"
          onClick={() => setOpenPopUp(true)}
        >
          Publish
        </button>
      </div>
      <Dialog open={openPopup} className="smallModel">
        <Box className="smallModel__head">
          <Typography variant="h5" className="smallModel__title">
            Publish Plan
          </Typography>
          <Button
            className="smallModel__closeBtn"
            onClick={() => setOpenPopUp(false)}
          >
            <Close />
          </Button>
        </Box>
        <Box px={2} py={4} display={"flex"} flexDirection={"column"} gap={2}>
          {data.pendingCount > 0 ? (
            <>
            <Typography variant="h6">
              There are
              <Box component={"span"} fontWeight={"bold"}>
                {" " + data.pendingCount + " "}
              </Box>
              pending schedule(s) found. Do you want to publish them as
              official schedules?
            </Typography>
            <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
              <Box>
                <Button
                  className="button"
                  variant="contained"
                  onClick={handlePublish}
                >
                  {data.pendingCount > 0 ? "Publish" : "Cancel"}
                </Button>
              </Box>
            </Box>
          </>
          ) : (
            <Typography variant="h6">No pending schedules found in this plan.</Typography>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default Publish;
