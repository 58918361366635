import { UserResponse } from "@descope/core-js-sdk";

export const getRolesForTenant = (user: UserResponse, tenantId: string) => {
  let roleNames: string[] | undefined = [];

  user?.userTenants?.forEach((tenant) => {
    if (tenant.tenantId === tenantId) {
      roleNames = tenant.roleNames;
    }
  });
  return roleNames;
};
