import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close, Edit } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  setClientContactDetails,
  setClientId,
  setEditClient,
  setInitialState,
  updateClientContactDetailById,
} from "../../../../redux/features/client-main/client-slice";

import ClientContactDetailsForm from "../../client-stepper/ClientContactDetails";

import { ClientContactDetails } from "../../../../types/client";

import VerifiedAddressImg from "../../../../assets/images/images-svg/verifiedAddress.svg";

interface ClientSubContactDetailsProps {
  clientId: string;
  clientContactDetails: ClientContactDetails;
}

const ClientSubContactDetails: React.FC<ClientSubContactDetailsProps> = ({
  clientContactDetails,
  clientId,
}) => {
  // #region variables region

  //form handler for edit or new
  const { isEditClient } = useAppSelector((state) => state.client);

  //handles the edit for client contact details
  const [editContactInfo, setEditContactInfo] = useState<boolean>(false);

  //dispatch for redux
  const dispatch = useAppDispatch();

  // #endregion

  // #region methods region

  //handles the close logic when save changes or close edit form for client contact details
  const handleCloseForContactDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setEditContactInfo(false);
  };

  //handles the data setting in redux for client contact details
  const handleEditContactClick = () => {
    if (!clientContactDetails) {
      const clientContactDetailsObj = {
        id: "",
        entityType: "Client",
        entityId: "",
        phoneNumber: "",
        email: "",
        address: {
          fullAddress: "",
          lat: 0,
          lng: 0,
        },
        schoolAddress: { fullAddress: "", lng: 0, lat: 0 },
        schoolCity: "",
        schoolState: "",
        schoolZipCode: "",
        city: "",
        state: "",
        zipCode: "",
        contactMethod: "",
        bestTimeToContact: "",
      };
      dispatch(
        setClientContactDetails({ ...clientContactDetailsObj, isEdit: true })
      );
    } else {
      dispatch(
        setClientContactDetails({
          ...clientContactDetails,
          isEdit: true,
        })
      );
    }
    dispatch(setEditClient(true));
    setEditContactInfo(true);
  };

  //handles the edit form submission for client contact details
  const onSubmitContactInfo = () => {
    dispatch(setClientId({ id: clientId }));
    dispatch(updateClientContactDetailById());
    setEditContactInfo(false);
    dispatch(setEditClient(false));
  };

  // #endregion
  return (
    <Grid item xs={12} lg={6} xl={4}>
      <div className="detailsCard">
        <div className="detailsCard__header">
          <Typography className="detailsCard__title">
            Contact Information
          </Typography>
          <IconButton
            onClick={() => handleEditContactClick()}
            className="detailsCard__headBtn"
            size="small"
            edge="start"
          >
            <Edit />
          </IconButton>
        </div>
        <div className="detailsCard__body">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={5}>
              <div className="detailsCard-part">
                <Typography className="detailsCard-part__title">
                  Phone Number
                </Typography>
                <Typography className="detailsCard-part__text">
                  {clientContactDetails?.phoneNumber}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={7}>
              <div className="detailsCard-part">
                <Typography className="detailsCard-part__title">
                  E-mail Address
                </Typography>
                <Typography className="detailsCard-part__text">
                  {clientContactDetails?.email}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <div className="addressCard">
            <Grid container spacing={0}>
              <Grid item xs={12} md={5}>
                <div className="detailsCard-part">
                  <Typography className="detailsCard-part__title">
                    Home Address{" "}
                    {clientContactDetails?.address?.fullAddress &&
                    clientContactDetails?.address?.fullAddress?.trim().length >
                      0 &&
                    clientContactDetails?.address?.lat !== 0 ? (
                      <img
                        className="verifiedAddress"
                        src={VerifiedAddressImg}
                        alt="VerifiedAddressImg"
                      />
                    ) : (
                      ""
                    )}
                  </Typography>
                  <Typography className="detailsCard-part__text">
                    {clientContactDetails?.address?.fullAddress}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={2}>
                <div className="detailsCard-part">
                  <Typography className="detailsCard-part__title">
                    City
                  </Typography>
                  <Typography className="detailsCard-part__text">
                    {clientContactDetails?.city}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className="detailsCard-part">
                  <Typography className="detailsCard-part__title">
                    State
                  </Typography>
                  <Typography className="detailsCard-part__text">
                    {clientContactDetails?.state}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className="detailsCard-part">
                  <Typography className="detailsCard-part__title">
                    Zip Code
                  </Typography>
                  <Typography className="detailsCard-part__text">
                    {clientContactDetails?.zipCode}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
          {clientContactDetails?.schoolAddress?.fullAddress && (
            <div className="addressCard">
              <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      School Address{" "}
                      {clientContactDetails?.schoolAddress?.fullAddress?.trim()
                        .length > 0 &&
                      clientContactDetails?.schoolAddress?.lat !== 0 ? (
                        <img
                          className="verifiedAddress"
                          src={VerifiedAddressImg}
                          alt="VerifiedAddressImg"
                        />
                      ) : (
                        ""
                      )}
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientContactDetails?.schoolAddress?.fullAddress}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={5} md={2}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      City
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientContactDetails?.schoolCity}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} md={2}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      State
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientContactDetails?.schoolState}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={3} md={2}>
                  <div className="detailsCard-part">
                    <Typography className="detailsCard-part__title">
                      Zip Code
                    </Typography>
                    <Typography className="detailsCard-part__text">
                      {clientContactDetails?.schoolZipCode}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
          <Grid container spacing={0}>
            <Grid item xs={12} sm={5}>
              <div className="detailsCard-part">
                <Typography className="detailsCard-part__title">
                  Contact Preference
                </Typography>
                <Typography className="detailsCard-part__text">
                  {clientContactDetails?.contactMethod}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={7}>
              <div className="detailsCard-part">
                <Typography className="detailsCard-part__title">
                  When to Contact
                </Typography>
                <Typography className="detailsCard-part__text">
                  {clientContactDetails?.bestTimeToContact}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {isEditClient && editContactInfo && (
        <>
          <Dialog
            open={editContactInfo}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Client - Contact Detail
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={handleCloseForContactDetails}
              >
                <Close />
              </Button>
            </Box>
            <ClientContactDetailsForm onSubmit={onSubmitContactInfo} />
          </Dialog>
        </>
      )}
    </Grid>
  );
};

export default ClientSubContactDetails;
