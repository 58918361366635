import { automation } from "../types/board";

export const defaultAutomation: automation = {
  cardDetails: { event: "", stage: "", for: "", alertConfig: "",alertType:"" },
  cardAction: {
    inmailTemplate: "",
    emailTemplate: "",
    smsTemplate: "",
    inmail: false,
    email: false,
    sms: false,
    newLocation: false,
    board: "",
    stage: "",
    form: [],

    sourceProjectId: "",
    sourceTaskIds: [],
    targetProjectId: "",
    dueInDays: 0,

    concept: "",
    conceptValue: "",
  },
  apply: false,
  name: "",
};

export const AlertType = [
  { key: 1, value: "Client Authorization Expiration" },
  { key: 2, value: "Client Availability Expiration" },
  { key: 3, value: "Employee Availability Expiration" },
  { key: 4, value: "Employee Credential Expiration" },
] as const;
