import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Close, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import {
  setContactDetails,
  setInitialState,
  setProviderId,
  updateProviderContactById,
} from "../../../../redux/features/providers-main/provider-slice";
import { AppDispatch } from "../../../../redux/store";
import { ContactDetails } from "../../../../types/provider";
import ContactDetailsForm from "../../providers-forms/ContactDetailsForm";
import VerifiedAddressImg from "../../../../assets/images/images-svg/verifiedAddress.svg";

interface SubContactDetailsProps {
  contactDetails: ContactDetails;
  id: string;
}

const SubContactDetails: React.FC<SubContactDetailsProps> = ({
  contactDetails,
  id,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //handles the popup for editing contact details
  const [editContactDetails, setEditContactDetails] = useState<boolean>(false);

  // #endregion

  // #region methods region

  //contact details update handler
  const editContactDetailsUpdater = () => {
    dispatch(setProviderId({ id: id }));
    dispatch(updateProviderContactById());
    setEditContactDetails(false);
  };

  //handles filling form for editing contact details
  const setContactEditHandler = (): void => {
    if (!contactDetails) {
      const contactDetailsObj = {
        entityType: "Provider",
        entityId: "",
        phoneNumber: "",
        email: "",
        address: { fullAddress: "", lng: 0, lat: 0 },
        schoolAddress: { fullAddress: "", lng: 0, lat: 0 },
        schoolCity: "",
        schoolState: "",
        schoolZipCode: "",
        city: "",
        state: "",
        zipCode: "",
        contactMethod: "",
        bestTimeToContact: "",
        id: "",
        createdAt: "",
        updatedAt: "",
        otherAddresses: [],
      };
      dispatch(setContactDetails({ ...contactDetailsObj }));
    } else {
      dispatch(
        setContactDetails({
          ...contactDetails,
        })
      );
    }
    setEditContactDetails(true);
  };

  // #endregion

  return (
    <Grid item xs={12}>
      <div className="detailsCard">
        <div className="detailsCard__header">
          <Typography className="detailsCard__title">
            Contact Information
          </Typography>
          <IconButton
            className="detailsCard__headBtn"
            size="small"
            edge="start"
            onClick={() => setContactEditHandler()}
          >
            <Edit />
          </IconButton>
        </div>
        <div className="detailsCard__body">
          <Grid container spacing={0}>
            <Grid item xs={12} md={3}>
              <div className="detailsCard-part">
                <Typography className="detailsCard-part__title">
                  Phone Number
                </Typography>
                <Typography className="detailsCard-part__text">
                  {contactDetails?.phoneNumber}
                </Typography>
              </div>
              <Grid mt={3}>
                <div className="detailsCard-part">
                  <Typography className="detailsCard-part__title">
                    E-mail Address
                  </Typography>
                  <Typography className="detailsCard-part__text">
                    {contactDetails?.email}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="contactProviderCard">
                <div className="detailsCard-part">
                  <Typography className="detailsCard-part__title">
                    Address{" "}
                    {contactDetails?.address?.fullAddress?.trim().length > 0 &&
                    contactDetails?.address?.lat !== 0 ? (
                      <img
                        className="verifiedAddress"
                        src={VerifiedAddressImg}
                        alt="VerifiedAddressImg"
                      />
                    ) : (
                      ""
                    )}
                  </Typography>
                  <Typography className="detailsCard-part__text">
                    {contactDetails?.address?.fullAddress}{" "}
                  </Typography>
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={6} md={6} mt={2}>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        City
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {contactDetails?.city}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3} mt={2}>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        State
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {contactDetails?.state}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3} mt={2}>
                    <div className="detailsCard-part">
                      <Typography className="detailsCard-part__title">
                        Zip Code
                      </Typography>
                      <Typography className="detailsCard-part__text">
                        {contactDetails?.zipCode}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <Grid item xs={6} md={6}>
                <div className="detailsCard-part">
                  <Typography className="detailsCard-part__title">
                    Contact Method
                  </Typography>
                  <Typography className="detailsCard-part__text">
                    {contactDetails?.contactMethod}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} md={6} mt={3}>
                <div className="detailsCard-part">
                  <Typography className="detailsCard-part__title">
                    Time To Contact
                  </Typography>
                  <Typography className="detailsCard-part__text">
                    {contactDetails?.bestTimeToContact}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {editContactDetails && (
        <Dialog open={editContactDetails} className="largeModel">
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Employee
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setEditContactDetails(false);
                  dispatch(setInitialState(null));
                }}
              >
                <Close />
              </Button>
            </Box>
          </Grid>
          <ContactDetailsForm
            isNew={false}
            updateHandler={editContactDetailsUpdater}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default SubContactDetails;
