import {
  CustomForm,
  ICommmunication,
  updateForms,
} from "../../../types/businessSettings";
import { AllTemplates } from "../../../constants/parent-settings";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import FormService from "../../../service/form.service";
import { GetFormCards } from "../forms/form-slice";

const initialState: ICommmunication = {
  ...AllTemplates,
};

const intakeFormSlice = createSlice({
  name: "intakeFormSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(PostCustomForm.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          intakeformlist: [action.payload, state.intakeformlist],
        };
      }
    });

    builder.addCase(UpdateExternalForm.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          intakeClientFormData: action.payload,
        };
      }
    });
    /* not use!
    builder.addCase(GetIntakeClientFormData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          intakeClientFormData: action.payload,
        };
      }
    });
    */
    builder.addCase(deleteIntake.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          intakeformlist: state.intakeformlist.filter(
            (item) => item.id !== action.payload
          ),
        };
      }
    });
  },
});

/* not use!!
//for getting all intakeClientFormData
export const GetIntakeClientFormData = createAsyncThunk(
  "/getallintakeClientFormdata",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await BusinessSetting.getIntakeClientFormData();

      return data;
    } catch (e: any) {
      //do something if needed
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);
*/

//creates intake forms
export const PostCustomForm = createAsyncThunk(
  "/createIntakeForm",
  async (
    {
      name,
      jsonData,
      formURL,
      formEmbeddedCode,
      type,
      formType,
    }: {
      name: string;
      jsonData?: [] | undefined;
      formURL?: string | undefined;
      formEmbeddedCode?: string | undefined;
      type: string;
      formType: string;
    },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));

    if (type === "External") {
      let payloadData: CustomForm = {
        name: name,
        url: formURL,
        embeddedCode: formEmbeddedCode,
        formType: formType,
        formCategory: name,
      };

      try {
        const { status, message, data } = await FormService.postCustomForm(
          payloadData
        );
        if (status) {
          dispatch(setResponseValue({ name: "success", value: true }));
          dispatch(setResponseValue({ name: "message", value: message }));
          dispatch(GetFormCards({}));
          return data;
        }
      } catch (e: any) {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: e?.response?.data?.message,
          })
        );
      } finally {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    } else {
      let payloadData: CustomForm = {
        name: name,
        jsonData: jsonData,
        formType: formType,
        formCategory: name,
      };
      try {
        const { status, message, data } = await FormService.postCustomForm(
          payloadData
        );
        if (status) {
          dispatch(setResponseValue({ name: "success", value: true }));
          dispatch(setResponseValue({ name: "message", value: message }));
          dispatch(GetFormCards({}));
          return data;
        }
      } catch (e: any) {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: e?.response?.data?.message,
          })
        );
      } finally {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    }
  }
);

//creates intake forms
export const UpdateExternalForm = createAsyncThunk(
  "/UpdateExternalForm",
  async (
    {
      id,
      name,
      formURL,
      embeddedCode,
      type,
      formType,
    }: {
      id: string;
      name: string;
      formURL: string;
      embeddedCode?: string;
      type: string;
      formType: string;
    },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));

    if (type === "External") {
      let payloadData: updateForms = {
        id: id,
        name: name,
        url: formURL,
        embeddedCode: embeddedCode,
        formType: formType,
        formCategory: name,
      };

      try {
        const { status, message, data } = await FormService.updateFormDataById(
          id,
          payloadData
        );
        if (status) {
          dispatch(setResponseValue({ name: "success", value: true }));
          dispatch(setResponseValue({ name: "message", value: message }));
          dispatch(GetFormCards({}));
          return data;
        }
      } catch (e: any) {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: e?.response?.data?.message,
          })
        );
      } finally {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    }
  }
);

export const UpdateFormDataById = createAsyncThunk(
  "/updateFormDataById",
  async (
    {
      id,
      name,
      jsonData,
      formType,
    }: {
      id: string;
      name: string;
      jsonData: {};
      formType: string;
    },
    { dispatch, getState }
  ) => {
    const payloadData = {
      name: name,
      jsonData: jsonData,
      formCategory: name,
      formType: formType,
    };

    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message, data } = await FormService.updateFormDataById(
        id,
        payloadData
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        // dispatch(GetIntakeClientFormData());
        dispatch(GetFormCards({}));

        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//Duplicated form data by id
export const DuplicateFormDataById = createAsyncThunk(
  "/DuplicateFormDataById",
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message, data } = await FormService.duplicateFormDataById(
        id
      );
      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        // dispatch(GetIntakeClientFormData());
        dispatch(GetFormCards({}));

        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteIntake = createAsyncThunk(
  "/deleteIntake/",
  async ({ id, formType }: { id: string; formType: string }, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await FormService.deleteFormById(id);
      if (status) {
        // dispatch(GetDataCatalog());
        dispatch(GetFormCards({}));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return id;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export default intakeFormSlice;
