import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { AppDispatch, RootState } from "../../../redux/store";
import { ITaskState } from "../../../types/trello-board";
import Board from "../../trello-board/Board";
import {
  moveProviderCardToAnotherBoard,
  setProviderTasks,
} from "../../../redux/features/providers-main/provider-board-slice";
import { OrgService } from "../../../constants/company-user";
import { OrgData } from "../../../types/company";

const Boards = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
`;

interface ProviderBoardProps {
  callbacks: ((id: string) => Promise<void>)[];
  chatCommand: (id: string) => void;
}

const BoardStage: React.FC<ProviderBoardProps> = ({
  callbacks,
  chatCommand,
}) => {
  // #region variables region

  const org = useSelector<RootState, OrgData>(
    (state) => state.organizationSlice.orgData
  );

  //intake stages
  const initonBoardingStages = useSelector<RootState, any>(
    (state) => state.providerOnBoardingStage.stages
  );
  const [onBoardingStages, setOnBoardingStages] =
    useState(initonBoardingStages);
  //client boards
  const inittasks = useSelector<RootState, ITaskState>(
    (state) => state.providerBoardSlice
  );
  const [tasks, setTasks] = useState(inittasks);
  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  // #endregion

  // #region methods region

  //handles drop logic
  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    if (!destination) return;

    // Move Tasks
    if (source.droppableId === destination?.droppableId) {
      // Move a task in same board.
      let reOrderedTasks = [...tasks[destination.droppableId]];
      const sourceTask = {
        ...reOrderedTasks[source.index],
        // boardColumnUpdatedAt: new Date().toString(),
      };
      reOrderedTasks.splice(source.index, 1);
      reOrderedTasks.splice(destination?.index, 0, sourceTask);
      dispatch(
        setProviderTasks({
          ...tasks,
          [destination?.droppableId]: reOrderedTasks,
        })
      );
      const fromBoardId = onBoardingStages.find(
        (item: any) => item.stageName === source.droppableId
      );

      if (fromBoardId) {
        dispatch(
          moveProviderCardToAnotherBoard({
            fromBoardId: fromBoardId.id,
            toBoardId: fromBoardId.id, //null,
            cardId: draggableId,
            index: destination.index,
          })
        );
      }
    } else if (source?.droppableId !== destination?.droppableId) {
      // Move task to different board
      let modifiedSourceTasks = [...tasks[source.droppableId]];
      let modifiedDestinationTasks = [...tasks[destination?.droppableId]];
      const sourceTask = {
        ...modifiedSourceTasks[source.index],
        boardColumnUpdatedAt: new Date().toString(),
      };
      modifiedSourceTasks.splice(source.index, 1);
      modifiedDestinationTasks.splice(destination?.index, 0, sourceTask);
      dispatch(
        setProviderTasks({
          ...tasks,
          [source.droppableId]: modifiedSourceTasks,
          [destination?.droppableId]: modifiedDestinationTasks,
        })
      );
      const fromBoardId = onBoardingStages.find(
        (item: any) => item.stageName === source.droppableId
      );
      const toBoardId = onBoardingStages.find(
        (item: any) => item.stageName === destination.droppableId
      );

      if (fromBoardId && toBoardId) {
        dispatch(
          moveProviderCardToAnotherBoard({
            fromBoardId: fromBoardId.id,
            toBoardId: toBoardId.id,
            cardId: draggableId,
            index: destination.index,
          })
        );
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (
      org.orgService &&
      org.orgService.length === 1 &&
      org.orgService.includes(OrgService.MentalHealth)
    ) {
      const singleStage = initonBoardingStages[0];
      setOnBoardingStages([singleStage]);
      setTasks({ [singleStage.stageName]: inittasks[singleStage.stageName] });
    } else {
      setTasks(inittasks);
    }
  }, [initonBoardingStages, org, inittasks]);

  // #endregion

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={"boards"}
        type={"boards"}
        direction={"horizontal"}
      >
        {({ innerRef, droppableProps, placeholder }) => (
          <Boards ref={innerRef} {...droppableProps}>
            <div className="allStageCards allStageCardsBoard">
              {Object.keys(tasks).map((stageName, index) => (
                <Board
                  key={stageName}
                  index={index}
                  stage={
                    onBoardingStages?.filter(
                      (stage: any) => stage.stageName === stageName
                    )[0]
                  }
                  tasks={tasks[stageName]}
                  callbacks={callbacks}
                  chatCommand={chatCommand}
                />
              ))}
            </div>
            {placeholder}
          </Boards>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default BoardStage;
