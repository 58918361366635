import React, { useEffect, useState, Dispatch } from "react";
import { useUser } from "@descope/react-sdk";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { Delete, Search } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getAllUserProfiles } from "../../../../redux/features/user-slice";
import CloseIcon from "@mui/icons-material/Close";
import ProviderImg from "../../../../assets/images/images-svg/provider-default.svg";

interface IUsers {
  id: string;
  name: string;
  profilePictureUrl: string;
}

const AssignTaskModal: React.FC<{
  setAssignedTo: Dispatch<any>;
  assignedTo: any;
  fromModal: boolean;
}> = ({ assignedTo, setAssignedTo, fromModal }) => {
  const dispatch = useAppDispatch();

  /* Descope Integration */
  const { user } = useUser();

  const [searchValue, setSearchValue] = useState<string>("");
  //stores search list
  const [searchList, setSearchList] = useState<IUsers[]>([]);

  const users = useAppSelector((state) =>
    state.user.tenantUsers
      .filter((item: any) => item.authRole !== "Parent")
      .map((item: any) => ({
        id: item.id,
        name: item.firstName + " " + item.lastName,
        profilePictureUrl: item.profilePictureUrl,
      }))
  );

  //get match value
  const getMatchData = (item: any, searchVal: string): boolean => {
    if (
      searchVal &&
      !item.toLowerCase().includes(searchVal.toLowerCase().trim())
    ) {
      return false;
    }
    if (searchVal === "") {
      return false;
    }
    return true;
  };

  const handleSearchResult = (searchVal: string) => {
    let result: any[] = [];
    if (searchVal !== undefined) {
      if (searchVal !== "") {
        users.map((item: IUsers) => {
          return getMatchData(item.name, searchVal) && result.push(item);
        });
      } else {
        result = [...users];
      }
    }
    setSearchList(result);
  };

  useEffect(() => {
    dispatch(
      getAllUserProfiles({
        authTenantId: user.customAttributes?.currentTenantId,
        callerEmail: user.email as string,
        callerName: user.name as string,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="taskFor">
      <div className="mb-18 relative">
        <Box className="recipents-search">
          {fromModal && assignedTo?.id !== undefined && (
            <div className="profilePart userInfo-Card">
              <img src={assignedTo?.profilePictureUrl || ProviderImg} alt="" />
              <Typography>{assignedTo?.name}</Typography>
              <CloseIcon
                style={{ cursor: "pointer" }}
                color="primary"
                onClick={() => {
                  setAssignedTo(null);
                }}
              />
            </div>
          )}
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              value={searchValue}
              size="medium"
              placeholder=""
              onChange={(e) => {
                handleSearchResult(e.target.value);
                setSearchValue(e.target.value);
              }}
              sx={{
                "& fieldset": { border: "none" },
              }}
            />
          </FormControl>

          <Search color="primary" />
        </Box>
        {searchList?.length > 0 && (
          <Box className="board-modelSearch-data">
            {searchList.map((item) => (
              <div
                className="profilePart userlist"
                onClick={() => {
                  setAssignedTo(item);
                  setSearchList([]);
                  setSearchValue("");
                }}
              >
                <img src={ProviderImg} alt="" />
                <Typography>{item.name}</Typography>
              </div>
            ))}
          </Box>
        )}
      </div>
      {!fromModal && (
        <Box paddingTop={3}>
          <Typography paddingBottom={2} fontSize={15}>
            Assign Task To :{" "}
          </Typography>
          {assignedTo?.id && (
            <Box className="assigned-row">
              <div className="image-outer  flex-image">
                <img className="userSmallCircle" src={ProviderImg} alt="" />
                <Typography>{assignedTo?.name}</Typography>
              </div>

              <Delete
                sx={{ cursor: "pointer", color: "#096dd9" }}
                onClick={() => setAssignedTo(null)}
              />
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

export default AssignTaskModal;
