import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { ClientForms } from "../../types/client";
import { useEffect, useRef, useState } from "react";
import DataTable from "../Plannings/data-View/DataTable";
import { clearSchedulesMatchValues } from "../../redux/features/schedules-main/match-slice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, useAppSelector } from "../../redux/store";
import { ReactComponent as FilterIcon } from "../../assets/images/images-svg/filterIcon.svg";
import { ScheduleFilters } from "../../types/schedule";
import { getBussinessName } from "../../constants/common";
import { OrgBU } from "../../types/company";
import CloseIcon from "@mui/icons-material/Close";
import { setSchedulesFilterValues } from "../../redux/features/schedules-main/schedules-filter-slice";
import { dataUpdated, GetAllClientData } from "../../redux/features/client-main/client-list-slice";
import { getBusinessUnitName } from "../../utils/EntityUtils";
import { Close } from "@mui/icons-material";
import ScheduleFilter from "../filter-main/ScheduleFilter";
import { ProviderForms } from "../../types/provider";
import { filterSchedules } from "../../helpers/schedule-filter-helper/schedule-filter-helper";
import "./schedule-match/schedulesMatch.scss";
import { fontSize } from "@mui/system";
import { bindActionCreators } from "@reduxjs/toolkit";
import { GetAllProviders } from "../../redux/features/providers-main/provider-list-slice";
// import { GetScheduleList } from "../../redux/features/schedules-main/schedule-list-slice";

export interface ISelectedRecord {
  id: string;
  type: string;
}
const Schedules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  //binding action creators
  const dispatchAction = bindActionCreators(
    {
      // GetScheduleList,
      GetAllClientData,
      GetAllProviders,
    },
    dispatch
  );
  //same dispatch ref between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);

  //stores filtered Data
  const [selectedrecord, setSelectedRecord] = useState<ISelectedRecord>({
    id: "",
    type: "",
  });
  const [open, setOpen] = useState<boolean>(false);
  //controls filter modal visibility
  const [filterModal, setFilterModal] = useState<boolean>(false);

  // //client list
  // const clientList = useSelector<RootState, ClientForms[]>(
  //   (state) => state.clientListSlice.listData
  // );

  // //provider list
  // const ProviderList = useSelector<RootState, ProviderForms[]>(
  //   (state) => state.providerListSlice.listData
  // );
  const clientList = useAppSelector((state) => state.clientListSlice.listData);
  const ProviderList = useAppSelector((state) => state.providerListSlice.listData);

  //filter values from redux
  const filterTypes = useSelector<RootState, ScheduleFilters>(
    (state) => state.schedulesFilterSlice
  );

  //search value from redux
  const searchValue = useSelector<RootState, string>(
    (state) => state.activeStep.searchValue
  );

  //filtered Ids
  const [filterlist, setFilterList] = useState<string[]>([]);
  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );
  const handleBeginMatch = () => {
    if (selectedrecord?.id !== "") {
      dispatch(clearSchedulesMatchValues({}));
      const type = selectedrecord.type === "Employee" ? "Provider" : "Client";
      localStorage.setItem("entityType", type);
      navigate(`/schedule/${selectedrecord?.id}`, {
        state: {
          counter: 1,
          entityType: type,
        },
      });
    } else {
      setOpen(true);
    }
  };
  //handles closing of single filter
  const handleCloseSingleFilter = (key: string, item: string) => {
    if (key === "insuranceProvider") {
      let value = filterTypes.insuranceProvider.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, insuranceProvider: value })
      );
    } else if (key === "businessUnit") {
      let value = filterTypes.businessUnit.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, businessUnit: value })
      );
    } else if (key === "clientStatus") {
      let value = filterTypes.clientStatus.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, clientStatus: value })
      );
    } else if (key === "providerStatus") {
      let value = filterTypes.providerStatus.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, providerStatus: value })
      );
    } else if (key === "intakeStage") {
      let value = filterTypes.intakeStage.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, intakeStage: value })
      );
    } else if (key === "onBoardingStage") {
      let value = filterTypes.onBoardingStage.filter((i) => i !== item);
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, onBoardingStage: value })
      );
    } else if (key === "ScheduleStatus") {
      dispatch(
        setSchedulesFilterValues({ ...filterTypes, ScheduleStatus: "" })
      );
    } else if (key === "startDate") {
      dispatch(setSchedulesFilterValues({ ...filterTypes, startDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "endDate") {
      dispatch(setSchedulesFilterValues({ ...filterTypes, endDate: null }));
      dispatch(dataUpdated({ dataUpdate: false }));
    } else if (key === "age") {
      dispatch(setSchedulesFilterValues({ ...filterTypes, age: null }));
    }
  };

  useEffect(() => {
    // console.log("fetch all client and provider data");
    dispatchActionRef.current.GetAllClientData();
    dispatchActionRef.current.GetAllProviders();
  }, []);

  // console.log(searchValue, "searchValue");
  useEffect(() => {
    let clientListId: string[] = [];
    let ProviderListId: string[] = [];
    if (clientList) {
      clientListId = filterSchedules(filterTypes, searchValue, clientList).map(
        (item) => {
          return item.clientBasicDetails.id;
        }
      );
    }
    if (ProviderList) {
      ProviderListId = filterSchedules(
        filterTypes,
        searchValue,
        ProviderList
      ).map((item) => {
        return item.employmentDetails.id;
      });
    }
    setFilterList([...clientListId, ...ProviderListId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProviderList.length, clientList.length, filterTypes, searchValue]);

  return (
    <div className="pageTemplate">
      <Grid container spacing={0}>
        {/* comment out, may use later. 
        <Grid item xs={12} mb={"48px"}>
          <ScheduleDashboard />
        </Grid> 
        */}
        {/* <Grid item xs={12} mb={"48px"}>
          <ScheduleCards
            filteredData={filteredData}
            setFilteredData={setFilteredData}
          />
        </Grid>
        <Grid item xs={12}>
          <ScheduleList filteredData={filteredData} />
        </Grid> */}
        <div
          style={{
            width: "100%",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" className="DetailsPageTitle">
            Current Schedules
          </Typography>
          <div className="scheduleFilter-btn">
            <ButtonGroup
              disableElevation
              variant="contained"
              className="filterGroup-btn"
            >
              <Button
                onClick={() => {
                  setFilterModal(true);
                }}
              >
                <FilterIcon />
              </Button>
            </ButtonGroup>
            <Button
              sx={{ fontSize: "16px", borderRadius: "8px" }}
              variant="contained"
              onClick={handleBeginMatch}
            >
              Begin to Match
            </Button>
          </div>
        </div>
        <Box className="selected-filtersButtons" sx={{ width: "100%" }}>
          {Object.entries(filterTypes)
            .filter(([_, value], idx) => {
              if (value && typeof value === "string") return true;
              if (value?.length > 0 && typeof value === "object") return true;
              return false;
            })
            .map(([key, value], index) => {
              if (typeof value === "object") {
                return value.map((subItem: string, subIndex: number) => {
                  if (
                    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
                      subItem
                    )
                  ) {
                    return (
                      <Button className="border-button" key={index}>
                        {getBussinessName(subItem, businessUnits)}
                        <span className="closeBtn">
                          <CloseIcon
                            fontSize="small"
                            onClick={() => {
                              handleCloseSingleFilter(key, subItem);
                            }}
                          />
                        </span>
                      </Button>
                    );
                  }
                  return (
                    <Button className="border-button" key={subIndex}>
                      {subItem}
                      <span className="closeBtn">
                        <CloseIcon
                          fontSize="small"
                          onClick={() => {
                            handleCloseSingleFilter(key, subItem);
                          }}
                        />
                      </span>
                    </Button>
                  );
                });
              } else {
                return (
                  <Button className="border-button" key={index}>
                    {key === "businessUnit"
                      ? getBusinessUnitName(businessUnits, value)
                      : key === "age"
                      ? `Age:${value}`
                      : value}
                    <span className="closeBtn">
                      <CloseIcon
                        fontSize="small"
                        onClick={() => {
                          handleCloseSingleFilter(key, value);
                        }}
                      />
                    </span>
                  </Button>
                );
              }
            })}
        </Box>

        <DataTable
          setSelectedRecords={setSelectedRecord}
          type={"Match"}
          beginToMatch={handleBeginMatch}
          filteredIds={filterlist}
        />
      </Grid>
      <Dialog open={filterModal} className="largeModel scheduleFilter">
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            Filter Schedules
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => setFilterModal(false)}
          >
            <Close />
          </Button>
        </Box>
        <ScheduleFilter
          closeModal={(val: boolean, filterObject: ScheduleFilters) => {
            dispatch(setSchedulesFilterValues({ ...filterObject }));
            setFilterModal(val);
          }}
          filterData={true}
          listData={[]}
          isFilterClient="Both"
        />
      </Dialog>
      <Dialog open={open} className="checklistModal">
        <div>
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
                Warning
            </Typography>
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <DialogContent style={{ height: "100px" ,display:"flex",alignItems:"center"}}>
            <Typography sx={{fontSize:"18px"}}>Please select a row to begin to match.</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              className="button"
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default Schedules;
