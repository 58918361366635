import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import BoardService from "../../../service/customBoard.service";

export interface IRuns {
  id: string;
  descope_tenantId: string;
  jobType: string;
  jobName: string;
  status: string;
  boardId: string | null;
  entityType: string | null;
  entityId: string | null;
  entityName: string | null;
  jobData: any;
  fileAction: string;
  fileName: string;
  fileUrl: string;

  // isReviewed: boolean | null;
  // reviewedBy: string | null;
  // reviewedAt: string | null;
  // reviewComments: string| null;
  createdAt: string;
  updatedAt: string;
}

const initialState: IRuns[] = [];

const runsSlice = createSlice({
  name: "runsSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetRuns.fulfilled, (state, action) => {
      return (state = action.payload);
    });
  },
});

export const GetRuns = createAsyncThunk("/getRuns", async (_, { dispatch }) => {
  try {
    dispatch(setResponseValue({ name: "pending", value: true }));

    const { data, message } = await BoardService.getRuns();
    dispatch(setResponseValue({ name: "success", value: true }));
    dispatch(setResponseValue({ name: "message", value: message }));
    return data;
  } catch (error: any) {
    console.log(error);
    dispatch(setResponseValue({ name: "error", value: true }));
    dispatch(
      setResponseValue({
        name: "message",
        value:
          error?.message || error?.response?.data?.message || "Error occured",
      })
    );
    throw error;
  } finally {
    dispatch(setResponseValue({ name: "pending", value: false }));
  }
});

export default runsSlice;
