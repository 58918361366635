import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Role } from "../../../types/descope";
import DescopeService from "../../../service/descope.service";
import { setResponseValue } from "../api-response/api-response";

// Initial State
export interface DescopeInitState {
  tenantRoles: Role[];
  // currentTenant: string;
}

const initialState: DescopeInitState = {
  tenantRoles: [],
  // currentTenant: "",
};

const descopeSlice = createSlice({
  name: "descope",
  initialState: initialState,
  reducers: {
    // setAllRoles: (state, action) => {
    //   state.tenantRoles = action.payload;
    //   console.log("descope tenantRoles:", action.payload);
    //   return state;
    // },
    // setCurrentTenant: (state, action) => {
    //   state.currentTenant = action.payload;
    //   console.log("descope slice:", state.currentTenant);
    //   return state;
    // },
    // resetCurrentTenant: (state, action) => {
    //   state.currentTenant = "";
    //   return state;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDescopeRoles.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload.length > 0) {
          return {
            ...state,
            tenantRoles: action.payload,
          };
        }
      }
    });
  },
});

export const getAllDescopeRoles = createAsyncThunk(
  "descope/allDescopeRoles",
  async (_, { getState, dispatch }) => {
    try {
      const { status, data, message } =
        await DescopeService.retrieveAllDescopeRoles();
      if (status) {
        const filteredRoles = data?.filter((role: any) => role.name !== "ABAEngine Admin" && role.name !== "Tenant Admin")
        // dispatch(setResponseValue({ name: "success", value: true }));
        // dispatch(setResponseValue({ name: "message", value: message }));

        return filteredRoles;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    }
  }
);

export const switchTenantForUser = createAsyncThunk(
  "descope/switchTenantForUser",
  async (
    {
      userId,
      email,
      tenantId,
    }: {
      userId: string;
      email: string;
      tenantId: string;
    },
    { getState, dispatch }
  ) => {
    try {
      //notify backend switch tenant & update new tenant in JWT token
      const { status, data, message } =
        await DescopeService.switchTenantForUser(tenantId);

      if (status) {
        // dispatch(setCurrentTenant(tenantId));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    }
  }
);

// export const {
//   setAllRoles,
// } = descopeSlice.actions;

export default descopeSlice;
