import { CopyBlock, atomOneLight} from "react-code-blocks";

interface ICopyBoard {
  code: string;
  language: string;
  showLineNumbers: boolean;
  startingLineNumber: number;
}

export const MyCoolCodeBlock: React.FC<ICopyBoard> = ({
  code,
  language,
  showLineNumbers,
  startingLineNumber,
}) => {

  //#region variable region

  //Css for snippet
  // const dracula = {
  //   background: "black",
  //   text: "#f8f8f2",
  //   padding: "15px",
  //   borderRadius: "12px",
  // };
  //#endregion


  return (
    <CopyBlock
      text={code}
      language={language}
      showLineNumbers={showLineNumbers}
      startingLineNumber={startingLineNumber}
      highlight="4,5,6,7,8,9"
      theme={atomOneLight}
      codeBlock
    />
  );
};
