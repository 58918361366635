import React, { useEffect, useState } from "react";
import { BoldBI } from "@boldbi/boldbi-embedded-sdk";
import axios from "../../axios/axios";
import { getSessionToken } from "@descope/react-sdk";
import { DashboardType } from "../../types/dashboard";
import "./dashboard.scss";

// Url of the authorizationserver action in the ValuesController of the NodeJs application.
const authorizationUrl = process.env.REACT_APP_BACKEND_SERVER_BASE_URL + "/dashboard/authorize";

const Dashboard: React.FC<{ type: DashboardType }> = ({ type }) => {
  // const [embedConfig, setEmbedConfig] = useState({});
  // const BoldBiObj = new BoldBI();

  const token = getSessionToken();

  const renderDashboard = (data: any) => {
    const dashboard = BoldBI.create({
      serverUrl: `${data.ServerUrl}/${data.SiteIdentifier}`,
      dashboardId: data.DashboardId,
      embedContainerId: "dashboard",
      embedType: data.EmbedType,
      environment: data.Environment,
      mode: BoldBI.Mode.View,
      width: "100%",
      height: window.innerHeight + "px",
      expirationTime: 100000,
      authorizationServer: {
        url: authorizationUrl,
        headers: { authorization: `Bearer ${token}` },
      },
      // filterParameters: "descope_tenantId=T2ciETjztqA9C4AQMCfbFK4DDkZo",
    });

    dashboard.loadDashboard();
  };

  useEffect(() => {
    const fetchData = async () => {
      // const id = type;
      try {
        const { data } = await axios.get(`/dashboard-setting/${type}`);
        // setEmbedConfig(data);
        renderDashboard(data);
      } catch (error) {
        console.log(error);
        // Handle error
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <div id="Dashboard" className="asas">
      <div id="viewer-section">
        <div id="dashboard"></div>
      </div>
    </div>
  );
};

export default Dashboard;
