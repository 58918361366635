import MessageHeading from "./message-heading/MessageHeading";
import { Grid } from "@mui/material";
import MessageDetails from "./message-details/MessageDetails";

const Message = () => {
  return (
    <>
      <div className="pageTemplate mapDetailsTemplate">
        <MessageHeading />
        <MessageDetails />
      </div>
    </>
  );
};

export default Message;
