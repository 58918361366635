import axios from "../axios/axios";
import { DeleteResponse } from "../types/response";

/**
 * Delete an availability by its id
 * @param {string} id - The id of the availability to delete
 * @returns {Promise<DeleteResponse>} - The response from the server
 */
export async function deleteAvailabilityById(id: string) {
    try {
      const { data } = await axios.delete<DeleteResponse>(`/availability/${id}`);
  
      return data;
    } catch (e: any) {
      throw e?.response?.data;
    }
  }
  
  /**
 * Creates a new client and associates it with availabilities
 * @param {any[]} availabilityObj - The availabilities to be associated with the new client
 * @returns {Promise<any>} - The newly created client
 */
  export async function createAvailability(availabilityObj: any[]) {
    try {
      const data = await axios.post(`/availability/`, {
        availabilities: availabilityObj,
      });
  
      return data.data;
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

/**
 * Updates a client with their availabilities
 * @param {any[]} availabilities - The availabilities to be associated with the client
 * @returns {Promise<{status: string, message: string}>} - The status and message of the response
 */
export async function updateAvailabilityDetails(
    availabilities: any[]
  ) {
    try {
      let data = null;
      data = await axios.post(`/availability`, {
        availabilities: availabilities,
      });
      return { status: data.data.status, message: data.data.message };
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  const AvailabilityService = {
    createAvailability,
    updateAvailabilityDetails,
    deleteAvailabilityById,
  };


export default AvailabilityService;