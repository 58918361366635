import {
  Box,
  Button,
  Dialog,
  Grid,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { useEffect, useState } from "react";
import { ReactComponent as LeftArrow } from "../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/images-svg/rightArrow.svg";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import Review from "../../assets/images/images-png/Export.png";
import Download from "../../assets/images/images-svg/Download.svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import moment from "moment";
import { FormRequestStatus } from "../../constants/session";
import { ISession } from "../../types/session";
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from "../settings/business-settings/BusinessForm";
import CloseIcon from "@mui/icons-material/Close";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";

const SessionForms = () => {
  const navigate = useNavigate();
  const [isDesktop, setDesktop] = useState<number>(4); // Number of visible slides
  const [activeIndex, setActiveIndex] = useState<number>(0); // Starting index
  const [open, setOpen] = useState<boolean>(false);
  const [survey, setSurvey] = useState<any>(null);
  const currentSession = useAppSelector<ISession | null>(
    (state) => state.sessionSlice.currentSession
  );

  // Set the number of slides visible based on screen size (adaptive behavior)
  useEffect(() => {
    const updateScreenWidth = () => {
      if (window.innerWidth > 1250) {
        setDesktop(4);
      } else if (window.innerWidth > 991) {
        setDesktop(3);
      } else if (window.innerWidth > 650) {
        setDesktop(2);
      } else {
        setDesktop(1);
      }
    };
    updateScreenWidth();
    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  // Left scroll button logic
  const handleLeft = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => Math.max(prevIndex - isDesktop, 0));
    }
  };

  // Right scroll button logic
  const handleRight = () => {
    if (!currentSession) {
      return;
    }
    if (activeIndex + isDesktop < currentSession?.requiredForms.length) {
      setActiveIndex((prevIndex) =>
        Math.min(
          prevIndex + isDesktop,
          currentSession?.requiredForms.length - 1
        )
      );
    }
  };
  const showArrow =
    currentSession && isDesktop < currentSession?.requiredForms.length;
  return (
    <Box className="sessionForms">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={currentSession ? currentSession?.requiredForms.length : 0}
        visibleSlides={isDesktop}
        step={isDesktop}
        // currentSlide={activeIndex}
        orientation="horizontal"
        className="cardSlider"
      >
        <Slider className="slider">
          {currentSession?.requiredForms.map((item, i) => (
            <Slide index={i} key={item.id} className="slide">
              <Grid item xs={12} style={{ margin: "10px" }} key={i}>
                <div className="documentCard">
                  <div className="documentCard__img">
                    <div className="documentCard__img-card">
                      <p>{item.formCategory}</p>
                    </div>
                  </div>
                  <div className="documentCard__btn">
                    <span
                      style={{ backgroundColor: "#096dd9" }}
                      onClick={() => {
                        const surveydata = new Model(item.formSchema);
                        surveydata.showCompleteButton = false;
                        setSurvey(surveydata);
                        setOpen(true);
                      }}
                    >
                      Preview
                    </span>
                  </div>
                </div>
              </Grid>
            </Slide>
          ))}
        </Slider>
        {showArrow && (
          <ButtonBack className="sliderNavBtn" onClick={handleLeft}>
            <LeftArrow />
          </ButtonBack>
        )}
        {showArrow && (
          <ButtonNext className="sliderNavBtn" onClick={handleRight}>
            <RightArrow />
          </ButtonNext>
        )}
      </CarouselProvider>
      <Box className="session-Request">
        <Typography className="formRequest-title">
          Request & Submission
        </Typography>
        <TableContainer component={Paper} className="tableResponsive">
          <StyledTable
            sx={{
              minWidth: 700,
              borderCollapse: "separate",
              cursor: "pointer",
              borderSpacing: "0 8px",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Person</StyledTableCell>
                <StyledTableCell>Form</StyledTableCell>
                <StyledTableCell>Send Date</StyledTableCell>
                <StyledTableCell>Submit Date</StyledTableCell>
                <StyledTableCell>Review Date</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>

                <StyledTableCell>Data Review</StyledTableCell>
                <StyledTableCell>Result</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentSession?.formRequests &&
                currentSession?.formRequests.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {item?.entityType}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box className="flex">
                        <span className="userMapCart__img">
                          <img
                            src={item.profileUrl || ClientImg}
                            className="userSmallCircle"
                            alt={""}
                          />
                        </span>
                        <Typography className="ClientName">
                          {item.entityName}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      {item?.formName}
                    </StyledTableCell>
                    {/* sent Date */}
                    <StyledTableCell>
                      {moment(item.sentDtm).format("ll")}
                    </StyledTableCell>
                    {/* Submit date */}

                    <StyledTableCell>
                      {item.submitDtm ? (
                        <Typography>
                          {moment(item.submitDtm).format("ll")}
                        </Typography>
                      ) : (
                        <Box className="dateMessage">Not Submitted Yet</Box>
                      )}
                    </StyledTableCell>
                    {/* review Date */}
                    <StyledTableCell>
                      {item?.reviewDtm ? (
                        moment(item?.reviewDtm).format("ll")
                      ) : (
                        <Box className="dateMessage">Not Sign Yet</Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{item.status}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        className="flex"
                        onClick={() => {
                          if (item.status !== FormRequestStatus.Sent)
                            navigate(`/form-review/${item.id}`);
                        }}
                      >
                        <img src={Review} alt="" />
                        {(item.status === FormRequestStatus.Complete ||
                          item.status === FormRequestStatus.InReview ||
                          item.status === FormRequestStatus.Submitted) && (
                          <Typography>
                            {item.reviewData?.checkList?.length || 0} /{" "}
                            {item.checkListCount ||
                              item.form?.checkList?.length}{" "}
                            reviewed
                          </Typography>
                        )}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* download */}
                      {item.pdfUrl && (
                        <a href={item?.pdfUrl}>
                          <img src={Download} alt="" />
                        </a>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Box>
      {open && (
        <Dialog open={open} className="smallModel--intakeforms session-preview">
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              preview
            </Typography>

            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Box>

          <div>
            <Survey id="id" model={survey} />;
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              margin: "12px",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </div>
        </Dialog>
      )}
    </Box>
  );
};

export default SessionForms;
