import moment from "moment";

  /**
   * get age
   * @param dateString
   * @returns age of the child based on date of birth
   */
  export const getAge = (dateString: string): number => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  // Only support when "08:30 PM" type string
  const getHrsMins = (time: string) => {
    const firstPass = time.split(":");

    let hours = firstPass[0];
    let secondValue = firstPass[1];

    const secondPass = secondValue.split(" ");
    let minute = secondPass[0];
    let type = secondPass[1]
    if (type === "PM") {
      // if (time === "12:00 PM" || time === "12:15 PM" || time === "12:30 PM" || time === "12:45 PM") {
      if (time.startsWith("12")) {
        return {
          hours: Number(hours),
          minute: Number(minute)
        }
      } else {
        return {
          hours: Number(hours) + 12,
          minute: Number(minute)
        }
      }
    } else {
      return {
        hours: Number(hours),
        minute: Number(minute),
      };
    }
  };

  export const getDifferenceBetweenFromTo = ({
    fromTime,
    toTime,
  }: {
    fromTime: string;
    toTime: string;
  }) => {
    if (!fromTime || !toTime) {
      return 0;
    }
    
    const fromTimeHrsMins = getHrsMins(fromTime);
    const toTimeHrsMins = getHrsMins(toTime);
  
    // Fixed Date
    const fromDate = new Date("2022-11-11");
    fromDate.setHours(fromTimeHrsMins.hours);
    fromDate.setMinutes(fromTimeHrsMins.minute);
    const toDate = new Date("2022-11-11");
    toDate.setHours(toTimeHrsMins.hours);
    toDate.setMinutes(toTimeHrsMins.minute);
    //
    let first = moment(fromDate, "DD-MM-YYYY");
    let second = moment(toDate, "DD-MM-YYYY");
    let diff = second.diff(first, "minutes");
  
    return diff / 60;
  };