import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Model, settings } from "survey-core";
import { Survey } from "survey-react-ui";
import { getLatLng } from "react-google-places-autocomplete";

import SurveyComplete from "./SurveyComplete";

import { getPublicForm, postPublicForm } from "../../service/form.service";
import { setResponseValue } from "../../redux/features/api-response/api-response";

import { IEmbedForm } from "../../types/embedform";

import "./embedform.scss";
import English from "../../assets/images/images-png/united-kingdom.png";
import Spanish from "../../assets/images/images-png/spain.png";
import China from "../../assets/images/images-png/china.png";
import { Serializer } from "survey-core";
import { BusinessFormType } from "../../constants/forms";

const Embedform = () => {
  // #region variable region

  //dispatch for redux
  const dispatch = useDispatch();

  //used to get param value
  const { formId } = useParams();

  //used to store data json
  const [datajson, setDatajson] = useState<IEmbedForm>();

  const [messageError, setMessageError] = useState<string>("");

  //used for redirecting to completeSurvey Page
  //Pending, Loading, Success, Failed
  const [loadingStatus, setLoadingStatus] = useState<string>("Pending");

  //selected language
  const [selectedLang, setSelectedLang] = useState<string>("default");

  // A variable that will store files until the survey is completed
  const [fileStorage, setFileStorage] = useState<any>({}); //questionName -> files
  //#endregion

  //#region methods region
  /* implementation memo

  1.31.2024 - Delayed File Upload
  https://surveyjs.io/form-library/examples/delayed-file-upload/reactjs#content-code

  7.15.2024 - Must Setup Default Value
  Otherwise, the file upload will not work properly
  (survey.onUploadFiles.add((sender, options) => not called for some reason)

  11.11.2024 - Optionally execute skip trigger on page next
  https://github.com/surveyjs/survey-library/issues/2380
  */

  Serializer.getProperty("file", "allowMultiple").defaultValue = true;
  Serializer.getProperty("file", "waitForUpload").defaultValue = true;
  Serializer.getProperty("file", "needConfirmRemoveFile").defaultValue = true;
  Serializer.getProperty("file", "storeDataAsText").defaultValue = false;

  //set up survey model
  const survey = new Model(datajson?.formSchema);
  // survey.showCompletedPage = false; //turn off default surveyJS Complete Page
  settings.executeSkipTriggerOnValueChanged = false;
  
  
  survey.onUploadFiles.add((sender, options) => {
    // Add files to the temporary storage
    let tempFileStorage = fileStorage;

    //lookup by question name
    if (tempFileStorage && tempFileStorage[options.name] !== undefined) {
      tempFileStorage[options.name] = [
        ...tempFileStorage[options.name],
        ...options.files,
      ];
    } else {
      tempFileStorage[options.name] = options.files;
    }
    setFileStorage(tempFileStorage);

    // Load file previews
    const content: any[] = [];
    options.files.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        content.push({
          name: file.name,
          type: file.type,
          content: fileReader.result,
          file: file,
        });
        if (content.length === options.files.length) {
          // Return a file for preview as a { file, content } object
          options.callback(
            content.map((fileContent) => {
              return {
                file: fileContent.file,
                content: fileContent.content,
              };
            })
          );
        }
      };
      fileReader.readAsDataURL(file);
    });
  });

  // // Handles file removal
  survey.onClearFiles.add((_, options) => {
    // Empty the temporary file storage if "Clear All" is clicked
    let tempFileStorage = fileStorage;

    if (options.fileName === null && tempFileStorage) {
      tempFileStorage[options.name] = [];
      options.callback("success");
      setFileStorage(tempFileStorage);
      return;
    }

    // Remove an individual file
    const tempFiles = tempFileStorage[options.name];
    if (!!tempFiles) {
      const fileInfoToRemove = tempFiles.filter(
        (file: any) => file.name === options.fileName
      )[0];
      if (fileInfoToRemove) {
        const index = tempFiles.indexOf(fileInfoToRemove);
        tempFiles.splice(index, 1);
      }
    }
    options.callback("success");
  });

  survey.onAfterRenderQuestion.add(function (survey, options) {
    if (
      options.question.title === "Address" ||
      options.question.name === "fullAddress"
    ) {
      // Check if the question type is "text" and has the name "googleAutocomplete"
      const input = options.htmlElement.getElementsByTagName("input")[0];

      // Retrieve the input element associated with the address question
      if (input && window.google.maps.places?.Autocomplete) {
        // Ensure that the input element exists
        const autocomplete = new window.google.maps.places.Autocomplete(input);
        // Initialize Google Address Autocomplete for the input element

        // Add event listener to handle place selection
        autocomplete.addListener("place_changed", async function () {
          const place = autocomplete.getPlace();
          // Check if place data is available
          if (!place.geometry || !place.address_components) {
            console.error("No place data available");
            return;
          }
          // Find postal code , state and  from address components
          let postalCode = "";
          let state = "";
          let city = "";
          let address = "";
          let lat = 0;
          let lng = 0;
          try {
            const res = await getLatLng(place as any);
            lat = res.lat;
            lng = res.lng;
          } catch (error) {
            console.log("Error while getting lat and lng", error);
          }

          place.address_components.forEach(function (component) {
            // add street number
            if (component.types.includes("street_number")) {
              address += "" + component.long_name;
            }
            // add street name
            if (component.types.includes("route")) {
              address += " " + component.long_name;
            }

            // if (component.types.includes("sublocality_level_1")) {
            //   address += ", " + component.long_name;
            // }
            // if (component.types.includes("sublocality_level_2")) {
            //   address += ", " + component.long_name;
            // }

            if (component.types.includes("postal_code")) {
              postalCode = component.long_name;
            } else if (
              component.types.includes("administrative_area_level_1")
            ) {
              state = component.long_name;
            } else if (component.types.includes("locality")) {
              city = component.long_name;
            }
          });

          //fill the address
          survey?.setValue("fullAddress", address); // new employee form
          survey?.setValue("address", address); // client intake form  // TODO: we should make consistent field names for surveys

          //fill the zipcode according to address
          survey?.setValue("zipCode", postalCode);

          //fill the state according to address
          survey?.setValue("state", state);

          //fill the city according to address
          survey?.setValue("city", city);

          survey?.setValue("lat", lat);
          survey?.setValue("lng", lng);
        });
      } else {
        console.error(
          "Input element not found or Google Map script isn't loaded yet."
        );
      }
    }
  });
  // survey oncomplete
  survey.onComplete.add((sender, options) => {
    if (
      datajson?.formType === BusinessFormType.IntakePacketForm ||
      datajson?.formType === BusinessFormType.EmployeePacketForm
    ) {
      survey.completedHtml = "<h2>Cannot save form !</h2>";
      return;
    }
    survey.showCompletedPage = false;
    // Display the "Saving..." message (pass a string value to display a custom message)
    options.showSaveInProgress();
    setLoadingStatus("Loading");
    setMessageError("");

    // Upload files to the server
    const questionsToUpload = Object.keys(fileStorage);

    const formData = new FormData();
    for (let i = 0; i < questionsToUpload.length; i++) {
      const questionName = questionsToUpload[i];
      const question = survey.getQuestionByName(questionName);
      const filesToUpload = fileStorage[questionName];

      //a. update answer in PDF
      const answer = filesToUpload.map((file: any) => {
        return {
          type: file.type,
          name: file.name,
          content: "", //save space only for public form here!!
        };
      });
      if (question && answer) question.value = answer;

      //b. add files to form data
      filesToUpload.forEach((file: any) => {
        formData.append("files", file);
      });
    }
    //c. add form data
    formData.append("surveyData", JSON.stringify(survey.data)); //add survey field data

    setTimeout(function () {
      if (datajson?.id !== undefined) {
        PostPublicForm(
          // survey.data,  //survey Data
          datajson.id, //form Id
          formData,
          options
        );
      }
    }, 2000);
  });

  //#endregion

  //#region api calling
  const GetPublicForm = useCallback(
    async (formId: string) => {
      try {
        const { data } = await getPublicForm(formId);

        if (data) {
          setDatajson(data);
        }
      } catch (e: any) {
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(setResponseValue({ name: "message", value: e?.message }));
      } finally {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    },
    [dispatch]
  );

  const PostPublicForm = useCallback(
    async (formId: string, formData: FormData, options: any) => {
      setLoadingStatus("Loading");
      setMessageError("");
      try {
        const { status, message, data } = await postPublicForm(
          formId,
          formData
        );

        if (status) {
          options.showSaveSuccess();
          setLoadingStatus("Success");
          dispatch(setResponseValue({ name: "success", value: true }));
          dispatch(setResponseValue({ name: "message", value: message }));
        } else {
          options.showSaveError(message);
          setLoadingStatus("Failed");
          setMessageError(message);
          dispatch(setResponseValue({ name: "error", value: true }));
          dispatch(setResponseValue({ name: "message", value: message }));
        }
      } catch (e: any) {
        options.showSaveError("Error while saving. Please try again.");
        setLoadingStatus("Failed");
        setMessageError(e?.message);
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(setResponseValue({ name: "message", value: e?.message }));
      } finally {
        dispatch(setResponseValue({ name: "pending", value: false }));
      }
    },
    [dispatch]
  );

  // attached warning Indication to the survey
  const renderButton = (text: string, canRender: boolean, ids: string) => {
    if (!canRender) return undefined;

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3
          style={{
            fontWeight: "350",
            fontSize: "20px",
            color: "red",
            margin: "0px",
            borderBottom: "1px thick black",
          }}
        >
          {text}
        </h3>
      </div>
    );
  };

  //#endregion

  //#region useEffect region

  //fetch api for Tanant ids
  useEffect(() => {
    setLoadingStatus("Pending");
    setMessageError("");

    if (formId !== undefined) {
      GetPublicForm(formId);
    }
  }, [formId, GetPublicForm]);

  // handles survey modal display mode
  // useEffect(() => {
  //   // if (datajson?.formSchema?.locale !== undefined) {
  //   //   survey.locale = "es";
  //   // }
  //   if (survey.locale) {
  //     setSelectedLang(survey.locale);
  //   }
  // }, [survey]);

  useEffect(() => {
    if (selectedLang) {
      survey.locale = selectedLang;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLang]);

  useEffect(() => {
    //sets height of the form dynamically
    const sdTitle = document.querySelector(".sd-title") as HTMLElement;
    if (sdTitle) {
      const sdTitleHeight = sdTitle.offsetHeight + 88;
      const content = document.querySelector(".sv-components-row") as HTMLElement;
      if (content) {
        content.style.height = `calc(100vh - ${sdTitleHeight}px)`;
      }
    }
  }, [survey]);
  //#endregion

  return (
    <Box className="survayFormModule">
      <Box className="bef">
        <FormControl fullWidth size="small" className="lang-drop">
          <InputLabel id="language">Language</InputLabel>
          <Select
            labelId="language"
            id="demo-simple-select"
            value={selectedLang}
            label="Language"
            onChange={(e: any) => {
              if (e.target.value === "es" || e.target.value === "zh-cn") {
                setSelectedLang(e.target.value);
              } else {
                setSelectedLang("default");
              }
            }}
          >
            <MenuItem
              value={"default"}
              key={"en"}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              English
              <img src={English} alt="" height={"30px"} width={"30px"} />
            </MenuItem>
            <MenuItem
              value={"es"}
              key={"es"}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Spanish
              <img src={Spanish} alt="" height={"30px"} width={"30px"} />
            </MenuItem>
            <MenuItem
              value={"zh-cn"}
              key={"zh-cn"}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Chinese
              <img src={China} alt="" height={"30px"} width={"30px"} />
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        {loadingStatus !== "Pending" ? (
          <SurveyComplete
            loadingStatus={loadingStatus}
            errorMessage={messageError}
          />
        ) : (
          <>
            {(datajson?.formType === BusinessFormType.IntakePacketForm ||
              datajson?.formType === BusinessFormType.AssessmentForm) &&
              renderButton(
                "Please complete this form in the Parent Portal.",
                true,
                "Instruction"
              )}
            {datajson?.formType === BusinessFormType.EmployeePacketForm &&
              renderButton(
                "Please complete this form in the Employee Portal.",
                true,
                "Instruction"
              )}
            <Survey model={survey} className="h-[200px]" />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Embedform;
