import { AvailabilityDetails, ISelectedAvailability } from "../types/availability";
import {
  UtilizationResult,
  UtilizationRequestType,
} from "../types/utilization";

export const defaultClientAvailabilityDetail: AvailabilityDetails = {
  id: "",
  entityType: "Client",
  entityId: null,
  beginDate: null,
  endDate: null,
  createdAt: "",
  updatedAt: "",
  selectedDays: [
    {
      dayOfWeek: "",
      startTime: "",
      endTime: "",
    },
  ],
  heatMap: [],
  timeSlots: [],
  availabilitySelectedDays: [],
  availabilityName: "",
}

export const defaultProviderAvailabilityDetail: AvailabilityDetails =
{
  id: "",
  entityType: "Provider",
  entityId: null,
  beginDate: null,
  endDate: null,
  createdAt: "",
  updatedAt: "",
  selectedDays: [
    {
      dayOfWeek: "",
      startTime: "",
      endTime: "",
    },
  ],
  heatMap: [],
  timeSlots: [],
  availabilitySelectedDays: [],
  availabilityName: "",
};

export const availability: ISelectedAvailability = {
  availability: {
    ...defaultClientAvailabilityDetail,
  },
};

//------------------------------------------------------------------------

export const defaultUtilization: UtilizationResult = {
  request: {
    requestType: UtilizationRequestType.PerUserAvailabilityTable,
    tenantId: "", //which tenant organization
    //requestType=PerAvailability
    availabilityId: "",

    //requestType=PerDateRange
    //requestType=PerAvailability
    //requestType=PerUser
    //requestType=PerPlan
    checkFromDate: new Date(),
    checkToDate: new Date(),

    //requestType=PerUser
    entityType: "",
    entityId: "",

    //requestType=PerAuth & Code
    authId: "",
    authCode: "",

    //requestType=PerPlan
    planId: "",
  },
  status: "",
  //breakdown results at Time Table (availability or authorization)
  timeTables: [],

  //group by level
  totalWeeksChecked: 0,
  weeksHaveBooked: 0,

  //aggregate level for all time tables
  totalBooked: 0,
  remainingHours: 0,
  totalAvailability: 0,
  totalAuthorization: 0,
  remainingAuthHours: 0,

  //aggregate level for all time tables
  weeklyAvgBooked: 0,
  weeklyAvgAvailability: 0,
  weeklyAvgAuthorization: 0,
  weeklyAvgRemainedByAvailability: 0,
  weeklyAvgRemainedByAuthorization: 0,

  //utilization%
  utilizationPcntByAvailability: 0,
  utilizationPcntByAuthorization: 0,

  error: "",
};
