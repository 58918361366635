import { Box, Button, Grid, InputLabel } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setClientBasicDetails,
  setEditClient,
  updateClientBasicDetailById,
} from "../../../redux/features/client-main/client-slice";

const ClientParentDetailsForm: React.FC<{
  setEditParentInfo: any;
}> = ({ setEditParentInfo }) => {
  const dispatch = useDispatch();
  const clientBasicDetails = useSelector(
    (state: RootState) => state.client.clientBasicDetails
  );

  const handleChange = (e: any) => {
    const obj = {
      name: e.target.name,
      value: e.target.value,
    };
    dispatch(setClientBasicDetails(obj));
  };

  const handleSubmit = () => {
    dispatch(updateClientBasicDetailById({}));
    setEditParentInfo(false);
    dispatch(setEditClient(false));
  };

  // handle focus
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  return (
    <Box className="stepperForm">
      <ValidatorForm onSubmit={handleSubmit}>
        {/* <h1 className="stepperForm__title">Parent Information</h1> */}
        <div className="stepperForm__inner" style={{ marginBottom: "50px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">
                  Parent/Guardian 1 First Name
                </InputLabel>
                <TextValidator
                  id="parentfirstname"
                  onChange={handleChange}
                  name="parentFirstName"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("parentfirstname");
                    }
                  }}
                  value={clientBasicDetails.parentFirstName}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">
                  Parent/Guardian 1 Last Name
                </InputLabel>
                <TextValidator
                  className="form-control"
                  onChange={handleChange}
                  name="parentLastName"
                  id="parentLastName"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("parentLastName");
                    }
                  }}
                  value={clientBasicDetails.parentLastName}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">
                  Parent/Guardian 2 First Name (Optional)
                </InputLabel>
                <TextValidator
                  id="parent2FirstName"
                  onChange={handleChange}
                  name="parent2FirstName"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("parentfirstname");
                    }
                  }}
                  value={clientBasicDetails.parent2FirstName}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="fromGroup">
                <InputLabel className="fromLabel">
                  Parent/Guardian 2 Last Name (Optional)
                </InputLabel>
                <TextValidator
                  className="form-control"
                  onChange={handleChange}
                  name="parent2LastName"
                  id="parent2LastName"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      removeFocus("parentLastName");
                    }
                  }}
                  value={clientBasicDetails.parent2LastName}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <Box className="stepperForm__footer">
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit" className="button">
            Save Changes
          </Button>
        </Box>
      </ValidatorForm>
    </Box>
  );
};

export default ClientParentDetailsForm;
