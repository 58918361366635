import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { Board as boardInterface } from "../../../../types/board";
import Board from "../../../trello-board/Board";
import { ITaskState } from "../../../../types/trello-board";
import { useDispatch } from "react-redux";
import { updateCustomBoardStage } from "../../../../redux/features/custom-board/custom-board-slice";
import { moveClientCardToAnotherBoard } from "../../../../redux/features/client-main/client-board-slice";

interface ClientBoardProps {
  boardInfo: boardInterface;
  callbacks: ((clientId: string) => Promise<void>)[];
  chatCommand: (id: string) => void;
}

const Boards = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
`;

const ManualBoard: React.FC<ClientBoardProps> = ({
  boardInfo,
  callbacks,
  chatCommand,
}) => {
  //#region variable region

  //handle tasks
  const [tasks, setTasks] = useState<ITaskState>({});
  //handle dispatch
  const dispatch = useDispatch();
  //#endregion

  //#region methods region
  //handle ondrag end
  const onDragEnd = async (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    if (source.droppableId === destination?.droppableId) return;
    let selectedUser: any, fromStageId, toStageId;
    const removeUser = tasks[source.droppableId].filter((user) => {
      if (user.id === draggableId) {
        selectedUser = { ...user, boardColumnUpdatedAt: new Date().toString() };
        return false;
      } else {
        return true;
      }
    });

    if (destination && selectedUser) {
      setTasks({
        ...tasks,
        [source.droppableId]: removeUser,
        [destination.droppableId]: [
          selectedUser,
          ...tasks[destination.droppableId],
        ],
      });

      const updatedBoard = boardInfo.stages.map((stage) => {
        if (stage.stageName === source.droppableId) {
          fromStageId = stage.id;
          return {
            ...stage,
            boardColumnNames: stage.boardColumnNames.filter(
              (card) => card.id !== selectedUser.id
            ),
          };
        } else if (stage.stageName === destination.droppableId) {
          toStageId = stage.id;
          return {
            ...stage,
            boardColumnNames: [...stage["boardColumnNames"], selectedUser],
          };
        } else {
          return stage;
        }
      });

      if (fromStageId && toStageId && selectedUser) {
        dispatch(
          moveClientCardToAnotherBoard({
            fromBoardId: fromStageId,
            toBoardId: toStageId,
            cardId: selectedUser.id,
            index: destination.index,
          })
        );

        dispatch(
          updateCustomBoardStage({
            boardId: boardInfo.id,
            stages: updatedBoard,
          })
        );
      }
    }
  };

  //#endregion

  //#region useEffect region
  useEffect(() => {
    const convertedTotask: any = {};

    const stages = [...boardInfo.stages].sort((a, b) => a.index - b.index);
    stages.forEach((stage) => {
      convertedTotask[stage.stageName] = stage.boardColumnNames;
    });

    setTasks(convertedTotask);
  }, [boardInfo]);
  //#endregion

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={"boards"}
        type={"boards"}
        direction={"horizontal"}
      >
        {({ innerRef, droppableProps, placeholder }) => (
          <Boards ref={innerRef} {...droppableProps}>
            <div className="allStageCards">
              {Object.keys(tasks).map((stageName, index) => {
                return (
                  <Board
                    key={stageName}
                    index={index}
                    stage={
                      boardInfo?.stages?.filter(
                        (stage: any) => stage.stageName === stageName
                      )[0]
                    }
                    tasks={tasks[stageName]}
                    callbacks={callbacks}
                    chatCommand={chatCommand}
                  />
                );
              })}
            </div>
            {placeholder}
          </Boards>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ManualBoard;
