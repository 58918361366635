import React from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../redux/store";

import { ClientForms, InsuranceProviders } from "../../../types/client";

import { getBusinessUnitName } from "../../../utils/EntityUtils";
import { getAge } from "../../../utils/DateUtils";

import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import VerifiedAddressImg from "../../../assets/images/images-svg/verifiedAddress.svg";

interface MapProviderProfileProps {
  clientDataPopUP: ClientForms;
}
const MapClientProfile: React.FC<MapProviderProfileProps> = ({
  clientDataPopUP,
}) => {
  // #region methods region
  const clientInsurance = useAppSelector(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  const navigate = useNavigate();

  //business units
  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //return the Insurance Name
  const getInsuranceName = (insuranceId: string): string => {
    const val = clientInsurance.find((item) => item.id === insuranceId);
    if (val) {
      return val.insuranceProviderName;
    }
    return "";
  };

  //redirect on client details page
  const handleChange = () => {
    navigate(`/client/${clientDataPopUP.clientBasicDetails.id}`);
  };

  //#endregion
  return (
    <>
      <div className="mapUserInfo__img">
        <img
          src={
            clientDataPopUP?.clientBasicDetails?.clientProfile?.url || ClientImg
          }
          alt="profileImage"
        />
      </div>
      <div className="mapUserInfo__details">
        <div onClick={() => handleChange()}>
          <h4 className="mapUserInfo__name activeLink">
            {clientDataPopUP?.clientBasicDetails?.childFirstName}{" "}
            {clientDataPopUP?.clientBasicDetails?.childLastName}
          </h4>
        </div>

        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">Client DOB</h6>
          <p>
            {clientDataPopUP?.clientBasicDetails?.childDateOfBirth}
            {" ("}
            {clientDataPopUP?.clientBasicDetails?.childDateOfBirth
              ? getAge(clientDataPopUP?.clientBasicDetails?.childDateOfBirth)
              : ""}
            {" yrs old)"}
          </p>
        </div>

        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">Client Status</h6>
          <p>{clientDataPopUP?.clientBasicDetails?.clientType}</p>
        </div>

        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">Business Unit</h6>
          <p>
            {/* {getBusinessUnitName(
              clientDataPopUP?.clientBasicDetails?.businessUnit
            )} */}
            {getBusinessUnitName(
              businessUnits,
              clientDataPopUP?.clientBasicDetails?.businessUnit
            )?.map((res: string, index: number) => {
              return <span>{`${index ? "," : ""} ${res}`} </span>;
            })}
          </p>
        </div>
        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">Intake Stage</h6>
          <p>{clientDataPopUP?.clientBasicDetails?.intakeStage}</p>
        </div>
        {/* comment by hui temporarily
        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">Capacity</h6>
          <p>20 hours</p>
        </div>
        */}
        <div className="mapUserInfo__item">
          <h6 className="mapUserInfo__item-title">Insurance</h6>
          <p>
            {clientDataPopUP?.clientInsurance.map(
              (item: InsuranceProviders, index: number) => (
                <span key={index}>
                  {clientDataPopUP?.clientInsurance.length > 1 && index !== 0
                    ? ", "
                    : null}
                  {item.insuranceProviderName}
                </span>
              )
            )}
          </p>
        </div>
        {
          <div className="mapUserInfo__item">
            <h6 className="mapUserInfo__item-title">
              Address
              <span>
                {clientDataPopUP.clientContactDetails !== null
                  ? clientDataPopUP.clientContactDetails?.address?.lat !==
                      0 && (
                      <img
                        className="verifiedAddress"
                        src={VerifiedAddressImg}
                        alt="varification address"
                      />
                    )
                  : ""}
              </span>
            </h6>
            <p>
              {clientDataPopUP.clientContactDetails !== null
                ? clientDataPopUP?.clientContactDetails?.address?.fullAddress +
                  " " +
                  clientDataPopUP?.clientContactDetails?.city +
                  " " +
                  clientDataPopUP?.clientContactDetails?.state +
                  " " +
                  clientDataPopUP?.clientContactDetails?.zipCode
                : ""}
            </p>
          </div>
        }
      </div>
    </>
  );
};

export default MapClientProfile;
