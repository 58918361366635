import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  GetAdminScheduleSetting,
  UpdateAdminScheduleSetting,
} from "../../../../redux/features/data-catalog/data-catalog-slice";
import { IAdminScheduleSetting } from "../../../../types/businessSettings";

const AdminScheduleSetting = () => {
  
  //dispatch  
  const dispatch = useDispatch<AppDispatch>();

  //stores admin schedule settings
  const adminSettings = useSelector<RootState, IAdminScheduleSetting>(
    (state) => state.dataCatalogSlice.adminScheduleSettings
  );
  
  //useEffect region
  useEffect(() => {
    dispatch(GetAdminScheduleSetting());
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <Box className="detailsCard">
          <Box className="detailsCard__header">
            <Typography className="detailsCard__title">
              Admin Rebooking
            </Typography>
          </Box>
          <Box className="detailsCard__body">
            <Box className="settingBox">
              <div className="settingBox__part">
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Who are eligible for the rebooking providers :
                  </h6>
                </FormGroup>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={adminSettings.rebookProviderType}
                  name="radio-buttons-group"
                  onChange={(e) => {
                    dispatch(
                      UpdateAdminScheduleSetting({
                        ...adminSettings,
                        rebookProviderType: e.target.value,
                      })
                    );
                  }}
                >
                  <FormControlLabel
                    value="bu"
                    control={<Radio />}
                    label="Any provider for the same BU"
                  />
                  <FormControlLabel
                    value="org"
                    control={<Radio />}
                    label="Any Provider for the Organization"
                  />
                </RadioGroup>
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Number of days to check availability since cancelled date:
                  </h6>
                  <input
                    type="number"
                    min="1"
                    value={adminSettings.maxDaysToRebook}
                    onChange={(e) => {
                      if (["+", "-", "0"].includes(e.target.value)) {
                        e.preventDefault();
                      } else {
                        dispatch(
                          UpdateAdminScheduleSetting({
                            ...adminSettings,
                            maxDaysToRebook: parseInt(e.target.value),
                          })
                        );
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Number of new providers to show when rebooking :
                  </h6>
                  <input
                    type="number"
                    min="1"
                    value={adminSettings.maxProviderToRebook}
                    onChange={(e) => {
                      if (["+", "-", "0"].includes(e.target.value)) {
                        e.preventDefault();
                      } else {
                        dispatch(
                          UpdateAdminScheduleSetting({
                            ...adminSettings,
                            maxProviderToRebook: parseInt(e.target.value),
                          })
                        );
                      }
                    }}
                  />
                </FormGroup>
              </div>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdminScheduleSetting;
