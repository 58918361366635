import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  setClientBasicDetails,
  updateClientBasicDetailById,
} from "../../../../redux/features/client-main/client-slice";

import EditConfirmation from "../../../../layouts/edit-confirmation/EditConfirmation";

import { ClientBasicDetails } from "../../../../types/client";
import { UpdateValue } from "../../../../types/provider";
import { ClientType } from "../../../../constants/client-status";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ClientSubStatusProps {
  clientBasicDetails: ClientBasicDetails;
}

const ClientSubStatus: React.FC<ClientSubStatusProps> = ({
  clientBasicDetails,
}) => {
  // #region variables region

  //client intake stages
  const clientIntakeStages = useAppSelector(
    (state) => state.clientIntakeStage.stages
  );

  //client concern stages
  const ConcernedStage = useAppSelector(
    (state) => state.clientListSlice.clientConcerns
  );

  //handles the confirmation model opening/closing for client status/ on track stage and concerned state
  const [editModal, setEditModal] = useState<boolean>(false);

  //stores the name to display while opening confirmation popup for client status/ on track stage and concerned state
  const [editModalName, setEditModalName] = useState<string>("");

  //handles the update redux object for client status/ on track stage and concerned state
  const [editModalValue, setEditModalValue] = useState<UpdateValue>({
    name: "",
    value: "",
  });

  //stores IntakeStage Data String
  const [intakeStage, setIntakeStage] = useState<string>("");

  //stores concerned stage data
  const [concernedStage, setConcernedStage] = useState<string[]>([]);

  const [checkList, setCheckList] = useState<string[]>([]);

  //dispatch for redux
  const dispatch = useAppDispatch();

  // #endregion

  // #region methods region

  //updates the client status
  const updateClientStatus = (value: boolean): void => {
    if (value) {
      if (editModalValue.name === "concernedStage")
        setEditModalValue({
          name: "concernedStage",
          value: [...concernedStage],
        });
      dispatch(
        setClientBasicDetails({
          ...clientBasicDetails,
          [editModalValue.name]: editModalValue.value,
          isEdit: true,
        })
      );

      //update intake stage field in client basic details
      //also need to update client board data
      if (editModalValue.name === "intakeStage") {
        let stageId: string = "";
        clientIntakeStages.forEach((item) => {
          if (intakeStage === item.stageName) {
            stageId = item.id;
            return;
          }
        });
        dispatch(
          updateClientBasicDetailById({
            stageId,
          })
        );
      } else {
        dispatch(updateClientBasicDetailById({}));
      }
    }

    setEditModal(false);
  };

  // #endregion

  // #region useEffect region

  useEffect(() => {
    if (clientBasicDetails) {
      setIntakeStage(clientBasicDetails.intakeStage);
      // setConcernedStage(clientBasicDetails.concernedStage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientBasicDetails]);

  useEffect(() => {
    // dispatch(GetAllClientConcerns());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checklist = clientIntakeStages.find(
      (stage) => stage.stageName === intakeStage
    )?.checkList;

    if (checklist && checklist.length) {
      setCheckList(checklist);
      const filter = checklist.filter((list: string) =>
        clientBasicDetails?.checklistHistory.includes(list)
      );
      setConcernedStage(filter);
    } else {
      setCheckList([]);
      const filter = clientBasicDetails?.checklistHistory.filter((list) =>
        ConcernedStage.find((checklist) => checklist.conceptValue === list)
      );
      if (filter && filter.length) {
        setConcernedStage(filter);
      }
    }
  }, [intakeStage]);
  // #endregion

  return (
    <Grid item xs={12} lg={6} xl={4}>
      <Box className="detailsCard">
        <Box className="detailsCard__header">
          <Typography className="detailsCard__title">Intake Status</Typography>
        </Box>
        <Box className="detailsCard__body">
          <Grid container>
            <Grid item xs={12} md={8} lg={12}>
              <Typography className="detailsCard-part__title">
                Intake Status
              </Typography>
              <Box className="fromGroup-chips" style={{ marginBottom: "15px" }}>
                {ClientType.map((item) => (
                  <Card
                    className={
                      clientBasicDetails?.clientType === item.value
                        ? "fromGroup-chip-active"
                        : "detailsCard-part__chip"
                    }
                    onClick={() => {
                      setEditModal(true);
                      setEditModalName("Client Status");
                      setEditModalValue({
                        name: "clientType",
                        value: item.value,
                      });
                    }}
                    key={item.key}
                  >
                    {item.value}
                  </Card>
                ))}
              </Box>
              <Box className="fromGroup" style={{ marginBottom: "15px" }}>
                <Typography className="detailsCard-part__title">
                  Intake Stage
                </Typography>
                <Select
                  className="form-control"
                  value={intakeStage}
                  placeholder="Select"
                  onChange={(e: SelectChangeEvent<string>) => {
                    setEditModal(true);
                    setEditModalName("Intake Stage");
                    setEditModalValue({
                      name: "intakeStage",
                      value: e.target.value,
                    });
                    setIntakeStage(e.target.value);
                  }}
                  MenuProps={MenuProps}
                >
                  {clientIntakeStages?.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.stageName}>
                        {item.stageName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box className="fromGroup">
                <Typography className="detailsCard-part__title">
                  Checklist
                </Typography>
                <FormControl>
                  <Select
                    multiple
                    className="form-control"
                    value={concernedStage}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    onChange={(e: SelectChangeEvent<string[]>) => {
                      setConcernedStage(e.target.value as string[]);
                    }}
                  >
                    {checkList.length
                      ? checkList.map((item) => {
                          return (
                            <MenuItem key={item} value={item}>
                              <Checkbox
                                checked={concernedStage.includes(item)}
                              />
                              {item}
                            </MenuItem>
                          );
                        })
                      : ConcernedStage.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.conceptValue}>
                              <Checkbox
                                checked={concernedStage?.includes(
                                  item.conceptValue
                                )}
                              />
                              {item.conceptValue}
                            </MenuItem>
                          );
                        })}
                  </Select>
                  <div className="largeModel__footer">
                    <Button
                      className="button"
                      type="submit"
                      onClick={() => {
                        setEditModal(true);
                        setEditModalName("Stage Checklist");
                        setEditModalValue({
                          name: "checklistHistory",
                          value: concernedStage,
                        });
                      }}
                    >
                      Save Changes
                    </Button>
                  </div>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {editModal && (
        <EditConfirmation
          name={editModalName}
          setModal={(value) => updateClientStatus(value)}
        />
      )}
    </Grid>
  );
};

export default ClientSubStatus;
