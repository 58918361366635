import { createSlice } from "@reduxjs/toolkit";
import { ClientFilters } from "../../../types/client";

const initialState: ClientFilters = {
  businessUnit: [],
  clientStatus: [],
  insuranceProvider: [],
  availabilityStatus: "",
  intakeStage: [],
  concerns: [],
  ScheduleStatus: "",
  startDate: null,
  endDate: null,
  // age:null,
};

const clientFilterSlice = createSlice({
  name: "clientFilterSlice",
  initialState: initialState,
  reducers: {
    setClientFilterValues: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearClientFilterValues: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { setClientFilterValues, clearClientFilterValues } =
  clientFilterSlice.actions;

export default clientFilterSlice;