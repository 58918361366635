import moment from "moment";
import { ClientForms, ISchedules } from "../../types/client";
import { ProviderForms } from "../../types/provider";
import { MapsFilter } from "../../types/map";
import { AvailabilityDetails } from "../../types/availability";
import { getAge } from "../../utils/DateUtils";
import { searchClientText, searchProviderText } from "../../utils/FilterUtils";
import { EntityScheduleStatus } from "../../constants/providers";

export const canFilterMaps = (
  filterValues: MapsFilter,
  searchVal: string
): boolean => {
  if (
    filterValues.businessUnit.length > 0 ||
    filterValues.intakeStage.length > 0 ||
    filterValues.clientConcerns.length > 0 ||
    filterValues.insuranceProvider.length > 0 ||
    filterValues.providerStatus.length > 0 ||
    filterValues.providerTasks.length > 0 ||
    filterValues.onBoardingStage.length > 0 ||
    filterValues.clientStatus.length > 0 ||
    filterValues.ScheduleStatus ||
    filterValues.startDate ||
    filterValues.endDate ||
    filterValues.ageFrom ||
    filterValues.ageTo ||
    searchVal
  ) {
    return true;
  }
  return false;
};

//logic for filtering a single provider and Client
export const filterSingleClient = (
  item: ClientForms,
  filterValues: MapsFilter,
  searchVal: string
): boolean => {
  const searchText = searchClientText(searchVal, item);
  if (!searchText) {
    return false;
  }

  if (item.clientBasicDetails) {

    if (
      filterValues.businessUnit.find((item) => item === "All Units") !==
        "All Units" &&
      filterValues.businessUnit.length &&
      // !filterValues.businessUnit.find(
      //   (unit) => unit === item.clientBasicDetails.businessUnit
      // )
      !filterValues.businessUnit.some((ele) =>
        item.clientBasicDetails.businessUnit.includes(ele)
      )
    ) {
      return false;
    }
    if (filterValues.clientStatus.length > 0) {
      if (
        !filterValues.clientStatus.includes(item.clientBasicDetails.clientType)
      ) {
        return false;
      }
    }
    if (
      filterValues.intakeStage.length &&
      filterValues.intakeStage.find((item) => item === "All Stages") !==
        "All Stages"
    ) {
      if (
        !filterValues.intakeStage.includes(item.clientBasicDetails.intakeStage)
      ) {
        return false;
      }
    }

    if (
      !filterValues.clientConcerns.includes("All Concerns") &&
      filterValues.clientConcerns.length
    ) {
      if (
        filterValues.clientConcerns.length &&
        !filterValues.clientConcerns.includes(
          item.clientBasicDetails.checklistHistory[0]
        )
        // !item.clientBasicDetails.concernedStage.includes(
        // )
      ) {
        return false;
      }
    }

    if (
      filterValues.insuranceProvider.find(
        (item) => item === "All Employees"
      ) !== "All Employees" &&
      filterValues.insuranceProvider.length
    ) {
      let count = 0;
      item.clientInsurance.forEach((item) => {
        if (
          filterValues.insuranceProvider.find(
            (unit) => unit === item.insuranceProviderName
          )
        ) {
          count++;
        }
      });
      if (count === 0) {
        return false;
      }
    }


    if(filterValues.ageFrom || filterValues.ageTo) {

      if (!item.clientBasicDetails.childDateOfBirth) {
        return false;
      }
      const age = getAge(item.clientBasicDetails.childDateOfBirth);

      if (filterValues.ageFrom === null || filterValues.ageFrom?.length === 0) {
        if (age <= Number(filterValues.ageTo?.trim())) {
          return true;
        }
      } 

      if (filterValues.ageTo === null || filterValues.ageTo?.length === 0) {
        if (age >= Number(filterValues.ageFrom?.trim())) {
          return true;
        }
      }

      if (age <= Number(filterValues.ageTo?.trim()) && 
        age >= Number(filterValues.ageFrom?.trim())) {
        return true;
      }
      return false;
    }
  }
  // if (filterValues.ScheduleStatus) {
  //   if (item.clientUtilization.status === filterValues.ScheduleStatus) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  if (filterValues.ScheduleStatus) {
    if (filterValues.ScheduleStatus === EntityScheduleStatus.NoAvailability ||
      filterValues.ScheduleStatus === EntityScheduleStatus.AvailabilityExpired) {
        //read from clientUtilization
        if (item.clientUtilization.status === filterValues.ScheduleStatus) {
          return true;
        } else {
          return false;
        }
    } else {
      //read from clientAuthUtilization
      if (item.clientAuthUtilization.status === filterValues.ScheduleStatus) {
        return true;
      } else {
        return false;
      }
    }
  }
  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  return true;
};

//return evaluate schedule array
export const evaluateSchedulerData = (
  item: ClientForms | ProviderForms,
  filterValues: { startDate: string | null; endDate: string | null }
) => {
  let arr: AvailabilityDetails[] = [];
  item.availabilityDetails.filter((item: AvailabilityDetails) => {
    return filterSingleScheduleListItem(item, filterValues) && arr.push(item);
  });
  return arr;
};

//logic for filtering a single provider and Client
export const filterSingleScheduleListItem = (
  item: AvailabilityDetails,
  filterValues: { startDate: string | null; endDate: string | null }
): boolean => {
  if (filterValues.startDate && filterValues.endDate) {
    // if (
    //   new Date(moment(filterValues.startDate).format("YYYY-MM-DD")) <=
    //     new Date(item.specificDate) &&
    //   new Date(item.specificDate) <=
    //     new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
    // ) {
    //   return true;
    // }
    if (
      new Date(moment(filterValues.endDate).format("YYYY-MM-DD")) >=
      new Date(moment(item.endDate).format("YYYY-MM-DD"))
    ) {
      if (
        new Date(moment(item.endDate).format("YYYY-MM-DD")) >=
        new Date(moment(filterValues.startDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        new Date(moment(item.beginDate).format("YYYY-MM-DD")) <=
        new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
};

//logic for filtering a single provider and Client
export const filterSingleProvider = (
  item: ProviderForms,
  filterValues: MapsFilter,
  searchVal: string
): boolean => {
  const searchText = searchProviderText(searchVal, item);
  if (!searchText) {
    return false;
  }

  if (
    filterValues.insuranceProvider.find((item) => item === "All Employees") !==
      "All Employees" &&
    filterValues.insuranceProvider.length
  ) {
    let count = 0;
    item.providerInsurance.forEach((item) => {
      if (filterValues.insuranceProvider.find((unit) => unit === item.name)) {
        count++;
      }
    });
    if (count === 0) {
      return false;
    }
  }
  if (
    filterValues.businessUnit.filter((item) => item !== "All Units") &&
    filterValues.businessUnit.length &&
    // !filterValues.businessUnit.filter(
    //   (unit) => unit === item.employmentDetails.businessUnit.find((ele)=> ele === unit)
    // )
    !filterValues.businessUnit.some((ele) =>
      item.employmentDetails.businessUnit.includes(ele)
    )
  ) {
    return false;
  }
  if (filterValues.providerStatus.length > 0) {
    if (
      !filterValues.providerStatus.includes(item.employmentDetails.providerType)
    ) {
      return false;
    }
  }

  let onBoardingFlag = true;
  while (onBoardingFlag) {
    if (filterValues.onBoardingStage.length > 0) {
      if (filterValues.onBoardingStage.includes("All Stages")) {
        onBoardingFlag = false;
      } else {
        let count = 0;
        filterValues.onBoardingStage.forEach((matchValue: string) => {
          if (item.employmentDetails.onBoardingStage === matchValue) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        onBoardingFlag = false;
      }
    }
    onBoardingFlag = false;
  }

  let checkListFlag = true;
  while (checkListFlag) {
    if (filterValues.providerTasks.length > 0) {
      if (filterValues.providerTasks.includes("All Stages")) {
        checkListFlag = false;
      } else {
        let count = 0;
        filterValues.providerTasks.forEach((matchValue: string) => {
          if (item.employmentDetails.checklistHistory.includes(matchValue)) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        checkListFlag = false;
      }
    }
    checkListFlag = false;
  }
  if (filterValues.ScheduleStatus) {
    if (item.providerUtilization.status === filterValues.ScheduleStatus) {
      return true;
    } else {
      return false;
    }
  }
  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  return true;
};

//return if an object is provider
function instanceOfA(object: any): object is ProviderForms {
  if (object) return "employmentDetails" in object;
  else return false;
}

//logic for handling the search and filter
export const filterMaps = <T>(
  filterValues: MapsFilter,
  searchVal: string,
  data: T[]
): T[] => {
  return canFilterMaps(filterValues, searchVal)
    ? data.filter((item: T) => {
        if (instanceOfA(item)) {
          return filterSingleProvider(
            item as ProviderForms,
            filterValues,
            searchVal
          );
        } else {
          return filterSingleClient(
            item as ClientForms,
            filterValues,
            searchVal
          );
        }
      })
    : data;
};
