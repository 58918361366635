import { AvailabilityDetails } from "../types/availability";
import {
  AuthCode,
  ClientAuthPayload,
  ClientBasicDetails,
  ClientContactDetails,
  ClientForms,
  InsuranceProviders,
} from "../types/client";
import moment from "moment";
import { defaultUtilization } from "./availability";

export const language = [
  { key: 0, value: "English" },
  { key: 1, value: "Spanish" },
] as const;

export const contactMethod = [
  { key: 0, value: "Phone" },
  { key: 1, value: "Text" },
  { key: 2, value: "Email" },
] as const;

export const bestTimeContact = [
  { key: 0, value: "Morning" },
  { key: 1, value: "Afternoon" },
  { key: 2, value: "Evening" },
] as const;

export const clientStepsLabels = [
  "Basic",
  "Contact",
  "Admin",
  "Insurance",
  "Authorizations",
  "Availability",
] as const;

export const clientType = ["Intake", "Ongoing", "Inactive"] as const;

export const availabilityStatus = [
  "Current Availability",
  "Expired Availability",
] as const;

export const onTrackStage = [
  "New",
  "Benefit Check in Process",
  "Pending Client Paperwork",
  "Initial Auth Request in Process",
  "Initial Auth Request Received",
  "Clinical - Scheduling/Completing Assessment",
  "Clinical - Assessment Complete",
  "Direct Service Auth Request in Process",
  "Direct Service Auth Request Received",
  "Scheduling",
  "Intake Complete",
] as const;

export const concernedStage = [
  "On Hold",
  "Waiting on Client Diagnosis",
  "Waiting on ABA Script for Services",
  "Discontinued",
] as const;

export const AuthCodes: AuthCode = {
  id: "",
  conceptValue: "",
};

export const authQty = ["One", "Two"] as const;

export const clientPopoverOptions: string[] = [
  "Edit Basic",
  "Edit Contact",
  "Edit Administrative",
  "Edit Insurance Providers",
  "Edit Availability",
  "Delete Card"
];

//intial state for clientContactDetails
export const clientContactDetails: ClientContactDetails = {
  id: "",
  createdAt: "",
  updatedAt: "",
  entityType: "Client",
  entityId: "",
  phoneNumber: "",
  email: "",
  address: {
    fullAddress: "",
    lat: 0,
    lng: 0,
  },
  schoolAddress: { fullAddress: "", lng: 0, lat: 0 },
  schoolCity: "",
  schoolState: "",
  schoolZipCode: "",
  city: "",
  state: "",
  zipCode: "",
  contactMethod: "",
  bestTimeToContact: "",
  otherAddresses: [],
};

//intial state for clientInsurance
export const clientInsurance: InsuranceProviders = {
  isPrimary: true,
  insuranceProviderName: "",
  clientId: "",
  insuranceId: "",
  id: "",
};

//intial state for authorization
export const defeaultAuthorization: ClientAuthPayload = {
  id: "",
  insurance: clientInsurance,
  authorizationName: `Authorization`,
  authPeriod: {
    startDate: null,
    endDate: null,
  },
  insuranceId: "",
  auth: [
    {
      authCode: "",
      units: "",
      authApprovalUnit: "",
    },
  ],
  files: [],
  totalAuthTime: {
    hours: 0,
    minutes: 0,
  },
  createdAt: "",
  updatedAt: "",
  endDate: "",
  authorizationNumber: ""
};

//intial state for clientBasicDetails
export const clientBasicDetails: ClientBasicDetails = {
  id: "",
  parentFirstName: "",
  parentLastName: "",
  parent2FirstName: "",
  parent2LastName: "",
  childFirstName: "",
  childLastName: "",
  childDateOfBirth: "",
  childGender: "",
  childLanguages: [],
  businessUnit: [],
  concerns: "",
  hearAboutUs: "",
  insuranceProvider: "",
  clientType: "Intake",
  onTrackStage: "",
  checklistHistory: [],
  messageCount: 0,
  intakeStage: "New",
  isEdit: false,
  clientProfile: {},
  createdAt: "",
  updatedAt: "",
  parentProfile: [],
  //  bookedServices: [],
  files: [],
  supervisorId: "",
  supervisor: {
    id: "",
    createdAt: "",
    updatedAt: "",
    firstName: "",
    lastName: "",
    businessUnit: "",
    providerType: "Onboarding",
    employmentStatus: "",
    languages: [],
    type: "",
    files: [],
    checklistHistory: [], //TODO: need to sync with backend change
    onBoardingStage: "New Hire/Signed Offer Letter", //TODO: need to sync with backend change
    providerProfile: null,
    isSupervisor: false,
    supervisorId: "",
    //canBeSupervisor: true,
  },
  lastMessage: 0,
};

//intial state for availabilityDetails
export const defaultClientAvailability: AvailabilityDetails = {
  id: "",
  entityType: "Client",
  entityId: null,
  beginDate: moment().local().format("YYYY-MM-DD"),
  endDate: moment().local().add(6, "M").local().format("YYYY-MM-DD"),
  createdAt: "",
  updatedAt: "",
  selectedDays: [
    {
      dayOfWeek: "",
      startTime: "",
      endTime: "",
    },
  ],
  heatMap: [],
  timeSlots: [],
  availabilitySelectedDays: [],
  availabilityName: "",
};

export const ClientInitialState: ClientForms = {
  clientBasicDetails: clientBasicDetails,
  clientContactDetails: clientContactDetails,
  availabilityDetails: [defaultClientAvailability],
  isEditClient: false,
  clientInsurance: [clientInsurance],
  authorizations: [defeaultAuthorization],
  clientFiles: [],
  clientMessages: [],
  bookedServices: [],
  schedules: [],
  clientAuthUtilization: defaultUtilization,
  clientUtilization: defaultUtilization,
};
