import React, { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  getEmployeeScheduleSettings,
  updateParentScheduling,
} from "../../../../redux/features/data-catalog/data-catalog-slice";
import {
  EmployeeSetting,
} from "../../../../types/businessSettings";
const BusinessEmployeePortal = () => {
  //for dispatch
  const dispatch = useDispatch<AppDispatch>();

  //stores settings for employee schedule portal
  const { allowEmployeeToSeeAvailablity, allowEmployeeToUpdateAvailablity } =
    useSelector<RootState, EmployeeSetting>(
      (state) => state.dataCatalogSlice.EmployeeSetting
    );

  //useEffect
  useEffect(() => {
    dispatch(getEmployeeScheduleSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <Box className="detailsCard">
          <Box className="detailsCard__header">
            <Typography className="detailsCard__title">
              Employee Availability
            </Typography>
          </Box>
          <Box className="detailsCard__body">
            <Box className="settingBox">
              <div className="settingBox__part">
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Allow Employee to see availability
                  </h6>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={allowEmployeeToSeeAvailablity}
                        onChange={() =>
                          dispatch(
                            updateParentScheduling({
                              type: "employee",
                              data: {
                                allowEmployeeToSeeAvailablity:
                                  !allowEmployeeToSeeAvailablity,
                                allowEmployeeToUpdateAvailablity,
                              },
                            })
                          )
                        }
                      />
                    }
                    label={allowEmployeeToSeeAvailablity ? "On" : "Off"}
                  />
                </FormGroup>
                <FormGroup className="settingBox__field">
                  <h6 className="settingBox__field-title">
                    Allow Employee to update availability
                  </h6>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={allowEmployeeToUpdateAvailablity}
                        onChange={() =>
                          dispatch(
                            updateParentScheduling({
                              type: "employee",
                              data: {
                                allowEmployeeToUpdateAvailablity:
                                  !allowEmployeeToUpdateAvailablity,
                                allowEmployeeToSeeAvailablity,
                              },
                            })
                          )
                        }
                      />
                    }
                    label={allowEmployeeToUpdateAvailablity ? "On" : "Off"}
                  />
                </FormGroup>
              </div>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BusinessEmployeePortal;
