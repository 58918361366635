import axios from "../axios/axios";
import { DataCatalogConcepts } from "../constants/catalog";
import { InsurancesProviders } from "../types/response";

//for all authCodes
export const getAllAuthCodes = async () => {
  try {
    const concept = DataCatalogConcepts.AuthCode;
    const { data } = await axios.get(`/datacatalog/${concept}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//for all providers type
export const GetAllProviderTypes = async () => {
  try {
    const concept = DataCatalogConcepts.ProviderType;
    const { data } = await axios.get(`/datacatalog/${concept}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//for all providers type
export const GetAllProviderSpecialty = async () => {
  try {
    const concept = DataCatalogConcepts.ProviderSpecialty;
    const { data } = await axios.get(`/datacatalog/${concept}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// for getting all insurance providers
export const GetAllInsuranceProviders = async () => {
  try {
    const concept = DataCatalogConcepts.Insurance;
    const { data } = await axios.get<InsurancesProviders>(
      `/datacatalog/${concept}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//For All Cancel Reason
export const GetAllClientCancelReasons = async () => {
  try {
    const concept = DataCatalogConcepts.ClientCancelReason;
    const { data } = await axios.get(`/datacatalog/${concept}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//for All provider cancel Reason
export const GetAllProviderCancelReasons = async () => {
  try {
    const concept = DataCatalogConcepts.ProviderCancelReason;
    const { data } = await axios.get(`/datacatalog/${concept}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//For All Billable Activity
export const GetAllBillableActivities = async () => {
  try {
    const concept = DataCatalogConcepts.BillableActivity;
    const { data } = await axios.get(`/datacatalog/${concept}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//For All Non Billable Activity
export const GetAllNonBillableActivities = async () => {
  try {
    const concept = DataCatalogConcepts.NonBillableActivity;
    const { data } = await axios.get(`/datacatalog/${concept}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// //For All Checklist PaperWork
// export const GetAllChecklistPaperWork = async () => {
//   try {
//     const concept = DataCatalogConcepts.ChecklistPaperWork;
//     const {data} = await axios.get(`/datacatalog/${concept}`);
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

// //For All Checklist Training
// export const GetAllChecklistTraining = async () =>{
//   try {
//     const concept = DataCatalogConcepts.ChecklistTraining;
//     const {data} = await axios.get(`/datacatalog/${concept}`);
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

// //For All Checklist Credentialing
// export const GetAllChecklistCredentialing = async () => {
//   try {
//     const concept = DataCatalogConcepts.ChecklistCredentialing;
//     const {data} = await axios.get(`/datacatalog/${concept}`);
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

// //For All Concerned Stage
// export const GetAllConcernedStage = async () => {
//   try {
//     const concept = DataCatalogConcepts.ConcernedStage;
//     const {data} = await axios.get(`/datacatalog/${concept}`);
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// }

//For All Template Variable
export const GetAllTemplateVariables = async () => {
  try {
    const concept = DataCatalogConcepts.TemplateVariable;
    const { data } = await axios.get(`/datacatalog/${concept}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const DataCatalogService = {
  getAllAuthCodes,
  GetAllProviderTypes,
  GetAllProviderSpecialty,
  GetAllInsuranceProviders,
  GetAllClientCancelReasons,
  GetAllProviderCancelReasons,
  GetAllBillableActivities,
  GetAllNonBillableActivities,
  // GetAllChecklistPaperWork,
  // GetAllChecklistTraining,
  // GetAllChecklistCredentialing,
  // GetAllConcernedStage,
  GetAllTemplateVariables,
};

export default DataCatalogService;
