import {
  ICommmunication,
  NewMessageTemplate,
  NewDataCatalog,
  ITemplateVariable,
  ParentSchedule,
  EmployeeSetting,
  IIntegrationSetting,
  IAdminScheduleSetting,
  // IntakeFormList,
  // IIntakeClientFormData,
} from "../types/businessSettings";
import { formCard, formRequestDetail } from "../constants/forms";

export const messageMethods = ["InMail", "Email", "SMS"] as const;

//category constant
export const Category = ["In-Mail", "SMS", "Email"] as const;

// Audience constant
export const Audience = ["Client", "Provider", "Admin"] as const;

//message templates
export const NewMessageTemplates: NewMessageTemplate = {
  id: " ",
  name: " ",
  from: " ",
  subject: " ",
  message: " ",
  methods: " ",
  audience: " ",
  tenantId: " ",
  active: false,
  createdAt: " ",
  updatedAt: " ",
  admins: [],
};

//data catalogs
export const NewDataCatalogs: NewDataCatalog = {
  id: "",
  catalogName: "",
  conceptValue: "",
  isActive: false,
  data: {
    minsPerUnit: 0,
    unitsPerDay: 0,
    rules: [
      {
        insurance: [],
        groupCode: false,
        noOfClients: 0,
        doubleBookings: false,
        parallelCodes: [],
      },
    ],
  },
  updatedAt: "",
  updatedBy: {
    firstName: "",
    lastName: "",
    profilePictureUrl: "",
  },
  description: "",
};

//template variable
export const TemplateVaribles: ITemplateVariable = {
  id: "",
  conceptValue: "",
};

//constant for data from parentportal schedules
export const ParentSchedules: ParentSchedule = {
  allowParentToCancel: false,
  allowParentToRebook: false,
  maxBookingToCancel: 1,
  maxHrsToCancle: 0,
  maxProviderToRebook: 1,
  rebookProviderType: "",
  serviceType: [],
  showProviderName: false,
  showProviderType: false,
  maxDaysToRebook: 0,
  minHrsToCancel: 0,
  showProvider: false,
};
export const EmployeeSchedules: EmployeeSetting = {
  allowEmployeeToSeeAvailablity: false,
  allowEmployeeToUpdateAvailablity: false,
};

export const AdminSchedules: IAdminScheduleSetting = {
  rebookProviderType: "org",
  maxDaysToRebook: 30,
  maxProviderToRebook: 5,
};

//for business setting
export const AllTemplates: ICommmunication = {
  MessageTemplates: [],
  NewDataCatalog: [NewDataCatalogs],
  BillableCatalog: [NewDataCatalogs],
  NonBillableCatalog: [NewDataCatalogs],
  ProviderReasonCatalog: [NewDataCatalogs],
  ClientReasonCatalog: [NewDataCatalogs],
  templateVariables: [TemplateVaribles],
  ParentSchedule: ParentSchedules,
  intakeformlist: [formCard],
  intakeClientFormData: [formRequestDetail],
  EmployeeSetting: EmployeeSchedules,
  currentMessageTemplate: {
    isEdit: false,
    templateData: NewMessageTemplates,
  },
  adminScheduleSettings: AdminSchedules,
};

export const integrationSetting: IIntegrationSetting = {
  id: "",
  descope_tenantId: "",
  // isActive: false,
  appName: "",
  isActive: false,
  credential: "",
};
