import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ProviderService from "../../../service/provider.service";
import CommonService from "../../../service/common.service";
import { ProviderForms, ListData } from "../../../types/provider";
import { ProvidersInitialState } from "../../../constants/providers";
import { setResponseValue } from "../api-response/api-response";
import { ReverseList } from "../../../helpers/reverse-list/reverse-list";
import {
  setProviderData,
  setProviderFilesFromResponse,
  setProviderMessages,
} from "./provider-slice";
import DataCatalogService from "../../../service/dataCatalog.service";
import { MatchedProvider } from "../../../types/schedule";

const initialState: ListData = {
  singleProvider: { ...ProvidersInitialState },
  listDataChange: false,
  listData: [],
  recentlyAdded: [],
  providerFilesChange: false,
  providerMessagesChange: false,
  loader: false,
  ProviderType: [],
  ProviderSpecialty: [],
  checklistCredentialing: [],
  checkListPaperWork: [],
  checkListTraining: [],
  inactiveListData: [],
  dataUpdate: false,
  utilizationProviderData: [],
};

const providerListSlice = createSlice({
  name: "providersList",
  initialState: initialState,
  reducers: {
    dataUpdated: (state, action) => {
      state.dataUpdate = action.payload.dataUpdate;
    },
    setListDataChange: (state, action: PayloadAction<null>) => {
      return {
        ...state,
        listDataChange: !state.listDataChange,
      };
    },
    setSingleProviderData: (state, action: PayloadAction<ProviderForms>) => {
      return {
        ...state,
        singleProvider: action.payload,
      };
    },
    setListData: (state, action: PayloadAction<null>) => {
      state.listData = [];
      return state;
    },
    setProviderFilesDataChange: (state, action: PayloadAction<null>) => {
      state.providerFilesChange = !state.providerFilesChange;
      return state;
    },
    setProviderMessagesChange: (state, action: PayloadAction<null>) => {
      state.providerMessagesChange = !state.providerMessagesChange;
      return state;
    },
    setLoader: (state, action) => {
      return {
        ...state,
        loader: !state.loader,
      };
    },
    clearSingleProvder: (state) => {
      return {
        ...state,
        singleProvider: ProvidersInitialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      GetAllProviders.fulfilled,
      (state, action: PayloadAction<ProviderForms[] | undefined>) => {
        if (action.payload) {
          const reversedList = ReverseList<ProviderForms>(action.payload);

          const list = reversedList.sort(
            (a: ProviderForms, b: ProviderForms) => {
              return (
                (new Date(b.employmentDetails.createdAt) as any) -
                (new Date(a.employmentDetails.createdAt) as any)
              );
            }
          );

          return {
            ...state,
            listData: list,
          };
        }
        return state;
      }
    );
    builder.addCase(GetInactiveProviders.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const reversedList = ReverseList<ProviderForms>(action.payload);

        const list = reversedList.sort((a: ProviderForms, b: ProviderForms) => {
          return (
            (new Date(b.employmentDetails?.createdAt) as any) -
            (new Date(a.employmentDetails?.createdAt) as any)
          );
        });

        return {
          ...state,
          inactiveListData: list,
        };
      }
      return state;
    });
    builder.addCase(GetProviderById.pending, (state, action) => {});
    builder.addCase(GetProviderById.fulfilled, (state, action) => {
      return {
        ...state,
        singleProvider: {
          ...(action.payload as ProviderForms),
        },
      };
    });
    builder.addCase(GetProviderById.rejected, (state, action) => {});
    builder.addCase(GetProviderTypes.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          ProviderType: action.payload,
        };
      }
    });
    builder.addCase(GetProviderSpecialty.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          ProviderSpecialty: action.payload,
        };
      }
    });
    builder.addCase(GetUtilizationProviders.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          utilizationProviderData: action.payload.provider,
        };
      }
    });
    //   builder.addCase(GetChecklistCredentialing.fulfilled, (state, action)=>{
    //     if(action.payload !== undefined) {
    //         return {
    //             ...state,
    //             checklistCredentialing:action.payload
    //         }
    //     }
    // })
    // builder.addCase(getAllChecklistPaperWork.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     checkListPaperWork: action.payload,
    //   };
    // });

    // builder.addCase(getAllChecklistTraining.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     checkListTraining: action.payload,
    //   };
    // });
  },
});

export const GetProviderById = createAsyncThunk(
  "providersList/GetProviderById",
  async ({ providerId }: { providerId: string }, { dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { data, status } = await ProviderService.GetProviderById(
        providerId
      );
      if (status) {
        dispatch(setProviderData(data));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetAllProviders = createAsyncThunk(
  "providersList/GetAllProviders",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    dispatch(setLoader({}));
    try {
      const { data, status } = await ProviderService.GetAllProvidersEndpoint();
      if (status) {
        dispatch(setLoader({}));
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getProviderFilesById = createAsyncThunk(
  "providerListSlice/getProviderFilesById",
  async ({ providerId }: { providerId: string }, { dispatch }) => {
    try {
      const data = await CommonService.getFilesByEntityId(providerId);
      if (data.status) {
        dispatch(setProviderFilesFromResponse(data.data));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getProviderMessagesById = createAsyncThunk(
  "providerListSlice/getProviderMessagesById",
  async ({ providerId }: { providerId: string }, { dispatch }) => {
    try {
      const { status, data } = await ProviderService.getProviderMessagesById(
        providerId
      );
      if (status) {
        dispatch(setProviderMessages(data));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const postProviderMessageById = createAsyncThunk(
  "providerListSlice/postProviderMessageByID",
  async (
    {
      refetch,
      message,
      providerId,
    }: { refetch?: boolean; message: string; providerId: string },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message: responseMessage } =
        await ProviderService.postProviderMessageById(message, providerId);
      if (status) {
        if (refetch) {
          dispatch(getProviderMessagesById({ providerId }));
        } else {
          dispatch(setProviderMessagesChange(null));
        }
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: responseMessage }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const EditProviderMessageById = createAsyncThunk(
  "providerListSlice/EditProviderMessageById",
  async (
    {
      providerId,
      selectedMessage,
      commentId,
    }: { providerId?: string; selectedMessage: string; commentId: string },
    { dispatch }
  ) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { status, message: responseMessage } =
        await ProviderService.updateProviderMessageById(
          selectedMessage,
          commentId
        );
      if (status) {
        if (providerId) {
          dispatch(getProviderMessagesById({ providerId }));
        } else {
          dispatch(setProviderMessagesChange(null));
        }
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: responseMessage }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteProviderMessageById = createAsyncThunk(
  "providerListSlice/deleteProviderMessageById",
  async (
    { providerId, commentId }: { providerId?: string; commentId: string },
    { getState, dispatch }
  ) => {
    try {
      const { status, message: responseMessage } =
        await ProviderService.deleteProviderMessageById(commentId);
      if (status) {
        if (providerId) {
          dispatch(getProviderMessagesById({ providerId }));
        } else {
          dispatch(setProviderMessagesChange(null));
        }
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: responseMessage }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetProviderTypes = createAsyncThunk(
  "/GetProviderTypes",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await DataCatalogService.GetAllProviderTypes();
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetProviderSpecialty = createAsyncThunk(
  "/GetProviderSpecialty",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await DataCatalogService.GetAllProviderSpecialty();
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//get in active providers
export const GetInactiveProviders = createAsyncThunk(
  "/GetInactiveProviders",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ProviderService.getInactiveProviders();
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

// //Get All Checklist Credentialing
// export const GetChecklistCredentialing = createAsyncThunk(
//   "/GetChecklistCredentialing",
//   async (_,{dispatch}) => {
//       try{
//           dispatch(setResponseValue({name: "pending", value: true }));
//           const {data}= await DataCatalogService.GetAllChecklistCredentialing();
//           return data;
//       } catch (e: any) {
//           //do something if needed
//         } finally {
//           dispatch(setResponseValue({ name: "pending", value: false }));
//         }
//   }
// )

// //Get All Checklist PaperWork
// export const getAllChecklistPaperWork = createAsyncThunk(
//   "/getChecklistPaperwoRK",
//   async (_, { dispatch }) => {
//     try {
//       dispatch(setResponseValue({ name: "pending", value: true }));
//       const { data } = await GetAllChecklistPaperWork();
//       return data;
//     } catch (e: any) {
//       //do something if needed
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   }
// );

// //Get All Checklist PaperWork
// export const getAllChecklistTraining = createAsyncThunk(
//   "/getChecklistTraining",
//   async (_, { dispatch }) => {
//     try {
//       dispatch(setResponseValue({ name: "pending", value: true }));
//       const { data } = await GetAllChecklistTraining();
//       return data;
//     } catch (e: any) {
//       //do something if needed
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   }
// );

//get utilization provider filter data
export const GetUtilizationProviders = createAsyncThunk(
  "/getUtilizationProviders",
  async (
    {
      startDate,
      endDate,
      isList,
      listData,
    }: {
      startDate: string;
      endDate: string;
      isList: boolean;
      listData: MatchedProvider[];
    },
    { dispatch, getState }
  ) => {
    let { providerListSlice } = getState() as {
      providerListSlice: { listData: ProviderForms[]; dataUpdate: boolean };
    };

    const payloadData = {
      startDate,
      endDate,
      clientIds: [],
      providerIds: isList
        ? listData.map((ele) => ele.employmentDetails.id)
        : providerListSlice.listData.map(
            (provider) => provider.employmentDetails.id
          ),
    };

    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const data = await CommonService.postFilteredUtilzation(payloadData);
      dispatch(dataUpdated({ dataUpdate: true }));
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const {
  setListDataChange,
  setSingleProviderData,
  setListData,
  setProviderMessagesChange,
  setProviderFilesDataChange,
  setLoader,
  dataUpdated,
  clearSingleProvder,
} = providerListSlice.actions;

export default providerListSlice;
