import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  MenuProps,
  EmploymentStatus,
  filterProviderStatuses,
  filterAvailabilityTypes,
  filterOnTrackChangeTypes,
  EntityScheduleStatus,
} from "../../constants/providers";
import { IcheckListTraining, ProviderFilters } from "../../types/provider";
import { OrgBU } from "../../types/company";
import { InsuranceProvider } from "../../types/response";
import { getBussinessName } from "../../constants/common";
import { ProviderType } from "../../types/schedule";
import {
  GetProviderTypes,
  GetUtilizationProviders,
  dataUpdated,
  // getAllChecklistTraining,
} from "../../redux/features/providers-main/provider-list-slice";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getInactiveProviders } from "../../service/provider.service";

interface ProviderFilterProps {
  closeModal: (val: boolean, filterObject: ProviderFilters) => void;
}

const ProviderFilter: React.FC<ProviderFilterProps> = ({ closeModal }) => {
  // #region variables region

  const dispatch = useDispatch<AppDispatch>();

  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //gets the selected filter values from redux
  const [filterValues, setFilterValues] = useState<ProviderFilters>(
    useSelector<RootState, ProviderFilters>(
      (state) => state.providerFilterSlice
    )
  );

  //insurance providers
  const insuranceProviders = useSelector<RootState, InsuranceProvider[]>(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  //Provider dropdown list
  const ProviderTypeList = useSelector<RootState, ProviderType[]>(
    (state) => state.providerListSlice.ProviderType
  );

  //stores client Checklist training
  const ProviderCheckList = useSelector<RootState, IcheckListTraining[]>(
    (state) => state.providerListSlice.checkListTraining
  );

  // Store startDate
  const [startDate, setStartDate] = useState<Dayjs | any | null>(null);

  // store enddate
  const [endDate, setEndDate] = useState<Dayjs | any | null>(null);

  //stores error messages
  const [error, setError] = useState<string>("");

  // #endregion

  // #region methods region

  //handles closing the filter Modal and calling the filter method
  const handleFilterValues = (item: {
    name: string;
    value: string | string[];
  }): void => {
    const obj = {
      ...filterValues,
      [item.name]: item.value,
    };
    setFilterValues({ ...obj });
  };

  // //handles in-active providers
  // const filterInactiveProviders = async () => {
  //   await getInactiveProviders();
  // };

  //handle feild data
  const handleChange = (item: {
    name: string;
    value: string[] | string;
  }): void => {
    const obj = {
      ...filterValues,
      [item.name]: item.value,
    };
    setFilterValues({ ...obj });
  };

  //handle onBoardChange
  const handleBoardChange = (e: any) => {
    if (
      !e.target.value.includes("All Stages") &&
      filterValues.onBoardingStage.includes("All Stages")
    ) {
      setFilterValues({ ...filterValues, onBoardingStage: [] });
    } else if (
      e.target.value.includes("All Stages") &&
      !filterValues.onBoardingStage.includes("All Stages")
    ) {
      setFilterValues({
        ...filterValues,
        onBoardingStage: [...filterOnTrackChangeTypes, "All Stages"],
      });
    } else if (
      e.target.value.includes("All Stages") &&
      e.target.value.length === filterOnTrackChangeTypes.length
    ) {
      setFilterValues({
        ...filterValues,
        onBoardingStage: e.target.value.filter((e: any) => e !== "All Stages"),
      });
    } else if (e.target.value.length === filterOnTrackChangeTypes.length) {
      setFilterValues({
        ...filterValues,
        onBoardingStage: [...filterOnTrackChangeTypes, "All Stages"],
      });
    } else {
      handleFilterValues({
        name: "onBoardingStage",
        value: e.target.value,
      });
    }
  };

  //hnadle checklist onChange
  // const handleChecklist =(e:any)=>{

  //   if (!e.target.value.includes("All Tasks") && filterValues.checkList.includes("All Tasks")) {
  //     setFilterValues({ ...filterValues, checkList: [] })
  //   }
  //    else if (e.target.value.includes("All Tasks") && !filterValues.checkList.includes("All Tasks")) {
  //     setFilterValues({ ...filterValues, checkList: [...ProviderCheckList, "All Tasks"] })
  //   }
  //   else if (e.target.value.includes("All Tasks") && e.target.value.length === ProviderCheckList.length) {
  //     setFilterValues({ ...filterValues, checkList: e.target.value.filter((e:any) => e !== "All Tasks") })
  //   }
  //   else if (e.target.value.length === ProviderCheckList.length ){
  //     setFilterValues({ ...filterValues, checkList: [...ProviderCheckList, "All Tasks"] })
  //   }
  //   else {
  //     handleFilterValues({
  //       name: "checkList",
  //       value: e.target.value,
  //     });
  //   }
  // }

  //handle appointment start date
  const handleStartDate = (newValue: Dayjs | null) => {
    setError("");
    setStartDate(newValue);
    setFilterValues((prevState) => {
      return {
        ...prevState,
        // startDate: dayjs(newValue).format("MM/DD/YYYY"),
        startDate: newValue ? dayjs(newValue).format("MM/DD/YYYY") : null,
      };
    });
  };

  //handle appointment end date
  const handleEndDate = (newValue: Dayjs | null) => {
    setError("");
    setEndDate(newValue);
    setFilterValues((prevState) => {
      return {
        ...prevState,
        //  startDate: dayjs(startDate).format("MM/DD/YYYY"),
        // endDate: dayjs(newValue).format("MM/DD/YYYY"),
        endDate: newValue ? dayjs(newValue).format("MM/DD/YYYY") : null,
      };
    });
  };

  // #endregion

  // #region useEffect region
  useEffect(() => {
    dispatch(GetProviderTypes());
    // dispatch(getAllChecklistTraining());
  }, []);
  // #endregion

  return (
    <React.Fragment>
      <div className="stepperForm__inner p-30px">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Business Unit</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.businessUnit}
                  onChange={(e) => {
                    handleChange({
                      name: "businessUnit",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected: any) => {
                    selected = selected.map((select: any) => {
                      return getBussinessName(select, businessUnits);
                    });
                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                >
                  {businessUnits.map((unit, index) => (
                    <MenuItem key={index} value={unit.id}>
                      <Checkbox
                        checked={
                          filterValues.businessUnit.indexOf(unit.id) > -1
                        }
                      />
                      <ListItemText primary={unit.businessUnitName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Insurance Providers</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.insuranceProvider}
                  onChange={(e) => {
                    handleChange({
                      name: "insuranceProvider",
                      value: e.target.value,
                    });
                  }}
                  //disabled={filterValues.onboardingStatus === "Inactive"}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {insuranceProviders.map((unit, index) => (
                    <MenuItem key={index} value={unit.insuranceProviderName}>
                      <Checkbox
                        checked={
                          filterValues.insuranceProvider.indexOf(
                            unit.insuranceProviderName
                          ) > -1
                        }
                      />
                      <ListItemText primary={unit.insuranceProviderName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Provider Type</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.providerType}
                  onChange={(e) => {
                    handleChange({
                      name: "providerType",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {ProviderTypeList.map((unit, index) => (
                    <MenuItem key={index} value={unit.conceptValue}>
                      <Checkbox
                        checked={
                          filterValues.providerType.indexOf(unit.conceptValue) >
                          -1
                        }
                      />
                      <ListItemText primary={unit.conceptValue} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Availability Status</InputLabel>
              <div className="fromGroup-chips">
                {filterAvailabilityTypes.map((item: string, index: number) => (
                  <Card
                    className={
                      filterValues.availabilityStatus === item
                        ? "fromGroup-chip-active"
                        : ""
                    }
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      if (filterValues.availabilityStatus === item) {
                        handleFilterValues({
                          name: "availabilityStatus",
                          value: "",
                        });
                      } else {
                        handleFilterValues({
                          name: "availabilityStatus",
                          value: item,
                        });
                      }
                    }}
                    key={item}
                  >
                    {item}
                  </Card>
                ))}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Onboarding Status</InputLabel>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filterValues.onboardingStatus}
                  onChange={(e) => {
                    handleChange({
                      name: "onboardingStatus",
                      value: e.target.value,
                    });
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {filterProviderStatuses.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      <Checkbox
                        checked={
                          filterValues.onboardingStatus.indexOf(unit) > -1
                        }
                      />
                      <ListItemText primary={unit} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Onboarding Stage
              </InputLabel>
              <FormControl>
                <Select
                  multiple
                  className="form-control"
                  value={filterValues.onBoardingStage}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  onChange={handleBoardChange}
                >
                  <MenuItem value={"All Stages"}>
                    <Checkbox
                      checked={
                        filterValues.onBoardingStage.indexOf("All Stages") > -1
                      }
                    />
                    {"All Stages"}
                  </MenuItem>
                  {filterOnTrackChangeTypes.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={
                            filterValues.onBoardingStage.indexOf(item) > -1
                          }
                        />
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <Box className="fromGroup">
              <InputLabel id="demo-simple-select-label" className="fromLabel">
                Checklist
              </InputLabel>
              <FormControl>
                <Select
                  multiple
                  className="form-control"
                  value={filterValues.checkList}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    handleChange({
                      name: "checkList",
                      value: e.target.value,
                    });
                  }}
                >
                  {ProviderCheckList.map((unit, index) => (
                    <MenuItem key={index} value={unit.conceptValue}>
                      <Checkbox
                        checked={
                          filterValues.checkList.indexOf(unit.conceptValue) > -1
                        }
                      />
                      <ListItemText primary={unit.conceptValue} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <InputLabel className="fromLabel">Appointment Date</InputLabel>
            {/* 
            <Box
              className="formGroup datePicker"
              style={{ position: "relative" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    value={`${filterValues.startDate}${" - "}${
                      filterValues.endDate
                    }`}
                    onClick={() => setOpenRangePicker(!isOpenRangePicker)}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={filterValues.startDate}
                    onClick={() => setOpenRangePicker(!isOpenRangePicker)}
                  ></TextField>{" "}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={filterValues.endDate}
                    onClick={() => setOpenRangePicker(!isOpenRangePicker)}
                  ></TextField>
                </Grid>
                {isOpenRangePicker ? (
                  <DateRange
                    ranges={[
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: "selection",
                      },
                    ]}
                    onChange={handleSelect}
                    months={2}
                    minDate={new Date()}
                    direction="horizontal"
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Box> */}
            <Box
              className="formGroup datePicker"
              style={{ position: "relative" }}
            >
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <DatePicker
                        // disabled={filterValues.onboardingStatus === "Inactive"}
                        className="singleDatePicker"
                        label="Start Date"
                        value={filterValues.startDate}
                        onChange={handleStartDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        // disabled={filterValues.onboardingStatus === "Inactive"}
                        className="singleDatePicker"
                        label="end Date"
                        value={filterValues.endDate}
                        onChange={handleEndDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Box>
            {error && <p style={{ color: "#d32f2f" }}>{error}</p>}
          </Grid>
          <Grid item xs={12} md={12}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">Schedule Status</InputLabel>
              <div className="fromGroup-chips">
                {Object.values(EntityScheduleStatus).map((item: string, index: number) => (
                  <Card
                    className={
                      filterValues.ScheduleStatus === item
                        ? "fromGroup-chip-active"
                        : ""
                    }
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      if (filterValues.ScheduleStatus === item) {
                        handleFilterValues({
                          name: "ScheduleStatus",
                          value: "",
                        });
                      } else {
                        handleFilterValues({
                          name: "ScheduleStatus",
                          value: item,
                        });
                      }
                    }}
                    key={item}
                  >
                    {item}
                  </Card>
                ))}
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      {/* {filterValues.onboardingStatus === "Inactive" && (
        <div
          style={{
            background: "#e9f3fb",
            display: "flex",
            justifyContent: "center",
            color: "#d32f2f",
            paddingLeft: "20px",
            height: "50px",
          }}
        >
          <h5 style={{ margin: "15px" }}>
            You can filter [ Business Unit, Insurance Providers, Provider Type,
            Employment Status, Onboarding Stage, Checklist ] Only
          </h5>
        </div>
      )} */}

      <Box className="stepperForm__footer b-0">
        <Button
          color="inherit"
          className="border-button"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            setFilterValues({
              businessUnit: [],
              insuranceProvider: [],
              providerType: [],
              employmentStatus: "",
              onboardingStatus: [],
              availabilityStatus: "",
              onBoardingStage: [],
              checkList: [],
              ScheduleStatus: "",
              startDate: null,
              endDate: null,
            });
            dispatch(dataUpdated({ dataUpdate: false }));
          }}
        >
          Clear Filter
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            const isStartDateValid =
              !startDate || dayjs(startDate, "MM/DD/YYYY", true).isValid();
            const isEndDateValid =
              !endDate || dayjs(endDate, "MM/DD/YYYY", true).isValid();

            if (
              (!startDate && endDate) ||
              (!endDate && startDate) ||
              (startDate && !isStartDateValid) ||
              (endDate && !isEndDateValid)
            ) {
              e.preventDefault();
              setError("Please enter valid dates.");
            } else {
              e.preventDefault();
              setError("");
              closeModal(false, { ...filterValues });
            }
            if (filterValues.startDate && filterValues.endDate) {
              dispatch(
                GetUtilizationProviders({
                  startDate: filterValues.startDate,
                  endDate: filterValues.endDate,
                  isList:false,
                  listData :[]
                })
              );
            }
          }}
          className="button"
        >
          Save Filters
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default ProviderFilter;
