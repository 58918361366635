import { Box, Card, CardMedia, Chip, Button, Typography } from "@mui/material";

import { useAppDispatch } from "../../redux/store";
import { DisconnectApp } from "../../redux/features/integration-setting/integration-setting-slice";
import { useState } from "react";
import DeleteConfirmation from "../../layouts/delete-confirmation/DeleteConfirmation";

interface AppCardProps {
  appName: string;
  logo: string;
  connected: boolean;
  onClick: () => void;
}

const AppCard = ({ appName, logo, connected, onClick }: AppCardProps) => {
  const dispatch = useAppDispatch();
  const [disConnectApp, setDisconnectApp] = useState<boolean>(false);
  const handleDisconnect = () => {
    dispatch(DisconnectApp({ appName }));
  };

  return (
    <Card
      className="cardBox"
      sx={{
        maxWidth: "1024px",
        display: "flex",
        flexDirection: {
          md: "row",
          xs: "column",
        },
        alignItems: "center",
        gap: "36px",
        padding: "16px 32px",
      }}
    >
      <CardMedia
        component={"img"}
        image={logo || ""}
        sx={{ maxWidth: "324px" }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Chip
          color={connected ? "success" : "default"}
          label={connected ? "Live" : "No setup"}
          sx={{ width: "80px", borderRadius: "4px" }}
        />
        <Typography
          sx={{ lineHeight: "32px !important", fontSize: "18px" }}
        >{`Connect your ABAEngine standard events like Clients, Employees, and Schedules events to ${appName} account, so that Created, Updated, Deleted events and data can be synced to ${appName} system. `}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            width: "fit-content",
            alignSelf: "end",
          }}
        >
          {/* <Button
            sx={{ display: "flex", alignItems: "center", gap: "4px" }}
            onClick={onClick}
          >
            <Settings />
            Configure
          </Button> */}
          {connected ? (
            <Button onClick={() => setDisconnectApp(true)} variant="outlined">
              Dis-Connect
            </Button>
          ) : (
            <Button onClick={onClick} variant="contained">
              Connect
            </Button>
          )}
        </Box>
      </Box>
      {disConnectApp && (
        <DeleteConfirmation
          name={""}
          title="Are you sure you want to Dis-Connect ?"
          setModal={(value) => {
            if (value) {
              handleDisconnect();
            }
            setDisconnectApp(false);
          }}
        />
      )}
    </Card>
  );
};

export default AppCard;
