import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from "../../types/auth";

// Initial State
export interface AuthInitState {
  // token: string | null;
  sideMenuSlider: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: string;
  loginResponse: LoginResponse;
}

const initialState: AuthInitState = {
  // token: localStorage.getItem("eggtoken"),
  sideMenuSlider: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: "",
  loginResponse: {
    nickname: "",
    name: "",
    picture: "",
    email: "",
    email_verified: false,
    sub: "",
    updated_at: "",
  },
};

const authSlice = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = "";
      state.isSuccess = false;
      state.isError = false;
      state.isFetching = false;
    },
    setNickname: (state, action) => {
      if (state.loginResponse !== null) {
        state.loginResponse.nickname = action.payload;
        state.isSuccess = true;
        state.isFetching = false;
        state.isError = false;
      }
    },
    setName: (state, action) => {
      if (state.loginResponse !== null)
        state.loginResponse.name = action.payload;
    },
    setPicture: (state, action) => {
      if (state.loginResponse !== null)
        state.loginResponse.picture = action.payload;
    },
    setEmail: (state, action) => {
      if (state.loginResponse !== null)
        state.loginResponse.email = action.payload;
    },
    setEmail_verified: (state, action) => {
      if (state.loginResponse !== null)
        state.loginResponse.email_verified = action.payload;
    },
    setSub: (state, action) => {
      if (state.loginResponse !== null)
        state.loginResponse.sub = action.payload;
    },
    setUpdated_at: (state, action) => {
      if (state.loginResponse !== null)
        state.loginResponse.updated_at = action.payload;
    },
    setSideMenuSlider: (state, action: PayloadAction<boolean>) => {
      state.sideMenuSlider = action.payload;
    },
  },

  extraReducers: (builder) => {},
});

export const {
  clearState,
  setNickname,
  setName,
  setPicture,
  setEmail,
  setEmail_verified,
  setSub,
  setUpdated_at,
  setSideMenuSlider,
} = authSlice.actions;
export default authSlice;
