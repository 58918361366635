import moment from "moment";
import { ClientForms, InsuranceProviders } from "../../types/client";
import { BookedSchedule, ScheduleFilters } from "../../types/schedule";
import { searchClientText, searchProviderText } from "../../utils/FilterUtils";
import { ProviderForms } from "../../types/provider";
import { AvailabilityDetails } from "../../types/availability";
import { EntityScheduleStatus } from "../../constants/providers";

//checks if any value is selected filter filter or search
export const canFilterSchedules = (
  filterValues: ScheduleFilters,
  searchVal: string
): boolean => {
  if (
    filterValues.clientStatus.length > 0 ||
    filterValues.providerStatus.length > 0 ||
    filterValues.businessUnit.length > 0 ||
    filterValues.availabilityStatus ||
    filterValues.insuranceProvider.length > 0 ||
    filterValues.intakeStage.length > 0 ||
    filterValues.concerns.length > 0 ||
    filterValues.ScheduleStatus ||
    filterValues.startDate && filterValues.endDate ||
    // filterValues.age !== null ||
    searchVal
  ) {
    return true;
  }
  return false;
};

//return evaluate schedule array
export const evaluateSchedulerData = (
  item: ClientForms | ProviderForms,
  filterValues: { startDate: string | null; endDate: string | null }
) => {
  let arr: AvailabilityDetails[] = [];
  item.availabilityDetails.filter((item: AvailabilityDetails) => {
    return filterSingleScheduleListItem(item, filterValues) && arr.push(item);
  });
  return arr;
};

//logic for filtering a single provider and Client
export const filterSingleScheduleListItem = (
  item: AvailabilityDetails,
  filterValues: { startDate: string | null; endDate: string | null }
): boolean => {
  if (filterValues.startDate && filterValues.endDate) {
    if (
      new Date(moment(filterValues.endDate).format("YYYY-MM-DD")) >=
      new Date(moment(item.endDate).format("YYYY-MM-DD"))
    ) {
      if (
        new Date(moment(item.endDate).format("YYYY-MM-DD")) >=
        new Date(moment(filterValues.startDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        new Date(moment(item.beginDate).format("YYYY-MM-DD")) <=
        new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
};

//logic for filtering a single provider and Client
export const filterSingleClient = (
  item: ClientForms,
  filterValues: ScheduleFilters,
  searchVal: string
): boolean => {
  var startDate =
    item.availabilityDetails && item.availabilityDetails.length > 0
      ? new Date(item.availabilityDetails[0]?.beginDate as string)
      : null;
  var endDate =
    item.availabilityDetails && item.availabilityDetails.length > 0
      ? new Date(
          item.availabilityDetails[item.availabilityDetails.length - 1]
            ?.endDate as string
        )
      : null;

  var today = new Date();

  const searchText = searchClientText(searchVal, item);
  if (!searchText) {
    return false;
  }

  if (item.clientBasicDetails) {
    if (
      !filterValues.businessUnit.includes("All Units") &&
      filterValues.businessUnit.length > 0 &&
      !filterValues.businessUnit.some((ele) =>
        item.clientBasicDetails.businessUnit.includes(ele)
      )
    ) {
      return false;
    }
    if (filterValues.clientStatus.length > 0) {
      if (
        !filterValues.clientStatus.includes(item.clientBasicDetails.clientType)
      ) {
        return false;
      }
    }
    if (
      filterValues.availabilityStatus === "Current" &&
      startDate &&
      endDate &&
      !(today >= startDate && today <= endDate)
    ) {
      return false;
    } else if (
      filterValues.availabilityStatus === "Expired" &&
      endDate &&
      !(today > endDate)
    ) {
      return false;
    }

    if (
      filterValues.intakeStage.length > 0 &&
      !filterValues.intakeStage.includes("All Stages")
    ) {
      if (
        !filterValues.intakeStage.includes(item.clientBasicDetails.intakeStage)
      ) {
        return false;
      }
    }
    if (
      !filterValues.concerns.includes("All Concerns") &&
      filterValues.concerns.length > 0
    ) {
      if (
        filterValues.concerns &&
        !item.clientBasicDetails.checklistHistory.includes(
          filterValues.concerns[0]
        )
      ) {
        return false;
      }
    }
  }
  if (
    !filterValues.insuranceProvider.includes("All Employees") &&
    filterValues.insuranceProvider.length > 0
  ) {
    let count = 0;
    item.clientInsurance.forEach((item: InsuranceProviders) => {
      if (filterValues.insuranceProvider.includes(item.insuranceProviderName)) {
        count++;
      }
    });
    if (count === 0) {
      return false;
    }
  }
  // if (filterValues.ScheduleStatus) {
  //   if (item.clientUtilization.status === filterValues.ScheduleStatus) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  if (filterValues.ScheduleStatus) {
    if (filterValues.ScheduleStatus === EntityScheduleStatus.NoAvailability ||
      filterValues.ScheduleStatus === EntityScheduleStatus.AvailabilityExpired) {
        //read from clientUtilization
        if (item.clientUtilization.status === filterValues.ScheduleStatus) {
          return true;
        } else {
          return false;
        }
    } else {
      //read from clientAuthUtilization
      if (item.clientAuthUtilization.status === filterValues.ScheduleStatus) {
        return true;
      } else {
        return false;
      }
    }
  }
  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  return true;
};
//logic for filtering a single provider and Client
export const filterSingleProvider = (
  item: ProviderForms,
  filterValues: ScheduleFilters,
  searchVal: string
): boolean => {
  const searchText = searchProviderText(searchVal, item);
  if (!searchText) {
    return false;
  }

  if (
    filterValues.insuranceProvider.find((item) => item === "All Employees") !==
      "All Employees" &&
    filterValues.insuranceProvider.length
  ) {
    let count = 0;
    item.providerInsurance.forEach((item) => {
      if (filterValues.insuranceProvider.find((unit) => unit === item.name)) {
        count++;
      }
    });
    if (count === 0) {
      return false;
    }
  }
  if (
    filterValues.businessUnit.filter((item) => item !== "All Units") &&
    filterValues.businessUnit.length &&
    !filterValues.businessUnit.some((ele) =>
      item.employmentDetails.businessUnit.includes(ele)
    )
  ) {
    return false;
  }
  if (filterValues.providerStatus.length > 0) {
    if (
      !filterValues.providerStatus.includes(item.employmentDetails.providerType)
    ) {
      return false;
    }
  }

  let onBoardingFlag = true;
  while (onBoardingFlag) {
    if (filterValues.onBoardingStage.length > 0) {
      if (filterValues.onBoardingStage.includes("All Stages")) {
        onBoardingFlag = false;
      } else {
        let count = 0;
        filterValues.onBoardingStage.forEach((matchValue: string) => {
          if (item.employmentDetails.onBoardingStage === matchValue) {
            count++;
          }
        });
        if (count === 0) {
          return false;
        }
        onBoardingFlag = false;
      }
    }
    onBoardingFlag = false;
  }

  if (filterValues.ScheduleStatus) {
    if (item.providerUtilization.status === filterValues.ScheduleStatus) {
      return true;
    } else {
      return false;
    }
  }
  if (item.availabilityDetails?.length > 0) {
    if (filterValues.startDate && filterValues.endDate) {
      if (evaluateSchedulerData(item, filterValues).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  return true;
};

//return if an object is provider
function instanceOfA(object: any): object is ProviderForms {
  if (object) return "employmentDetails" in object;
  else return false;
}
export const filterSchedules = <T>(
  filterValues: ScheduleFilters,
  searchVal: string,
  data: T[]
): T[] => {
  return canFilterSchedules(filterValues, searchVal)
    ? data.filter((item: T) => {
        if (instanceOfA(item)) {
          return filterSingleProvider(
            item as ProviderForms,
            filterValues,
            searchVal
          );
        } else {
          return filterSingleClient(
            item as ClientForms,
            filterValues,
            searchVal
          );
        }
      })
    : data;
};

//logic for handling the search and filter
// export const filterSchedules = (
//   filterValues: ScheduleFilters,
//   searchVal: string,
//   data: ClientForms[]
// ): ClientForms[] => {
//   return canFilterSchedules(filterValues, searchVal)
//     ? data.filter((item: ClientForms) => {
//         return filterSingleSchedule(item, filterValues, searchVal);
//       })
//     : data;
// };

// //checks if any value is selected filter filter or search
// export const canFilterScheduleList = (filterValues: {
//   startDate: string | null;
//   endDate: string | null;
// }): boolean => {
//   if (filterValues.startDate && filterValues.endDate) {
//     return true;
//   }
//   return false;
// };

// //logic for filtering a single provider and Client
// export const filterSingleScheduleListItem = (
//   item: BookedSchedule,
//   filterValues: { startDate: string | null; endDate: string | null }
// ): boolean => {
//   if (filterValues.startDate && filterValues.endDate) {
//     if (
//       new Date(moment(filterValues.startDate).format("YYYY-MM-DD")) <=
//         new Date(item.specificDate) &&
//       new Date(item.specificDate) <=
//         new Date(moment(filterValues.endDate).format("YYYY-MM-DD"))
//     ) {
//       return true;
//     }
//   }

//   return false;
// };

// //logic for handling the search and filter
// export const filterSchedulesList = (
//   filterValues: { startDate: string | null; endDate: string | null },
//   data: BookedSchedule[]
// ): BookedSchedule[] => {
//   return canFilterScheduleList(filterValues)
//     ? data.filter((item: BookedSchedule) => {
//         return filterSingleScheduleListItem(item, filterValues);
//       })
//     : data;
// };
